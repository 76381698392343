<template>
  <div>
    <layout v-if="isLoggedIn">
      <transition name="fade" mode="out-in">
        <router-view class="py-6" />
      </transition>
    </layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/check',
    }),
  },
}
</script>

<style src="../assets/css/app.css" />
