import Vue from 'vue'
import App from './components/App.vue'

import Layout from './layouts/Layout'

import router from './router'
import store from './store'

import './filters'
import './plugins'
import './components/global'

Vue.component('layout', Layout)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
