<template>
  <div>
    <!-- Button Back -->
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'bestsellers' }">
        <i class="fas fa-long-arrow-alt-left mr-2" />Back to bestsellers</router-link
      >
    </button>
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">
          Edit bestseller
        </h2>
      </div>
    </div>
    <!-- Content -->
    <div class="mt-8">
      <div class="rounded-md bg-white p-5">
        <BestsellersForm
          v-model="form"
          v-if="bestseller"
          :bestseller="bestseller"
          v-on:submit="submit"
          v-on:reordered="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { find, get, debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import HasErrors from '~/mixins/HasErrors'
import BestsellersForm from '~/components/bestsellers/Form'
export default {
  components: {
    BestsellersForm,
  },
  mixins: [HasErrors],
  mounted() {
    this.load()
  },
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapGetters({
      bestsellerById: 'bestsellers/byId',
    }),
    bestsellerId() {
      return this.$route.params.id
    },
    bestseller() {
      return this.bestsellerById(this.bestsellerId)
    },
  },
  methods: {
    ...mapActions({
      updateBestseller: 'bestsellers/update',
      fetchById: 'bestsellers/fetchById',
    }),
    load() {
      this.fetchById(this.bestsellerId).then(response => {
        this.setForm(response.data)
      })
    },
    setForm(data) {
      this.form = Object.assign({}, _.omit(this.bestseller, ['image', 'mobile_image']))
    },
    submit(wrapSubmit) {
      wrapSubmit(this.updateBestseller({ id: this.bestsellerId, form: this.form }))
        .then(data => {
          this.successMessage('Bestsellers updated')
        })
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
  },
}
</script>
