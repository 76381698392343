import { schema } from 'normalizr'
import _ from 'lodash'

const processStrategy = holiday => {
  const relations = {
    delivery_conceptions: _.get(holiday, 'delivery_conceptions.data'),
  }

  return { ...holiday, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'holidays',
  {},
  {
    processStrategy,
  }
)
