import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  updateProductionCycle(store, { id, params }) {
    return axios
      .put(`/api/shipping/admin/users/${id}/profile/production-cycles`, params)
      .then(res => {
        processEntities(res.data.data, 'addresses')
        return res.data
      })
  },
  updatePreferredShippingCarrier(store, {id, params}) {
    return axios.post(`api/customers/admin/users/${id}/profile-preferred-carriers`, params)
  },
  resetPreferredShippingCarrier(store, {id, production_cycle_id}) {
    return axios.delete(`api/customers/admin/users/${id}/profile-preferred-carriers/${production_cycle_id}`)
  }
}
