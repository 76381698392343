<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <h4 class="mb-5 font-bold">Exclude from MOV</h4>

    <div v-if="isExcluded">
      <div class="text-sm">
        Customer is
        <span class="inline-block rounded bg-red-300 font-bold text-red-800">excluded</span>
        from minimum order validation and
        <span class="inline-block rounded bg-red-300 text-red-800"
          >can order <strong>5 products</strong> minimum</span
        >.
      </div>

      <div class="mt-5">
        <a
          href="#"
          class="btn rounded-sm bg-green-200 px-2 py-1 text-sm font-bold uppercase tracking-wide shadow hover:bg-green-300 hover:text-green-800"
          :class="{ disabled: loading }"
          @click.prevent="include"
        >
          <i v-if="loading" class="fa fa-spin fa-spinner" />
          <small>Include</small>
        </a>
      </div>
    </div>

    <div v-if="!isExcluded">
      <div class="text-sm">
        Customer is
        <span class="inline-block rounded bg-green-200 font-bold text-green-800">not excluded</span>
        from minimum order validation. The validation works as usual.
      </div>

      <div class="mt-5">
        <a
          href="#"
          class="btn rounded-sm bg-red-200 px-2 py-1 text-sm font-bold uppercase tracking-wide shadow hover:bg-red-300 hover:text-red-800"
          :class="{ disabled: loading }"
          @click.prevent="exclude"
        >
          <i v-if="loading" class="fa fa-spin fa-spinner" />
          <small>Exclude</small>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Confirm from '~/components/swal/Confirm'

export default {
  props: ['user'],
  data: () => ({
    loading: false,
  }),
  computed: {
    isExcluded() {
      return this.user.is_min_plan_exception
    },
  },
  methods: {
    ...mapActions({
      addRequest: 'mov-exclusions/addToExclusions',
      removeRequest: 'mov-exclusions/removeFromExclusions',
      fetchUserById: 'users/fetchById',
    }),
    async fetchUser() {
      const include = [
        'activePromoCodeApplication',
        'compedAccount',
        'promoCodeApplications',
        'defaultAddress',
        'kids',
        'profile',
      ]
      await this.fetchUserById({ id: this.user.id, params: { include } })
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    exclude() {
      if (this.loading) return false

      this.startLoading()

      Confirm.fire({
        text: `Customer ${this.user.email} will be added to the minimum plan validation exclusions. Are you sure?`,
        preConfirm: async () => {
          await this.addRequest(this.user.id)
            .then(async () => {
              this.successMessage('Success')
              await this.fetchUser()
            })
            .catch(error => {
              this.errorMessage(error)
            })
        },
      }).then(async () => {
        this.stopLoading()
      })
    },
    include() {
      if (this.loading) return false

      this.startLoading()

      Confirm.fire({
        text: `Customer ${this.user.email} will be removed from minimum plan validation exclusions. Are you sure?`,
        preConfirm: async () => {
          await this.removeRequest(this.user.id)
            .then(async () => {
              this.successMessage('Success')
              await this.fetchUser()
            })
            .catch(error => {
              this.errorMessage(error)
            })
        },
      }).then(async () => {
        this.stopLoading()
      })
    },
  },
}
</script>
