<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex items-end justify-between">
      <router-link :to="{ name: 'order-bumps' }">
        <button class="btn btn-gray self-start">
          <i class="fas fa-long-arrow-alt-left mr-2" />
          Back To List
        </button>
      </router-link>
    </div>

    <div v-if="!busy && orderBump">
      <h3 class="py-4 text-3xl">Edit {{ orderBump.header }}</h3>

      <order-bump-form v-if="orderBump" v-model="form" :order-bump="orderBump" @submit="submit" />
    </div>

    <loader v-if="busy" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderBumpForm from '~/components/orderBumps/Form'

export default {
  components: {
    OrderBumpForm,
  },

  data: () => ({
    form: {},
    busy: false,
  }),

  computed: {
    ...mapGetters({
      getOrderBumpById: 'order-bumps/byId',
    }),
    id() {
      return parseInt(this.$route.params.id, 10)
    },
    orderBump() {
      return this.getOrderBumpById(this.id)
    },
  },

  async mounted() {
    this.occupy()
    await this.fetchSingle(this.id)
    this.release()
  },

  methods: {
    ...mapActions({
      fetchSingle: 'order-bumps/show',
      update: 'order-bumps/update',
    }),
    async submit(wrapSubmit) {
      wrapSubmit(this.submitRequest(this.id, this.form.data))
    },
    async submitRequest(id, data) {
      try {
        await this.update({ id, data })
      } catch (exception) {
        this.errorMessage('Error', this.$get(exception, 'response.data.message'))
        throw exception
      }

      this.successMessage('Order Bump Updated!')
    },
    occupy() {
      this.busy = true
    },
    release() {
      this.busy = false
    },
  },
}
</script>
