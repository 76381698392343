import * as types from '../mutation-types'
import { addOrUpdateItems } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}
