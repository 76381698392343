import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
export const state = {
  items: [],
}
export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id == id),
}
export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}
export const actions = {
  fetch(store, params) {
    return axios.get('api/sales/admin/upsells', { params }).then(res => {
      processEntities(res.data.data, 'upsells')
      return res.data
    })
  },
  fetchById(store, {id, params}) {
    return axios.get(`api/sales/admin/upsells/${id}`, { params }).then(res => {
      processEntities(res.data.data, 'upsells')
      return res.data
    })
  },
  update(store, { id, form }) {
    return axios
      .post(`/api/sales/admin/upsells/${id}`, {...form})
      .then(res => {
        processEntities(res.data.data, 'upsells')
        return res.data
      })
  },
}
