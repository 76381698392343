<template>
  <div>
    <ValidationMessages class="px-4 py-4 sm:px-0" :errors="allErrors" />
    <div class="">
      <div class="space-y-6 lg:col-span-2">
        <InputGroup label="Name" :error="getError('name')">
          <input type="text" class="form-input w-full" v-model="form.name" />
        </InputGroup>
        <InputGroup label="Slug" :error="getError('slug')">
          <input type="text" class="form-input w-full" help-text="Unique external slug" v-model="form.slug" />
        </InputGroup>
        <InputGroup label="External Carrier" :error="getError('external_carrier')">
          <select class="form-input w-full" v-model="form.external_carrier">
            <option :value="null">Please select</option>
            <option value="ups">UPS</option>
            <option value="nonups">Non UPS</option>
          </select>
        </InputGroup>
        <InputGroup label="Netsuite ID" :error="getError('external_method_id')">
          <input type="text" class="form-input w-full" help-text="External carrier - ups or nonups" v-model="form.external_method_id" />
          <p>
            The Netsuite ID can be found in the url when editing the carrier. <a href="https://d.pr/i/24Wa4e" target="_blank" class="underline text-blue-500">Show me</a>
          </p>
        </InputGroup>
        <InputGroup label="OrderBot Shipping Method" :error="getError('order_bot_shipping_method')">
          <input type="text" class="form-input w-full" v-model="form.order_bot_shipping_method" />
        </InputGroup>
      </div>
      <div class="mt-6 flex justify-end lg:col-span-4">
        <NLButton :isLoading="submitting" v-on:click="submit" class="px-3 py-2"> Save </NLButton>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationMessages from '~/components/global/UI/form/ValidationMessages'
import FormMixin from '~/mixins/FormMixin'
import HasErrors from '~/mixins/HasErrors'
import NLButton from '~/components/global/input/Button'

export default {
  props: {
    carrier: {
      type: Object,
      default: null,
    },
  },
  components: { NLButton, ValidationMessages },
  mixins: [FormMixin, HasErrors],
  data: () => ({
    form: {
      name: null,
      slug: null,
      order_bot_shipping_method: null,
    },
  }),
}
</script>
