<template>
  <div>
    <div class="relative pb-3/5">
      <img
        class="absolute top-0 h-full w-full rounded-lg object-cover shadow-md"
        :src="product.image['meal-card-desktop']"
      />
    </div>
    <div class="relative -mt-16 px-5">
      <div class="rounded-lg bg-white p-4 shadow-lg">
        <div>
          <span
            class="rounded-full bg-teal-200 px-2 py-1 text-sm font-bold uppercase tracking-wide text-teal-800"
          >
            <router-link :to="editLinkUrl">{{ product.sku }}</router-link>
          </span>
          <span class="ml-2">&bull;</span>
          <span class="ml-2 font-bold text-gray-800"> ${{ product.price.original_price }} </span>
        </div>
        <div class="mt-4">
          <router-link
            class="font-semibold hover:text-teal-500 hover:underline"
            :to="editLinkUrl"
            >{{ product.name }}</router-link
          >
        </div>
        <div class="mt-2 text-sm text-gray-700">
          <span>{{ category }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import Confirm from '~/components/swal/Confirm'
import swal from 'sweetalert2'

export default {
  props: ['product'],
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      clone: 'products/clone',
    }),
    cloneProduct() {
      Confirm.fire({
        icon: 'info',
        text: `Are you sure you wish to clone the Product ${this.product.sku}?`,
        preConfirm: () => {
          return this.clone(this.product.sku).catch(error =>
            swal.showValidationMessage(`Request failed: ${error}`)
          )
        },
      }).then(
        result => result.value && this.$emit('cloned') && this.successMessage('Product Cloned!')
      )
    },
  },
  computed: {
    ...mapGetters({
      categoryById: 'categories/byId',
    }),
    editLinkUrl() {
      return { name: 'edit-product', params: { sku: this.product.sku } }
    },
    category() {
      return _.get(this.categoryById(this.product.category), 'name')
    },
  },
}
</script>
