<template>
  <modal :adaptive="true" name="deactivate-comped-account" height="auto">
    <div class="m-4">
      <div>Chose an option</div>
      <hr />
      <form>
        <div v-if="reasons.length" class="px-4 pt-5 sm:p-6 sm:pb-4 overflow-y-scroll h-60">
          <div :class="{ 'has-error': hasError('reasons') }">
            <div class="radio mb-1" v-for="reason in reasons" :key="reason.id">
              <label class="inline-flex items-center">
                <input type="radio"
                  :value="reason.id"
                  v-model="form.reason.reason_id"
                  class="form-radio h-5 w-5 text-teal-600"
                />
                <span class="ml-2 text-gray-700">{{ reason.title }}</span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="selectedReason" class="ml-6 mt-1">
          <div v-if="selectedReason.description" v-html="selectedReason.description"></div>
          <div v-if="selectedReason.question">
            <p class="text-sm text-gray-700">{{ selectedReason.question }}</p>
            <textarea v-model="form.reason.reason_details" class="form-input mt-1 block w-full" />
          </div>
        </div>
        <div class="ml-6 mb-6">
          <error :error="getError('reason_details')" />
          <error :error="getError('reason_id')" />
        </div>
        <div class="ml-6 space-x-4">
          <button class="btn btn-green" @click.prevent="save">{{ loading ? 'Just a moment' : 'Confirm' }}</button>
          <button class="btn btn-gray" @click.prevent="close">Cancel</button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['user'],
  data: () => ({
    loading: false,
    form: {
      reason: {
        reason_id: null,
        reason_details: null,
      },
    },
  }),
  methods: {
    ...mapActions([
      'users/updateCompedSettings',
      'comped-accounts/remove'
    ]),
    close() {
      this.$modal.hide('deactivate-comped-account')
    },
    async save() {
      try {
        await this.wrapSubmit(this['comped-accounts/remove']({userId: this.user.id, ...this.form.reason}))
        this.successMessage('Account deactivated')
        this.close()
      } catch(e) {
        this.errorMessage(e.response.data.message)
      }
    },
  },
  computed: {
    ...mapGetters({
      reasons: 'reasons/cancel',
      reasonById: 'reasons/byId'
    }),
    selectedReason() {
      return this.reasonById(this.form.reason.reason_id)
    },
  },
}
</script>
