import axios from 'axios'
import * as mutationTypes from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
}

export const mutations = {
  [mutationTypes.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch({ dispatch }) {
    return axios.get('/api/popup-icons').then(res => {
      processEntities(res.data.data, 'icons')
      return res.data
    })
  },
}
