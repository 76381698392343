<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <form-component :save="createHoliday" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import FormComponent from '~/components/holidays/Form'

export default {
  components: {
    FormComponent,
  },
  methods: {
    ...mapActions(['holidays/create']),
    createHoliday(data) {
      return this['holidays/create'](data).then(data => {
        this.successMessage('Holiday Created!')
        this.$router.push({ name: 'holidays' })
      })
    },
  },
}
</script>
