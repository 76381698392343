<template>
  <button
    class="block border-b border-gray-600 text-gray-700 transition duration-300 hover:border-gray-700 hover:text-gray-800 disabled:cursor-not-allowed disabled:opacity-80"
    type="button"
    v-bind="$attrs"
    @click="$emit('click')"
    :disabled="isLoading"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },
}
</script>
