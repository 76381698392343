<template>
  <th
    class="bg-gray-50 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
    :class="{ 'px-3 py-1.5': compact, 'px-6 py-3': !compact }"
  >
    <slot></slot>
  </th>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>
