import axios from 'axios'
import sortBy from 'lodash/sortBy'
import { addOrUpdateItems, processEntities } from '../helpers'
import * as types from '../mutation-types'

const skipType = 'SKIP'
const creditTypes = ['CREDIT_REGULAR', 'CREDIT_FOR_PRODUCT', 'CREDIT_FOR_PROMOCODE']
const cancelTypes = ['SUBSCRIPTION_CANCELLATION']
const pauseTypes = ['SUBSCRIPTION_PAUSE']
const skipTypes = [skipType]

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
  credit: state =>
    sortBy(
      state.items.filter(item => creditTypes.includes(item.type)),
      'title'
    ),
  cancel: state => state.items.filter(item => cancelTypes.includes(item.type)),
  pause: state => state.items.filter(item => pauseTypes.includes(item.type)),
  skip: state => state.items.filter(item => skipTypes.includes(item.type)),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetchPauseReasons(store) {
    return store.dispatch('fetchByType', pauseTypes.join(','))
  },
  fetchCreditReasons(store) {
    return store.dispatch('fetchByType', creditTypes.join(','))
  },
  fetchCancelReasons(store) {
    return store.dispatch('fetchByType', cancelTypes.join(','))
  },
  fetchSkipReasons(store) {
    axios.get('/api/sales/order-skipped-reasons').then(res => {
      const reasons = res.data.data.map(reason => ({ ...reason, type: skipType }))
      processEntities(reasons, 'reasons')
      return res.data
    })
  },
  fetchByType(store, type) {
    axios.get('/api/sales/admin/reasons', { params: { type } }).then(res => {
      processEntities(res.data.data, 'reasons')
      return res.data
    })
  },
}
