<template>
  <div class="relative">
    <div
      v-if="hasLeadingIcon"
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
    >
      <slot name="leading-icon" />
    </div>
    <input
      v-bind="$attrs"
      :value="value"
      :type="type"
      @input="onInput"
      class="block w-full rounded-md border-gray-200 bg-gray-50 py-1 px-2 text-gray-800 shadow-sm focus:border-teal-400/40 focus:bg-white focus:ring-2 focus:ring-teal-400/40 disabled:cursor-not-allowed disabled:opacity-80"
      :class="[hasLeadingIcon ? 'pl-10' : '']"
      :disabled="readonly"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'text', required: false },
    value: {},
    leadingIcon: { type: String, required: false },
    readonly: { type: Boolean, required: false },
  },
  data() {
    return {}
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event.target.value)
    },
  },
  computed: {
    hasLeadingIcon() {
      return !!this.$slots['leading-icon']
    },
  },
}
</script>
