<template>
  <modal
    :adaptive="true"
    name="add-credit"
    @before-open="beforeOpen"
    height="auto"
    :scrollable="true"
  >
    <header class="border-b border-gray-200 px-6 py-4">
      <h3 class="text-2xl font-semibold">Add Credits</h3>
      <div class="text-gray-600">
        <span class="font-semibold">{{ balance | dollars }}</span> is the current balance.
      </div>
    </header>

    <form class="space-y-6 py-4 px-4">
      <div class="flex items-start space-x-6 px-6">
        <div class="w-full">
          <label class="text-xs font-semibold uppercase text-gray-700"
            >How do you want to handle the refund?</label
          >
          <div class="mt-2 flex items-center space-x-6">
            <button
              class="relative flex-1 rounded border-2 border-transparent px-4 py-6 transition duration-300 hover:border-teal-500"
              :class="{ 'border-teal-600': strategy === 'credits' }"
              @click.prevent="setStrategy('credits')"
            >
              <span>Credits</span>
              <svg
                v-show="strategy === 'credits'"
                class="absolute right-0 top-0 mr-2 mt-2 h-5 w-5 fill-current text-teal-500"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <button
              class="relative flex-1 rounded border-2 border-transparent px-4 py-6 transition duration-300 hover:border-teal-500"
              :class="{ 'border-teal-600': strategy === 'refunds' }"
              @click.prevent="setStrategy('refunds')"
            >
              <span>Refund</span>
              <svg
                v-show="strategy === 'refunds'"
                class="absolute right-0 top-0 mr-2 mt-2 h-5 w-5 fill-current text-teal-500"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <InputGroup label="Order" :error="getError('order_id')">
        <Listbox v-model="form.order_id" v-slot="{ isOpen }">
          <ListboxLabel class="sr-only"> Select which order to apply credits to. </ListboxLabel>
          <ListboxButton class="form-select w-full text-left">
            <span v-if="order">
              Delivery at {{ order.deliver_at | date }}
              <span class="text-xs uppercase text-gray-600">{{ order.status }}</span>
            </span>
            <span v-else>Select an order</span>
          </ListboxButton>
          <ListboxList
            v-if="orders.length"
            v-show="isOpen"
            class="mt-3 h-64 divide-y-2 divide-gray-200 overflow-y-auto focus:shadow focus:outline-none"
          >
            <ListboxOption
              v-for="order in orders"
              :key="order.id"
              :value="order.id"
              v-slot="{ isActive, isSelected }"
            >
              <div
                class="rounded-lg px-2 py-1"
                :class="{
                  'bg-teal-600 text-white': isActive,
                  'border-2 border-teal-600': isSelected,
                }"
              >
                <div class="flex justify-between">
                  <div class="w-5/6">
                    <div class="flex justify-between">
                      <div>
                        <div
                          class="text-xs font-semibold uppercase text-gray-500"
                          :class="isActive ? 'text-teal-200' : 'text-gray-500'"
                        >
                          Cutoff Date
                        </div>
                        <div>{{ order.cutoff_at }}</div>
                      </div>
                      <div>
                        <div
                          class="text-xs font-semibold uppercase text-gray-500"
                          :class="isActive ? 'text-teal-200' : 'text-gray-500'"
                        >
                          Delivery Date
                        </div>
                        <div>{{ order.deliver_at }}</div>
                      </div>
                    </div>
                    <div class="mt-2 flex justify-between">
                      <div>
                        <div
                          class="text-xs font-semibold uppercase text-gray-500"
                          :class="isActive ? 'text-teal-200' : 'text-gray-500'"
                        >
                          Total
                        </div>
                        <div>{{ order.total_cost | dollars }}</div>
                      </div>
                      <div>
                        <div
                          class="text-xs font-semibold uppercase text-gray-500"
                          :class="isActive ? 'text-teal-200' : 'text-gray-500'"
                        >
                          Status
                        </div>
                        <div>{{ order.status }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-1/6 items-start justify-end">
                    <svg
                      v-show="isSelected"
                      :class="isActive ? 'text-white' : 'text-teal-500'"
                      class="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </ListboxOption>
          </ListboxList>
        </Listbox>
        <span v-if="order && order.total_discount_amount" class="text-sm text-gray-500">
          {{ order.total_discount_amount | dollars }} applied as a sum of all discounts
        </span>
      </InputGroup>
      <InputGroup label="Reason" :error="getError('reason_id')">
        <select class="form-select w-full" v-model="form.reason_id">
          <option :value="reason.id" v-for="reason in reasons" :key="reason.id">
            {{ reason.title }}
          </option>
        </select>
        <SelectedReasonDetails v-if="reason" :reason="reason">
          <div slot-scope="{ picks, withMeals, freeText }">
            <CreditReasonProductList
              :products="products"
              :withMeals="withMeals"
              :value="form.products"
              @input="value => (form.products = value)"
            />
            <CreditReasonExtraDetails
              :options="picks"
              :freeText="freeText"
              :value="form.reason_details"
              @input="value => (form.reason_details = value)"
            />
          </div>
        </SelectedReasonDetails>
      </InputGroup>
      <InputGroup :error="getError('amount')" label="Amount">
        <template v-slot:label>
          <div class="text-right">
            <label>
              <input type="checkbox" v-model="isFullAmount" class="form-checkbox h-4 w-4" />
              <span class="ml-1 text-sm text-gray-600">Full amount</span>
            </label>
          </div>
        </template>
        <div v-show="!isFullAmount">
          <div class="relative">
            <span class="absolute left-0 top-0 bottom-0 flex items-center pl-2">
              <CurrencyDollarIcon class="h-4 w-4 text-gray-500" />
            </span>
            <input
              type="text"
              v-model="form.amount"
              id="credit-amount"
              class="form-input w-full pl-8"
              :disabled="!isAmountManual"
            />
          </div>
          <div v-if="canCalculateAmount" class="mt-1 flex items-center space-x-4">
            <label>
              <input
                type="radio"
                class="form-radio h-4 w-4"
                v-model="isAmountManual"
                :value="true"
              />
              <span class="ml-1 text-xs text-gray-600">Specify amount manually</span>
            </label>
            <label>
              <input
                type="radio"
                class="form-radio h-4 w-4"
                v-model="isAmountManual"
                :value="false"
              />
              <span class="ml-1 text-xs text-gray-600">Calculate amount</span>
            </label>
          </div>
        </div>
        <div v-show="isFullAmount">
          <span v-if="order">{{ order.total_cost | dollars }}</span>
        </div>
      </InputGroup>
    </form>

    <footer
      class="flex items-center justify-end space-x-4 border-t border-gray-200 bg-gray-100 px-6 py-4"
    >
      <LinkButton @click="close">Cancel</LinkButton>
      <NLButton :isLoading="submitting" @click="submit" class="px-3 py-1">Confirm</NLButton>
    </footer>
  </modal>
</template>

<script>
import { CurrencyDollarIcon } from '@vue-hero-icons/solid'
import CreditReasonExtraDetails from '~/components/credits/CreditReasonExtraDetails'
import CreditReasonProductList from '~/components/credits/CreditReasonProductList'
import SelectedReasonDetails from '~/components/reasons/SelectedReasonDetails'
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import { Listbox, ListboxLabel, ListboxButton, ListboxList, ListboxOption } from '@tailwindui/vue'
import NLButton from '~/components/global/input/Button'
import LinkButton from '~/components/global/UI/LinkButton'

export default {
  mixins: [HasErrors],
  props: ['user'],
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxList,
    ListboxOption,
    NLButton,
    LinkButton,
    CurrencyDollarIcon,
    SelectedReasonDetails,
    CreditReasonExtraDetails,
    CreditReasonProductList,
  },
  data: () => ({
    form: {
      products: [],
      amount: null,
      order_id: null,
      reason_id: null,
      reason_details: null,
    },
    orders: [],
    strategy: 'credits',
    isFullAmount: false,
    isAmountManual: true,
  }),
  computed: {
    ...mapGetters([
      'products/bySKUs',
      'carts/byId',
      'orders/byId',
      'reasons/credit',
      'credit-accounts/regular',
    ]),
    balance() {
      return this.account && this.account.balance
    },
    account() {
      return this['credit-accounts/regular'](this.user.id)
    },
    reasons() {
      return this['reasons/credit']
    },
    reason() {
      return this.reasons.find(item => item.id === this.form.reason_id)
    },
    order() {
      return this.form.order_id && this['orders/byId'](this.form.order_id)
    },
    cart() {
      return this.order && this['carts/byId'](this.order.cart)
    },
    products() {
      const products = this.cart ? this['products/bySKUs'](this.cart.itemsAsProduct) : []
      return products.map(product => ({
        ...product,
        maxQuantity: product.quantity,
      }))
    },
    canCalculateAmount() {
      return this.reason && this.products?.length > 0
    },
  },
  watch: {
    'form.order_id'() {
      this['orders/fetchById']({ id: this.form.order_id, params: { include: 'cart' } })
      this.form.products = []
      if (this.isFullAmount) {
        this.form.amount = this.order.total_cost
      }
    },
    'form.reason_id'() {
      this.form.reason_details = null
    },
    isFullAmount(value) {
      if (value === true) {
        this.form.amount = this.order ? this.order.total_cost : null
        this.form.isAmountManual = true
      } else {
        this.calculateAmount()
      }
    },
    isAmountManual(value) {
      if (value === false) {
        this.calculateAmount()
      }
    },
    'form.products'() {
      if (!this.isAmountManual) {
        this.calculateAmount()
      }
    },
  },
  methods: {
    ...mapActions([
      'orders/fetchById',
      'users/fetchAllOrders',
      'credit-accounts/submitCreditTransaction',
      'reasons/fetchCreditReasons',
      'credit-transactions/get',
      'refunds/makeRefund',
      'refunds/fetchByUserId',
      'credit-accounts/calculateAmount',
    ]),
    submit() {
      const params = { ...this.form }
      params.products = params.products
        .filter(product => product.quantity !== 0)
        .map(({ sku, quantity }) => ({ sku, quantity }))
      this.strategy === 'credits' ? this.addCredit(params) : this.makeRefund(params)
    },
    addCredit(params) {
      this.wrapSubmit(
        this['credit-accounts/submitCreditTransaction']({
          creditAccountId: this.account.id,
          params,
        })
      )
        .then(() => {
          this.successMessage('Credit added!')
          this['credit-transactions/get'](this.account.id)
          this.close()
        })
        .catch(() => this.errorMessage('Error occurred'))
    },
    makeRefund(params) {
      this.wrapSubmit(this['refunds/makeRefund'](params))
        .then(() => {
          this.successMessage('Refund successfully issued!')
          this['refunds/fetchByUserId'](this.user.id)
          this.close()
        })
        .catch(e => {
          if (e.response.data.errors.billing) {
            this.errorMessage(e.response.data.errors.billing[0])
          } else {
            this.errorMessage('Error occurred')
          }
        })
    },
    async calculateAmount() {
      const products = this.form.products.filter(product => product.quantity !== 0)
      this.form.amount = await this['credit-accounts/calculateAmount']({
        orderId: this.form.order_id,
        products,
      })
    },
    setStrategy(strategy) {
      this.strategy = strategy
    },
    close() {
      this.$emit('close')
    },
    beforeOpen() {
      this['reasons/fetchCreditReasons']()
      this['users/fetchAllOrders']({ id: this.user.id, params: {orderBy: 'date_desc'}}).then(data => {
        this.orders = data.data
      })
    },
  },
}
</script>
