import { schema } from 'normalizr'
import orderWeek from '@/store/schema/order-weeks'
import productionCycle from '@/store/schema/production-cycles'

const processStrategy = buffer => {
  const relations = {
    orderWeek: _.has(buffer, 'orderWeek') ? buffer.orderWeek.data : null,
    productionCycle: _.has(buffer, 'productionCycle') ? buffer.productionCycle.data : null,
  }
  return {
    ...buffer,
    // productsQuantities: _.has(buffer, 'products') ? buffer.products.data.map(({ quantity }) => quantity) : null,
    // productsAvailability: _.has(buffer, 'products') ? buffer.products.data.map(({ is_available: isAvailable }) => isAvailable) : null,
    ..._.pickBy(relations, _.identity),
  }
}
export default new schema.Entity(
  'buffers',
  {
    // products: [products],
    order_week: orderWeek,
    production_cycle: productionCycle,
  },
  {
    processStrategy,
  }
)
