<template>
  <div>
    <PanelHeader>
      <h4 class="text-base font-semibold">Lock/Unlock for post cutoff changes</h4>
    </PanelHeader>
    <div class="bg-gray-100 px-4 py-4 sm:pt-6">
      <div v-if="isUnlocked" class="text-gray-600">
        This order <strong>is unlocked</strong> for post cutoff modifications
        <a
          @click.prevent="showLockModal"
          class="inline-flex items-center space-x-1 rounded-full bg-orange-600 py-1 px-2 text-xs uppercase tracking-wide text-white transition duration-300 hover:bg-orange-500"
          href="#"
        >
          <span>Lock it back?</span>
          <div class="flex h-5 w-5 items-center justify-center rounded-full bg-black bg-opacity-25">
            <svg
              class="h-3 w-3 text-orange-100"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </a>
      </div>
      <div v-if="!isUnlocked" class="text-gray-600">
        This order <strong>is locked</strong> for post cutoff modifications.
        <a
          @click.prevent="showUnlockModal"
          class="inline-flex items-center space-x-1 rounded-full bg-teal-600 py-1 px-2 text-xs uppercase tracking-wide text-white transition duration-300 hover:bg-teal-500"
          href="#"
        >
          <span>Unlock it?</span>
          <div class="flex h-5 w-5 items-center justify-center rounded-full bg-black bg-opacity-25">
            <svg
              class="h-3 w-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
    <UnlockPostCutoffChangesModal :user="user" :order="order" />
    <LockPostCutoffChangesModal :user="user" :order="order" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UnlockPostCutoffChangesModal from './UnlockPostCutoffChangesModal'
import LockPostCutoffChangesModal from './LockPostCutoffChangesModal'
export default {
  props: ['userId', 'orderId'],
  components: {
    UnlockPostCutoffChangesModal,
    LockPostCutoffChangesModal,
  },
  computed: {
    user() {
      return this['users/byId'](this.userId)
    },
    order() {
      return this['orders/byId'](this.orderId)
    },
    isUnlocked() {
      return this.order && this.order.is_unlocked_post_cutoff
    },
    ...mapGetters(['orders/byId', 'users/byId']),
  },
  methods: {
    showUnlockModal() {
      this.$modal.show('unlock-post-cutoff-changes-modal')
    },
    showLockModal() {
      this.$modal.show('lock-post-cutoff-changes-modal')
    },
  },
}
</script>
