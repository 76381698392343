<template>
  <label class="inline-flex items-center">
    <input
      type="checkbox"
      v-bind="$attrs"
      class="form-checkbox rounded border-gray-400 bg-teal-50 text-teal-600 focus:ring-teal-500"
      :checked="value"
      :value="value"
      v-on:input="$emit('input', !value)"
    />
    <span class="ml-2 cursor-pointer text-sm text-gray-800">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: ['label', 'value'],
}
</script>
