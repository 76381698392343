<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'order-weeks' }">
        <i class="fas fa-long-arrow-alt-left mr-2" />
        Back to order weeks
      </router-link>
    </button>
    <div v-if="buffer">
      <Panel>
        <PanelHeader
          >Post cutoff buffer for {{ buffer.orderWeek.number }}
          {{ buffer.productionCycle.name }}</PanelHeader
        >
        <div class="border-b border-gray-200 bg-white lg:px-2 lg:pt-6">
          <div class="bg-teal-100 p-4 lg:p-8">
            <h3 class="mb-1 font-semibold text-teal-800">Add New Product</h3>
            <div class="grid grid-cols-8 gap-x-4">
              <div class="col-span-5">
                <products-autocomplete
                  v-model="newProduct.sku"
                  :params="{ active: 1 }"
                  class="w-full"
                />
              </div>
              <div class="col-span-2">
                <input
                  type="number"
                  v-model="newProduct.quantity"
                  class="form-input h-full w-full bg-white"
                  placeholder="Qty"
                />
              </div>
              <div class="col-span-1 self-center">
                <button
                  @click="addNewProduct"
                  class="font-semibold text-teal-700 underline lg:rounded-full lg:border-2 lg:border-teal-500 lg:bg-teal-600 lg:px-4 lg:py-1 lg:font-semibold lg:text-white lg:transition lg:duration-150 lg:hover:bg-teal-500"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div class="py-6 px-4">
            <h3 class="text-lg font-semibold text-gray-600">Currently in buffer</h3>
            <p class="text-xs text-gray-500">Set quantity to 0 to remove the item</p>
            <div class="mt-3 grid grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-10 place-items-start">
              <div v-for="(item, i) in form.items" :key="item.sku"
                :class="{
                  'col-span-2': title(item.sku, form.items[i-1]?.sku)}
                ">
                <div v-if="title(item.sku, form.items[i-1]?.sku)" class="col-span-2">
                  <h3 class="text-xl my-4">{{title(item.sku, form.items[i-1]?.sku)}}</h3>
                </div>
                <div
                  class="flex items-center justify-between space-x-2 sm:justify-start text-sm font-semibold"
                  :class="{ 'has-error': hasError(`products.${i}.quantity`) }"
                >
                  <input
                    type="number"
                    class="form-input h-6 w-16 bg-white font-semibold text-teal-700 text-center py-1"
                    v-model="item.quantity"
                  />
                  <span>({{ item.sku }})</span>
                  <router-link
                    :to="{ name: 'edit-product', params: { sku: item.sku } }"
                    class="text-teal-500 underline"
                    >{{ item.name }}</router-link
                  >
                </div>
                <error
                  class="flex justify-end sm:block"
                  :error="getError(`products.${i}.quantity`)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end bg-gray-100 py-2 px-4">
          <button
            @click.prevent="submit"
            class="w-full rounded bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
          >
            Save
          </button>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import HasErrors from '~/mixins/HasErrors'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import Panel from '~/components/global/UI/Panel'
import PanelHeader from '~/components/global/UI/PanelHeader'

export default {
  mixins: [HasErrors],
  data() {
    return {
      form: {},
      newProduct: {},
    }
  },
  components: { Panel, PanelHeader },
  methods: {
    ...mapActions({
      fetchBufferById: 'buffers/fetchById',
      saveItems: 'buffers/saveItems',
    }),
    init() {
      this.fetchBufferById(this.bufferId).then(response => {
        this.clear(response.data)
      })
    },
    clear(data) {
      this.form = Object.assign({}, _.omit(this.buffer, ['id', 'productionCycle', 'orderWeek']), {
        items: data.items,
      })
    },
    addNewProduct() {
      if (this.form.items.find(item => item.sku == this.newProduct.sku)) {
        return this.errorMessage(
          `${this.newProduct.sku} is already in the buffer. Please alter the quantity below.`
        )
      }
      if (!this.newProduct.quantity || this.newProduct.quantity <= 0) {
        return this.errorMessage('quantity is invalid')
      }
      this.form.items.push(this.newProduct)
      this.newProduct = {}
      this.submit()
    },
    submit() {
      this.wrapSubmit(this.saveItems({ id: this.bufferId, products: this.form.items }))
        .then(() => {
          this.init()
          this.successMessage('Buffer updated')
        })
        .catch(err => {
          this.errorMessage(err.response.data.message)
        })
    },
    title(sku, prevSku) {
      if (!prevSku) {
        return sku.substring(0, 3)
      }
      if (sku && prevSku && sku.substring(0, 3) !== prevSku.substring(0, 3)) {
        return sku.substring(0, 3)
      }
      return null;
    }
  },
  computed: {
    ...mapGetters({
      allBuffers: 'buffers/all',
    }),
    bufferId() {
      return this.$route.params.id
    },
    buffer() {
      return this.allBuffers.find(id => this.bufferId)
    },
  },
  mounted() {
    this.init()
  },
}
</script>
