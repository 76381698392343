import { schema } from 'normalizr'
import { has, get, omit, pickBy, identity } from 'lodash'
import category from './categories'
import badge from './badges'
import relatedProducts from './related-products'
import customersAlsoLovedProducts from './customers-also-love-products'

const processStrategy = product => {
  product.badge = has(product, 'badge.data') ? product.badge.data : null

  const relations = {
    category: has(product, 'category') ? product.category.data : null,
    allergens: has(product, 'allergens') ? product.allergens.data : null,
    indicators: has(product, 'indicators') ? product.indicators.data : null,
    mealComponents: has(product, 'mealComponents') ? product.mealComponents.data : null,
    ratingAttributes: has(product, 'ratingAttributes') ? product.ratingAttributes.data : null,
    tags: has(product, 'tags') ? product.tags.data : null,
    price: has(product, 'price') ? product.price.data : null,
    related_products: get(product, 'relatedProducts')
      ? product.relatedProducts.data.map(relatedProduct => relatedProduct.sku)
      : [],
    customers_also_loved_products: get(product, 'customersAlsoLovedProducts')
      ? product.customersAlsoLovedProducts.data.map(relatedProduct => relatedProduct.sku)
      : [],
    images: has(product, 'images.data') ? product.images.data : [],
    heating_instructions: get(product, 'heatingInstructions')
      ? product.heatingInstructions.data.map(instruction => {
          return {
            id: instruction.id,
            title: instruction.title ? instruction.title : '',
            text: instruction.text,
            type: instruction.type.data.id,
          }
        })
      : [],
  }
  return omit({ ...product, ...pickBy(relations, identity) }, [
    'relatedProducts',
    'customersAlsoLovedProducts',
    'heatingInstructions',
  ])
}

const allergens = new schema.Entity('allergens')
const indicators = new schema.Entity('indicators')
const mealComponents = new schema.Entity('meal-components')
const ratingAttributes = new schema.Entity('rating-attributes')
const tags = new schema.Entity('tags')

export default new schema.Entity(
  'products',
  {
    category: category,
    badge: badge,
    tags: [tags],
    allergens: [allergens],
    indicators: [indicators],
    mealComponents: [mealComponents],
    ratingAttributes: [ratingAttributes],
    relatedProducts: [relatedProducts],
    customersAlsoLovedProducts: [customersAlsoLovedProducts],
  },
  {
    idAttribute: 'sku',
    processStrategy,
  }
)
