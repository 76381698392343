<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'experiments' }"
        ><i class="fas fa-long-arrow-alt-left mr-2" />Back To Experiments</router-link
      >
    </button>
    <form-component :experiment="experiment" :save="saveExperiment" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormComponent from '~/components/experiments/Form'

export default {
  components: {
    FormComponent,
  },
  mounted() {
    this['experiments/fetchById'](this.id)
  },
  computed: {
    ...mapGetters(['experiments/byId']),
    id() {
      return +this.$route.params.id
    },
    experiment() {
      return this['experiments/byId'](this.id)
    },
  },
  methods: {
    ...mapActions(['experiments/update', 'experiments/fetchById']),
    saveExperiment(data) {
      return this['experiments/update']({ id: this.id, params: data }).then(() =>
        this.successMessage('Experiment Updated!')
      )
    },
  },
}
</script>
