<template>
  <button
    :class="classes"
    v-bind="$attrs"
    @click="$emit('click')"
    :disabled="disabled"
    type="button"
  >
    <svg
      v-show="isLoading"
      class="mr-2 h-4 w-4 animate-spin text-white transition delay-100 duration-150"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <span>
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isLoading: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },
  computed: {
    disabled() {
      return this.isLoading === true || this.isDisabled
    },
    classes() {
      return [
        'flex items-center justify-center',
        'border border-transparent',
        'text-sm leading-5 text-white bg-teal-500 hover:bg-teal-600 font-medium rounded-md',
        'transition duration-150 ease-in-out',
        'disabled:cursor-not-allowed disabled:opacity-75',
      ]
    },
  },
}
</script>
