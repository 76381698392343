import HasErrors from './HasErrors'

export default {
  mixins: [HasErrors],

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    form: {},
  }),

  watch: {
    value() {
      this.clear()
    },
    form() {
      this.input()
    },
  },

  created() {
    this.clear()
  },

  methods: {
    input() {
      this.$emit('input', this.form)
    },
    clear() {
      if (this.form !== this.value) {
        this.form = Object.assign({}, this.form, this.value)
      }
    },
    submit() {
      this.$emit('submit', this.wrapSubmit)
    },
  },
}
