import { schema } from 'normalizr'
import _ from 'lodash'

const processStrategy = productionCycle => {
  const relations = {
    shipDays: _.map(_.get(productionCycle, 'enabled_ship_days'), shipDay => shipDay.name),
  }
  return {
    ..._.omit(productionCycle, ['enabled_ship_days', 'is_allocated_on_days']),
    ..._.pickBy(relations, _.identity),
    isAllocatedOnDays: _.get(productionCycle, 'is_allocated_on_days') || [],
  }
}

export default new schema.Entity(
  'productionCycles',
  {},
  {
    processStrategy,
  }
)
