<template>
  <li>
    <div
      class="block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
    >
      <div class="flex items-center px-4 py-4 lg:px-6">
        <div class="min-w-0 flex-1 lg:flex lg:flex-row-reverse lg:items-center lg:justify-between">
          <div class="lg:ml-8 lg:flex-1">
            <div class="truncate text-sm font-bold leading-5 text-teal-600">
              {{ icon.name }}
              <span class="ml-1 font-normal text-gray-500"
                >Is not presented to user, admin way to identify the icon among others</span
              >
            </div>
            <div class="mt-2 flex">
              <div class="text-sm leading-5 text-gray-600">
                <h3 class="space-x-4">
                  <span class="text-xs uppercase tracking-wide">title:</span>
                  <span class="text-gray-800">{{ icon.title }}</span>
                </h3>
                <div class="mt-1 flex items-center">
                  <span class="text-xs uppercase tracking-wide text-gray-600">subtitle:</span>
                  <p v-html="icon.description"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 shrink-0 lg:mt-0">
            <div class="flex overflow-hidden">
              <img
                class="shadow-solid inline-block h-20 w-20 rounded-full text-white"
                :src="icon.url"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="ml-5 shrink-0"></div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ['icon'],
  data() {
    return {}
  },
  methods: {
    // showEditModal(item) {
    //   this.$emit('editItem', item)
    // }
  },
}
</script>
