<template>
  <td
    v-bind="$attrs"
    class="whitespace-nowrap text-sm font-medium leading-5 text-gray-900"
    :class="{ 'px-3 py-2': compact, 'px-6 py-4': !compact }"
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>
