import axios from "axios";
import { addOrUpdateItems, processEntities } from '../helpers';
import * as types from '../mutation-types';

const getDefaultState = () => {
  return {
    items: []
  }
}

export const state = getDefaultState()

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [types.RESET_STATE]: (state) => Object.assign(state, getDefaultState())
}

export const actions = {
  fetch(store, params) {
    return axios.get('api/sales/admin/batch', {params}).then(res => {
      store.commit(types.RESET_STATE)
      processEntities(res.data.data, 'batches')
      return res.data
    })
  },
  regenerateOrders(store, params) {
    return axios.post('api/sales/admin/batch/regenerate-orders', params).then(res => {
      processEntities(res.data.data, 'batches')
      return res.data
    })
  },
  massApplyCredits(store, file) {
    let formData = new FormData();
    formData.append('csv', file);

    return axios.post('api/billing/admin/credits', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      processEntities(res.data.data, 'batches')
      return res.data
    })
  }
}
