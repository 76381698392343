<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex items-end justify-between">
      <router-link :to="{ name: 'tax-rates' }">
        <button class="btn btn-gray self-start">
          <i class="fas fa-long-arrow-alt-left mr-2" /> Back to All States
        </button>
      </router-link>
    </div>

    <div class="m-auto w-1/2">
      <h3 class="py-4 text-3xl">Editing '{{ $get(state, 'name') }}' State</h3>

      <hr class="border-gray-400" />

      <tax-rates-form v-model="form" @submit="submit" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TaxRatesForm from '~/components/tax-rates/Form'

export default {
  components: {
    TaxRatesForm,
  },

  data: () => ({
    form: {},
  }),

  computed: {
    ...mapGetters({
      getStateById: 'states/byId',
    }),
    id() {
      return this.$route.params.id
    },
    state() {
      return this.getStateById(this.id)
    },
  },

  mounted() {
    this.fetchStateById(this.id).then(this.clear)
  },

  methods: {
    ...mapActions({
      fetchStateById: 'states/fetchById',
      updateStateById: 'states/updateById',
    }),
    clear() {
      this.form = Object.assign({}, this.state)
    },
    submit(wrapSubmit) {
      wrapSubmit(this.updateStateById(this.form))
        .then(() => this.successMessage('Success', 'The state has been updated'))
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
  },
}
</script>
