<template>
  <div class="-mx-2 flex flex-wrap">
    <button
      v-for="option in availableOptions"
      :key="option.id"
      class="mx-2 my-1 transform whitespace-nowrap rounded border-2 px-1 text-center text-xs font-semibold uppercase tracking-wide transition duration-150 hover:scale-105 hover:outline-none"
      :class="
        isSelected(option)
          ? 'border-indigo-200 bg-indigo-100 text-indigo-700'
          : 'border-gray-300 text-gray-500'
      "
      v-on:click.stop.prevent="toggle(option)"
    >
      {{ option.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['availableOptions', 'value'],
  data() {
    return {
      selected: []
    }
  },
  methods: {
    isSelected(item) {
      return this.value.includes(item.id)
    },
    toggle(item) {
      if (this.isSelected(item)) {
        this.removeItem(item)
      } else {
        this.addItem(item)
      }
      this.$emit('input', this.selected)
    },
    removeItem(item) {
      const itemIndex = this.selected.indexOf(item.id);
      this.selected.splice(itemIndex, 1)
    },
    addItem(item) {
      this.selected.push(item.id)
    },
  },
  computed: {},
  mounted() {
    this.selected = this.value
  },
}
</script>
