<template>
  <li>
    <router-link
      :to="{ name: routeName }"
      class="flex items-center py-2 pr-4 text-sm transition-all duration-300 hover:text-gray-600"
      active-class="text-teal-600 font-bold"
    >
      <slot name="svg"></slot>
      <span class="ml-3" v-html="name"></span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: ['routeName', 'name'],
}
</script>
