<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <!-- All Gift Cards -->
    <div class="flex justify-between border-b border-solid border-gray-400 py-4">
      <h3 class="text-3xl">Gift Cards</h3>
      <ToggleGiftCardsBonuses />
    </div>

    <!-- Search Bar -->
    <div class="flex justify-between py-4">
      <div class="flex">
        <search v-model="options.code" @input="reloadPage" placeholder="Find a gift card" />
      </div>
    </div>

    <!-- Tabs -->
    <div>
      <div class="relative overflow-x-auto border-b border-r border-l border-gray-400 bg-white p-4">
        <ui-table>
          <template #thead>
            <table-heading class="text-left" compact>Code</table-heading>
            <table-heading class="text-left" compact>Recipient</table-heading>
            <table-heading class="text-left" compact>Gifter</table-heading>
            <table-heading class="text-right" compact>Value</table-heading>
            <table-heading class="text-right" compact>Remaining</table-heading>
            <table-heading class="text-left" compact>Card</table-heading>
            <table-heading>&nbsp;</table-heading>
          </template>
            <tr v-for="card in giftCards" :key="card.id">
              <table-cell compact>{{ card.code }}</table-cell>

              <table-cell class="text-xs" compact>
                {{ card.recipient_email }} <br>
                ({{ card.recipient_first_name }} {{ card.recipient_last_name }})
              </table-cell>
              <table-cell class="text-xs" compact>
                {{ card.gifter_email }} <br>
                ({{ card.gifter_first_name }} {{ card.gifter_last_name }})
              </table-cell>


              <table-cell class="text-right" compact>${{ card.value }}</table-cell>
              <table-cell class="text-right" compact>${{ card.remaining_amount }}</table-cell>
              <table-cell compact>{{ card.billing_card_last_4 }}</table-cell>

              <table-cell>
                <div class="flex-end mb-1 flex justify-end">
                  <a href="" class="icon-link icon-red" @click.prevent="removeGiftCard(card)">
                    <i class="fas fa-trash" />
                    <div class="tooltip">Delete</div>
                  </a>
                  <a href="" class="icon-link icon-gray" @click.prevent="refundGiftCard(card)">
                    <i class="fas fa-undo" />
                    <div class="tooltip">Refund</div>
                  </a>
                </div>
              </table-cell>
            </tr>
        </ui-table>

        <div class="mt-5 flex justify-end">
          <pagination
            :value="options.page"
            :per-page="options.per_page"
            :total="total"
            @input="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Table from '~/components/global/UI/Table'
import TableHeading from '~/components/global/UI/TableHeading'
import TableCell from '~/components/global/UI/TableCell'
import IndexPage from '~/mixins/IndexPage'
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'
import ToggleGiftCardsBonuses from '~/components/gift-cards/ToggleGiftCardsBonuses'

export default {
  mixins: [IndexPage],
  components: { ToggleGiftCardsBonuses, 'ui-table': Table, TableHeading, TableCell },
  data: () => ({
    options: {
      code: null,
    },
  }),
  computed: {
    ...mapGetters(['gift-cards/byIds']),
    giftCards() {
      return this['gift-cards/byIds'](this.ids)
    },
  },
  methods: {
    ...mapActions({
      fetchItems: 'gift-cards/fetch',
    }),
    ...mapActions(['gift-cards/remove', 'gift-cards/refund']),
    refundGiftCard(giftCard) {
      Confirm.fire({
        text: `Are you sure you wish to refund the gift card #${giftCard.code}?`,
        preConfirm: () => {
          return this['gift-cards/refund'](giftCard.id).catch(error => {
            const message =
              error.response && error.response.status === 422 ? error.response.data.message : error
            Swal.showValidationMessage(`Request failed: ${message}`)
          })
        },
      }).then(result => {
        if (result.value) {
          this.successMessage('Gift Card Refunded!')
          this.loadPage()
        }
      })
    },
    removeGiftCard(giftCard) {
      Confirm.fire({
        text: `Are you sure you wish to delete the gift card #${giftCard.code}?`,
        preConfirm: () => {
          return this['gift-cards/remove'](giftCard.id).catch(error => {
            const message =
              error.response && error.response.status === 422 ? error.response.data.message : error
            Swal.showValidationMessage(`Request failed: ${message}`)
          })
        },
      }).then(result => {
        if (result.value) {
          this.successMessage('Gift Card Removed!')
          this.loadPage()
        }
      })
    },
  },
}
</script>
