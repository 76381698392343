<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <aria-loader :loading="loading" />
    <h4 class="mb-4 font-bold">Ship Day Changes Log</h4>
    <h6 v-if="!events.length">No Changes Have Been Made</h6>
    <table v-else>
      <thead>
        <tr class="border-b-2 border-gray-400 font-bold">
          <th class="border border-gray-400 px-4 py-2">Description</th>
          <th class="border border-gray-400 px-4 py-2">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="activity in events" :key="activity.id">
          <td>
            {{ activity.description }}
          </td>
          <td>
            {{ activity.created_at | date }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getProfileLogs } from '@/infrastructure/api/user/logs/logs'
import AriaLoader from '@/components/AriaLoader'

export default {
  components: { AriaLoader },
  props: ['user'],
  data: () => ({
    events: [],
    loading: true,
  }),
  mounted() {
    getProfileLogs(this.user.id, 'ship_day_changed')
      .then(results => {
        this.events = results.sort((first, second) => {
          return new Date(second.created_at).getTime() - new Date(first.created_at).getTime()
        })
      })
      .finally(() => {
        this.loading = false
      })
  },
}
</script>
