import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
import { pickBy } from 'lodash'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  reset: (state) => state.items = [],
}

export const actions = {
  async fetch(store, parameters) {
    const queryParameters = {
      with_deleted: parameters && parameters.withDeleted ? 1 : 0,
      sku: parameters && parameters.sku
    };
    return await axios.get('api/admin/categories', {
      params: pickBy(queryParameters, (value) => value)
    }).then(res => {
      store.commit('reset')
      processEntities(res.data.data, 'categories')
      return res.data.data
    })
  },
  async fetchSingle(store, id) {
    const results = await axios.get(`api/categories/${id}`)
    return results.data.data
  },
  async update(store, { id, params }) {
    return await axios.put(`api/categories/${id}`, params)
  },
}
