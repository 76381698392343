import { schema } from 'normalizr'
import promoCodes from './promo-codes'
import _ from 'lodash'

const processStrategy = promoCodeApplication => {
  const relations = {
    promoCode: _.has(promoCodeApplication, 'promoCode')
      ? promoCodeApplication.promoCode.data
      : null,
  }
  return { ...promoCodeApplication, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'promoCodeApplications',
  {
    promoCode: promoCodes,
  },
  {
    processStrategy,
  }
)
