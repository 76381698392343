import axios from 'axios'
import { headersFormData, objectToFormData } from '@/utils/form-data'

const uriBase = '/api/sales/admin/order-bumps'

export const index = async params => {
  const response = await axios.get(uriBase, { params: { ...params, include: 'weeks' } })
  return response.data
}

export const show = async id => {
  const response = await axios.get(`${uriBase}/${id}`, { params: { include: 'weeks' } })
  return response.data
}

export const create = async data => {
  const formData = new FormData()
  const response = await axios.post(uriBase, objectToFormData(data, formData), {
    ...headersFormData,
    params: { include: 'weeks' },
  })
  return response.data
}

export const update = async (id, data) => {
  const formData = new FormData()
  formData.append('_method', 'PUT')
  const response = await axios.post(`${uriBase}/${id}`, objectToFormData(data, formData), {
    ...headersFormData,
    params: { include: 'weeks' },
  })
  return response.data
}
