import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, resolveIdAndParams, processEntities } from '../helpers'
import { objectToFormData, headersFormData } from '~/utils/form-data'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch(store, params) {
    return axios.get('api/popup-icons', { params }).then(res => {
      processEntities(res.data.data, 'popupIcons')
      return res.data
    })
  },
  store(store, form) {
    return axios.post('api/popup-icons', objectToFormData(form), headersFormData).then(res => {
      processEntities(res.data.data, 'popupIcons')
      return res.data
    })
  },
}
