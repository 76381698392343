import axios from 'axios'
import { normalize } from 'normalizr'
import { toArray } from 'lodash'
import { activitySchema } from '@/infrastructure/api/user/logs/activity.schema'

const getProfileLogs = async function (userId, logName) {
  const results = await axios.get(
    `/api/customers/admin/users/${userId}/log/profile?name=${logName}`
  )
  return processResults(results)
}

const getSubscriptionLogs = async function (userId, logName) {
  const results = await axios.get(
    `/api/customers/admin/users/${userId}/log/subscription?name=${logName}`
  )
  return processResults(results)
}

const getOrderLogs = async function (userId, logName) {
  const results = await axios.get(`/api/customers/admin/users/${userId}/log/order?name=${logName}`)
  return processResults(results)
}

const processResults = function (results) {
  if (!results) {
    return results
  }

  const data = results.data.data
  const normalized = normalize(data, [activitySchema])
  return normalized.entities.activity ? toArray(normalized.entities.activity) : []
}

export { getProfileLogs, getSubscriptionLogs, getOrderLogs }
