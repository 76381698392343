<template>
  <div class="overflow-x-auto">
    <table class="table-auto text-sm w-auto" v-if="cart">
      <thead>
        <tr>
          <th class="px-2 py-1"></th>
          <th class="px-2 py-1">Pre cutoff state</th>
          <th class="px-2 py-1">Post cutoff state</th>
          <th class="px-2 py-1">Order totals</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key) in cart.costs.subscription" :key="key">
          <td class="px-2 py-1">{{ titleHuman(key) }}</td>
          <td class="px-2 py-1">{{ formatPrice(cart.costs.pre_cutoff_changes[key]) }}</td>
          <td class="px-2 py-1">{{ formatPrice(cart.costs.post_cutoff_changes[key]) }}</td>
          <td class="px-2 py-1">{{ formatPrice(cart.costs.subscription[key]) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {formatPrice} from '../../utils/money'
export default {
  props: ['cart'],
  methods: {
    formatPrice,
    titleHuman(title) {
      return title.replace(/_/g, ' ')
    }
  }
}
</script>
