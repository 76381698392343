<template>
  <FilePond
    name="test"
    ref="pond"
    label-idle="Drop files here..."
    allow-multiple="true"
    accepted-file-types="image/jpeg, image/png"
    :storeAsFile="true"
    :files="value"
    @updatefiles="onUpdateFiles"
  />
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
const FilePond = vueFilePond(FilePondPluginImagePreview)

export default {
  props: ['value', 'uploadedFiles'],
  components: {
    FilePond,
  },
  methods: {
    onUpdateFiles(pondFiles) {
      const files = pondFiles.map(file => file.file)
      this.$emit('input', files)
    },
  },
}
</script>
