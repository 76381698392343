<template>
  <div>
    <panel>
      <template v-slot:header>
        <PanelHeader>
          <h2 class="text-base font-semibold">Referral Credits</h2>
          <div class="flex items-center space-x-1">
            <span class="text-sm text-gray-600">Aggregate Balance:</span>
            <span class="text-lg font-bold text-teal-700"> {{ aggregateBalance | dollars }} </span>
          </div>
        </PanelHeader>
      </template>
      <div class="divide-y-2 divide-gray-200 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:divide-y-0">
        <div class="flex flex-col justify-between">
          <div class="flex items-start justify-between">
            <div>
              <h2>Credits from being REFERRED</h2>
              <p class="text-sm text-gray-600">
                This account gets awarded when user signs up using someone else's referral link
              </p>
            </div>
            <div class="font-semibold">{{ referralAccountBalance | dollars }}</div>
          </div>
          <div class="mt-2 flex items-center justify-end space-x-4">
            <LinkButton @click="debitReferral" class="text-sm">Debit</LinkButton>
            <NLButton @click="creditReferral" class="px-2 py-1">Credit</NLButton>
          </div>
        </div>
        <div class="mt-4 flex flex-col justify-between pt-4 sm:mt-0 sm:pt-0">
          <div class="flex items-start justify-between">
            <div>
              <h2>Credits from REFERRING</h2>
              <p class="text-sm text-gray-600">
                This account gets awarded when someone sings up using the user's referral link
              </p>
            </div>
            <div class="font-semibold">{{ referrerAccountBalance | dollars }}</div>
          </div>
          <div class="mt-2 flex items-center justify-end space-x-4">
            <LinkButton @click="debitReferrer" class="text-sm">Debit</LinkButton>
            <NLButton @click="creditReferrer" class="px-2 py-1">Credit</NLButton>
          </div>
        </div>
      </div>
      <div class="border-t-2 border-slate-100 mt-2 pt-2 text-sm flex items-center">
        Referral Id: {{ user.referral_id }}
        <RefreshIcon class="h-4 w-4 text-red-300 ml-2 cursor-pointer" @click="regenerateReferralId"/>
      </div>
    </panel>
    <SubmitCreditAccountTransactionModal
      v-if="destinationAccountId"
      :creditAccountId="destinationAccountId"
      @closed="clear"
    />
    <RegenerateReferralIdModal
      :show="showRegenerateConfirmation"
      :user="user"
      @closed="showRegenerateConfirmation = false"
    />
  </div>
</template>

<script>
import Panel from '~/components/global/UI/Panel'
import PanelHeader from '~/components/global/UI/PanelHeader'
import LinkButton from '~/components/global/UI/LinkButton'
import NLButton from '~/components/global/input/Button'
import { mapActions, mapGetters } from 'vuex'
import SubmitCreditAccountTransactionModal from '~/components/credits/SubmitCreditAccountTransactionModal'
import RegenerateReferralIdModal from '~/components/credits/RegenerateReferralIdModal'
import { RefreshIcon } from '@vue-hero-icons/outline'

export default {
  props: ['user'],
  components: {
    Panel,
    PanelHeader,
    RefreshIcon,
    LinkButton,
    NLButton,
    SubmitCreditAccountTransactionModal,
    RegenerateReferralIdModal,
  },
  data() {
    return {
      destinationAccountId: null,
      showRegenerateConfirmation: false,
    }
  },
  computed: {
    ...mapGetters(['credit-accounts/referrer', 'credit-accounts/referral']),
    referrerAccount() {
      return this['credit-accounts/referrer'](this.user.id)
    },
    referralAccount() {
      return this['credit-accounts/referral'](this.user.id)
    },
    referrerAccountBalance() {
      return this.referrerAccount ? this.referrerAccount.balance : 0
    },
    referralAccountBalance() {
      return this.referralAccount ? this.referralAccount.balance : 0
    },
    aggregateBalance() {
      return this.referrerAccountBalance + this.referralAccountBalance
    },
  },
  methods: {
    debitReferrer() {
      this.destinationAccountId = this.referrerAccount.id
      this.$emit('showDebitModal', { accountId: this.destinationAccountId })
    },
    creditReferrer() {
      this.destinationAccountId = this.referrerAccount.id
      this.showModal('submit-credit-transaction-modal')
    },
    debitReferral() {
      this.destinationAccountId = this.referralAccount.id
      this.$emit('showDebitModal', { accountId: this.destinationAccountId })
    },
    creditReferral() {
      this.destinationAccountId = this.referralAccount.id
      this.showModal('submit-credit-transaction-modal')
    },
    showModal(name) {
      this.$nextTick(() =>
        this.$modal.show(name, {
          creditAccountId: this.destinationAccountId,
        })
      )
    },
    clear() {
      this.destinationAccountId = null
    },
    regenerateReferralId() {
      this.showRegenerateConfirmation = true
      this.$modal.show('regenerate-referral-id-modal')
    },
  },
}
</script>
