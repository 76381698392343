<template>
  <div>
    <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Order Bumps</h3>
      <div class="flex flex-row items-center">
        <select v-model="orderBumpsFilterSelected" class="w-52 mr-2 rounded border-gray-300 py-2 text-sm">
          <option :value="null" disabled>Filter by product</option>
          <option :value="null">All</option>
          <option v-for="item in orderBumpsFilter" :key="item.sku" :value="item.sku">
            {{ item.title }}
          </option>
        </select>

        <router-link
          :to="{ name: 'create-order-bump' }"
          class="rounded-sm bg-gray-700 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-500 hover:text-white focus:bg-teal-500 focus:text-white focus:outline-none"
        >
          <i class="fas fa-plus mr-2" /> Add Order Bump
        </router-link>
      </div>
    </div>

    <div v-if="loaded" class="px-4 pb-12">
      <div v-if="orderBumps.length">
        <div class="sm:-mx-2 sm:flex sm:flex-wrap">
          <div
            v-for="bump in orderBumps"
            :key="bump.slug"
            class="mt-8 sm:w-1/2 sm:px-2 lg:w-1/2 xl:w-1/2"
          >
            <order-bump-card :order-bump="bump" />
          </div>
        </div>

        <div class="mt-10 flex justify-end">
          <pagination
            :value="options.page"
            :per-page="options.per_page"
            :total="total"
            @input="onPageChange"
          />
        </div>
      </div>

      <empty-state v-else />
    </div>

    <loader v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderBumpCard from '@/components/orderBumps/OrderBumpCard'
import IndexPage from '~/mixins/IndexPage'
import _ from 'lodash'

export default {
  components: {
    OrderBumpCard,
  },
  mixins: [IndexPage],
  data() {
    return {
      orderBumpsFilterSelected: null,
      options: {
        page: 1,
        sort: 'id',
        order: 'desc',
        per_page: 100,
      },
    }
  },
  computed: {
    ...mapGetters({
      getOrderBumpById: 'order-bumps/byId',
    }),
    orderBumps() {
      return this.ids.map(id => this.getOrderBumpById(id))
        .filter(bump => !this.orderBumpsFilterSelected || this.orderBumpsFilterSelected == bump.product)
    },
    orderBumpsFilter() {
      return _.chain(this.ids.map(id => this.getOrderBumpById(id)))
        .groupBy(bump => bump.product)
        .map((item, sku) => ({ sku, title: `${sku} (${item.length}) - ${item[0].content}` }))
        .sort((a, b) => a?.sku?.localeCompare(b?.sku))
        .value()
    },
  },
  async mounted() {},
  methods: {
    ...mapActions({
      indexOrderBumps: 'order-bumps/index',
    }),
    async fetchItems(options) {
      return await this.indexOrderBumps(options)
    },
  },
}
</script>
