<template>
  <div class="">
    <div>
      <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200">
        <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Categories & Pricing</h3>
      </div>
    </div>

    <div class="mt-8">
      <div v-if="loaded">
        <div v-if="categories.length" class="mb-10">
          <div class="overflow-hidden bg-white shadow sm:rounded-md">
            <ul class="divide-y divide-gray-200">
              <li v-for="category in categories" :key="category.id">
                <router-link
                  :to="{ name: 'edit-category', params: { id: category.id } }"
                  class="relative block transition duration-150 ease-in-out focus:outline-none"
                >
                  <div class="absolute top-0 right-0 mt-2 mr-6">
                    <button
                      class="block transform text-teal-500 transition duration-150 hover:scale-125 hover:text-teal-400"
                    >
                      <svg
                        class="h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div class="text-sm text-gray-500">
                        <div class="font-bold leading-5 text-teal-600">{{ category.name }}</div>
                        <div>{{ category.description }}</div>
                      </div>
                      <div class="items-center text-sm leading-5 text-gray-500">
                        <div>Price: ${{ category.price.original_price }}</div>
                        <div>Premium Price: ${{ category.price.original_premium_price }}</div>
                        <div>Display Price: ${{ category.price.original_display_price }}</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="mt-5 flex justify-end">
            <pagination
              :value="options.page"
              :per-page="options.per_page"
              :total="total"
              @input="onPageChange"
            />
          </div>
        </div>
        <empty-state v-else />
      </div>
      <loader v-else />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'

export default {
  mixins: [IndexPage],
  components: {},
  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 10,
    },
  }),

  computed: {
    ...mapGetters({
      categories: 'categories/all',
    }),
  },

  methods: {
    ...mapActions({
      fetchItems: 'categories/fetch',
    }),
  },
}
</script>
