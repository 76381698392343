<template>
  <div>
    <div class="overflow-hidden rounded bg-white shadow-sm">
      <h1 class="border-b border-gray-200 px-4 py-2 text-xl font-bold lg:px-8 lg:py-4">
        {{ formTitle }}
      </h1>

      <div
        v-if="isEditingLimited"
        class="ml-3 mr-3 mt-3 rounded-b border-t-4 border-teal-500 bg-teal-100 px-4 py-3 text-teal-900 shadow-md"
        role="alert"
      >
        <div class="flex">
          <div class="py-1">
            <svg
              class="mr-4 h-6 w-6 fill-current text-teal-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="font-bold">The editing is limited</p>
            <p class="text-sm">
              The Promo Code has been used
              <b>{{ promoCode.applications_count }}</b> time(s).
            </p>
          </div>
        </div>
      </div>

      <!-- BASIC INFO -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3">
          <span class="font-semibold tracking-wide">Basic Info</span>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div>
            <div>
              <label class="mb-1 text-base tracking-wide">Code</label>
            </div>
            <div>
              <input
                class="form-input w-full"
                type="text"
                v-model="form.code"
                placeholder="PROMOCODE"
              />
              <error :error="getError('code')" />
            </div>
          </div>
          <div class="mt-6">
            <div>
              <label class="mb-1 text-base tracking-wide">Description (banner on top of the page)</label>
            </div>
            <textarea
              v-model="form.description"
              class="form-textarea w-full"
              placeholder="Get $15 off your first 2 weeks..."
            ></textarea>
            <error :error="getError('description')" />
            <div class="mt-3">
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="form.show_banner" class="form-checkbox h-5 w-5" />
                <span class="ml-2 text-sm text-gray-800">Show banner</span>
              </label>
              <error :error="getError('show_banner')" />
            </div>
          </div>
          <div class="mt-6">
            <div>
              <label class="mb-1 text-base tracking-wide">Description (checkout page)</label>
            </div>
            <textarea
              v-model="form.description_checkout"
              class="form-textarea w-full"
              placeholder="Get $15 off your first 2 weeks..."
            ></textarea>
            <error :error="getError('description_checkout')" />
            <div class="mt-3">
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="form.show_banner_checkout" class="form-checkbox h-5 w-5" />
                <span class="ml-2 text-sm text-gray-800">Show checkout page description</span>
              </label>
              <error :error="getError('show_banner_checkout')" />
            </div>
          </div>
        </div>
      </div>
      <!-- REGULATIONS -->
      <div
        class="border-b border-gray-200 px-4 pt-10 pb-10 sm:flex lg:px-8"
        :class="{ 'bg-red-100': hasError('regulations') }"
      >
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">Weekly Regulations</div>
          <div class="mt-4 text-sm text-gray-600">
            Add rules to each week application individually.
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <error class="mb-4" :error="getError('regulations')" />
          <div
            v-if="form.regulations.length"
            class="mb-4 overflow-hidden bg-white shadow sm:rounded-md"
          >
            <ul class="divide-y divide-gray-200">
              <li
                v-for="regulation in form.regulations"
                :key="regulation.weekly_ordinal_number"
                class="px-4 py-4 sm:px-6"
              >
                <FormattedRegulation
                  :regulation="regulation"
                  :editable="promoCode === undefined"
                  @remove="removeRegulation"
                />
              </li>
            </ul>
          </div>
          <div
            v-if="!!promoCode === false"
            class="grid gap-y-2 rounded-lg bg-gray-200 p-3 py-6 shadow"
          >
            <InputGroup label="Type">
              <select v-model="newRegulation.type" class="form-select w-full">
                <option v-for="item in types" :key="item.slug" :value="item.slug">
                  {{ item.label }}
                </option>
              </select>
            </InputGroup>
            <InputGroup label="Amount">
              <input type="text" v-model="newRegulation.amount" class="form-input w-full" />
            </InputGroup>
            <InputGroup label="Max. discount" v-if="isMaxDiscountVisible">
              <input type="text" v-model="newRegulation.maximum_discount" class="form-input w-full" />
            </InputGroup>

            <InputGroup label="Shipping & Tax">
              <div class="mt-2 flex">
                <div class="w-1/2">
                  <label class="mb-2 inline-flex items-center">
                    <input
                      type="checkbox"
                      v-model="newRegulation.free_shipping"
                      class="form-checkbox h-5 w-5"
                    />
                    <span class="ml-2 text-sm text-gray-800">Cover All Shipping</span>
                  </label>
                </div>
                <div class="w-1/2">
                  <label class="mb-2 inline-flex items-center">
                    <input type="checkbox" v-model="newRegulation.free_tax" class="form-checkbox h-5 w-5" />
                    <span class="ml-2 text-sm text-gray-800">Cover All Tax</span>
                  </label>
                </div>
              </div>
            </InputGroup>

            <InputGroup
              label="Week Number"
              help-text="Specify which week the following settings will apply to. Leave blank for a recurring application."
            >
              <select v-model="newRegulation.weekly_ordinal_number" class="form-select w-full">
                <option :value="null">{{ null }}</option>
                <option v-for="number in numbers" :key="number" :value="number">
                  {{ number }}
                </option>
              </select>
            </InputGroup>

            <div class="flex justify-end">
              <NLButton
                type="button"
                class="px-2 py-1"
                @click="addRegulation"
                :disabled="canNotAddRegulation"
              >
                Add Regulation
              </NLButton>
            </div>
          </div>
        </div>
      </div>
      <!-- LIMITERS -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">Application Limiters</div>
          <div class="mt-4 text-sm text-gray-600">
            Specify any criteria that should be met in order to qualify for promo code application.
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div class="mt-10">
            <div>
              <label class="mb-1 text-base tracking-wide">Subscription Status</label>
            </div>
            <div class="mt-4">
              <div class="grid grid-cols-2 lg:grid-cols-4">
                <div class="col-span-1" v-for="status in userStatuses" :key="status.slug">
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox lg:h-5 lg:w-5"
                      v-model="form.whitelisted_statuses"
                      :value="status.slug"
                    />
                    <span class="ml-2 text-sm text-gray-800">{{ status.label }}</span>
                  </label>
                </div>
              </div>
              <p class="mt-1 text-sm text-gray-600">
                Only users with these subscription statuses can apply the Promo Code.
              </p>
            </div>
          </div>
          <div class="-mx-6 mt-10 sm:flex">
            <div class="px-6 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">Expiration Date</label>
              </div>
              <div>
                <datepicker
                  :update-on-input="true"
                  v-model="form.expires_on"
                  :input-props="{
                    class: 'form-input w-full',
                    value: new Date(),
                  }"
                />
                <p class="mt-1 text-sm text-gray-600">When promo code will expire?</p>
                <error :error="getError('expires_on')" />
              </div>
            </div>
            <div class="mt-4 px-6 sm:mt-0 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">Expiration Window</label>
              </div>
              <div>
                <input
                  class="form-input w-full"
                  v-model="form.expiration_window"
                  placeholder="14"
                  min="1"
                />
                <p class="mt-1 text-sm text-gray-600">
                  Number of days when Promo Code will still be applied to the order after expiration
                  date for new customers if they hadn't had a chance to apply it before expiration
                  date.
                </p>
                <error :error="getError('expiration_window')" />
              </div>
            </div>
          </div>
          <div class="mt-10">
            <div>
              <label class="mb-1 text-base tracking-wide">Only Specific Emails</label>
            </div>
            <div class="mt-1">
              <textarea v-model="form.whitelisted_emails" class="form-textarea w-full"></textarea>
              <p class="text-sm text-gray-600">One email per line.</p>
              <error :error="getError('whitelisted_emails')" />
            </div>
          </div>
          <div class="mt-10">
            <div>
              <label class="mb-1 text-base tracking-wide">Uses Limit</label>
            </div>
            <div class="mt-1">
              <input class="form-input w-full" v-model="form.uses_limit" placeholder="100" />
              <p class="mt-1 text-sm text-gray-600">How many accounts may apply the Promo Code?</p>
              <error :error="getError('uses_limit')" />
            </div>
          </div>
          <div class="mt-10">
            <div>
              <label class="mb-1 text-base tracking-wide">Is for new customers?</label>
            </div>
            <div class="mt-1">
              <div>
                <label class="inline-flex items-center">
                  <input
                    type="checkbox"
                    class="form-checkbox lg:h-5 lg:w-5"
                    v-model="form.is_for_new_customer"
                  />
                  <span class="ml-2 text-sm text-gray-800">Yes</span>
                </label>
                <error :error="getError('is_for_new_customer')" />
              </div>
              <p class="mt-1 text-sm text-gray-600">
                Only customers with 0 active weeks can apply the promo code.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- ID.me Groups -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">ID.me Groups</div>
          <div class="mt-4 text-sm text-gray-600">
            Specify for which ID.me group the promo code will apply.
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div>
            <div>
              <label class="mb-1 text-base tracking-wide">ID.me Group</label>
            </div>
            <div class="mt-2">
              <select v-model="form.id_me_group" class="form-select w-full">
                <option value="military">Military</option>
                <option value="student">Student</option>
                <option value="medical_professional">Medical Professional</option>
                <option value="responder">First Responder</option>
                <option value="nurse">Nurse</option>
                <option value="teacher">Teacher</option>
                <option value="hospital_employee">Hospital Employee</option>
                <option :value="null">None</option>
              </select>
              <error :error="getError('id_me_group')" />
            </div>
          </div>
        </div>
      </div>
      <!-- SKU LIMITERS -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">SKU Limiters</div>
          <div class="mt-4 text-sm text-gray-600">
            This is only applicable for "Products Off" type Promo Code. Specify which products
            should be discounted
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div class="-mx-6 sm:flex">
            <div class="w-full px-6 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">SKU Begins With</label>
              </div>
              <div>
                <input
                  class="form-input w-full"
                  type="text"
                  v-model="form.sku_starts_with"
                  placeholder="SML"
                  :disabled="isEditingLimited ? 'disabled' : false"
                />
                <p class="text-xs italic text-gray-500">SKU Limiters can not be changed.</p>
                <p class="mt-1 text-sm text-gray-600">
                  Discount will be calculated off of the products that have SKU starting with this.
                </p>
                <error :error="getError('sku_starts_with')" />
              </div>
            </div>
            <div class="mt-4 w-full px-6 sm:mt-0 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">Specific SKUs</label>
              </div>
              <div>
                <input
                  class="form-input w-full"
                  type="text"
                  v-model="form.whitelisted_skus"
                  placeholder="SML5001,LRG1000"
                  :disabled="isEditingLimited ? 'disabled' : false"
                />
                <p class="text-xs italic text-gray-500">SKU Limiters can not be changed.</p>
                <p class="mt-1 text-sm text-gray-600">
                  Enter SKUs you want to be discountable separated by a comma
                </p>
                <error :error="getError('whitelisted_skus')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Order Total LIMITERS -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">Products Total Price Limiters</div>
          <div class="mt-4 text-sm text-gray-600">
            Limits promo code to affect only orders with products total price within the specified
            range. Does not prevent promo code application.
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div class="-mx-6 sm:flex">
            <div class="w-full px-6 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">Minimum Products Price</label>
              </div>
              <div>
                <div class="relative">
                  <span class="absolute left-0 top-0 bottom-0 flex items-center pl-2">
                    <svg
                      class="h-4 w-4 stroke-current text-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="12" y1="1" x2="12" y2="23"></line>
                      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                    </svg>
                  </span>
                  <input
                    class="form-input w-full pl-6"
                    type="text"
                    v-model="form.cart_amount_minimum"
                    placeholder=""
                  />
                </div>
                <p class="mt-1 text-sm text-gray-600">
                  <b>Leave blank if there is no minimum.</b> Min: 0. Max: 100000.
                </p>
                <error :error="getError('cart_amount_minimum')" />
              </div>
            </div>
            <div class="mt-4 w-full px-6 sm:mt-0 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">Maximum Products Price</label>
              </div>
              <div>
                <div class="relative">
                  <span class="absolute left-0 top-0 bottom-0 flex items-center pl-2">
                    <svg
                      class="h-4 w-4 stroke-current text-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="12" y1="1" x2="12" y2="23"></line>
                      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                    </svg>
                  </span>
                  <input
                    class="form-input w-full pl-6"
                    type="text"
                    v-model="form.cart_amount_maximum"
                  />
                </div>
                <p class="mt-1 text-sm text-gray-600">
                  <b>Leave blank if there is no maximum.</b> Min: 2. Max: 100000.
                </p>
                <error :error="getError('cart_amount_maximum')" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Unskip promo -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">Unskip Promo</div>
          <div class="mt-4 text-sm text-gray-600"></div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div class="-mx-6 sm:flex">
            <div class="w-full px-6">
              <div class="pb-6">
                <label class="inline-flex items-center">
                  <input type="checkbox" v-model="form.unskip_promo_button_enabled" class="form-checkbox h-5 w-5" />
                  <span class="ml-2 text-sm text-gray-800">Unskip Promo Enabled</span>
                </label>
<!--                <p class="text-sm text-gray-600">-->
<!--                  Show this description in user account with banner when promo code is applied-->
<!--                </p>-->
                <error :error="getError('unskip_promo_button_enabled')" />
              </div>

              <div class="pb-6" v-if="form.unskip_promo_button_enabled">
                <label for="unskip_promo_weeks_ids">
                  Weeks
                </label>
                <InputGroup
                  helpText="The promo will only be shown to orders for the selected weeks. (Only active and draft weeks are available.)"
                  :error="getError('unskip_promo_weeks_ids')"
                >
                  <select v-model="form.unskip_promo_weeks_ids" multiple class="form-input w-full" id="unskip_promo_weeks_ids">
                    <option
                      v-for="availableWeek in availableWeeks"
                      v-bind:key="availableWeek.id"
                      :value="availableWeek.id"
                    >
                      {{ availableWeek.number }}
                    </option>
                  </select>
                </InputGroup>
              </div>

              <div v-if="form.unskip_promo_button_enabled">
                <div>
                  <label class="mb-1 text-base tracking-wide">Button Text</label>
                </div>
                <div>
                  <textarea
                    class="form-input w-full"
                    type="text"
                    v-model="form.unskip_promo_button_text"
                    placeholder="UNSKIP THIS WEEK & GET 15% OFF!"
                  />
                  <error :error="getError('unskip_promo_button_text')" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- INTERNAL DATA -->
      <div class="mt-10 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">Internal Data</div>
          <div class="mt-4 text-sm text-gray-600">
            Specify any internal notes and promotional channels
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div class="">
            <div>
              <label class="mb-1 text-base tracking-wide">Promotional Channels</label>
            </div>
            <div class="mt-6">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <div class="col-span-1" v-for="(column, i) in promotionalChannels" :key="i">
                  <div v-for="channel in column">
                    <div>
                      <label class="mb-2 inline-flex items-center" :key="channel.slug">
                        <input
                          type="checkbox"
                          :value="channel.slug"
                          v-model="form.promotional_channels[channel.slug].checked"
                          class="form-checkbox"
                        />
                        <span class="ml-2 text-sm text-gray-800">{{ channel.label }}</span>
                      </label>
                      <input
                        type="text"
                        class="form-input mb-4 w-full text-sm"
                        v-if="channel.editable"
                        v-show="form.promotional_channels[channel.slug].checked"
                        v-model="form.promotional_channels[channel.slug].value"
                        placeholder="Details"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10">
            <div>
              <label class="mb-1 text-base tracking-wide">Notes</label>
            </div>
            <div class="mt-1">
              <textarea v-model="form.notes" class="form-textarea w-full" />
              <error :error="getError('notes')" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end bg-gray-100 py-6 px-4">
        <NLButton
          @click="onSave"
          class="w-full rounded bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
          :isLoading="submitting"
        >
          Submit
        </NLButton>
      </div>
    </div>
  </div>
</template>

<script>
import NLButton from '~/components/global/input/Button'
import _, {uniq} from 'lodash'
import moment from 'moment'
import HasErrors from '~/mixins/HasErrors'
import {mapActions, mapGetters} from 'vuex'
import { promotionalChannels, TYPE_PERCENT_OFF } from '~/store/extras/promo-code-presets'
import FormattedRegulation from '~/components/promo-codes/FormattedRegulation'
import InputGroup from '~/components/global/input/Group'

export default {
  mixins: [HasErrors],
  props: ['promoCode', 'save'],
  components: { InputGroup, NLButton, FormattedRegulation },
  data() {
    let promotionalChannelsInit = {}
    _.flatten(promotionalChannels).forEach(item => {
      promotionalChannelsInit[item.slug] = { checked: false, value: null }
    })

    return {
      form: {
        code: null,
        description: null,
        description_checkout: null,
        free_shipping: false,
        free_tax: false,
        first_week_type: null,
        first_week_amount: null,
        uses_limit: null,
        is_for_new_customer: null,
        promotional_channels: promotionalChannelsInit,
        notes: null,
        expires_on: null,
        expiration_window: 14,
        whitelisted_emails: '',
        whitelisted_statuses: [],
        whitelisted_skus: '',
        sku_starts_with: '',
        cart_amount_minimum: null,
        cart_amount_maximum: null,
        show_banner: false,
        show_banner_checkout: false,
        regulations: [],
        unskip_promo_button_enabled: false,
        unskip_promo_button_text: '',
        unskip_promo_weeks_ids: [],
      },
      newRegulation: {
        weekly_ordinal_number: null,
        type: null,
        amount: null,
        free_tax: false,
        free_shipping: false,
        maximum_discount: 0,
      },
      availableWeeks: [],
      isLoading: false,
    }
  },
  async mounted() {
    await this.retrieveOrderWeeks()
  },
  watch: {
    promoCode() {
      this.populateForm()
    },
    newRegulation: {
      deep: true,
      handler() {
        this.resetError('regulations')
      },
    },
    'form.regulations'() {
      this.resetError('regulations')
    },
  },
  computed: {
    ...mapGetters({
      types: 'promo-codes/types',
      promotionalChannels: 'promo-codes/promotionalChannels',
      userStatuses: 'promo-codes/userStatuses',
      findOrderWeekById: 'order-weeks/byId',
    }),
    isEditingLimited() {
      return this.promoCode ? this.promoCode.is_applied : false
    },
    preparedData() {
      const data = _.assign({}, this.form)
      if (this.form.whitelisted_emails) {
        data.whitelisted_emails = _.filter(this.form.whitelisted_emails.split('\n')).join(',')
      }
      return data
    },
    formTitle() {
      return this.promoCode ? 'Manage Promo Code' : 'New Promo Code'
    },
    numbers() {
      return [...Array(20).keys()].map(i => i + 1)
    },
    canNotAddRegulation() {
      return (
        this.newRegulation.type === null ||
        this.newRegulation.amount === null ||
        isNaN(this.newRegulation.amount) ||
        isNaN(this.newRegulation.maximum_discount)
      )
    },
    isMaxDiscountVisible(){
      return this.newRegulation.type === TYPE_PERCENT_OFF
    }
  },
  methods: {
    ...mapActions({
      fetchOrderWeeks: 'order-weeks/fetch',
    }),
    onSave() {
      this.wrapSubmit(this.save(this.preparedData)).catch(() => this.errorMessage('Error Occurred'))
    },
    populateForm() {
      this.form = {
        code: this.promoCode.code,
        description: this.promoCode.description,
        description_checkout: this.promoCode.description_checkout,
        free_shipping: this.promoCode.free_shipping,
        free_tax: this.promoCode.free_tax,
        uses_limit: this.promoCode.uses_limit,
        is_for_new_customer: this.promoCode.is_for_new_customer,
        promotional_channels: _.assign(
          {},
          this.form.promotional_channels,
          this.promoCode.promotional_channels
        ),
        notes: this.promoCode.notes,
        expires_on: this.promoCode.expires_on ? moment(this.promoCode.expires_on).toDate() : null,
        id_me_group: this.promoCode.id_me_group,
        expiration_window: this.promoCode.expiration_window,
        whitelisted_emails:
          this.promoCode.whitelisted_emails && this.promoCode.whitelisted_emails.join('\n'),
        whitelisted_statuses: this.promoCode.whitelisted_statuses,
        whitelisted_skus:
          this.promoCode.whitelisted_skus && this.promoCode.whitelisted_skus.join(','),
        sku_starts_with: this.promoCode.sku_starts_with && this.promoCode.sku_starts_with.join(','),
        cart_amount_minimum: this.promoCode.cart_amount_minimum,
        cart_amount_maximum: this.promoCode.cart_amount_maximum,
        show_banner: this.promoCode.show_banner,
        show_banner_checkout: this.promoCode.show_banner_checkout,
        regulations: this.promoCode.regulations.data,
        unskip_promo_button_enabled: this.promoCode.unskip_promo_button_enabled,
        unskip_promo_button_text: this.promoCode.unskip_promo_button_text || '',
        unskip_promo_weeks_ids: this.promoCode.unskip_promo_weeks_ids || [],
      }
    },
    addRegulation() {
      if (
        this.form.regulations.find(
          i => i.weekly_ordinal_number === this.newRegulation.weekly_ordinal_number
        )
      ) {
        this.errorMessage('There is a regulation for this week.')
        return
      }
      this.form.regulations.push(_.assign({}, this.newRegulation))
      this.newRegulation = {
        amount: null,
        type: null,
        weekly_ordinal_number: null,
        free_tax: false,
        free_shipping: false,
        maximum_discount: 0,
      }
    },
    removeRegulation(regulation) {
      this.form.regulations.splice(this.form.regulations.indexOf(regulation), 1)
    },
    async retrieveOrderWeeks() {
      const results = await this.fetchOrderWeeks({ params: { status: ['active', 'draft'] } })
      let ids = results.data.map(week => week.id)
      if (this.promoCode) {
        ids.push(...this.form.unskip_promo_weeks_ids)
      }
      ids = uniq(ids)

      this.availableWeeks = ids
        .map(id => this.findOrderWeekById(id))
        .sort((first, second) => new Date(second.date).getTime() - new Date(first.date).getTime())
    },
  },
}
</script>
