<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <form-component :save="createSkipPcChangeOffer"/>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import FormComponent from '~/components/skip-pc-change-offer/Form'

export default {
  components: {
    FormComponent,
  },
  methods: {
    ...mapActions(['skip-pc-change-offers/create']),
    createSkipPcChangeOffer(data, redirectToCreate = false) {
      return this['skip-pc-change-offers/create'](data).then(data => {
        this.successMessage('Skip Offer Created!')
        if (redirectToCreate) {
          this.$router.push({name: 'add-skip-pc-change-offer'})
        } else {
          this.$router.push({name: 'edit-skip-pc-change-offer', params: {id: data.data.id}})
        }
      })
    },
  },
}
</script>
