<template>
  <div>
    <div class="flex items-end justify-between">
      <BackButton title="Back to all products" route="products" />
    </div>
    <div class="mt-6 h-full grow overflow-auto">
      <h3 class="py-4 text-2xl font-semibold">Create Product</h3>

      <product-form v-model="form" @submit="submit">
        <template v-slot:skuFooter="skuFooter">
          <div>
            <label class="mt-2 inline-flex items-center">
              <input
                type="checkbox"
                v-model="sku_ignore_sales_report_validation"
                class="form-checkbox"
              />
              <span class="ml-2 text-sm text-gray-800">Ignore Netsuite SKU Validation</span>
            </label>
            <error
              :error="
                skuFooter.errors.sku_ignore_sales_report_validation
                  ? skuFooter.errors.sku_ignore_sales_report_validation[0]
                  : null
              "
            />
          </div>
        </template>
      </product-form>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import ProductForm from '~/components/products/Form'
import HasErrors from '~/mixins/HasErrors'
import BackButton from '~/components/global/UI/BackButton'

export default {
  components: {
    ProductForm,
    BackButton,
  },

  mixins: [HasErrors],

  data: () => ({
    form: {},
    sku_ignore_sales_report_validation: false,
  }),

  methods: {
    ...mapActions({
      createProduct: 'products/create',
    }),
    submit(wrapSubmit) {
      wrapSubmit(
        this.createProduct({
          ...this.form,
          sku_ignore_sales_report_validation: this.sku_ignore_sales_report_validation,
        })
      )
        .then(data => {
          this.successMessage('Product Created!')
          this.$router.push({ name: 'edit-product', params: { sku: data.data.sku } })
        })
        .catch(err => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.errorMessage('Error', this.$get(err, 'response.data.message'))
        })
    },
  },
}
</script>
