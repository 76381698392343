<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <header class="items-center justify-between md:flex">
      <h4 class="mb-4 font-bold">Credit Transaction Ledger</h4>
      <div class="flex items-center justify-between space-x-4 md:justify-end">
        <LinkButton @click="$emit('showDebitModal', { accountId: account.id })">Debit</LinkButton>
        <NLButton class="py-1 px-2" @click="showModal">Add Credit or Refund</NLButton>
      </div>
    </header>
    <h6 class="mt-4 mb-6 text-gray-700 md:mt-0 md:mb-0">
      <strong>{{ balance | dollars }}</strong> is current available balance
    </h6>

    <div class="divide-y-2 divide-gray-200">
      <div v-for="transaction in transactions" :key="transaction.id">
        <div class="px-2 py-5">
          <div class="flex justify-between">
            <div
              class="flex items-center space-x-1"
              :class="{
                'text-teal-500': transaction.amount > 0,
                'text-red-500': transaction.amount < 0,
              }"
            >
              <svg
                class="h-5 w-5 stroke-current"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span class="font-semibold">{{ transaction.amount | dollars }}</span>
            </div>
            <div class="flex items-center space-x-1">
              <svg
                class="h-4 w-4 stroke-current text-gray-500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                ></path>
              </svg>
              <span class="text-gray-600">{{ transaction.date | date }} </span>
            </div>
          </div>
          <div class="mt-3">
            <span class="text-gray-800">{{ reason(transaction).title }}</span>
            <span v-if="transaction.reason_details">
              <span class="text-gray-700">({{ transaction.reason_details }})</span>
            </span>
          </div>
          <div class="mt-3" v-show="order(transaction).deliver_at">
            <div class="text-gray-600">Applied on order delivered at:</div>
            <div class="text-gray-800">{{ order(transaction).deliver_at | date }}</div>
          </div>
        </div>
      </div>
    </div>

    <AddCreditOrRefundModal :user="user" v-if="isModalShown" @close="hideModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddCreditOrRefundModal from '~/components/users/AddCreditOrRefundModal'
import NLButton from '~/components/global/input/Button'
import LinkButton from '~/components/global/UI/LinkButton'

export default {
  props: ['user'],
  components: { AddCreditOrRefundModal, LinkButton, NLButton },
  data: () => ({ isModalShown: false }),
  methods: {
    reason(transaction) {
      return this['reasons/byId'](transaction.reason)
    },
    order(transaction) {
      return this['orders/byId'](transaction.order)
    },
    showModal() {
      this.isModalShown = true
      this.$nextTick(() => this.$modal.show('add-credit'))
    },
    hideModal() {
      this.isModalShown = false
      this.$modal.hide('add-credit')
    },
  },
  computed: {
    ...mapGetters([
      'reasons/byId',
      'orders/byId',
      'credit-accounts/regular',
      'credit-transactions/byAccountId',
    ]),
    balance() {
      return (this.account && this.account.balance) || 0
    },
    account() {
      return this['credit-accounts/regular'](this.user.id)
    },
    transactions() {
      return this.account ? this['credit-transactions/byAccountId'](this.account.id) : []
    },
  },
}
</script>
