<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">
          Create carrier
        </h2>
      </div>
    </div>

    <div class="mt-8">
      <div class="rounded-md bg-white p-5">
        <CarrierForm
          v-model="form"
          v-on:submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CarrierForm from '~/pages/carriers/Form'

export default {
  components: {
    CarrierForm
  },
  data: () => ({
    form: {},
  }),
  methods: {
    ...mapActions({
      createCarrier: 'carriers/create',
    }),
    submit(wrapSubmit) {
      wrapSubmit(this.createCarrier(this.form))
        .then(data => {
          this.successMessage('Carrier created')
          this.$router.push({ name: 'edit-carrier', params: { id: data.data.id } })
        })
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
  }
}
</script>
