import { schema } from 'normalizr'
import _ from 'lodash'

const processStrategy = category => {
  const relations = {
    price: _.get(category, 'price.data'),
  }

  return { ...category, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'categories',
  {},
  {
    processStrategy,
  }
)
