import _ from 'lodash'

export default {
  data: () => ({
    loading: false,
    loaded: false,
    requestQueued: false,
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 20,
    },
    key: 'id',
    ids: [],
    total: 0,
  }),

  computed: {
    filteredOptions() {
      const keys = []
      for (const key in this.options) {
        if (this.options.hasOwnProperty(key) && this.options[key]) {
          keys.push(key)
        }
      }
      return _.pick(this.options, keys)
    },
  },

  methods: {
    loadPage() {
      if (this.loading === true) {
        this.requestQueued = true
        return
      }
      this.loaded = false
      this.requestQueued = false
      this.loading = true

      this.fetchItems(this.filteredOptions)
        .then(data => {
          this.total = this.$get(data, 'meta.pagination.total', 0)
          this.ids = _.map(data.data, el => this.$get(el, this.key))
          this.loading = false

          if (this.requestQueued) this.loadPage()
        })
        .catch(err => {
          this.total = 0
          const message =
            (err.response && err.response.data && err.response.data.message) || 'Error!'
          this.errorMessage(message)
          this.loading = false
          if (this.requestQueued) this.loadPage()
        })
        .finally(() => (this.loaded = true))
    },
    reloadPage() {
      this.options.page = 1
      this.loadPage()
    },
    onPageChange(page) {
      this.options.page = page
      this.loadPage()
    },
    onSearch() {
      this.options.page = 1
      this.loadPage()
    },
  },

  mounted() {
    this.loadPage()
  },
}
