export const creditReasonSettings = {
  'broken-jar': { withMeals: true },
  'broken-seal': { withMeals: true },
  'broken-tray': { withMeals: true },
  'change-meals-post-cutoff': { withMeals: true },
  'damaged-package': { withMeals: true },
  'delivery-delay': { withMeals: false },
  'discretionary-ce-creditrefund': {
    withMeals: false,
    withExtraNotes: { value: true, caption: 'Extra notes' },
  },
  'foreign-material': {
    withMeals: true,
    picks: {
      items: ['Hair', 'Plastic', 'Bone'],
      caption: 'Foreign Materials',
      isSingleChoice: false,
    },
    withExtraNotes: { value: true, caption: 'Other items?' },
  },
  'fulfillment-shortage': { withMeals: true },
  'food-safety-sick': { withMeals: true, withExtraNotes: { value: true, caption: 'Extra notes' } },
  'meal-issuess': {
    withMeals: true,
    withExtraNotes: { value: true, caption: 'Extra notes' },
    picks: {
      items: ['Spoiled meal', 'Bloated seal'],
      isSingleChoice: false,
    },
  },
  'meal-selection-confirmation': { withMeals: true },
  'melted-gel-packs': { withMeals: false },
  'missing-meal': { withMeals: true },
  'missing-meal-component': {
    withMeals: true,
    withExtraNotes: { value: true, caption: 'Extra notes' },
  },
  'missing-order': {
    withMeals: false,
    picks: { items: ['delivered', 'not shipped'], isSingleChoice: true },
  },
  other: { withMeals: false, withExtraNotes: { value: true, caption: 'Extra notes' } },
  'promo-code': { withMeals: false, withExtraNotes: { value: true, caption: 'Extra notes' } },
  'received-wrong-order-mispick': {
    withMeals: false,
    withExtraNotes: { value: false, caption: 'Extra notes' },
  },
  'request-to-skip-week-after-cutoff': {
    withMeals: false,
    withExtraNotes: { value: false, caption: 'Extra notes' },
  },
}
