import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

Vue.filter('date', (string, format = 'M/D/Y') => {
  return (string && moment(string).format(format)) || '-'
})
Vue.filter('datetime_tz', (string, format = 'M/D/Y ha z') => {
  return (string && moment(string).format(format)) + 'CST' || '-'
})

Vue.filter('dollars', value => {
  return value !== undefined && null !== value ? '$' + value.toFixed(2) : '-'
})

Vue.filter('capitalize', str => {
  return _.capitalize(str)
})

Vue.filter('ordinal', n => {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
})

Vue.filter('plural', (val, count = 2) => {
  const lastCharacter = val.slice(val.length - 1, val.length)

  if ((count > 1 || count === 0) && lastCharacter !== 's') {
    return `${val}s`
  }

  return val
})
