<template>
  <modal name="add-icon-modal" :adaptive="true" height="auto" :scrollable="true">
    <header class="relative border-b border-gray-200 px-6 py-4">
      <button
        @click.prevent.stop="$modal.hide('add-icon-modal')"
        class="absolute top-0 right-0 mt-4 mr-4 md:hidden"
      >
        <svg
          class="h-5 w-5 text-teal-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <h3 class="text-2xl font-semibold">New Popup Icon</h3>
    </header>

    <form @submit.prevent="submit" class="mt-4 divide-y-2">
      <div class="space-y-4 py-4 px-4">
        <InputGroup
          label="name"
          help-text="This is not visible to user. Admin way to identify the item."
          :error="getError('name')"
        >
          <input type="text" class="form-input w-full" v-model="form.name" />
        </InputGroup>
        <InputGroup label="Title" help-text="This is what user see" :error="getError('title')">
          <input type="text" class="form-input w-full" v-model="form.title" />
        </InputGroup>
        <InputGroup
          label="Subtitle"
          help-text="Shown under title. Optional"
          :error="getError('description')"
        >
          <input type="text" class="form-input w-full" v-model="form.description" />
        </InputGroup>
        <InputGroup label="Image" :error="getError('image')">
          <image-field v-model="form.image" />
        </InputGroup>
      </div>

      <div class="flex justify-end bg-gray-100 px-4 py-4">
        <button
          :disabled="isBusy"
          type="submit"
          class="transaction block flex w-full items-center space-x-2 rounded bg-teal-600 px-4 py-3 text-sm font-semibold tracking-tight text-white duration-300 hover:bg-teal-500 disabled:cursor-not-allowed disabled:opacity-50 md:inline-flex md:w-auto"
        >
          <svg
            class="h-4 w-4 animate-spin"
            v-show="isBusy"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>Submit</span>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import ImageField from '~/components/products/ImageField'

export default {
  mixins: [HasErrors],
  components: { ImageField },
  data() {
    return {
      form: {
        name: '',
        title: '',
        description: '',
        file: null,
      },
      isBusy: false,
    }
  },
  methods: {
    ...mapActions({
      storeIcon: 'popup-icons/store',
    }),
    submit() {
      this.isBusy = true
      this.wrapSubmit(this['storeIcon'](this.form))
        .then(() => {
          this.successMessage('New icon created!')
          this.$modal.hide('add-icon-modal')
          this.$emit('added')
        })
        .catch(e => {
          this.errorMessage('Error Occurred!')
        })
        .finally(() => (this.isBusy = false))
    },
  },
  computed: {
    ...mapGetters({}),
  },
}
</script>
