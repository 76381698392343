<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-4 sm:mt-6">
    <div v-if="!isLoading">
      <!-- Button Back -->
      <div class="space-x-4 sm:flex sm:items-center">
        <button class="text-gray-700 underline hover:text-gray-800">
          <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
            <i class="fas fa-long-arrow-alt-left mr-2" />
            Back to {{ user.email }} details
          </router-link>
        </button>
        <span class="hidden sm:mx-4 sm:inline-block">&bull;</span>
        <Impersonate
          :user-id="userId"
          class="mt-2 bg-gray-300 text-gray-800 shadow-sm hover:bg-gray-400 sm:mt-0"
        />
        <span class="hidden sm:mx-4 sm:inline-block">&bull;</span>
        <router-link
          target="_blank"
          :to="{ name: 'edit-buffer', params: { id: order.buffer_id } }"
          class="inline-flex items-center space-x-2 hover:underline"
        >
          <svg
            class="h-4 w-4 text-current text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
              fill-rule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clip-rule="evenodd"
            />
          </svg>
          <span>See the buffer</span>
        </router-link>
      </div>

      <PanelSection>
        <div v-if="orderId">
          <div class="border-b border-gray-300 bg-white py-3 px-4">
            <h4 class="text-base font-semibold">Post Cutoff Changes for order #{{ order.id }}</h4>
          </div>
          <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6">
            <div>
              <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                Order Week
              </div>
              <div>
                <span class="font-bold">{{ order.week }}</span>
                <span class="mx-2">&bull;</span>
                <span v-if="order.is_skipped" class="text-sm font-semibold text-pink-600"
                  >Skipped</span
                >
                <span v-if="!order.is_skipped" class="text-sm font-semibold text-teal-600"
                  >Unskipped</span
                >
              </div>
            </div>
            <div class="mt-4">
              <div class="sm:flex sm:items-center">
                <div class="sm:flex-1">
                  <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Order Cutoff
                  </div>
                  <div>
                    <span class="font-semibold text-gray-700">{{
                      order.cutoff_at | datetime_tz
                    }}</span>
                  </div>
                </div>
                <div class="mt-2 sm:mt-0 sm:flex-1">
                  <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Post Cutoff Deadline
                  </div>
                  <div>
                    <span class="font-semibold text-gray-700">{{
                      order.post_cutoff_changes_deadline_at | datetime_tz
                    }}</span>
                  </div>
                </div>
                <div class="mt-2 sm:mt-0 sm:flex-1">
                  <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Delivery Date
                  </div>
                  <div>
                    <span class="font-semibold text-gray-700">{{ order.deliver_at | date }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                Shipping to
              </div>
              <div>
                {{ address.line_1 }} {{ address.line_2 }} {{ address.city }}, {{ address.state }}
                {{ address.zip_code }}
              </div>
            </div>
            <div class="mt-4 flex space-x-6">
              <div class="">
                <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">Processed order charges</div>
                <div class="font-semibold">Total ${{ order.total_cost }}</div>
                <div class="font-semibold" v-if="order.post_cutoff_cost">Only Post cutoff ${{ order.post_cutoff_cost }}</div>
                <div v-if="!order.post_cutoff_cost && !order.is_skipped">No post cutoff changes</div>
              </div>
              <div class="" v-if="cart && !order.is_skipped">
                <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Cart totals
                  <i class="fas fa-info-circle ml-2 hover:cursor-pointer text-teal-500"  @click="(showOrderCartTotals = !showOrderCartTotals)" />
                </div>
                <div>Total ${{ cart.costs.subscription.total_cost }}</div>
                <div>Pre cutoff ${{ cart.costs.pre_cutoff_changes.total_cost }}</div>
                <div>Post cutoff ${{ cart.costs.post_cutoff_changes.total_cost }}</div>
              </div>
            </div>
          </div>
          <div class="rounded bg-white py-3 px-4 shadow-inner">
            <div class="flex items-center space-x-2">
              <div class="flex items-center">
                <svg
                  class="h-4 w-4 stroke-current text-orange-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                  ></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
                <span class="ml-2 text-sm text-gray-600"
                  >Post Cutoff Changes window closes at
                  {{ order.post_cutoff_changes_deadline_at | datetime_tz }}.</span
                >
              </div>
              <div class="text-sm text-gray-600">
                <span v-if="order.post_cutoff_funds_return_strategy">
                  Any refundable amount will be
                  <a
                    v-on:click.prevent="showFundsReturnStrategyModal"
                    class="font-semibold text-teal-700 underline hover:text-teal-600"
                    href="#"
                  >
                    {{ order.post_cutoff_funds_return_strategy }}ed
                  </a>
                </span>
                <span v-if="!order.post_cutoff_funds_return_strategy">
                  Funds return strategy is not set,
                  <a
                    v-on:click.prevent="showFundsReturnStrategyModal"
                    class="inline-block rounded-full bg-teal-100 px-3 py-1 text-sm font-bold text-teal-700"
                    href="#"
                    >set it up?</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!orderId">
          <div class="shadeow rounded bg-white p-4 text-gray-700">
            No Post Cutoff Changes eligible order.
          </div>
        </div>
      </PanelSection>

      <div v-if="orderId">
        <PanelSection v-if="showOrderCartTotals">
          <order-cart-totals :cart="cart" />
        </PanelSection>
        <PanelSection>
          <allow-changes-post-cutoff :user-id="userId" :order-id="orderId" />
        </PanelSection>
        <PanelSection>
          <skip-post-cutoff :user-id="userId" :order-id="orderId" />
        </PanelSection>
        <PanelSection>
          <PostCutoffActivity :order="order" />
        </PanelSection>
      </div>
    </div>

    <Loader v-if="isLoading" />
    <FundsReturnStrategyModal :order="order" :user="user" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '~/components/global/Loader'
import SkipPostCutoff from './../../components/post-cutoff/SkipPostCutoff'
import AllowChangesPostCutoff from './../../components/post-cutoff/AllowChangesPostCutoff'
import PostCutoffActivity from './../../components/post-cutoff/PostCutoffActivity'
import FundsReturnStrategyModal from './../../components/post-cutoff/FundsReturnStrategyModal'
import OrderCartTotals from '../../components/post-cutoff/OrderCartTotals.vue'

export default {
  components: {
    Loader,
    SkipPostCutoff,
    AllowChangesPostCutoff,
    PostCutoffActivity,
    FundsReturnStrategyModal,
    OrderCartTotals
},
  data() {
    return {
      isLoading: false,
      userId: null,
      orderId: null,
      addressId: null,
      showOrderCartTotals: false,
    }
  },
  computed: {
    user() {
      return this['users/byId'](this.userId)
    },
    order() {
      return this['orders/byId'](this.orderId)
    },
    cart() {
      return this['carts/byId'](this.order?.cart)
    },
    address() {
      return this['addresses/byId'](this.addressId)
    },
    ...mapGetters(['orders/byId', 'users/byId', 'addresses/byId', 'carts/byId']),
  },
  async created() {
    try {
      this.isLoading = true
      this.userId = parseInt(this.$route.params.id, 10)
      await this.fetchUser()
      await this.fetchOrder()
    } catch (e) {
      this.errorMessage('Error occurred')
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    fetchUser() {
      return this['users/fetchById']({
        id: this.userId,
        params: {
          include: [
            'activePromoCodeApplication',
            'compedAccount',
            'promoCodeApplications',
            'defaultAddress',
            'kids',
          ],
        },
      })
    },
    async fetchOrder() {
      try {
        const response = await this['orders/fetchPostCutoffChangesEligible'](this.user.id)
        this.orderId = response.data.id
        this.addressId = response.data.address.data.id
      } catch (e) {
        this.orderId = null
      }
    },
    showFundsReturnStrategyModal() {
      this.$modal.show('funds-return-strategy-modal')
    },
    ...mapActions(['orders/fetchPostCutoffChangesEligible', 'users/fetchById']),
  },
}
</script>
