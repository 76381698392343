<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <form-component :save="createExperiment" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import FormComponent from '~/components/experiments/Form'

export default {
  components: {
    FormComponent,
  },
  methods: {
    ...mapActions(['experiments/create']),
    createExperiment(data) {
      return this['experiments/create'](data).then(data => {
        this.successMessage('Experiment Created!')
        this.$router.push({ name: 'edit-experiment', params: { id: data.data.id } })
      })
    },
  },
}
</script>
