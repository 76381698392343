import { schema } from 'normalizr'
import products from '~/store/schema/products'
import category from './categories'

const processStrategy = bestseller => {
  const relations = {
    products: _.has(bestseller, 'products') ? bestseller.products.data : null,
  }
  return { ...bestseller, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity('bestsellers', { products: [products] }, { processStrategy })
