export const EDITABLE_PRODUCT_FIELDS = [
  'is_active',
  'is_premium',
  'name',
  'with',
  'item_price',
  'fixed_rating',
  'packing_name',
  'ingredients',
  'meal-components',
  'rating-attributes',
  'package',
  'description',
  'excerpt',
  'image',
  'nutrition_image',
  'badge',
  'allergens',
  'indicators',
  'tags',
  'related_products',
  'customers_also_loved_products',
  'heating_instructions',
]
