import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities, resolveIdAndParams } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
  byIds: state => ids => state.items.filter(item => ids.includes(item.id)),
  byOrderId: state => orderId => state.items.filter(item => item.order_id === orderId),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {}
