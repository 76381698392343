// auth.js
export const LOGOUT = 'logout'
export const FETCH_USER_SUCCESS = 'fetch_user_success'

export const BATCH_ADD_OR_UPDATE = 'batch_add_or_update'
export const RESET_STATE = 'reset_state'
export const REMOVE = 'remove'

// products.js
export const BATCH_ADD_OR_UPDATE_PRODUCTS_DICTIONARY = 'batch_add_or_update_products_dictionary'
