<template>
  <div class="mx-10">
    <div class="rounded-md flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Mass Apply Credits</h3>
    </div>
    <div class="mt-8">
      <div v-if="!isLoading">
        <FormSection title="CSV file">
          <p>
            The CSV file <span class="font-bold">must</span> contain the following columns, in the same order.
            <br>
            The order_id field is optional.
          </p>
            <table class="w-full table-auto bg-white mt-3">
              <thead>
              <tr class="border-b-2 font-bold">
                <th class="border px-4 py-2">email</th>
                <th class="border px-4 py-2">amount</th>
                <th class="border px-4 py-2">reason</th>
                <th class="border px-4 py-2 text-left">order_id</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center">
                <td class="border px-4 py-2 text-left align-top">
                  useremail@gmail.com
                </td>
                <td class="border px-4 py-2 text-left align-top">
                  $2.20
                </td>
                <td class="border px-4 py-2 text-left align-top">
                  Credit Reason
                </td>
                <td class="border px-4 py-2 text-left align-top">
                  696969
                </td>
              </tr>
              <tr class="text-center">
                <td class="border px-4 py-2 text-left align-top">
                  useremail@gmail.com
                </td>
                <td class="border px-4 py-2 text-left align-top">
                  -5.20
                </td>
                <td class="border px-4 py-2 text-left align-top">
                  Credit Reason
                </td>
                <td class="border px-4 py-2 text-left align-top">

                </td>
              </tr>
              </tbody>
            </table>

          <FileUploadField @input="handleFileUpload"/>
        </FormSection>

        <div class="text-sm text-red-500 mt-4" v-if="firstError">{{ firstError }}</div>

        <div class="flex mt-6">
          <button-loader label="Apply Credits" :loading="saving" @click="store"/>
        </div>
      </div>
      <loader v-else/>
    </div>
  </div>
</template>
<script>
import ButtonLoader from "@/components/global/ButtonLoader.vue";
import Loader from "@/components/global/Loader.vue";
import {mapActions} from "vuex";
import HasErrors from '~/mixins/HasErrors';
import FormSection from "../../components/global/UI/form/FormSection.vue";
import FileUploadField from "@/components/products/FileUploadField.vue";

export default {
  mixins: [
    HasErrors
  ],
  components: {
    FileUploadField,
    Loader,
    ButtonLoader,
    FormSection,
  },
  data: () => ({
    isLoading: false,
    saving: false,
    csv: null,
    batches: []
  }),
  async mounted() {
    await this.fetchOrderBatches()
    this.isLoading = false
  },
  computed: {},
  methods: {
    ...mapActions({
      getBatches: 'batches/fetch',
      massApplyCredits: 'batches/massApplyCredits',
    }),
    async fetchOrderBatches() {
      this.batches = await this.getBatches({name: 'AdminMassApplyCredits'})
    },
    async store() {
      this.saving = true
      this.wrapSubmit(this.massApplyCredits(this.csv))
        .then(() => {
          this.successMessage('The credits are being processed')
          this.$router.push({name: 'mass-apply-credits'})
        })
        .catch(e => {
          const msg = e.response.data?.message ?? 'Error Occurred'
          this.errorMessage(msg)
        })
        .finally(() => {
          this.saving = false
        })
    },
    handleFileUpload( file ){
      this.csv = file;
    },
  }
}
</script>
