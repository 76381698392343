<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex justify-between py-4">
      <h3 class="text-3xl">Order Weeks</h3>
      <button class="btn btn-green" @click.prevent="create">
        <i class="fas fa-plus mr-2" /> Create Order Week
      </button>
    </div>

    <table class="mt-4 w-full table-auto bg-white">
      <thead>
        <tr class="border-b-2 font-bold">
          <th class="w-8 border px-4 py-2 text-sm font-semibold uppercase text-gray-600">ID</th>
          <th class="border px-4 py-2 text-left text-sm font-semibold uppercase text-gray-600">
            Week
          </th>
          <th class="border px-4 py-2 text-left text-sm font-semibold uppercase text-gray-600">
            Status
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="orderWeek in sortedOrderWeeks" :key="orderWeek.id" class="text-center">
          <td class="border px-4 py-2">
            {{ orderWeek.id }}
          </td>
          <td class="border px-4 py-2 text-left">
            <div>
              <div>
                <span class="block sm:mr-4 sm:inline-block">{{ orderWeek.number }}</span>
                <span class="text-sm text-gray-700">{{ orderWeek.date | date }}</span>
              </div>
              <div v-if="orderWeek.buffers">
                <div v-for="buffer in orderWeek.buffers.data" :key="buffer.id">
                  <div>
                    <router-link :to="{ name: 'edit-buffer', params: { id: buffer.id } }">
                      {{ buffer.productionCycle.data.name }} buffer
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="border px-4 py-2">
            <span class="rounded-sm px-2 text-xs font-semibold uppercase text-teal-600">{{
              orderWeek.status | capitalize
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import Confirm from '~/components/swal/Confirm'
import swal from 'sweetalert2'

export default {
  computed: {
    ...mapGetters({
      orderWeeks: 'order-weeks/all',
    }),
    sortedOrderWeeks() {
      return _.orderBy(this.orderWeeks, 'id', 'desc')
    },
  },

  mounted() {
    this.fetchItems({ params: { include: 'buffers.productionCycle' } })
  },

  methods: {
    ...mapActions({
      fetchItems: 'order-weeks/fetch',
      createOrderWeek: 'order-weeks/create',
    }),
    create() {
      Confirm.fire({
        icon: 'info',
        text: 'Are you sure you wish to create a new Order Week?',
        preConfirm: () => {
          return this.createOrderWeek().catch(error => {
            const message =
              error.response && error.response.status === 422 ? error.response.data.message : error
            swal.showValidationMessage(`Request failed: ${message}`)
          })
        },
      }).then(result => result.value && this.successMessage('Order Week created!'))
    },
  },
}
</script>
