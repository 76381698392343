<template>
  <div :class="{ 'flex space-x-4': inline, 'has-error': error }">
    <div v-if="label" :class="{ 'w-1/5 mt-3': inline }">
      <label
        class="text-xs font-semibold uppercase tracking-wider text-gray-600"
        :class="{ 'block w-full text-right': inline }"
        >{{ label }}
      </label>
      <slot name="label" />
    </div>
    <div class="flex-1">
      <slot />

      <div class="text-sm leading-5 text-gray-600">
        <p class="mt-1" v-if="helpText" v-html="helpText"></p>
        <slot name="helper" />
      </div>

      <div v-if="error">
        <error :error="error" />
      </div>
    </div>
  </div>
</template>

<script>
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: {
    label: {
      required: false,
    },
    helpText: {
      required: false,
    },
    inline: {
      required: false,
      default: true,
    },
    error: {
      required: false,
    },
  },
}
</script>
