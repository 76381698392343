import { schema } from 'normalizr'
import _ from 'lodash'
import roles from '~/store/schema/roles'
import kids from '~/store/schema/kids'
import addresses from '~/store/schema/addresses'
import subscriptions from '~/store/schema/subscriptions'
import compedAccounts from '~/store/schema/comped-accounts'
import promoCodeApplications from '~/store/schema/promo-code-applications'
import profiles from '~/store/schema/profiles'

const processStrategy = user => {
  const relations = {
    kids: _.get(user, 'kids.data'),
    defaultAddress: _.get(user, 'defaultAddress.data'),
    subscription: _.get(user, 'subscription.data'),
    compedAccount: _.get(user, 'compedAccount.data'),
    roles: _.get(user, 'roles.data'),
    profile: _.get(user, 'profile.data'),
    activePromoCodeApplication: _.has(user, 'activePromoCodeApplication')
      ? { ...user.activePromoCodeApplication.data, ...{ user_id: user.id } }
      : null,
    promoCodeApplications: _.has(user, 'promoCodeApplications')
      ? _.map(user.promoCodeApplications.data, application => ({
          ...application,
          ...{ user_id: user.id },
        }))
      : null,
  }
  return { ..._.omit(user, []), ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'users',
  {
    defaultAddress: addresses,
    subscription: subscriptions,
    kids: [kids],
    profile: profiles,
    compedAccount: compedAccounts,
    roles: [roles],
    activePromoCodeApplication: promoCodeApplications,
    promoCodeApplications: [promoCodeApplications],
  },
  {
    processStrategy,
  }
)
