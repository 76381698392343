<template>
  <div>
    <div class="flex items-center space-x-2" v-if="!readonly">
      <products-autocomplete v-model="sku" class="w-full" :params="{ active: 1 }" />
      <NLButton @click="add" class="px-2 py-1 font-semibold">Add</NLButton>
    </div>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(sku, index) in products" :key="index" v-if="productBySku(sku)" class="flex py-4">
        <img class="h-10 w-10 rounded-full" :src="productBySku(sku).image['cart-mobile']" />
        <div class="ml-3 flex-1">
          <p class="text-sm font-medium text-gray-900">{{ sku }}</p>
          <p class="text-sm text-gray-500">{{ productBySku(sku).name }}</p>
        </div>
        <div class="flex h-10 w-10 items-center justify-end" v-if="!readonly">
          <button @click.prevent="remove(sku)">
            <TrashIcon class="h-5 w-5 text-red-300" />
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { without } from 'lodash'
import { TrashIcon } from '@vue-hero-icons/outline'
import { mapGetters } from 'vuex'
export default {
  props: {
    readonly: { type: Boolean, required: false },
    initial: { type: Array, required: false },
  },
  components: { TrashIcon },
  created() {
    this.products = this.initial
  },
  data() {
    return {
      sku: '',
      products: [],
    }
  },
  computed: {
    ...mapGetters({ productBySku: 'products/bySKU' }),
  },
  methods: {
    add() {
      if (this.sku.length === 0) return
      this.products.push(this.sku)
    },
    remove(sku) {
      this.products.splice(this.products.indexOf(sku), 1)
    },
  },
  watch: {
    products(value) {
      this.$emit('update', value)
    },
  },
}
</script>
