<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <h4 class="mb-5 font-bold">Price variance groups</h4>
    <form class="mt-4 w-full" @submit.prevent="save">
      <div class="form-group select" :class="{ 'has-error': hasError('price_variance_group') }">
        <label class="">
          <select v-model="price_variance_group">
            <option :value="null">None</option>
            <option v-for="item in variances" :key="item.id" :value="item.id">
              Group {{ item.id }}
            </option>
          </select>
        </label>
        <error :error="getError('price_variance_group')" />
      </div>

      <button type="submit"
        class="btn btn-green"
        :disabled="!isDirty"
        :class="{
          'opacity-50': !isDirty
        }">
        Save
      </button>
    </form>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '~/mixins/FormMixin'

export default {
  props: ['user'],
  mixins: [FormMixin],
  data() {
    return {
      price_variance_group: null
    }
  },
  mounted() {
    this['price-variance-groups/fetch']()
    this.price_variance_group = this.user.price_variance_group_id
  },
  methods: {
    ...mapActions([
      'users/fetchById',
      'price-variance-groups/fetch',
      'price-variance-groups/savePriceVarianceForUser'
    ]),
    save() {
      const params = {
        id: this.user.id,
        price_variance_group: this.price_variance_group
      }

      this.wrapSubmit(this['price-variance-groups/savePriceVarianceForUser'](params))
        .then(() => {
          this.successMessage('Price variance updated!')
          this['users/fetchById'](this.user.id)
        })
        .catch(() => {
          this.errorMessage('Error Occurred!')
        })
    }
  },
  computed: {
    ...mapGetters({
      variances: 'price-variance-groups/all'
    }),
    isDirty() {
      return this.price_variance_group !== this.user.price_variance_group_id
    }
  },
}
</script>
