<template>
  <div class="relative flex items-center justify-end">
    <button
      class="rounded bg-teal-500 px-4 py-3 text-white transition duration-300 hover:bg-teal-400"
      :class="{ 'opacity-50': loading }"
      @click="$emit('click')"
    >
      {{ label }}
    </button>

    <div v-if="loading" class="ml-3">
      <div class="lds-spinner">
        <div v-for="i in 12" :key="i" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Save',
    },
  },
}
</script>
