import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byUserId: state => id => state.items.filter(item => item.user === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetchByUserId(store, userId) {
    return axios.get('api/billing/admin/refunds', { params: { user_id: userId } }).then(res => {
      processEntities(
        res.data.data.map(item => ({ ...item, user: userId })),
        'refunds'
      )
      return res.data
    })
  },
  makeRefund(store, params) {
    return axios.post('api/billing/admin/refunds', params)
  },
}
