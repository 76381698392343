<template>
  <div>
    <!-- Button Back -->
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'bestsellers' }"
        ><i class="fas fa-long-arrow-alt-left mr-2" />Back to bestsellers</router-link
      >
    </button>
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">
          Create New Bestsellers Order
        </h2>
      </div>
    </div>
    <!-- Content -->
    <div class="mt-8">
      <div class="rounded-md bg-white p-5">
        <BestsellersForm v-model="form" v-on:submit="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import { find, get, debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import HasErrors from '~/mixins/HasErrors'
import BestsellersForm from '~/components/bestsellers/Form'

export default {
  components: {
    BestsellersForm,
  },
  mixins: [HasErrors],
  data: () => ({
    form: {},
  }),
  methods: {
    ...mapActions({
      createBestseller: 'bestsellers/create',
    }),
    submit(wrapSubmit) {
      wrapSubmit(this.createBestseller(this.form))
        .then(data => {
          this.successMessage('Bestsellers created')
          this.$router.push({ name: 'edit-bestseller', params: { id: data.data.id } })
        })
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
  },
  watch: {},
}
</script>
