<template>
  <div class="h-full grow overflow-auto p-5">
    <div v-if="user">
      <!-- Button Back -->
      <button class="mb-6 text-gray-700 underline hover:text-gray-800">
        <router-link :to="{ name: 'users' }">
          <i class="fas fa-long-arrow-alt-left mr-2" />Back To All Users</router-link
        >
      </button>

      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9"
          >
            {{ user.email }}
          </h2>
          <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
            <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
              <user-status :name="user.status"></user-status>
            </div>
            <div
              v-if="user.is_admin"
              class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6"
            >
              <svg
                class="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clip-rule="evenodd"
                />
              </svg>
              Admin
            </div>
          </div>
        </div>
        <div class="mt-5 flex lg:mt-0 lg:ml-4">
          <span class="rounded-md shadow-sm">
            <Impersonate :user-id="user.id" class="bg-teal-500 text-white hover:bg-teal-600" />
          </span>
        </div>
      </div>
      <!-- Content -->
      <div class="mt-8 lg:flex">
        <div class="lg:mr-4 lg:w-2/3">
          <panel>
            <template v-slot:header>
              <PanelHeader>
                <h2 class="text-base font-semibold">User Info</h2>
              </PanelHeader>
            </template>

            <form @submit.prevent="saveUser" class="space-y-4">
              <InputGroup label="First Name" :error="getError('first_name')">
                <input type="text" class="form-input w-full" v-model="form.first_name" />
              </InputGroup>
              <InputGroup label="Last Name" :error="getError('last_name')">
                <input type="text" class="form-input w-full" v-model="form.last_name" />
              </InputGroup>
              <InputGroup label="Email" :error="getError('email')">
                <input type="text" class="form-input w-full" v-model="form.email" />
              </InputGroup>
              <InputGroup :error="getError('promo_code')" label="Promo Code">
                <promo-codes-autocomplete v-model="form.promo_code"></promo-codes-autocomplete>
              </InputGroup>
              <div class="mt-4 flex justify-end">
                <NLButton class="px-2 py-1" type="submit"> Save User </NLButton>
              </div>
            </form>
          </panel>
          <user-referral-credits :user="user" @showDebitModal="showDebitModal" />
          <user-credit :user="user" @showDebitModal="showDebitModal" />
          <user-refunds :user="user" />
          <user-kids :user="user" />
          <ship-day-changes-log :user="user" />
          <user-changes-log :user="user" />
          <order-skips-log :user="user" />
          <skip-week-log :user="user" />
          <change-meals-log :user="user" />
          <subscription-changes-log :user="user" />
          <promo-codes
            class="mt-6"
            :user="user"
            :active-promo-code-application="activePromoCodeApplication"
          />
        </div>
        <div class="mt-6 lg:mt-0 lg:w-1/3">
          <change-production-cycle :user="user" />
          <post-cutoff-changes :user="user" />
          <comped-account-management :user="user" />
          <price-variance-group :user="user" />
          <roles-management :user="user" />
          <scheduled-cancellations :user="user" />
          <cancel-account :user="user" />
          <remove-payment-methods :user="user" />
          <pause-account :user="user" />
          <reset-password :user="user" class="mt-10" />
          <exclude-from-m-o-v :user="user" />
          <create-manual-order :user="user" />
          <profile-preferred-carrier :user="user" />
        </div>
      </div>
    </div>
    <DebitModal
      v-if="debitAccountId"
      :creditAccountId="debitAccountId"
      @close="debitAccountId = null"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import Panel from '~/components/global/UI/Panel'
import PanelHeader from '~/components/global/UI/PanelHeader'
import NLButton from '~/components/global/input/Button'
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import ChangeMealsLog from '@/components/users/logs/ChangeMealsLog'
import SkipWeekLog from '@/components/users/logs/SkipWeekLog';
import OrderSkipsLog from '@/components/users/logs/OrderSkipsLog'
import PromoCodes from '~/components/users/PromoCodes'
import ShipDayChangesLog from '~/components/users/logs/ShipDayChangesLog'
import UserChangesLog from '@/components/users/logs/UserChangesLog'
import UserCredit from '~/components/users/UserCredit'
import UserReferralCredits from '~/components/users/UserReferralCredits'
import UserRefunds from '~/components/users/UserRefunds'
import UserKids from '~/components/users/UserKids'
import ChangeProductionCycle from '~/components/users/ChangeProductionCycle'
import CancelAccount from '~/components/users/CancelAccount'
import RemovePaymentMethods from '~/components/users/RemovePaymentMethods'
import ScheduledCancellations from '~/components/users/ScheduledCancellations'
import CompedAccountManagement from '~/components/users/CompedAccountManagement'
import RolesManagement from '~/components/users/RolesManagement'
import PauseAccount from '~/components/users/PauseAccount'
import SubscriptionChangesLog from '@/components/users/logs/SubscriptionChangesLog'
import UserStatus from '~/components/users/UserStatus'
import ResetPassword from '~/components/users/ResetPassword'
import PostCutoffChanges from '~/components/users/PostCutoffChanges'
import ExcludeFromMOV from '~/components/users/ExcludeFromMOV'
import DebitModal from '~/components/credits/DebitModal'
import PriceVarianceGroup from "@/components/users/PriceVarianceGroup";
import CreateManualOrder from "@/components/users/CreateManualOrder/CreateManualOrder";
import ProfilePreferredCarrier from "@/components/users/ProfilePreferredCarrier";

export default {
  components: {
    Panel,
    PanelHeader,
    NLButton,
    DebitModal,
    PostCutoffChanges,
    ChangeMealsLog,
    OrderSkipsLog,
    SkipWeekLog,
    PromoCodes,
    ShipDayChangesLog,
    UserChangesLog,
    UserCredit,
    UserReferralCredits,
    UserRefunds,
    UserKids,
    ChangeProductionCycle,
    CancelAccount,
    RemovePaymentMethods,
    ScheduledCancellations,
    CompedAccountManagement,
    RolesManagement,
    PauseAccount,
    SubscriptionChangesLog,
    UserStatus,
    ResetPassword,
    ExcludeFromMOV,
    PriceVarianceGroup,
    CreateManualOrder,
    ProfilePreferredCarrier,
  },
  mixins: [HasErrors],
  data: () => ({
    form: {
      first_name: null,
      last_name: null,
      email: null,
    },
    debitAccountId: null,
  }),
  async mounted() {
    const include = [
      'activePromoCodeApplication',
      'compedAccount',
      'promoCodeApplications',
      'defaultAddress',
      'kids',
      'profile',
      'profile.preferredCarriers'
    ]
    this['users/fetchById']({ id: this.id, params: { include } }).then(this.resetForm)
    this['credit-accounts/fetch'](this.id)
  },
  methods: {
    ...mapActions(['users/fetchById', 'users/update', 'credit-accounts/fetch']),
    saveUser() {
      const params = {
        ...this.form,
        ...{ promo_code: this.selectedPromoCode && this.selectedPromoCode.code },
      }
      this.wrapSubmit(this['users/update']({ id: this.id, params }))
        .then(() => this.successMessage('User Updated!'))
        .catch(() => {
          this.errorMessage('Error Occurred!')
        })
    },
    resetForm() {
      _.assign(this.form, _.pick(this.user, Object.keys(this.form)))
      this.form.promo_code =
        this.activePromoCodeApplication && this.activePromoCodeApplication.promoCode
    },
    showDebitModal(data) {
      this.debitAccountId = data.accountId
      this.$nextTick(() => this.$modal.show('debit-modal'))
    },
  },
  computed: {
    ...mapGetters(['users/byId', 'users/all', 'promo-codes/byId', 'promo-code-applications/byId']),
    id() {
      return +this.$route.params.id
    },
    user() {
      return this['users/byId'](this.id)
    },
    activePromoCodeApplication() {
      return (
        this.user.activePromoCodeApplication &&
        this['promo-code-applications/byId'](this.user.activePromoCodeApplication)
      )
    },
    selectedPromoCode() {
      return this['promo-codes/byId'](this.form.promo_code)
    },
  },
}
</script>
