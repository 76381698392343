export function mapDataKeysToParent(obj) {
  // Base case: if the current object is not an object or is null, return it as is
  if (typeof obj !== 'object' || obj === null) return obj;

  // If the current object has a 'data' property, merge the 'data' object with the parent
  if (obj.hasOwnProperty('data')) {
      // remove the 'data' property from the current object
      let { data, ...rest } = obj;
      return { ...rest, ...mapDataKeysToParent(data) };
  }

  // Iterate over the properties of the object
  let result = {};
  for (let key in obj) {
      // Recursively process each property
      result[key] = mapDataKeysToParent(obj[key]);
  }
  return result;
}
