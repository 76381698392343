<template>
  <div class="overflow-hidden rounded-lg bg-white p-4 shadow">
    <div class="border-b border-gray-300 bg-white py-3">
      <h4 class="text-base font-semibold">{{ productionCycle.name }}</h4>
    </div>
    <div class="mt-4">
      <div class="block space-y-6 md:flex md:space-y-0 md:divide-x-4 md:divide-gray-100">
        <div class="w-full px-0 md:w-1/2 md:px-8">
          <div>
            <div class="whitespace-nowrap text-xs uppercase tracking-wider text-gray-700">
              Cuts Off
            </div>
            <div>{{ productionCycle.order_cutoff_day }}</div>
          </div>
          <div class="mt-4">
            <div class="whitespace-nowrap text-xs uppercase tracking-wider text-gray-700">
              Ships On
            </div>
            <div>
              <span v-for="day in productionCycle.shipDays" :key="day">{{ day + ' ' }}</span>
            </div>
          </div>
        </div>
        <div class="w-full px-0 md:w-1/2 md:px-8">
          <div>
            <div class="whitespace-nowrap text-xs uppercase tracking-wider text-gray-700">
              Allocated if signed up on:
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div v-for="day in weekDays" :key="day">
                <label class="inline-flex items-center space-x-2">
                  <input
                    @change="updateAllocatableDays"
                    :checked="productionCycle.isAllocatedOnDays.includes(day)"
                    :value="day"
                    type="checkbox"
                    class="form-checkbox"
                  />
                  <span>{{ day }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['productionCycle'],
  data() {
    return {
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    }
  },
  methods: {
    updateAllocatableDays(e) {
      this.$emit('change', { id: this.productionCycle.id, day: e.target.value })
    },
  },
}
</script>
