import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

const getDefaultState = () => {
  return {
    items: []
  }
}

export const state = getDefaultState()

export const getters = {
  all: state => _.orderBy(state.items, ['priority'], 'asc'),
  byId: state => id => _.find(state.items, item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [types.RESET_STATE]: (state) => Object.assign(state, getDefaultState())
}

export const actions = {
  async fetch(store, params) {
    const res = await axios.get('api/sales/admin/banners', { params })
    store.commit(types.RESET_STATE)
    processEntities(res.data.data, 'saleBanners')
    return res.data
  },
  async updateBanner(store, { id, params }) {
    const res = await axios.put(`api/sales/admin/banners/${id}`, params)
    processEntities(res.data.data, 'saleBanners')
  },
  async createBanner(store, { id, params }) {
    const res = await axios.post(`api/sales/admin/banners`, params)
    processEntities(res.data.data, 'saleBanners')
    return res.data.data
  },
  async deleteBanner(store, id) {
    const res = await axios.delete(`api/sales/admin/banners/${id}`)
    processEntities(res.data.data, 'saleBanners')
    return res.data.data
  },
  async reorder(store, newOrder) {
    let orderPush = [];
    newOrder.forEach((banner, index) => {
      newOrder[index].priority = index + 1
      orderPush.push({ id: banner.id, priority: index + 1 })
    })
    store.commit(types.BATCH_ADD_OR_UPDATE, newOrder)

    const res = await axios.post(`api/sales/admin/banners/reorder`, orderPush)
    processEntities(res.data.data, 'saleBanners')
}

}
