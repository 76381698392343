import Vue from 'vue'
import _ from 'lodash'

Vue.mixin({
  computed: {
    $get() {
      return _.get
    },
  },
})
