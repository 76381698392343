import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch() {
    return axios.get('api/shipping/states').then(res => {
      processEntities(res.data.data, 'states')
      return res.data
    })
  },
  fetchById({ commit }, id) {
    return axios.get(`api/shipping/states/${id}`).then(res => {
      processEntities(res.data.data, 'states')
      return res.data
    })
  },
  updateById({ commit }, params) {
    return axios.put(`api/shipping/states/${params.id}`, params).then(res => {
      processEntities(res.data.data, 'states')
      return res.data
    })
  },
}
