<template>
  <label class="inline-flex items-center">
    <input
      type="radio"
      v-bind="$attrs"
      class="form-radio h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out"
      :value="value"
      :checked="isChecked"
      @change="$emit('change', $event.target.value)"
    />
    <span class="ml-2 cursor-pointer text-sm text-gray-800">{{ label }}</span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: "",
      required:true
    },
    modelValue: {
      default: ""
    },
    value: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value
    }
  }
}
</script>
