import axios from 'axios'
import * as types from '../mutation-types'
import {addOrUpdateItems, processEntities} from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  reset: (state) => state.items = [],
}

export const actions = {
  async fetch(store, {path}) {
    return await axios.get('api/marketing/pages').then(res => {
      store.commit('reset')
      processEntities(res.data.data, 'marketingPages')
      return res.data.data
    })
  },
  async fetchSingle(store, id) {
    const results = await axios.get(`api/marketing/pages/${id}`)
    return results.data.data
  },
  async update(store, {id, params}) {
    return await axios.put(`api/admin/marketing/pages/${id}`, params)
  },
}
