<template>
  <div class="px-4">
    <div class="border-b border-gray-300 pb-5 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9">
          Promo Codes
        </h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <span class="rounded-md shadow-sm">
          <router-link
            :to="{ name: 'promo-codes-mass-apply' }"
            class="focus:shadow-outline-blue inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
          >
            Mass Apply Promo Codes
          </router-link>
        </span>
        <span class="ml-3 rounded-md shadow-sm">
          <router-link
            :to="{ name: 'create-promo-code' }"
            class="focus:shadow-outline-teal inline-flex items-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none active:bg-teal-700"
          >
            Create Promo Code
          </router-link>
        </span>
      </div>
    </div>
    <div class="mt-6">
      <search
        v-model="options.code"
        @input="reloadPage"
        class="flex w-full items-center justify-between md:justify-start"
        placeholder="Find Promo Code"
        :input-classes="{
          'bg-white focus:bg-gray-100 w-full py-2 px-3 text-gray-800 ': true,
        }"
        :button-classes="{
          'px-3 py-2 bg-teal-500 text-white rounded-sm': true,
        }"
      />
    </div>
    <div class="mt-6 bg-white">
      <Table>
        <template v-slot:thead>
          <Heading>Code</Heading>
          <Heading>Status</Heading>
          <Heading>Notes</Heading>
          <Heading>Applied Count</Heading>
          <Heading>Expires on</Heading>
          <Heading>Actions</Heading>
        </template>
        <tr v-for="promoCode in promoCodes" :key="promoCode.id">
          <Cell>
            <router-link
              :to="{ name: 'edit-promo-code', params: { id: promoCode.id } }"
              class="block font-semibold text-teal-600 hover:text-teal-700"
            >
              <span>{{ promoCode.code }}</span>
            </router-link>
            <span class="block text-sm text-gray-600">
              {{ promoCode.created_at }}
            </span>
          </Cell>
          <Cell>
            <span
              class="inline-block shrink-0 rounded-full px-2 py-0.5 text-xs font-medium leading-4 text-teal-800"
              :class="[promoCode.is_active ? 'bg-teal-100' : 'bg-red-100']"
              >{{ promoCode.status }}</span
            >
          </Cell>
          <Cell>
            <p class="mt-2 whitespace-pre-line text-sm leading-5 text-gray-700">
              {{ promoCode.notes }}
            </p>
          </Cell>
          <Cell>
            <div
              class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-300 font-semibold"
            >
              {{ promoCode.applications_count }}
            </div>
          </Cell>
          <Cell>
            <span v-if="promoCode.expires_on">{{ promoCode.expires_on }}</span>
            <span v-else>N/A</span>
          </Cell>
          <Cell>
            <div class="flex flex-col items-center justify-center">
              <a
                href="#"
                @click.prevent="disablePromoCode(promoCode)"
                class="focus:shadow-outline-blue inline-flex flex-1 items-center justify-center rounded-br-lg border border-transparent text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 stroke-current text-red-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </a>
              <a
                v-clipboard:copy="promoCode.application_link_url"
                v-clipboard:success="() => successMessage('Copied!')"
                class="focus:shadow-outline-blue inline-flex flex-1 cursor-pointer items-center justify-center rounded-bl-lg border border-transparent text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none"
              >
                <span class="ml-3">Copy Link</span>
              </a>
            </div>
          </Cell>
        </tr>
      </Table>
    </div>
    <div class="mt-5 flex justify-end">
      <pagination
        :value="options.page"
        :per-page="options.per_page"
        :total="total"
        @input="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'
import Table from '~/components/global/UI/Table'
import Heading from '~/components/global/UI/TableHeading'
import Cell from '~/components/global/UI/TableCell'

export default {
  mixins: [IndexPage],
  components: { Table, Heading, Cell },
  data: () => ({
    options: {
      per_page: 20,
      code: null,
    },
  }),
  computed: {
    ...mapGetters([
      'promo-codes/byIds',
      'promo-codes/presetBySlug',
      'promo-codes/promotionalChannelBySlug',
    ]),
    promoCodes() {
      return this['promo-codes/byIds'](this.ids)
    },
  },
  methods: {
    ...mapActions({
      fetchItems: 'promo-codes/fetch',
    }),
    ...mapActions(['promo-codes/remove', 'promo-codes/disable']),
    removePromoCode(promoCode) {
      Confirm.fire({
        text: `Are you sure you wish to delete the Promo Code #${promoCode.code}?`,
        preConfirm: () => {
          return this['promo-codes/remove'](promoCode.id).catch(error => {
            const message =
              error.response && error.response.status === 422 ? error.response.data.message : error
            Swal.showValidationMessage(`Request failed: ${message}`)
          })
        },
      }).then(result => {
        if (result.value) {
          this.successMessage('Promo Code Deleted!')
          this.loadPage()
        }
      })
    },
    disablePromoCode(promoCode) {
      Confirm.fire({
        text: `Are you sure you wish to disable the Promo Code #${promoCode.code}?`,
        preConfirm: () => {
          return this['promo-codes/disable'](promoCode.id).catch(error => {
            const message =
              error.response && error.response.status === 422 ? error.response.data.message : error
            Swal.showValidationMessage(`Request failed: ${message}`)
          })
        },
      }).then(result => {
        if (result.value) {
          this.successMessage('Promo Code Disabled!')
          this.loadPage()
        }
      })
    },
  },
}
</script>
