<template>
  <div :key="batch.id" class="flex items-center w-full p-4 text-gray-500 bg-white rounded-lg shadow" role="alert">
    <div class="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-blue-500 bg-blue-100 rounded-lg p-4">
      <span>{{ batch.progress }}%</span>
    </div>
    <div class="ml-3 text-sm font-normal">
      <h3 class="font-medium">{{ batch.name }}</h3>
      <div class="text-base">
        {{ batch.processed_jobs }} orders processed out of {{ batch.total_jobs }}.
        {{ batch.failed_jobs }} failed.
      </div>
    </div>
    <div class="text-base font-bold ml-auto">
      {{ batchStatus(batch) }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      batch: Object
    },
    methods: {
      batchStatus(batch) {
        if (batch.cancelled_at) {
          return 'Cancelled'
        }

        // When the batch allows failures but its jobs have 0 retries set, the batch is never marked as finished.
        // https://github.com/laravel/framework/issues/36180#issuecomment-775115440
        if (batch.pending_jobs - batch.failed_jobs === 0) {
          return 'Finished'
        }

        if (!batch.finished_at) {
          return 'Processing'
        }
        return 'Finished'
      }
    }
  }
</script>
