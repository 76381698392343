<template>
  <div class="grid grid-cols-2 gap-6 sm:grid-cols-3">
    <div
      v-for="image in product.images"
      :key="image.id"
      class=""
      :class="[isBeingDeleted(image.id) ? 'animate-pulse cursor-not-allowed' : '']"
    >
      <div class="relative">
        <div class="absolute -top-2 -left-2">
          <button
            class="rounded-full border-2 border-gray-100 bg-white p-1 hover:bg-red-50 disabled:cursor-not-allowed"
            @click.prevent="removeImage(image.id)"
            :disabled="isBeingDeleted(image.id)"
          >
            <TrashIcon class="h-4 w-4 text-red-300" />
          </button>
        </div>
      </div>
      <div class="overflow-hidden rounded">
        <img :src="image.uris['meal-card-desktop']" class="aspect-square" />
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from '@vue-hero-icons/outline'
import { mapActions } from 'vuex'
export default {
  props: ['product'],
  components: { TrashIcon },
  data() {
    return {
      processingDeletion: null,
    }
  },
  methods: {
    ...mapActions({
      deleteImage: 'products/deleteImage',
      fetchProductBySku: 'products/fetchBySku',
    }),
    async removeImage(imageId) {
      this.processingDeletion = imageId
      const response = await this.deleteImage({ productSku: this.product.sku, imageId })
      this.fetchProductBySku(this.product.sku)
      this.successMessage('Image deleted')
      this.processingDeletion = null
    },
    isBeingDeleted(imageId) {
      return this.processingDeletion === imageId
    },
  },
}
</script>
