<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <aria-loader :loading="loading" />
    <button type="button" class="btn btn-sm btn-green mt-2 mb-4 px-4 py-2 w-full" @click="openModal">
      Manage orders
    </button>

    <modal @closed="$emit('close')" :adaptive="true" name="delivery-calculator-modal" height="auto">
      <header class="border-b border-gray-200 px-6 py-4">
        <div class="flex justify-between items-center">
          <h3 class="text-2xl font-semibold">
            Orders for this and future cycles
          </h3>
          <div v-if="loading" class="text-base p-2 rounded">Just a moment..</div>
        </div>
      </header>

      <div class="py-4 px-6 text-sm">
        <table class="w-full table-auto bg-white"
          :class="{
            'opacity-50': loading
          }">
          <thead>
            <tr class="border-b-2 font-bold">
              <th class="border px-4 py-2 text-left">Order week</th>
              <th class="border px-4 py-2">PC A</th>
              <th class="border px-4 py-2">PC B</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="creatable in loadedCreatables" class="text-center" :key="creatable.orderWeek.id">
              <td class="border px-4 py-2 text-left align-top">
                {{ creatable.orderWeek.number }}<br />
                {{ creatable.orderWeek.date }}
              </td>
              <td class="border px-4 py-2 text-left align-top">
                <ManualOrderRow
                  :creatable="creatable"
                  :order="creatable.pcA.order?.data?.id ? creatable.pcA.order?.data : null"
                  :order-week="creatable.orderWeek"
                  :production-cycle="creatable.pcA.productionCycle?.data"
                  :delivery-quote="creatable.pcA.deliveryQuote?.data"
                  @createOrder="confirmCreate"
                  @resumeOrder="confirmResume"
                  @cancelOrder="confirmCancel"
                />
              </td>
              <td class="border px-4 py-2 text-left align-top">
                <ManualOrderRow
                  :order="creatable.pcB.order?.data?.id ? creatable.pcB.order?.data : null"
                  :order-week="creatable.orderWeek"
                  :production-cycle="creatable.pcB.productionCycle?.data"
                  :delivery-quote="creatable.pcB.deliveryQuote?.data"
                  @createOrder="confirmCreate"
                  @resumeOrder="confirmResume"
                  @cancelOrder="confirmCancel"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="errors.length" class="font-semibold mt-4 text-center text-lg text-red-600">
          {{ errors.join('') }}
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AriaLoader from "@/components/AriaLoader";
import ManualOrderRow from "@/components/users/CreateManualOrder/ManualOrderRow";
import _ from 'lodash'

export default {
  components: {ManualOrderRow, AriaLoader },
  props: ['user'],
  data: () => ({
    loading: false,
    newOrder: {
      order_week_id: null,
      production_cycle_id: null,
    },
    creatables: [],
    errors: []
  }),
  computed: {
    ...mapGetters({
      productionCycles: 'production-cycles/all'
    }),
    loadedCreatables() {
      let groupByWeek = _.groupBy(this.creatables, (creatable) => creatable.order_week_id)
      return Object.keys(groupByWeek).map(weekId => ({
        orderWeek: groupByWeek[weekId][0].orderWeek.data,
        pcA: groupByWeek[weekId].find(grp => grp.production_cycle_id === 1),
        pcB: groupByWeek[weekId].find(grp => grp.production_cycle_id === 2),
      }))
    }
  },
  methods: {
    ...mapActions({
      creatableOrders: 'orders/creatableOrders',
      createOrder: 'orders/createOrder',
      resumeOrder: 'orders/resumeOrder',
      cancelOrder: 'orders/cancelOrder',
    }),
    async openModal() {
      this.$modal.show('delivery-calculator-modal')
      await this.reloadCreatables()
    },
    async reloadCreatables() {
      this.loading = true
      const res = await this.creatableOrders({ id: this.user.id })
      this.creatables = res.data.data
      this.loading = false;
    },
    confirmCreate(orderWeek, productionCycle) {
      if (confirm(`Please confirm you want to create a new order`)) {
        const params = {
          user_id: this.user.id,
          order_week_id: orderWeek.id,
          production_cycle_id: productionCycle.id
        }

        this.createOrder({ id: this.user.id, params })
          .then(res => {

            this.successMessage(res.message)
            this.reloadCreatables()
          })
          .catch(e => {
            const errors = e.response.data.errors;

            this.errors = Object.keys(errors).map(key => errors[key].join('') )
            this.errorMessage(this.errors.join(''))
          })
      }
    },
    confirmResume(order) {
      if (confirm(`Please confirm you want to active this order?`)) {
        const params = {
          order_id: order.id
        }

        this.resumeOrder({ id: this.user.id, params })
          .then(res => {

            this.successMessage(res.message)
            this.reloadCreatables()
          })
          .catch(e => {
            const errors = e.response.data.errors;

            this.errors = Object.keys(errors).map(key => errors[key].join('') )
            this.errorMessage(this.errors.join(''))
          })
      }
    },
    confirmCancel(order) {
      if (confirm(`Please confirm you want to CANCEL this order?`)) {
        const params = {
          order_id: order.id
        }

        this.cancelOrder({ id: this.user.id, params })
          .then(res => {

            this.successMessage(res.message)
            this.reloadCreatables()
          })
          .catch(e => {
            const errors = e.response.data.errors;

            this.errors = Object.keys(errors).map(key => errors[key].join('') )
            this.errorMessage(this.errors.join(''))
          })
      }
    }
  }
}
</script>
