<template>
  <div class="py-6 px-3 overflow-hidden rounded-lg bg-white shadow space-y-4">
    <div class="border-b border-gray-300 bg-white py-3 -mx-3">
      <h4 class="px-4 text-base font-semibold">Post Cutoff Management</h4>
    </div>

    <div>
      <router-link
        v-if="postCutoffOrderId"
        class="rounded-sm bg-teal-500 px-3 py-2 text-sm font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none"
        :to="{ name: 'post-cutoff', params: { userId: user.id } }"
      >
        Manage Post Cutoff Order
      </router-link>
      <div v-if="!postCutoffOrderId" class="text-sm px-3 py-2">No Post Cutoff Changes eligible order.</div>
    </div>

    <div>
      <router-link
        v-if="postCutoffSuspendedOrderid"
        class="rounded-sm bg-orange-500 px-3 py-2 text-sm font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none"
        :to="{ name: 'suspended-order', params: { userId: user.id } }"
      >
        Manage Suspended Order
      </router-link>
      <div v-if="!postCutoffSuspendedOrderid" class="text-sm px-3 py-2">No suspended orders</div>
    </div>

    <div>
      <router-link
        v-if="rogueOrder?.order_week_id"
        class="rounded-sm bg-orange-500 px-3 py-2 text-sm font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none"
        :to="{ name: 'rogue-order', params: { userId: user.id } }"
      >
        Create PostCutoff Rogue Order
      </router-link>
      <div v-if="!rogueOrder" class="text-sm px-3 py-2">No rogue order available</div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapDataKeysToParent } from '../../utils/mapDataKeysToParent'

export default {
  props: ['user'],
  data() {
    return {
      postCutoffOrderId: null,
      postCutoffSuspendedOrderid: null,
      rogueOrder: null,
      ongoingBufferId: null,
    }
  },
  async created() {
    this.fetchPostCutoffOrder()
    this.fetchUnsuspendPostCutoffEligibleOrder()

    await this.fetchCurrentlyOngoingBuffer()
    if (this.ongoingBuffer) {
      this.fetchPostCutoffRogueOrder()
    }
  },
  computed: {
    subscription() {
      return this['subscriptions/byId'](this.user.subscription)
    },
    ongoingBuffer() {
      return this.ongoingBufferId ? this['buffers/byId'](this.ongoingBufferId) : null
    },
    ...mapGetters([
      'subscriptions/byId',
      'buffers/byId'
    ])
  },
  methods: {
    async fetchPostCutoffOrder() {
      try {
        const { data: { id }, } = await this['orders/fetchPostCutoffChangesEligible'](this.user.id)
        this.postCutoffOrderId = id
      } catch (e) {
        this.postCutoffOrderId = null
      }
    },
    async fetchUnsuspendPostCutoffEligibleOrder() {
      try {
        const { data: { id } } = await this['orders/fetchUnsuspendPostCutoffEligibleOrder']({ userId: this.user.id })
        this.postCutoffSuspendedOrderid = id
      } catch (e) {
        this.postCutoffSuspendedOrderid = null
      }
    },
    async fetchPostCutoffRogueOrder() {
      try {
        const res = await this['orders/fetchEligibleRogueOrder']({userId: this.user.id, bufferId: this.ongoingBufferId})
        this.rogueOrder = mapDataKeysToParent(res.data.data)
      } catch (e) {
        this.rogueOrder = null
      }
    },
    async fetchCurrentlyOngoingBuffer() {
      try {
        const { data } = await this['buffers/fetchCurrentlyOngoingBuffer']()
        this.ongoingBufferId = data.id
      } catch (e) {}
    },
    ...mapActions([
      'orders/fetchPostCutoffChangesEligible',
      'orders/fetchUnsuspendPostCutoffEligibleOrder',
      'orders/fetchEligibleRogueOrder',
      'buffers/fetchCurrentlyOngoingBuffer'
    ]),
  },
}
</script>
