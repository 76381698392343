<template>
  <div v-if="product">
    <div class="flex items-end justify-between">
      <BackButton title="Back to all products" route="products" />
    </div>

    <div class="mt-6 h-full grow overflow-auto">
      <h3 class="py-4 text-2xl font-semibold">Edit {{ product.name }}</h3>

      <product-form v-if="loaded" v-model="form" :product="product" @submit="submit" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ProductForm from '~/components/products/Form'
import { uniq } from 'lodash'
import BackButton from '~/components/global/UI/BackButton'

export default {
  components: {
    ProductForm,
    BackButton,
  },

  data: () => ({
    loaded: false,
    form: {},
  }),

  computed: {
    ...mapGetters({
      productBySku: 'products/bySKU',
    }),
    sku() {
      return this.$route.params.sku
    },
    product() {
      return this.productBySku(this.sku)
    },
  },

  async created() {
    const response = await this.fetchProductBySku(this.sku)
    await this.clear()
    this.loaded = true
  },

  methods: {
    ...mapActions({
      fetchProductBySku: 'products/fetchBySku',
      updateProduct: 'products/update',
      deleteImage: 'products/deleteImage',
    }),
    async clear() {
      this.form = Object.assign(
        {},
        _.omit(this.product, [
          'image',
          'images',
          'nutrition_image',
          'tags',
          'icons',
          'rating',
          'badge',
        ]),
        {
          tags: this.product.tags,
          icons: _.map(this.product.icons.data, icon => icon.id),
          badge: this.product?.badge ? this.product.badge : null,
        }
      )
      await uniq(this.product.composables).forEach(async item => await this.fetchProductBySku(item))
    },
    submit(wrapSubmit) {
      wrapSubmit(this.updateProduct({ sku: this.sku, form: this.form }))
        .then(() => {
          this.successMessage('Product updated!')
          this.form.images = []
          this.clear()
        })
        .catch(err => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.errorMessage('Error', this.$get(err, 'response.data.message'))
        })
    },
  },
}
</script>
