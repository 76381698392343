import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  cancel(store, { id, data }) {
    return axios
      .delete(`/api/customers/admin/users/${id}/active-subscriptions`, { data })
      .then(res => {
        processEntities(res.data.data, 'subscriptions')
        return res.data
      })
  },
  scheduleCancellation(store, { id, data }) {
    return axios
      .post(`/api/customers/admin/users/${id}/auto-cancelled-subscriptions`, data)
      .then(res => {
        processEntities(res.data.data, 'subscriptions')
        return res.data
      })
  },
  unscheduleCancellation(store, id) {
    return axios
      .delete(`/api/customers/admin/users/${id}/auto-cancelled-subscriptions`)
      .then(res => {
        processEntities(res.data.data, 'subscriptions')
        return res.data
      })
  },
  pause(store, { id, data }) {
    return axios.post(`/api/sales/admin/paused-subscriptions/${id}`, data).then(res => {
      processEntities(res.data.data, 'subscriptions')
      return res.data
    })
  },
  unpause(store, id) {
    return axios.post(`/api/customers/admin/users/${id}/active-subscriptions`).then(res => {
      processEntities(res.data.data, 'subscriptions')
      return res.data
    })
  },

  async getPauseRequestSettings(store, subscriptionId) {
    const {
      delivery_day,
      min_date,
      max_date
    } = await axios.get(`/api/sales/subscription/${subscriptionId}/pause-request-settings`).then(res => {
      return res.data.data
    })

    return {
      deliveryDay: delivery_day,
      minDate: (new Date(min_date)).toISOString(),
      maxDate: (new Date(max_date)).toISOString()
    }
  }
}
