<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'promo-codes' }"
        ><i class="fas fa-long-arrow-alt-left mr-2" />Back To Promo Codes</router-link
      >
    </button>
    <form-component :promo-code="promoCode" :save="savePromoCode" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormComponent from '~/components/promo-codes/Form'

export default {
  components: {
    FormComponent,
  },
  mounted() {
    this['promo-codes/fetchById'](this.id)
  },
  computed: {
    ...mapGetters(['promo-codes/byId']),
    id() {
      return +this.$route.params.id
    },
    promoCode() {
      return this['promo-codes/byId'](this.id)
    },
  },
  methods: {
    ...mapActions(['promo-codes/update', 'promo-codes/fetchById']),
    savePromoCode(data) {
      return this['promo-codes/update']({ id: this.id, params: data }).then(() =>
        this.successMessage('Promo Code Updated!')
      )
    },
  },
}
</script>
