<template>
  <div class="mx-10">
    <div class="rounded-md flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Settings</h3>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div class="overflow-hidden bg-white shadow sm:rounded-md mb-10 ">
          <ul class="divide-y divide-gray-100">
            <li v-for="setting in settingsAll" v-bind:key="setting.id" class="p-4 text-gray-700" :class="{'border border-red-500': getError(setting.name)}">
              <div v-if="setting.type === 'boolean'" class="flex items-center mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[150px]">{{ setting.title }}</div>
                <CheckboxInput
                  v-if="setting.type === 'boolean'"
                  type="checkbox"
                  :value="getValueForId(setting.id)"
                  @input="setValueForId(setting.id, $event)"
                  class="form-checkbox h-5 w-5"
                />
              </div>

              <div v-if="setting.type !== 'boolean'" class="mb-2">
                  <div class="mb-1 text-gray-700 capitalize min-w-[150px]">{{ setting.title }}</div>
                  <TextInput
                    v-if="setting.type === 'text'"
                    :value="getValueForId(setting.id)"
                    @input="setValueForId(setting.id, $event)"
                    class="w-full block"
                  />
                  <TextInput
                    v-else-if="setting.type === 'numeric'"
                    type="number"
                    :value="getValueForId(setting.id)"
                    @input="setValueForId(setting.id, $event)"
                    class="w-full block"
                  />
                  <TextAreaInput
                    v-else-if="setting.type === 'textarea'"
                    :value="getValueForId(setting.id)"
                    @input="setValueForId(setting.id, $event)"
                    class="w-full block"
                  />
                  <div v-else-if="setting.type === 'boolean'"></div>
                  <div v-else>
                    Unknown element {{ setting.type }} for {{ setting.name }}
                  </div>
              </div>
              <p class="text-sm">{{ setting.desc }}</p>
              <p class="text-red-500 text-sm" v-if="getError(setting.name)">{{ getError(setting.name) }}</p>
            </li>
          </ul>
        </div>

        <div class="flex">
          <button-loader label="Save" :loading="saving" @click="save" />
        </div>
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import IndexPage from "@/mixins/IndexPage";
import HasErrors from '~/mixins/HasErrors'
import { groupBy } from 'lodash'
import CheckboxInput from "@/components/global/input/CheckboxInput.vue";

export default {
  mixins: [
    IndexPage,
    HasErrors
  ],
  components: {
    CheckboxInput
  },
  data: () => ({
    settingsAll: [],
    loaded: false,
    saving: false,
  }),
  async mounted() {
    try {
      await this.fetchItems()
      this.loaded = false

      this.settingsFromStore
        .filter(setting => setting.group == this.group)
        .forEach((setting) => this.settingsAll.push(setting))

      this.loaded = true
    } catch (err) {
      this.errorMessage(err?.response?.data?.message || err)
    }
  },
  computed: {
    ...mapGetters({
      settingsFromStore: 'settings/all'
    }),
    group() {
      return this.$route.params.group
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'settings/fetch',
      update: 'settings/update',
    }),
    getValueForId(id) {
      return this.settingsAll.find(s => s.id == id).payload
    },
    setValueForId(id, value) {
      this.settingsAll.map(setting => {
        if (setting.id == id) {
            setting.payload = value
        }
        return setting
      })
    },
    async save() {
      this.saving = true;
      try {
        const settings = {}
        this.settingsAll.forEach(setting => settings[setting.name] = setting.payload)

        const res = await this.wrapSubmit(this.update({ group: this.group, ...settings }))
        this.successMessage('Success', 'The settings are updated')
      } catch (err) {
        this.errorMessage('Error', this.$get(err, 'response.data.message'))
      }
      this.saving = false
    }
  }
}
</script>
