<template>
  <div>
    <div
      v-if="has"
      class="rounded-md rounded-tl-none rounded-bl-none border-l-4 border-red-300 bg-red-50 p-4"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            There were {{ errors.length }} errors with your submission
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <ul role="list" class="list-disc space-y-1 pl-5">
              <li v-for="(error, index) in errors" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XCircleIcon } from '@vue-hero-icons/solid'
export default {
  props: {
    errors: { type: Array, required: true },
  },
  components: { XCircleIcon },
  computed: {
    has() {
      return this.errors.length
    },
  },
}
</script>
