<template>
  <modal
    @closed="$emit('close')"
    :adaptive="true"
    name="debit-modal"
    height="auto"
    :scrollable="true"
  >
    <header class="border-b border-gray-200 px-6 py-4">
      <h3 class="text-2xl font-semibold">Make a debit transaction</h3>
      <div class="text-gray-600">
        <span class="font-semibold">{{ balance | dollars }}</span> is the current balance.
      </div>
    </header>

    <div class="space-y-6 px-6 py-4">
      <InputGroup label="Amount" :error="getError('amount')" helpText="Amount in dollars">
        <input type="text" v-model="form.amount" class="form-input w-full" />
      </InputGroup>

      <InputGroup label="Reason" :error="getError('reason_details')">
        <textarea v-model="form.reason_details" class="form-textarea w-full"></textarea>
      </InputGroup>
    </div>

    <footer class="flex justify-end border-t border-gray-200 bg-gray-100 px-6 py-4">
      <NLButton @click="debit" class="px-3 py-1" :isLoading="submitting"
        >Submit Transaction</NLButton
      >
    </footer>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import InputGroup from '~/components/global/input/Group'
import NLButton from '~/components/global/input/Button'

export default {
  mixins: [HasErrors],
  props: ['creditAccountId'],
  components: { InputGroup, NLButton },
  data: () => ({
    form: {
      amount: null,
      reason_details: null,
    },
  }),
  computed: {
    ...mapGetters(['credit-accounts/byId']),
    balance() {
      return this.account && this.account.balance
    },
    account() {
      return this['credit-accounts/byId'](this.creditAccountId)
    },
  },
  methods: {
    ...mapActions(['credit-accounts/submitDebitTransaction', 'credit-transactions/get']),
    debit() {
      this.wrapSubmit(
        this['credit-accounts/submitDebitTransaction']({
          creditAccountId: this.account.id,
          ...this.form,
        })
      )
        .then(() => {
          this.successMessage('Amount has been debited!')
          this['credit-transactions/get'](this.account.id)
          this.close()
        })
        .catch(() => this.errorMessage('Error occurred'))
    },
    close() {
      this.$modal.hide('debit-modal')
    },
  },
}
</script>
