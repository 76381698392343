<template>
  <div>
    <label class="mb-1 text-base tracking-wide">
      {{ label }}
    </label>

    <label
      class="block w-full cursor-pointer rounded-lg border border-gray-200 py-2 px-3 shadow transition duration-150 hover:bg-gray-50"
    >
      <input type="file" @change="uploadImage" class="hidden" />
      <span class="inline-flex items-center space-x-1">
        <UploadIcon class="h-4 w-4 text-gray-400" />
        <span class="text-sm text-gray-700">Upload Image</span>
      </span>
    </label>

    <div v-if="preview" class="mt-2">
      <div class="relative pb-3/5">
        <img
          class="image-placeholder absolute top-0 h-full w-full rounded-lg object-cover shadow-md"
          :src="preview"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UploadIcon } from '@vue-hero-icons/solid'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultImage: {
      type: String,
      default: null,
    },
  },
  components: { UploadIcon },
  data: () => ({
    file: null,
    preview: null,
  }),

  methods: {
    uploadImage(event) {
      this.file = event.target.files[0]

      const fileReader = new FileReader()

      fileReader.onload = e => (this.preview = e.target.result)
      fileReader.readAsDataURL(this.file)

      this.$emit('input', this.file)
    },
  },

  mounted() {
    if (this.defaultImage) {
      this.preview = this.defaultImage
    }
  },
}
</script>
