<template>
  <modal :adaptive="true" name="cancel-subscription" @before-open="beforeOpen" height="auto">
    <div class="m-4">
      <div class="pb-1">Please, select the cancellation reason</div>
      <hr class="mb-2" />
      <form v-if="reasons.length">
        <div :class="{ 'has-error': hasError('reason') }" class="mb-4">
          <error :error="getError('reason')" />
          <div class="radio mb-1" v-for="reason in reasons" :key="reason.id">
            <label class="inline-flex">
              <input
                name="reason"
                type="radio"
                :value="reason.id"
                v-model="form.id"
                class="mr-2 mt-1"
              />
              <span class="flex-1">{{ reason.title }}</span>
            </label>
            <div v-if="form.id === reason.id">
              <div v-if="reason.description" v-html="reason.description"></div>
              <div v-if="reason.question" class="pl-5">
                <p>{{ reason.question }}</p>
                <textarea
                  v-if="reason.question"
                  v-model="form.details"
                  class="w-full border border-gray-200 py-2 px-3 focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group button-section form-actions" v-if="!loading">
          <button class="btn btn-green mr-3" @click.prevent="close">Dismiss</button>
          <button class="btn btn-red mr-3" @click.prevent="confirm">Confirm</button>
        </div>
        <span v-else>Loading...</span>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['user'],
  data: () => ({
    loading: false,
    form: {
      id: null,
      details: null,
    },
  }),
  computed: {
    ...mapGetters(['reasons/cancel', 'reasons/byId', 'subscriptions/byId']),
    subscription() {
      return this['subscriptions/byId'](this.user.subscription)
    },
    selectedReason() {
      return this['reasons/byId'](this.form.id)
    },
    reasons() {
      return this['reasons/cancel']
    },
  },
  methods: {
    ...mapActions(['reasons/fetchCancelReasons', 'subscriptions/cancel']),
    beforeOpen() {
      this['reasons/fetchCancelReasons']()
    },
    confirm() {
      if (!this.validate() || this.loading) {
        return false
      }

      this.loading = true
      this['subscriptions/cancel']({ id: this.user.id, data: { reason: this.form } })
        .then(this.close)
        .catch(() => {})
        .then(() => (this.loading = false))
    },
    close() {
      this.$modal.hide('cancel-subscription')
    },
    validate() {
      this.resetErrors()

      if (!this.form.id) {
        this.errors.reason = ['Please select a cancellation reason.']
        return false
      }

      if (this.selectedReason.answer_required && !this.form.details) {
        this.errors.reason = ['Please provide a response to: ' + this.selectedReason.question]
        return false
      }

      return true
    },
  },
}
</script>
