export const objectToFormData = (obj, form, namespace) => {
  const formData = form || new FormData()
  let formKey = null

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      if (obj[property] instanceof Date) {
        formData.append(formKey, obj[property].toISOString())
      } else if (obj[property] instanceof File) {
        formData.append(formKey, obj[property], obj[property].name)
      } else if (typeof obj[property] === 'boolean') {
        formData.append(formKey, +obj[property])
      } else if (typeof obj[property] === 'object') {
        objectToFormData(obj[property], formData, formKey)
      } else {
        formData.append(formKey, obj[property])
      }
    }
  }

  return formData
}

export const headersFormData = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}
