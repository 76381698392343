import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
  byIds: state => ids => state.items.filter(item => ids.includes(item.id)),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch() {
    return axios.get('/api/sales/admin/production-cycles').then(res => {
      processEntities(res.data.data, 'productionCycles')
      return res.data
    })
  },
  saveAllocatableShipDays(store, { productionCycleId, days }) {
    return axios.put(
      `api/sales/admin/production-cycles/${productionCycleId}/allocateable-ship-days`,
      { days }
    )
  },
}
