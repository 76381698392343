import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities, sortByDate } from '../helpers'
import axios from 'axios'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byAccountId: state => {
    return accountId => {
      return state.items.filter(item => item.credit_account_id === accountId)
    }
  },
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => {
    addOrUpdateItems(state, items)
    state.items = [
      ...state.items
      .sort(sortByDate('date'))
    ]
  },
}

export const actions = {
  async get(state, accountId) {
    const response = await axios.get(`api/credits/credit-accounts/${accountId}/transactions`)
    processEntities(response.data.data, 'creditTransactions')
  },
}
