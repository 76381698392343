<template>
  <modal
    :adaptive="true"
    name="lock-post-cutoff-changes-modal"
    height="auto"
    classes="w-full bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
  >
    <div>
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-orange-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Are you sure you want to <strong>lock</strong> post cutoff changes for the order?
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Customer will not be able to modify order post cutoff. Any changes they have already
                made will be processed at deadline {{ order.post_cutoff_changes_deadline_at }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click.prevent="confirm"
            type="button"
            class="focus:shadow-outline-orange inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-orange-500 focus:border-orange-700 focus:outline-none sm:text-sm sm:leading-5"
          >
            Yes
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click.prevent="close"
            type="button"
            class="focus:shadow-outline inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['order', 'user'],
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions(['orders/lockPostCutoffChanges']),
    async confirm() {
      if (this.loading) {
        return false
      }

      this.loading = true

      try {
        await this.wrapSubmit(
          this['orders/lockPostCutoffChanges']({ orderId: this.order.id, userId: this.user.id })
        )
        this.successMessage('Order has been locked for post cutoff modifications')
        this.close()
      } catch (e) {
        this.errorMessage('Error occurred')
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$modal.hide('lock-post-cutoff-changes-modal')
    },
  },
}
</script>
