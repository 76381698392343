<template>
  <li>
    <a
      href="#"
      @click.prevent="toggleChildrenList"
      class="relative block transition duration-150 ease-in-out focus:outline-none"
    >
      <div class="absolute top-0 right-0 mt-2 mr-6">
        <button
          @click.prevent.stop="showEditModal(menuItem)"
          class="block transform text-teal-500 transition duration-150 hover:scale-125 hover:text-teal-400"
        >
          <svg
            class="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="flex min-w-0 flex-1 items-center">
          <div class="shrink-0"></div>
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div class="text-sm font-bold leading-5 text-teal-600">{{ menuItem.name }}</div>
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                <span class="truncate"
                  >On activate:
                  <span class="font-semibold text-gray-600">{{ onActivate }}</span></span
                >
              </div>
            </div>
            <div class="hidden md:block">
              <div v-if="hasChildren">
                <div class="text-sm leading-5 text-gray-500">Children</div>
                <div class="mt-2 space-y-1">
                  <div v-for="item in children">
                    <div class="text-sm font-medium leading-5 text-gray-600">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasChildren">
          <svg
            v-if="!childrenListVisible"
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            v-if="childrenListVisible"
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </a>
    <div v-show="childrenListVisible && hasChildren">
      <div class="border-l-2 border-teal-300 bg-gray-100 pl-6">
        <ProductMenuItem
          :menuItem="menuItem"
          v-for="menuItem in children"
          :key="menuItem.id"
          @editItem="showEditModal"
        />
      </div>
    </div>
  </li>
</template>

<script>
import ProductMenuItem from '~/components/ProductMenuItems/ProductMenuItem'
export default {
  props: ['menuItem'],
  components: { ProductMenuItem },
  name: 'ProductMenuItem',
  data() {
    return {
      childrenListVisible: false,
    }
  },
  methods: {
    toggleChildrenList() {
      this.childrenListVisible = !this.childrenListVisible
    },
    showEditModal(item) {
      this.$emit('editItem', item)
    },
  },
  computed: {
    children() {
      const children = this.menuItem.children
      if (Array.isArray(children)) {
        return children
      }
      return []
    },
    hasChildren() {
      return this.children.length > 0
    },
    onActivate() {
      return this.menuItem.on_activate
    },
  },
}
</script>
