import * as _ from 'lodash'
export const TYPE_DOLLARS_OFF = 'dollars_off'
export const TYPE_PERCENT_OFF = 'percent_off'
export const TYPE_PRODUCTS_OFF = 'products_off'

export const types = [
  { slug: TYPE_DOLLARS_OFF, label: 'Dollars off' },
  { slug: TYPE_PERCENT_OFF, label: 'Percent off' },
  { slug: TYPE_PRODUCTS_OFF, label: 'Products off' },
]
export const promotionalChannels = [
  [
    { slug: 'Organic - Facebook', label: 'Organic - Facebook' },
    { slug: 'Organic - Instagram', label: 'Organic - Instagram' },
    { slug: 'Organic - Pinterest', label: 'Organic - Pinterest' },
    { slug: 'Organic - Twitter', label: 'Organic - Twitter' },
  ],
  [
    { slug: 'Paid - Social', label: 'Paid - Social' },
    { slug: 'Paid - Search', label: 'Paid - Search' },
    { slug: 'paidOther', label: 'Paid - Other', editable: true },
  ],
  [
    { slug: 'influencerOther', label: 'Influencer', editable: true },
    { slug: 'affiliateOther', label: 'Affiliate', editable: true },
    { slug: 'B2B2COther', label: 'B2B2C', editable: true },
    { slug: 'EmailOther', label: 'Email', editable: true },
  ],
]
export const userStatuses = [
  { slug: 'active', label: 'Active' },
  { slug: 'cancelled', label: 'Cancelled' },
  { slug: 'paused', label: 'Paused' },
  { slug: 'suspended', label: 'Suspended' },
]
