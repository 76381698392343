<template>
  <router-link :to="{ name: route }">
    <button class="flex items-center space-x-1 bg-gray-100 transition duration-300 hover:underline">
      <ChevronDoubleLeftIcon class="h-4 w-4 text-gray-500" />
      <span>{{ title }}</span>
    </button>
  </router-link>
</template>

<script>
import { ChevronDoubleLeftIcon } from '@vue-hero-icons/solid'
export default {
  props: ['route', 'title'],
  components: { ChevronDoubleLeftIcon },
}
</script>
