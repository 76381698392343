<template>
  <div>
    <div>
      <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200">
        <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Popup Icons</h3>
        <button
          v-on:click.prevent="showAddModal"
          class="flex items-center space-x-1 rounded-md bg-teal-500 px-3 py-2 text-white hover:bg-teal-600"
        >
          <svg
            class="h-4 w-4 text-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="block text-lg">new icon</span>
        </button>
      </div>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div v-if="icons.length" class="mb-10">
          <div class="overflow-hidden bg-white shadow sm:rounded-md">
            <ul class="divide-y divide-gray-200">
              <PopupIcon v-for="icon in icons" :icon="icon" :key="icon.id" />
            </ul>
          </div>
        </div>
        <empty-state v-else />
        <PopupIconAddModal @added="loadPage" />
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import PopupIcon from '~/components/PopupIcons/PopupIcon'
import PopupIconAddModal from '~/components/PopupIcons/PopupIconAddModal'

export default {
  mixins: [IndexPage],
  components: { PopupIcon, PopupIconAddModal },
  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 100,
    },
  }),

  computed: {
    ...mapGetters({
      iconById: 'popup-icons/byId',
    }),
    icons() {
      return _.map(this.ids, id => this.iconById(id))
    },
  },

  methods: {
    ...mapActions({
      fetchItems: 'popup-icons/fetch',
    }),
    showAddModal() {
      this.$modal.show('add-icon-modal')
    },
  },
}
</script>
