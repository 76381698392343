<template>
  <div>
    <div class="overflow-hidden rounded bg-white shadow-sm">
      <h1 class="border-b border-gray-200 px-4 py-2 text-xl font-bold lg:px-8 lg:py-4">
        New Holiday
      </h1>

      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/4">
          <span class="font-semibold tracking-wide">Basic Info</span>
          <p class="mt-3 text-sm text-gray-600">
            If order <strong>Ship Date</strong> is fallen in between of the start and end date of
            the holiday then the following shipment rules will be applied to it.
          </p>
          <p class="mt-3 text-sm text-gray-600">
            Holiday Name is what will be used in the popup on user account
          </p>
        </div>
        <div class="mt-6 ml-0 sm:mt-0 sm:ml-6 sm:w-3/4">
          <div>
            <div>
              <label class="mb-1 text-base tracking-wide">Name</label>
            </div>
            <div>
              <input class="form-input w-full" type="text" v-model="form.name" placeholder="Name" />
              <error :error="getError('name')" />
            </div>
          </div>
          <div class="-mx-6 mt-6 sm:flex">
            <div class="px-6 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">Start Date</label>
              </div>
              <div>
                <datepicker
                  :update-on-input="true"
                  v-model="form.start_date"
                  :min-date="new Date()"
                  :input-props="{ class: 'form-input w-full' }"
                />
                <error :error="getError('start_date')" />
              </div>
            </div>
            <div class="mt-4 px-6 sm:mt-0 sm:w-1/2">
              <div>
                <label class="mb-1 text-base tracking-wide">End Date</label>
              </div>
              <div>
                <datepicker
                  :update-on-input="true"
                  v-model="form.end_date"
                  :min-date="form.start_date"
                  :input-props="{ class: 'form-input w-full' }"
                />
                <error :error="getError('end_date')" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/4 sm:pr-10">
          <div class="font-semibold tracking-wide">Rules</div>
          <p class="mt-3 text-sm text-gray-600">
            Pleas specify rule for each carrier. The "Number of days to shift" field will be used to
            add or subtract the amount of days from the order's ship date.
            <br />
            i.e. if you want to affect delivery date to be moved 1 day forward, you should put
            <code class="text-red-500">1</code> to the "Number of days to shift". This will affect
            the ship date, hence the delivery date.
          </p>
        </div>
        <div class="mt-6 ml-0 sm:mt-0 sm:ml-6 sm:w-3/4">
          <div v-for="(rule, i) in form.rules" :key="i" class="-mx-6 sm:flex">
            <div class="grow px-6 sm:w-1/3">
              <div>
                <label class="mb-1 text-base tracking-wide">Carrier</label>
              </div>
              <div class="mt-1">
                <select class="form-select w-full" v-model="rule.carrier_id">
                  <option v-for="carrier in carriers" :key="carrier.id" :value="carrier.id">
                    {{ carrier.name }}
                  </option>
                </select>
                <error :error="getError(`rules.${i}.carrier_id`)" />
              </div>
            </div>
            <div class="grow px-6 sm:w-1/3">
              <div>
                <label class="mb-1 text-base tracking-wide">Number of days to shift</label>
              </div>
              <div class="mt-1">
                <input
                  class="form-input w-full"
                  type="text"
                  v-model="rule.shift_days"
                  placeholder="Shift Days"
                />
              </div>
              <p class="mt-1 whitespace-nowrap text-sm text-gray-600">
                Can be positive or negative
              </p>
              <error :error="getError(`rules.${i}.shift_days`)" />
            </div>
            <div class="grow-0 px-6 pt-6 text-right">
              <button v-if="i === 0" class="btn btn-green pt-2" @click.prevent="addRule">
                <i class="fas fa-plus" />
              </button>
              <button v-if="i !== 0" class="btn btn-small btn-red" @click.prevent="removeRule(i)">
                <i class="fas fa-minus" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end bg-gray-100 py-6 px-4">
        <button
          @click.prevent="onSave"
          class="w-full rounded bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HasErrors from '~/mixins/HasErrors'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [HasErrors],
  props: ['save'],
  created() {
    this.fetchCarriers()
  },
  data() {
    return {
      form: {
        name: null,
        start_date: new Date(),
        end_date: new Date(),
        rules: [
          {
            carrier_id: null,
            shift_days: null,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      carriers: 'carriers/all',
    }),
  },
  methods: {
    ...mapActions({
      fetchCarriers: 'carriers/fetch',
    }),
    addRule() {
      this.form.rules.push({
        carrier_id: null,
        shift_days: null,
      })
    },
    removeRule(i) {
      this.form.rules.splice(i, 1)
    },
    onSave() {
      this.wrapSubmit(this.save(this.form)).catch(() => this.errorMessage('Error Occurred'))
    },
  },
}
</script>
