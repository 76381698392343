import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
import { isEmpty } from 'lodash'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items.filter(item => !isEmpty(item)),
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch() {
    return axios.get('api/product-badges').then(res => {
      processEntities(res.data.data, 'badges')
      return res.data
    })
  },
}
