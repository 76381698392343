<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-5">
    <div v-if="banner && !fetching">

      <div class="flex justify-between items-center mb-6">
        <button class="text-gray-700 underline hover:text-gray-800">
          <router-link :to="{ name: 'sale-banners' }">
            <i class="fas fa-long-arrow-alt-left mr-2" />Back
          </router-link>
        </button>
        <button v-if="canDeleteBanner" class="btn btn-red btn-sm" @click="deleteIfConfirmed">
          <i class="fas fa-trash mr-2" /> Delete banner
        </button>
      </div>

      <h3 class="text-3xl">{{ id === 'new' ? 'New Banner' : banner.title }}</h3>
      <p class="text-sm text-gray-600">{{ banner.help }}</p>
      <p class="text-sm text-gray-600" v-if="banner.slug">Unique slug: {{ banner.slug }}</p>

      <!-- Content -->
      <div class="mt-8 lg:flex">
        <div class="lg:mr-4 lg:w-2/3">
          <form action="#" method="post" class="overflow-hidden rounded-lg bg-white p-4 shadow space-y-6" @submit.prevent="submit" enctype="multipart/form-data">

            <InputGroup label="Banner type"
                        :error="getError('type')" :inline="false"
                        :class="{'opacity-50': !isNewResource}">
              <div class="flex flex-col space-y-2">
                <RadioboxInput label="Sales" :value="bannerTypes.BANNER_SALES" v-model="banner.type" :key="bannerTypes.BANNER_SALES" :disabled="!isNewResource" />
                <RadioboxInput label="New Meals" :value="bannerTypes.BANNER_NEW_MEALS" v-model="banner.type" :key="bannerTypes.BANNER_NEW_MEALS" :disabled="!isNewResource" />
              </div>
            </InputGroup>

            <InputGroup v-if="canCustomizeContent" :inline="false" label="Title" :error="getError('title')" helpText="The main title">
              <TextInput v-model="banner.title" />
            </InputGroup>

            <InputGroup v-if="canCustomizeContent" :inline="false" label="Description" :error="getError('description')" helpText="Description under title. Use [newMealDate] for date replacement, ex (October 1)">
              <textarea rows="2"
                class="block w-full rounded-md border-gray-200 bg-gray-50 py-1 px-2 text-gray-800 shadow-sm focus:border-teal-400/40 focus:bg-white focus:ring-2 focus:ring-teal-400/40 disabled:cursor-not-allowed disabled:opacity-80"
                v-model="banner.description"></textarea>
            </InputGroup>

            <InputGroup
              v-if="canCustomizeContent"
              :inline="false"
              label="Banner Label"
              :error="getError('label')"
              helpText="The text of the label that will be shown in the UI. Usually set to 'New Meals'"
            >
              <TextInput v-model="banner.label"/>
            </InputGroup>

            <div v-if="canCustomizeLink" class="flex space-x-4">
              <InputGroup :inline="false" label="Link title" :error="getError('link_title')" helpText="Text on the link" class="w-1/3">
                <TextInput v-model="banner.link_title" />
              </InputGroup>

              <InputGroup :inline="false" label="Link to" :error="getError('link_url')" helpText="Destination on click" class="w-2/3">
                <TextInput v-model="banner.link_url" />
              </InputGroup>
            </div>

            <InputGroup :inline="false" :error="newImageError || getError('image')" label="Image">
              <div class="my-2">
                <input type="file" accept="image/*" @change="uploadImage" ref="newImageInput" class="hidden" />
                <button type="button" class="inline-flex items-center space-x-1 bg-gray-100 rounded py-2 px-4 border border-gray-400 hover:bg-gray-50" @click="$refs.newImageInput.click()">
                  <UploadIcon class="h-4 w-4 text-gray-400" />
                  <span class="text-sm text-gray-700">Upload Image (max 5mb)</span>
                </button>
              </div>

              <img :src="banner.image" />
            </InputGroup>

            <InputGroup
              :error="getError('status')"
              label="Banner status"
              :inline="false"
            >
              <div class="flex flex-col space-y-2">
                <RadioboxInput label="Disable" value="disabled" v-model="banner.status" key="disabled" />
                <RadioboxInput label="Enable for all weeks" value="enabled_all_weeks" v-model="banner.status" key="enabled_all_weeks" />
                <RadioboxInput v-if="canSetSpecificWeeks" label="Enable for specific weeks" value="enabled_selected_weeks" v-model="banner.status" key="enabled_selected_weeks" />
              </div>
            </InputGroup>

            <InputGroup v-if="canSetSpecificWeeks && banner.status === 'enabled_selected_weeks'"
              :inline="false"
              label="Active for weeks"
              :error="getError('sale_banner_order_week')"
              helpText="Sales: Show the banner during the week selected. NewMeals - show the banner before the week selected."
            >
              <select v-model="banner.sale_banner_order_week" class="form-input w-full" multiple size="7">
                <option disabled :value="null">Please select active weeks</option>
                <option v-for="week in availableWeeks" v-bind:key="week.id" :value="week.id">
                  {{ week.number }}
                </option>
              </select>
            </InputGroup>

            <div class="mt-4 text-right">
              <button type="submit" :class="{ disabled: submitting }"
                class="rounded-sm bg-teal-500 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none">
                <span v-if="submitting">Saving...</span>
                <span v-if="!submitting">Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
    <loader v-if="fetching" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import { UploadIcon } from '@vue-hero-icons/solid'
import { BANNER_SALES, BANNER_NEW_MEALS } from '../../constants/sale-banners'

export default {
  components: {
    UploadIcon,
},
mixins: [HasErrors],
  data: () => ({
    banner: {
      slug: null,
      type: "",
      status: "disabled",
      title: null,
      description: null,
      label: null,
      link_url: null,
      link_title: null,
      image: null,
      priority: 999,
      sale_banner_order_week: [],
      help: "Big banner - shown to the user on top of the page"
    },
    newImage: null,
    fetching: false,
    availableWeeks: [],
    bannerTypes: {
      'BANNER_SALES': BANNER_SALES,
      'BANNER_NEW_MEALS': BANNER_NEW_MEALS
    }
  }),
  mounted() {
    this.fetching = true
    this.retrieveOrderWeeks()
    this.fetch()
      .then(() => {
        if (!this.isNewResource) {
          this.banner = this.saleBannerById(this.id)
        }
      })
      .finally(() => this.fetching = false)
  },
  computed: {
    ...mapGetters({
      saleBannerById: 'sale-banners/byId'
    }),
    id() {
      return this.$route.params.id == 'new'
        ? 'new'
        : +this.$route.params.id
    },
    canCustomizeContent() {
      return [BANNER_SALES,  BANNER_NEW_MEALS].includes(this.banner.type)
    },
    canCustomizeLink() {
      return [BANNER_SALES].includes(this.banner.type)
    },
    canSetSpecificWeeks() {
      return [BANNER_SALES, BANNER_NEW_MEALS].includes(this.banner.type)
    },
    canDeleteBanner() {
      return [BANNER_SALES, BANNER_NEW_MEALS].includes(this.banner.type) && this.id != 'new'
    },
    newImageSize() {
      return this.newImage ? Math.round(this.newImage.size / 1024 / 1024) : null
    },
    newImageError() {
      return this.newImageSize > 5 ? 'Please use images smaller than 5MB' : null;
    },
    isNewResource() {
      return this.id === 'new'
    }
  },
  methods: {
    ...mapActions({
      updateBanner: 'sale-banners/updateBanner',
      createBanner: 'sale-banners/createBanner',
      deleteBanner: 'sale-banners/deleteBanner',
      fetch: 'sale-banners/fetch',
      fetchOrderWeeks: 'order-weeks/fetch',
    }),
    uploadImage: async function(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(!files.length) {
        return
      }

      this.newImage = files[0]

      if (this.newImageSize < 5) {
        const fileReader = new FileReader()
        await fileReader.readAsDataURL(this.newImage)

        this.$emit('input', this.newImage)
        fileReader.onload = e => (this.banner.image = e.target.result)
      }
    },
    async submit() {
      const action = this.isNewResource ? this.createBanner : this.updateBanner
      try {
        const res = await this.wrapSubmit(action({ id: this.id, params: this.banner }))
        this.successMessage('Success', 'The banner is updated')

        if (this.isNewResource) {
          this.$router.push({ name: 'edit-sale-banner', params: { id: res.id } })
        }
      } catch(err) {
          this.errorMessage('Error', this.$get(err, 'response.data.message'))
      }
    },
    async deleteIfConfirmed() {
      this.confirmAlert(
        { title: 'Are you sure you want to delete this banner?' },
        async () => {
          await this.deleteBanner(this.id)
          this.successMessage('Success', 'The banner is deleted')
          this.$router.push({ name: 'sale-banners' })
        }
      )
    },
    async retrieveOrderWeeks() {
      const results = await this.fetchOrderWeeks({ params: { status: ['active'] } })

      this.availableWeeks = results.data
        .sort((first, second) => new Date(second.date).getTime() - new Date(first.date).getTime())
    },
  },
}
</script>
