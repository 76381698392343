import { schema } from 'normalizr'
import _ from 'lodash'

const processStrategy = activity => {
  const relations = {
    causer: _.get(activity, 'causer.data'),
  }

  return { ...activity, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'postCutoffActivities',
  {},
  {
    processStrategy,
  }
)
