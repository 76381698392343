<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <h4 class="mb-5 font-bold">Preferred shipping carrier</h4>
    <form class="mt-4 w-full" @submit.prevent="save">
      <div :key="preferred_carrier.production_cycle_id"
        v-for="(preferred_carrier, index) in preferred_carriers"
        class="form-group select"
        :class="{ 'has-error': hasError('preferred_carriers') }"
      >
        <div class="">
          <div class="flex justify-between">
            <h3>{{productionCycleName(preferred_carrier.production_cycle_id)}}</h3>
            <button type="button"
              class="hover:underline"
              v-if="preferred_carrier.carrier_id"
              @click="confirmResetForCycle(preferred_carrier.production_cycle_id)"
            >
              Reset
            </button>
          </div>

          <div>
            <select v-model="preferred_carrier.carrier_id">
              <option :value="null" disabled>Auto</option>
              <option v-for="carrier in carriersForCycle(preferred_carrier.production_cycle_id)" :key="carrier.id" :value="carrier.id">
                {{ carrier.name }}
              </option>
            </select>
            <error :error="getError(`preferred_carriers.${index}.carrier_id`)" />
          </div>

          <div class="grid grid-cols-2 gap-4 mt-4" v-if="preferred_carrier.carrier_id">
            <div>
              <select class="text-sm" v-model="preferred_carrier.ship_day_name">
                <option :value="null">Ship day</option>
                <option v-for="day in weekDays" :key="day" :value="day">
                  {{ day }}
                </option>
              </select>
              <error :error="getError(`preferred_carriers.${index}.ship_day_name`)" />
            </div>

            <div>
              <select class="text-sm" v-model="preferred_carrier.days_in_transit">
                <option :value="null">Days in transit</option>
                <option v-for="transit in daysInTransit" :key="transit" :value="transit">
                  {{ transit }}
                </option>
              </select>
              <error :error="getError(`preferred_carriers.${index}.days_in_transit`)" />
            </div>
          </div>
        </div>
      </div>

      <button type="submit"
          class="btn btn-green"
          :disabled="loading"
          :class="{ 'opacity-50': loading }">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '~/mixins/FormMixin'

export default {
  props: ['user'],
  mixins: [FormMixin],
  data() {
    return {
      loading: false,
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      daysInTransit: Array.from({length: 10}, (_, i) => i + 1),
      preferred_carriers: [
        {
          production_cycle_id: 1,
          carrier_id: null,
          ship_day_name: null,
          days_in_transit: null,
        },
        {
          production_cycle_id: 2,
          carrier_id: null,
          ship_day_name: null,
          days_in_transit: null,
        }
      ]
    }
  },
  async mounted() {
    await this.carriersFetch()
    await this.loadPreferred()
  },
  methods: {
    ...mapActions({
      fetchUserById: 'users/fetchById',
      carriersFetch: 'carriers/fetch',
      updatePreferredShippingCarrier: 'profiles/updatePreferredShippingCarrier',
      resetPreferredShippingCarrier: 'profiles/resetPreferredShippingCarrier',
    }),
    async loadPreferred() {
      this.preferred_carriers = [...this.preferred_carriers.map(preferred => {
        const alreadySaved = this.profile.preferredCarriers?.find(saved => saved.production_cycle_id == preferred.production_cycle_id)
        return alreadySaved ?? preferred
      })]
    },
    save() {
      this.loading = true
      const data = {
        id: this.user.id,
        params: {
          preferred_carriers: Object.keys(this.preferred_carriers)
            .filter(production_cycle_id => this.preferred_carriers[production_cycle_id]?.carrier_id)
            .map(production_cycle_id => ({
              production_cycle_id, ...this.preferred_carriers[production_cycle_id]
            }))
        }
      }
      this.wrapSubmit(this.updatePreferredShippingCarrier(data))
        .then(() => this.successMessage('Preferred carrier updated'))
        .catch((e) => this.errorMessage('Error Occurred!'))
        .finally(() => this.loading = false)
    },
    carriersForCycle(cycleId) {
      return this.carriers.length > 0
        ? this.carriers?.filter(item => item.production_cycles.includes(cycleId))
        : []
    },
    productionCycleName(id) {
      return this.productionCycles.find(pc => pc.id == id)?.name
    },
    carrierSetForPc(id) {
      return this.profile.preferredCarriers?.find(saved => saved.production_cycle_id == id)?.carrier_id?? false
    },
    confirmResetForCycle(production_cycle_id) {
      if(confirm(`This will reset the shipping rule for PC ${production_cycle_id}. Are you sure?`)) {
        this.wrapSubmit(this.resetPreferredShippingCarrier({ id: this.user.id, production_cycle_id}))
        .then(async () => {
          this.preferred_carriers.map(preferred => {
            if (preferred.production_cycle_id == production_cycle_id) {
              preferred.carrier_id = null
            }
          })
          this.successMessage('Preferred carrier set to auto')
        })
        .catch((e) => this.errorMessage('Error Occurred!'))
        .finally(() => this.loading = false)
      }
    }
  },
  computed: {
    ...mapGetters({
      productionCycles: 'production-cycles/all',
      carriers: 'carriers/all',
      profileById: 'profiles/byId'
    }),
    profile() {
      return this.profileById(this.user.profile)
    }
  }
}
</script>
