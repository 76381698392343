<template>
  <div class="px-4">
    <div class="border-b border-gray-300 pb-5 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9">
          Experiments
        </h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <span class="ml-3 rounded-md shadow-sm">
          <router-link
            :to="{ name: 'create-experiment' }"
            class="focus:shadow-outline-teal inline-flex items-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none active:bg-teal-700"
          >
            Create Experiment
          </router-link>
        </span>
      </div>
    </div>
    <!--    <div class="mt-6">-->
    <!--      <search-->
    <!--        v-model="options.code"-->
    <!--        @input="reloadPage"-->
    <!--        class="flex w-full items-center justify-between md:justify-start"-->
    <!--        placeholder="Find Promo Code"-->
    <!--        :input-classes="{-->
    <!--          'bg-white focus:bg-gray-100 w-full py-2 px-3 text-gray-800 ': true,-->
    <!--        }"-->
    <!--        :button-classes="{-->
    <!--          'px-3 py-2 bg-teal-500 text-white rounded-sm': true,-->
    <!--        }"-->
    <!--      />-->
    <!--    </div>-->
    <div class="mt-6 bg-white">
      <Table>
        <template v-slot:thead>
          <Heading>ID</Heading>
          <Heading>Name</Heading>
          <Heading>Active</Heading>
          <Heading>Last Update</Heading>
        </template>
        <tr v-for="experiment in experiments" :key="experiment.id">
          <Cell>
            <router-link
              :to="{ name: 'edit-experiment', params: { id: experiment.id } }"
              class="block font-semibold text-teal-600 hover:text-teal-700"
            >
              <span>{{ experiment.id }}</span>
            </router-link>
          </Cell>
          <Cell>
            <router-link
              :to="{ name: 'edit-experiment', params: { id: experiment.id } }"
              class="block font-semibold text-teal-600 hover:text-teal-700"
            >
              <span>{{ experiment.name }}</span>
            </router-link>
          </Cell>
          <Cell>
            <span
              class="inline-block shrink-0 rounded-full px-2 py-0.5 text-xs font-medium leading-4"
              :class="[experiment.active ? 'bg-teal-100 text-teal-800' : 'bg-red-100 text-gray-800']"
            >{{ experiment.active ? 'Active' : 'Inactive' }}</span>
          </Cell>
          <Cell>
            <span>{{ formatDate(new Date(experiment.updated_at), 'yyyy-MM-dd HH:mm') }}</span>
          </Cell>
        </tr>
      </Table>
    </div>
    <div class="mt-5 flex justify-end">
      <pagination
        :value="options.page"
        :per-page="options.per_page"
        :total="total"
        @input="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import Table from '~/components/global/UI/Table'
import Heading from '~/components/global/UI/TableHeading'
import Cell from '~/components/global/UI/TableCell'
import Pagination from "@/components/global/Pagination.vue";
import {format as formatDate} from "date-fns"

export default {
  mixins: [IndexPage],
  components: {Pagination, Table, Heading, Cell},
  data: () => ({
    options: {
      per_page: 20,
      code: null,
    },
    formatDate,
  }),
  computed: {
    ...mapGetters([
      'experiments/byIds',
    ]),
    experiments() {
      return this['experiments/byIds'](this.ids)
    },
  },
  methods: {
    ...mapActions({
      fetchItems: 'experiments/fetch',
    }),
  },
}
</script>
