<template>
  <div v-if="subscription">
    <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
      <h4 class="mb-5 font-bold">Future Cancellation</h4>
      <span v-if="!isScheduled">
        <a href="#" @click.prevent="openModal" class="btn btn-gray">Schedule</a>
      </span>
      <span class="canceled-info" v-else>
        Cancellation scheduled for {{ subscription.to_be_cancelled_at | date }}
        <a href="#" @click.prevent="unschedule" class="btn btn-gray">Unschedule</a>
      </span>
      <schedule-cancellation-modal :user="user" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ScheduleCancellationModal from '~/components/users/ScheduleCancellationModal'

export default {
  components: { ScheduleCancellationModal },
  props: ['user'],
  computed: {
    ...mapGetters(['subscriptions/byId']),
    subscription() {
      return this['subscriptions/byId'](this.user.subscription)
    },
    isScheduled() {
      return this.subscription.to_be_cancelled_at
    },
  },
  methods: {
    ...mapActions(['subscriptions/unscheduleCancellation']),
    openModal() {
      this.$modal.show('schedule-cancellation')
    },
    unschedule() {
      this['subscriptions/unscheduleCancellation'](this.user.id).then(() => {
        this.successMessage('Cancellation unscheduled')
      })
    },
  },
}
</script>
