<template>
  <modal :adaptive="true" name="pause-subscription" @before-open="beforeOpen" height="auto">
    <div class="m-4">
      <div class="pb-1">Please, select the pause reason</div>
      <hr class="mb-2" />
      <form v-if="reasons.length">
        <div :class="{ 'has-error': hasError('reasons') }">
          <error :error="getError('reasons')" />
          <div class="radio mb-1" v-for="reason in reasons" :key="reason.id">
            <label class="inline-flex items-center">
              <input
                name="reason"
                type="radio"
                :value="reason.id"
                v-model="form.reason.id"
                class="form-radio"
              />
              <span class="ml-1 flex-1">{{ reason.title }}</span>
            </label>
            <div
              v-if="
                selectedReason && selectedReason.id === reason.id && selectedReason.answer_required
              "
            >
              <div v-html="reason.description"></div>
              <div>
                <p>{{ reason.question }}</p>
                <textarea
                  v-model="form.reason.details"
                  class="form-textarea w-full"
                  :class="{ 'border-red-300 bg-red-100 bg-opacity-50': hasError('details') }"
                  rows="3"
                ></textarea>
                <error :error="getError('details')" />
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-3 mb-4" />
        <div class="mb-4" :class="{ 'has-error': hasError('until') }">
          <label class="mb-3 block">
            <span class="mb-1 block">Pause Until</span>
            <datepicker v-if="subscriptionPauseSettings"
              :popover="{ placement: 'top' }"
              v-model="form.until"
              :min-date="subscriptionPauseSettings.minDate"
              :max-date="subscriptionPauseSettings.maxDate"
              :disabled-dates="{ weekdays: disabledDays }"
            />
          </label>
          <error :error="getError('until')" />
        </div>
        <div class="form-group button-section form-actions" v-if="!loading">
          <button class="text-red-500 text-opacity-80" @click.prevent="close">Dismiss</button>
          <button
            class="btn ml-2 rounded-sm bg-green-200 px-2 py-1 text-sm font-bold uppercase tracking-wide shadow hover:bg-green-300 hover:text-green-800"
            @click.prevent="confirm"
          >
            Confirm
          </button>
        </div>
        <span v-else>Loading...</span>
      </form>
    </div>
  </modal>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['user'],
  data: () => ({
    minDate: new Date().setDate(new Date().getDate() + 1),
    maxDate: new Date().setMonth(new Date().getMonth() + 3),
    subscriptionPauseSettings: null,
    loading: false,
    form: {
      until: null,
      reason: {
        id: null,
        details: null,
      },
    },
  }),
  computed: {
    ...mapGetters(['reasons/pause', 'reasons/byId', 'subscriptions/byId', 'profiles/byId']),
    disabledDays() {
      const dayOfWeekMap = {
        Sunday: 1,
        Monday: 2,
        Tuesday: 3,
        Wednesday: 4,
        Thursday: 5,
        Friday: 6,
        Saturday: 7,
      }
      const days = [1, 2, 3, 4, 5, 6, 7]
      return _.without(days, dayOfWeekMap[this.profile.default_delivery_day])
    },
    profile() {
      return this['profiles/byId'](this.user.profile)
    },
    subscription() {
      return this['subscriptions/byId'](this.user.subscription)
    },
    selectedReason() {
      return this['reasons/byId'](this.form.reason.id)
    },
    reasons() {
      return this['reasons/pause']
    },
  },
  methods: {
    ...mapActions(['reasons/fetchPauseReasons', 'subscriptions/pause', 'subscriptions/getPauseRequestSettings']),
    async beforeOpen() {
      this['reasons/fetchPauseReasons']()
      this.subscriptionPauseSettings = await this['subscriptions/getPauseRequestSettings'](this.user.subscription);
    },
    confirm() {
      if (!this.validate() || this.loading) {
        return false
      }

      this.loading = true

      const data = {
        until: this.form.until.toISOString().split('T')[0],
        reason_id: this.form.reason.id,
        reason_details: this.form.reason.details,
      }
      this.wrapSubmit(this['subscriptions/pause']({ id: this.user.subscription, data }))
        .then(this.close)
        .catch(() => {})
        .then(() => (this.loading = false))
    },
    close() {
      this.$modal.hide('pause-subscription')
    },
    validate() {
      this.resetErrors()

      if (!this.form.reason.id) {
        this.errors.reasons = ['Please select a pause reason.']
        return false
      }

      if (this.selectedReason.answer_required && !this.form.reason.details) {
        this.errors.details = ['Please provide additional details']
        return false
      }

      return true
    },
  },
}
</script>
