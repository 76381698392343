import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities, resolveIdAndParams } from '../helpers'
import moment from 'moment'

const getDefaultState = () => {
  return {
    items: []
  }
}

export const state = getDefaultState()

export const getters = {
  all: state => state.items.sort((a, b) => moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 1),
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [types.RESET_STATE]: (state) => Object.assign(state, getDefaultState())
}

export const actions = {
  fetch({ commit }, params) {
    return axios.get('api/shipping/zip-code-imports/', { params }).then(res => {
      commit(types.RESET_STATE)
      processEntities(res.data.data, 'zipCodeImports')
      return res.data
    })
  },
  upload(store, params) {
    return axios.post('api/shipping/zip-code-imports/upload', params, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
  },
  zipImport(store, id) {
    return axios.post('api/shipping/zip-code-imports/import/{id}'.replace('{id}', id))
  },
  zipDelete(store, id) {
    return axios.post('api/shipping/zip-code-imports/delete/{id}'.replace('{id}', id))
  }
}
