<template>
  <div class="mt-6 overflow-hidden rounded-lg shadow-lg">
    <slot name="header" />

    <div class="bg-white p-4 pt-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { required: false, default: null },
  },
}
</script>
