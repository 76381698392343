<script>
import star from '~/components/products/rating/star.svg'
import starBlank from '~/components/products/rating/star-blank.svg'

export default {
  props: {
    value: {
      type: Number,
      default: 0.0,
    },
    mb: {
      type: String,
      default: 'mb-8 sm:mb-37',
    },
  },

  data() {
    return {
      blankBackground: `background-image: url("${starBlank}");`,
      background: `background-image: url("${star}");`,
    }
  },

  computed: {
    percentage() {
      return (this.value * 100) / 5
    },

    text() {
      return `${this.value} out of 5.00`
    },

    isVisible() {
      return this.value >= 4
    },

    coverage() {
      return `width: ${this.percentage}%;`
    },
  },
  methods: {},
}
</script>

<style>
.product-rating .w-84,
.product-rating .w-84 > span {
  background-size: 17px 14px;
}
</style>

<template>
  <div class="product-rating" :class="mb" :title="text" v-if="isVisible">
    <div class="w-84 h-14" :style="blankBackground">
      <span class="block h-full" :style="`${coverage} ${background}`"></span>
    </div>
  </div>
</template>
