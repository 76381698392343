<template>
  <modal :adaptive="true" name="schedule-cancellation" height="400">
    <div class="m-4">
      <div class="pb-1">Schedule subscription cancellation</div>
      <hr class="mb-5" />
      <form>
        <div :class="{ 'has-error': hasError('when') }" class="mb-5">
          <error :error="getError('when')" />
          <datepicker v-model="form.when" :min-date="minDate" />
        </div>
        <div>
          <button class="btn btn-green mr-3" @click.prevent="schedule">Schedule</button>
          <button class="btn btn-red mr-3" @click.prevent="close">Cancel</button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'

export default {
  mixins: [HasErrors],
  props: ['user'],
  data: () => ({
    minDate: new Date().setDate(new Date().getDate() + 1),
    form: {
      when: null,
    },
  }),
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['subscriptions/scheduleCancellation']),
    schedule() {
      this.wrapSubmit(
        this['subscriptions/scheduleCancellation']({ id: this.user.id, data: this.form })
      ).then(() => {
        this.successMessage('Cancellation scheduled')
        this.close()
      })
    },
    close() {
      this.$modal.hide('schedule-cancellation')
    },
  },
}
</script>
