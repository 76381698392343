import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities, storeEntities } from '../helpers'
import { index, show, create, update } from '~/services/api/order-bumps'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  async create(store, data) {
    const results = await create(data)
    processEntities(results.data, 'orderBumps')
    return results
  },

  async update(store, { id, data }) {
    const results = await update(id, data)
    processEntities(results.data, 'orderBumps')
  },

  async index(store, params) {
    const results = await index(params)
    processEntities(results.data, 'orderBumps')
    return results
  },

  async show(store, id) {
    const results = await show(id)
    processEntities(results.data, 'orderBumps')
  },
}
