<template>
  <div class="">
    <div>
      <div class="flex items-center justify-between py-4">
        <div class="flex items-center space-x-2">
          <h3 class="text-2xl font-semibold">All Users</h3>
          <span v-if="loading">
            <i class="fas fa-spinner fa-spin mr-2" />
          </span>
        </div>
        <router-link
          :to="{ name: 'add-user' }"
          class="rounded-sm bg-gray-700 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-500 hover:text-white focus:bg-teal-500 focus:text-white focus:outline-none"
        >
          <i class="fas fa-plus mr-2" /> Add User
        </router-link>
      </div>

      <div class="bg-gray-700 px-4 py-4 md:bg-white">
        <search
          v-model="options.keyword"
          @input="onSearch"
          class="flex w-full items-center justify-between md:justify-start"
          placeholder="Find user"
          :input-classes="{
            'bg-gray-800 focus:bg-gray-200 w-full py-2 px-3 text-gray-100 focus:text-gray-800 md:bg-gray-200 md:text-gray-800': true,
          }"
          :button-classes="{
            'px-3 py-2 bg-gray-600 text-white rounded-sm focus:bg-white focus:text-gray-800 hover:bg-white hover:text-gray-800 md:bg-gray-300 md:text-gray-800 md:hover:bg-gray-600 md:hover:text-white': true,
          }"
        />
      </div>
    </div>

    <div class="mt-8">
      <div class="mb-8 flex space-x-4">
        <InputGroup :inline="false" label="Status" class="w-1/4">
          <SelectInput v-model="options.status" @input="changeStatus">
            <option :value="null">Any</option>
            <option v-for="status in statuses" :value="status.value" :key="status.value">{{status.label}}</option>
          </SelectInput>
        </InputGroup>
        <InputGroup :inline="false" label="Sort" class="w-1/4">
          <SelectInput v-model="options.order" @input="loadPage">
            <option value="subscriptionAsc">Asc</option>
            <option value="subscriptionDesc">Desc</option>
          </SelectInput>
        </InputGroup>
        <InputGroup :inline="false" label="Production Cycle" class="w-1/4">
          <SelectInput v-model="options.production_cycle" @input="loadPage">
            <option :value="null">Any</option>
            <option value="1">PC A</option>
            <option value="2">PC B</option>
          </SelectInput>
        </InputGroup>
      </div>
      <!-- <tabs v-model="options.status" :options="statuses" @input="changeStatus" /> -->

      <div v-if="loaded">
        <div v-if="users.length" class="mb-10">
          <div v-for="user in users" :key="user.id">
            <user-card :user="user"></user-card>
          </div>
          <div class="mt-5 flex justify-end">
            <pagination
              :value="options.page"
              :per-page="options.per_page"
              :total="total"
              @input="onPageChange"
            />
          </div>
        </div>
        <empty-state v-else />
      </div>
      <loader v-else />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import UserCard from '~/components/users/UserCard'

export default {
  mixins: [IndexPage],
  components: { UserCard },
  data: () => ({
    statuses: [
      { label: 'Active', value: 'active' },
      { label: 'Paused', value: 'paused' },
      { label: 'Suspended', value: 'suspended' },
      { label: 'Lapsed', value: 'lapsed' },
      { label: 'Canceled', value: 'cancelled' },
      { label: 'Banned', value: 'banned' },

      { label: 'Abandoned Carts', value: 'abandoned' },

      { label: 'Comped Accounts', value: 'comped', param: 'is_comped' },
      { label: 'Admins', value: 'admins', param: 'is_admin' },
    ],
    options: {
      include: ['defaultAddress'],
      page: 1,
      sort: 'id',
      order: 'subscriptionAsc',
      per_page: 10,
      keyword: '',
      status: null,
      is_admin: null,
      production_cycle: null,
    },
  }),
  computed: {
    ...mapGetters({
      userById: 'users/byId',
    }),
    users() {
      return _.map(this.ids, id => this.userById(id))
    },
  },
  methods: {
    ...mapActions({
      fetchItems: 'users/fetch',
    }),
    changeStatus() {
      const status = this.statuses.find(item => item.value === this.options.status)
      this.options.is_admin = this.$get(status, 'param') === 'is_admin' ? 1 : null
      this.options.is_comped = this.$get(status, 'param') === 'is_comped' ? 1 : null
      this.reloadPage()
    },
  },
}
</script>
