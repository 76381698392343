<template>
  <button
    @click.prevent="impersonate"
    type="button"
    class="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out"
  >
    <svg
      class="-ml-1 mr-2 h-3 w-3 stroke-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polyline points="17 1 21 5 17 9"></polyline>
      <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
      <polyline points="7 23 3 19 7 15"></polyline>
      <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
    </svg>
    Impersonate
  </button>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['userId'],
  methods: {
    ...mapActions(['auth/impersonate']),
    impersonate() {
      this['auth/impersonate'](this.userId)
    },
  },
}
</script>
