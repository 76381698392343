import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetchById(store, id) {
    return axios
      .get(`api/post-cutoff-changes-buffers/${id}`, {
        params: { include: ['orderWeek', 'productionCycle'] },
      })
      .then(res => {
        processEntities(res.data.data, 'buffers')
        return res.data
      })
  },
  fetchCurrentlyOngoingBuffer(store) {
    return axios
      .get(`api/admin/post-cutoff-changes-buffers/ongoing`, {
        params: { include: ['orderWeek', 'productionCycle'] },
      })
      .then(res => {
        processEntities(res.data.data, 'buffers')
        return res.data
      })
      .catch(console.log)
  },
  saveItems(store, { id, products }) {
    return axios.post(`api/post-cutoff-changes-buffers/${id}/products`, { products }).then(res => {
      processEntities(res.data.data, 'buffers')
      return res.data
    })
  },
}
