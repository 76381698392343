<template>
  <div class="bg-gray-50 p-4 rounded mt-4" v-if="visible">
    <div v-if="options.caption" class="mb-2 text-lg font-semibold text-gray-700">
      {{ options.caption }}
    </div>
    <div v-for="(item, i) in options.items" :key="i">
      <label v-if="options.singleChoice === false" class="inline-flex items-center gap-x-2">
        <input type="checkbox" class="form-checkbox" v-model="items" :value="item" />
        <span>{{ item }}</span>
      </label>
      <label v-else class="inline-flex items-center gap-x-2">
        <input type="radio" v-model="items" :value="item" />
        <span>{{ item }}</span>
      </label>
    </div>

    <div v-if="freeText.enabled" class="mt-2">
      <textarea
        class="form-textarea w-full text-sm text-gray-700"
        v-model="text"
        :placeholder="freeText.placeholder"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: ['options', 'freeText', 'value'],
  data() {
    return {
      items: [],
      text: '',
    }
  },
  methods: {
    notify() {
      this.$emit('input', this.everythingToString)
    },
  },
  computed: {
    visible() {
      return this.options.items.length > 0 || this.freeText.enabled === true
    },
    itemsToString() {
      return Array.isArray(this.items) ? this.items.join(', ') : this.items
    },
    everythingToString() {
      return `${this.itemsToString} ${this.text}`.trim()
    },
  },
  watch: {
    items() {
      this.notify()
    },
    text() {
      this.notify()
    },
    value(value) {
      if (value === null) {
        this.items = []
        this.text = ''
      }
    },
  },
}
</script>
