<template>
  <modal
    name="edit-menu-item"
    :adaptive="true"
    @before-open="beforeOpen"
    @closed="close"
    height="auto"
    :scrollable="true"
  >
    <header class="relative border-b border-gray-200 px-6 py-4">
      <button
        @click.prevent.stop="close"
        class="absolute top-0 right-0 mt-4 mr-4"
      >
        <svg
          class="h-5 w-5 text-teal-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <h3 class="text-2xl font-semibold">Edit {{ menuItem.name }}</h3>
      <div v-if="isExpandable">
        <p class="text-xs font-medium uppercase tracking-wide text-gray-600">
          This menu item expands on click and can not fetch items itself.
        </p>
      </div>
      <div v-if="!isExpandable">
        <p class="text-xs font-medium uppercase tracking-wide text-gray-600">
          This menu item fetches actual products. It can fetch
          <strong>{{ typesCommaSeparated }}</strong>
        </p>
      </div>
    </header>

    <form @submit.prevent="submit" class="mt-4 divide-y-2">
      <div class="space-y-4 py-4 px-4">
        <InputGroup label="name" help-text="This is what displays as the actual menu item name">
          <input type="text" class="form-input w-full" v-model="menuItem.name" />
        </InputGroup>
        <InputGroup
          label="title"
          help-text="This will be displayed on the actual web page as a title of the section"
        >
          <input type="text" class="form-input w-full" v-model="menuItem.title" />
        </InputGroup>
        <InputGroup
          label="description"
          help-text="Sub text under the title. Basic HTML tags are allowed."
        >
          <textarea class="form-textarea block w-full" v-model="menuItem.description"></textarea>
        </InputGroup>
        <InputGroup
          label="homepage-description"
          help-text="Homepage description. Basic HTML tags are allowed."
        >
          <textarea class="form-textarea block w-full" v-model="menuItem.homepage_description"></textarea>
        </InputGroup>
        <InputGroup label="slug" help-text="Piece of URL that will be used for the navigation">
          <input class="form-input block w-full" v-model="menuItem.slug" />
        </InputGroup>
        <InputGroup
          label="primary category"
          help-text="This category will be used to display pricing on marketing pages. Required"
        >
          <select-input v-model="menuItem.primary_category">
            <option v-for="category in categories" :value="category.id" :key="category.id" class="capitalize">{{ category.name }}</option>
          </select-input>
        </InputGroup>
        <InputGroup
          label="primary composable category"
          help-text="This category will be used to display pack pricing on marketing pages"
        >
          <select-input v-model="menuItem.primary_composable_category">
            <option :value="null"></option>
            <option v-for="category in categories" :value="category.id" :key="category.id" class="capitalize">{{ category.name }}</option>
          </select-input>
        </InputGroup>
        <InputGroup label="New flag" help-text="When this is enabled, a 'New!' flag will be shown next to the category name (in menu and edit meals pages).">
          <CheckboxInput label="" v-model="menuItem.is_new_flag" />
        </InputGroup>

        <InputGroup label="Image" :error="getError('image')" help-text="Image for Homepage">
          <ImageField v-model="menuItem.image" :default-image="$get(editingItem, 'image')" />
        </InputGroup>

        <InputGroup label="Bullets" :error="getError('image')" help-text="Menu page header 'bullets'">
          <textarea class="form-textarea block w-full" v-model="menuItem.menu_page_bullets"></textarea>
        </InputGroup>
      </div>

      <div v-if="!isExpandable" class="space-y-4 py-4 px-4">
        <div v-if="editingItem.types.includes('products')">
          <legend class="text-xs font-bold uppercase tracking-wide text-gray-600">
            The Item will fetch meals from the following categories:
          </legend>
          <ToggleableList
            class="mt-2"
            v-model="menuItem.categories"
            :available-options="categories"
          />
        </div>
        <div v-if="editingItem.types.includes('bestsellers')">
          <legend class="text-xs font-bold uppercase tracking-wide text-gray-600">
            The Item will fetch the following bestsellers:
          </legend>
          <ToggleableList
            class="mt-2"
            v-model="menuItem.bestsellers"
            :available-options="bestsellers"
          />
        </div>
      </div>

      <div class="flex justify-end bg-gray-100 px-4 py-4">
        <button
          type="submit"
          class="transaction block w-full rounded bg-teal-600 px-4 py-3 text-sm font-semibold tracking-tight text-white duration-300 hover:bg-teal-500 md:inline-block md:w-auto"
        >
          Submit
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToggleableList from './ToggleableList'
import HasErrors from '~/mixins/HasErrors'
import CheckboxInput from "@/components/global/input/CheckboxInput.vue";
import SelectInput from '@/components/global/input/SelectInput.vue'
import ImageField from '@/components/products/ImageField.vue'
export default {
  props: ['editingItem'],
  mixins: [HasErrors],
  components: {
    ImageField,
    SelectInput,
    CheckboxInput, ToggleableList },
  data() {
    return {
      menuItem: {
        image: null,
      },
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: 'categories/fetch',
      updateMenuItem: 'menu-items/update',
      fetchBestsellers: 'bestsellers/fetch',
      fetchItems: 'menu-items/fetch',
    }),
    submit() {
      this.wrapSubmit(this['updateMenuItem']({ id: this.editingItem.id, params: this.menuItem }))
        .then(async () => {
          this.successMessage('Menu Item Updated!')
          this.$emit('updated', this.editingItem.id)
          this.close()
        })
        .catch(error => {
          const message = error.response && error.response.status === 422 ? error.response.data.message : error
          this.errorMessage(message)
        })
    },
    close() {
      this.$emit('closed')
    },
    beforeOpen() {
      this.fetchCategories()
      this.fetchBestsellers()
    },
  },
  computed: {
    ...mapGetters({
      categories: 'categories/all',
      bestsellers: 'bestsellers/all',
    }),
    isExpandable() {
      return this.menuItem.on_activate === 'expand'
    },
    typesCommaSeparated() {
      return this.editingItem.types.join(', ')
    },
  },
  mounted() {
    this.menuItem = {
      name: this.editingItem.name,
      title: this.editingItem.title,
      description: this.editingItem.description,
      homepage_description: this.editingItem.homepage_description,
      slug: this.editingItem.slug,
      is_new_flag: this.editingItem.is_new_flag,
      primary_category: this.editingItem.primaryCategory.id,
      menu_page_bullets: this.editingItem.menu_page_bullets,
      primary_composable_category: this.editingItem.primaryComposableCategory.id || null,
      categories: this.editingItem.categories.map(i => i.id),
      bestsellers: this.editingItem.bestsellers.map(i => i.id),
    }
  },
}
</script>
