<template>
  <div class="px-4">
    <div class="border-b border-gray-300 pb-5 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9">
          Skip PC Change Offer Settings
        </h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
      <span class="ml-3 rounded-md shadow-sm">
          <router-link
            :to="{ name: 'add-skip-pc-change-offer' }"
            class="focus:shadow-outline-teal inline-flex items-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none active:bg-teal-700"
          >
            Create
          </router-link>
        </span>
      </div>
    </div>
    <div class="mt-6 bg-white">
      <Table>
        <template v-slot:thead>
          <Heading>Order Index</Heading>
          <Heading>Enabled</Heading>
          <Heading>Whitelisted Emails</Heading>
        </template>
        <tr v-for="skip_pc_change_offer in skip_pc_change_offers" :key="skip_pc_change_offer.id">
          <Cell>
            <router-link
              :to="{ name: 'edit-skip-pc-change-offer', params: { id: skip_pc_change_offer.id } }"
              class="block font-semibold text-teal-600 hover:text-teal-700"
            >
              <span>{{ skip_pc_change_offer.order_index }}</span>
            </router-link>
          </Cell>
          <Cell>
            <span
              class="inline-block shrink-0 rounded-full px-2 py-0.5 text-xs font-medium leading-4"
              :class="[skip_pc_change_offer.enabled ? 'bg-teal-100 text-teal-800' : 'bg-red-100 text-gray-600']"
            >{{ skip_pc_change_offer.enabled ? 'Enabled' : 'Disabled' }}</span
            >
          </Cell>
          <Cell>
            <span
              class="inline-block shrink-0 rounded-full px-2 py-0.5 text-xs font-medium leading-4 bg-teal-100 text-teal-800"
            >{{ skip_pc_change_offer.whitelisted_emails ? skip_pc_change_offer.whitelisted_emails.length + ' users' : 'All users' }}</span>
          </Cell>
        </tr>
      </Table>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import Table from '~/components/global/UI/Table'
import Heading from '~/components/global/UI/TableHeading'
import Cell from '~/components/global/UI/TableCell'

export default {
  mixins: [IndexPage],
  components: {Table, Heading, Cell},
  methods: {
    ...mapActions({
      fetchItems: 'skip-pc-change-offers/fetch',
    }),
  },
  computed: {
    ...mapGetters({
      skip_pc_change_offers: 'skip-pc-change-offers/all',
    }),
  },
}
</script>
