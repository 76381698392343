import router from '../index'
import store from '~/store'
import { LOGIN_URL } from '~/config'

/**
 * Handle login
 */
export default function () {
  if (store.getters['auth/check']) {
    router.push({ name: 'users' })
    return false
  }

  store
    .dispatch('auth/fetchUser')
    .then(() => {
      router.push({ name: 'users' })
    })
    .catch(() => {
      window.location.href = LOGIN_URL
    })
}
