import { schema } from 'normalizr'
import menuItems from './menu-items'
import bestsellers from './bestsellers'

const processStrategy = menuItem => {
  const relations = {
    primaryCategory: _.get(menuItem, 'primaryCategory.data'),
    primaryComposableCategory: _.get(menuItem, 'primaryComposableCategory.data'),
    categories: _.get(menuItem, 'categories'),
    bestsellers: _.get(menuItem, 'bestsellers'),
    children: _.get(menuItem, 'children.data', []),
  }
  return { ..._.omit(menuItem, []), ..._.pickBy(relations, _.identity) }
}
export default new schema.Entity('menuItems', {}, { processStrategy })
