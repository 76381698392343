<script>
import Abstract from '~/components/global/Autocompletes/Abstract'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [Abstract],
  data: () => ({
    key: 'sku',
    searchKey: 'nameOrSku',
  }),
  computed: {
    ...mapGetters({
      getItem: 'products/bySKU',
    }),
  },
  methods: {
    ...mapActions({
      fetch: 'products/fetch',
    }),
  },
}
</script>
