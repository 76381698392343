<template>
  <form class="mt-4 w-full" @submit.prevent="submit">
    <div class="form-group">
      <label
        >ID
        <input :value="form.id" placeholder="AL" disabled />
      </label>
    </div>

    <div class="form-group">
      <label
        >Name
        <input :value="form.name" placeholder="Alabama" disabled />
      </label>
    </div>

    <div class="form-group" :class="{ 'has-error': hasError('tax') }">
      <label
        >Tax(%)
        <input v-model="form.tax" type="number" placeholder="Tax" step="0.01" />
      </label>
      <error :error="getError('tax')" />
    </div>

    <hr class="mt-4 mb-6 border-gray-400" />

    <button-loader label="Update State" :loading="submitting" />
  </form>
</template>

<script>
import FormMixin from '~/mixins/FormMixin'

export default {
  mixins: [FormMixin],
}
</script>
