<template>
  <div>
    <ValidationMessages class="px-4 py-4 sm:px-0" :errors="allErrors" />
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-x-16">
      <div class="space-y-6 lg:col-span-2">
        <InputGroup label="Title" help-text="This goes under the image" :error="getError('name')">
          <input type="text" class="form-input w-full" v-model="form.name" />
        </InputGroup>
        <InputGroup
          label="Cart CTA"
          help-text="This will be displayed as a CTA in the cart"
          :error="getError('cart_cta')"
        >
          <input type="text" class="form-input w-full" v-model="form.cart_cta" />
        </InputGroup>
        <InputGroup
          label="Subcopy"
          help-text="This goes under the title"
          :error="getError('subtitle')"
        >
          <input type="text" class="form-input w-full" v-model="form.subtitle" />
        </InputGroup>
        <InputGroup label="Button text" :error="getError('button_text')">
          <input type="text" class="form-input w-full" v-model="form.button_text" />
        </InputGroup>
        <InputGroup
          label="Button Alt Text"
          help-text="The button will change its reading to this when cart is not empty"
          :error="getError('button_alt_text')"
        >
          <input type="text" class="form-input w-full" v-model="form.button_alt_text" />
        </InputGroup>
        <InputGroup label="Description" :error="getError('description')">
          <textarea class="form-textarea w-full" v-model="form.description" />
        </InputGroup>
        <InputGroup label="Image" :error="getError('image')">
          <ImageField v-model="form.image" :default-image="$get(bestseller, 'image')" />
        </InputGroup>
        <InputGroup label="Mobile Image" :error="getError('mobile_image')">
          <ImageField v-model="form.mobile_image" :default-image="$get(bestseller, 'mobile_image')" />
        </InputGroup>
        <InputGroup label="Appearance">
          <InputGroup :error="getError('show_on_cart')">
            <CheckboxInput label="Show on cart" v-model="form.show_on_cart" />
          </InputGroup>
          <InputGroup :error="getError('show_on_menu')">
            <CheckboxInput label="Show on menu" v-model="form.show_on_menu" />
          </InputGroup>
        </InputGroup>
      </div>
      <div
        class="mt-6 border-t-2 border-gray-500 border-opacity-25 pt-6 lg:col-span-2 lg:mt-0 lg:border-t-0 lg:pt-0"
      >
        <h2 class="text-xl font-semibold text-gray-600">Products</h2>
        <div class="mt-4">
          <InputGroup :error="getError('products')">
            <div class="flex items-center">
              <products-autocomplete
                v-model="newProductSku"
                :params="{ active: 1 }"
                :exceptions="form.products"
                class="w-full"
                @selected="$refs.addButton.focus()"
              />
              <button
                v-on:click="addProduct"
                class="focus:shadow-outline-teal ml-4 inline-flex items-center rounded-md border border-transparent bg-teal-100 px-3 py-2 text-sm font-medium leading-4 text-teal-700 transition duration-150 ease-in-out hover:bg-teal-50 focus:border-teal-300 focus:outline-none active:bg-teal-200"
                ref="addButton"
              >
                Add
              </button>
            </div>
          </InputGroup>

          <div>
            <div class="mt-6 flow-root">
              <draggable
                tag="ul"
                v-model="form.products"
                v-on:update="$emit('reordered', wrapSubmit)"
                class="-my-5 divide-y divide-gray-200"
              >
                <li v-for="product in selectedProducts" :key="product.sku" class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="shrink-0">
                      <img
                        class="h-8 w-8 rounded-full"
                        :src="product.image['cart-mobile']"
                        alt=""
                      />
                    </div>
                    <div class="min-w-0 flex-1">
                      <p class="text-sm font-medium text-gray-900">
                        {{ product.name }}
                      </p>
                      <p class="text-sm text-gray-500">
                        {{ product.sku }}
                      </p>
                    </div>
                    <div class="flex items-center space-x-2">
                      <a
                        href="#"
                        class="inline-flex cursor-move items-center bg-white px-3 py-1 text-sm font-medium leading-5 text-gray-700 hover:bg-gray-100"
                      >
                        <DotsVerticalIcon class="h-4 w-4" />
                      </a>
                      <a href="#" v-on:click.prevent="removeProduct(product.sku)">
                        <XIcon class="h-4 w-4 text-red-400" />
                      </a>
                    </div>
                  </div>
                </li>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 flex justify-end lg:col-span-4">
        <NLButton :isLoading="submitting" v-on:click="submit" class="px-3 py-2"> Save </NLButton>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationMessages from '~/components/global/UI/form/ValidationMessages'
import { mapGetters } from 'vuex'
import FormMixin from '~/mixins/FormMixin'
import HasErrors from '~/mixins/HasErrors'
import { DotsVerticalIcon, XIcon } from '@vue-hero-icons/outline'
import ImageField from '~/components/products/ImageField'
import NLButton from '~/components/global/input/Button'

export default {
  props: {
    bestseller: {
      type: Object,
      default: null,
    },
  },
  components: { NLButton, ImageField, DotsVerticalIcon, XIcon, ValidationMessages },
  mixins: [FormMixin, HasErrors],
  data: () => ({
    form: {
      name: null,
      cart_cta: null,
      subtitle: null,
      image: null,
      mobile_image: null,
      products: [],
      show_on_cart: false,
      show_on_menu: false,
    },
    newProductSku: null,
    orderedProducts: [],
  }),
  computed: {
    ...mapGetters({
      productsBySKUs: 'products/bySKUs',
      productBySKU: 'products/bySKU',
    }),
    selectedProducts() {
      let my_new_var = 'fpp'
      return this.form.products.map(sku => this.productBySKU(sku))
    },
  },
  methods: {
    addProduct() {
      if (this.form.products.find(i => i === this.newProductSku) || !this.newProductSku) return
      this.form.products.push(this.newProductSku)
      this.newProductSku = null
    },
    removeProduct(sku) {
      this.form.products.splice(this.form.products.indexOf(sku), 1)
    },
  },
}
</script>
