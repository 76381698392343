import axios from 'axios'
import _ from 'lodash'
import * as mutationTypes from '../mutation-types'
import { addOrUpdateItems, processEntities, removeItem } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
  byIds: state => ids => _.filter(state.items, item => ids.includes(item.id)),
}

export const mutations = {
  [mutationTypes.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [mutationTypes.REMOVE]: (state, item) => removeItem(state, item),
}

export const actions = {
  fetch({ dispatch }, params) {
    return axios.get('api/admin/rating-attributes', { params }).then(res => {
      processEntities(res.data.data, 'ratingAttributes')
      return res.data
    })
  },
  create({ dispatch }, params) {
    return axios.post('/api/sales/admin/rating-attributes', params).then(res => {
      processEntities(res.data.data, 'ratingAttributes')
      return res.data
    })
  },
}
