<template>
  <div>
    <div>
      <div class="flex items-center justify-between py-3">
        <h3 class="text-2xl font-semibold">All Products</h3>
        <router-link
          :to="{ name: 'create-product' }"
          class="rounded-sm bg-gray-700 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-500 hover:text-white focus:bg-teal-500 focus:text-white focus:outline-none"
        >
          <i class="fas fa-plus mr-2" /> Add Product
        </router-link>
      </div>

      <div class="bg-gray-700 px-4 py-4 sm:bg-white">
        <search
          v-model="options.nameOrSku"
          @input="onSearch"
          class="flex w-full items-center justify-between justify-between sm:justify-start"
          placeholder="Find product"
          :input-classes="{
            'bg-gray-800 focus:bg-gray-200 w-full py-2 px-3 text-gray-100 focus:text-gray-800 sm:bg-gray-200 sm:text-gray-800': true,
          }"
          :button-classes="{
            'px-3 py-2 bg-gray-600 text-white rounded-sm focus:bg-white focus:text-gray-800 hover:bg-white hover:text-gray-800 sm:bg-gray-300 sm:text-gray-800 sm:hover:bg-gray-600 sm:hover:text-white': true,
          }"
        />
      </div>
    </div>

    <div v-if="loaded" class="px-4 pb-12">
      <div v-if="products.length">
        <div class="sm:-mx-2 sm:flex sm:flex-wrap">
          <div
            v-for="product in products"
            :key="product.sku"
            class="mt-8 sm:w-1/2 sm:px-2 lg:w-1/3 xl:w-1/4"
          >
            <product-card :product="product" @cloned="loadPage"></product-card>
          </div>
        </div>

        <div class="mt-10 flex justify-end">
          <pagination
            :value="options.page"
            :per-page="options.perPage"
            :total="total"
            @input="onPageChange"
          />
        </div>
      </div>

      <empty-state v-else />
    </div>

    <loader v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import IndexPage from '~/mixins/IndexPage'
import ProductCard from '~/components/products/ProductCard'

export default {
  mixins: [IndexPage],
  components: { ProductCard },
  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      perPage: 16,
      nameOrSku: '',
      status: null,
      categories: null,
      include: 'category,heatingInstructions',
    },
    key: 'sku',
  }),

  computed: {
    ...mapGetters({
      productBySku: 'products/bySKU',
    }),
    products() {
      return _.map(this.ids, sku => this.productBySku(sku))
    },
  },

  methods: {
    ...mapActions({
      fetchItems: 'products/fetch',
    }),
  },
}
</script>
