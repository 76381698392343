import axios from 'axios'
import { addOrUpdateItems, processEntities } from '../helpers'
import * as types from '../mutation-types'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  async remove(store, params) {
    const res = await axios.delete(`/api/customers/admin/users/${params.userId}/comped-accounts`, { data: params })
    processEntities(res.data.data, 'compedAccounts')
    return res.data
  },
}
