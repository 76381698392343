<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-4 sm:mt-6">
    <div v-if="!isLoading && eligibleOrderFound">
      <!-- Button Back -->
      <div class="space-x-4 sm:flex sm:items-center">
        <button class="text-gray-700 underline hover:text-gray-800">
          <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
            <i class="fas fa-long-arrow-alt-left mr-2" />
            Back to {{ user.email }} details
          </router-link>
        </button>
        <span class="hidden sm:mx-4 sm:inline-block">&bull;</span>
        <Impersonate
          :user-id="userId"
          class="mt-2 bg-gray-300 text-gray-800 shadow-sm hover:bg-gray-400 sm:mt-0"
        />
        <span class="hidden sm:mx-4 sm:inline-block">&bull;</span>
        <router-link
          target="_blank"
          :to="{ name: 'edit-buffer', params: { id: order.buffer_id } }"
          class="inline-flex items-center space-x-2 hover:underline"
        >
          <svg
            class="h-4 w-4 text-current text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
              fill-rule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clip-rule="evenodd"
            />
          </svg>
          <span>See the buffer</span>
        </router-link>
      </div>

      <PanelSection>
        <div v-if="orderId && order.status === 'suspended'">
          <div class="border-b border-gray-300 bg-white py-3 px-4">
            <h4 class="text-base font-semibold">Suspended order reactivation #{{ order.id }}</h4>
          </div>
          <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6">
            <div>
              <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                Order Week
              </div>
              <div>
                <span class="font-bold">{{ order.week }}</span>
                <span class="mx-2">&bull;</span>
                <span class="text-sm font-semibold text-black-600 capitalize">{{ order.status }}</span>
              </div>
            </div>
            <div class="mt-4">
              <div class="sm:flex sm:items-center">
                <div class="sm:flex-1">
                  <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Order Cutoff
                  </div>
                  <div>
                    <span class="font-semibold text-gray-700">{{
                      order.cutoff_at | datetime_tz
                    }}</span>
                  </div>
                </div>
                <div class="mt-2 sm:mt-0 sm:flex-1">
                  <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Post Cutoff Deadline
                  </div>
                  <div>
                    <span class="font-semibold text-gray-700">{{
                      order.post_cutoff_changes_deadline_at | datetime_tz
                    }}</span>
                  </div>
                </div>
                <div class="mt-2 sm:mt-0 sm:flex-1">
                  <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Delivery Date
                  </div>
                  <div>
                    <span class="font-semibold text-gray-700">{{ order.deliver_at | date }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="text-xs font-semibold uppercase tracking-wider text-gray-600">
                Shipping to
              </div>
              <div>
                {{ address.line_1 }} {{ address.line_2 }} {{ address.city }}, {{ address.state }}
                {{ address.zip_code }}
              </div>
            </div>
          </div>
          <div class="rounded bg-white py-3 px-4 shadow-inner">
            <div class="flex items-center space-x-2">
              <div class="flex items-center">
                <svg
                  class="h-4 w-4 stroke-current text-orange-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                  ></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
                <span class="ml-2 text-sm text-gray-600">
                  Post Cutoff Changes window closes at
                  {{ order.post_cutoff_changes_deadline_at | datetime_tz }}.
                </span>
              </div>
              <div class="text-sm text-gray-600">
                <span v-if="order.post_cutoff_funds_return_strategy">
                  Any refundable amount will be
                  <a
                    v-on:click.prevent="showFundsReturnStrategyModal"
                    class="font-semibold text-teal-700 underline hover:text-teal-600"
                    href="#"
                  >
                    {{ order.post_cutoff_funds_return_strategy }}ed
                  </a>
                </span>
                <span v-if="!order.post_cutoff_funds_return_strategy">
                  Funds return strategy is not set,
                  <a
                    v-on:click.prevent="showFundsReturnStrategyModal"
                    class="inline-block rounded-full bg-teal-100 px-3 py-1 text-sm font-bold text-teal-700"
                    href="#"
                    >set it up?</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="lastChargeFailed === true">
          <div class="border-b border-gray-300 bg-white py-3 px-4">
            <h4 class="text-base font-semibold">Updated status #{{ order.id }}</h4>
          </div>
          <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6 space-y-4 text-base">
            <p>The order {{order.id}} is now created but the payment failed. This happens when the unsuspend charge failed.</p>
            <p>Please keep in mind: The order already contains the post cutoff buffer items moved to it when the initial unsuspend was tried. If the user has updated their payment details, you can attempt to charge again.</p>
            <button
              @click.prevent="attemptSecondCharge"
              type="button"
              class="w-auto focus:shadow-outline-orange inline-flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-orange-500 focus:border-orange-700 focus:outline-none sm:text-sm sm:leading-5"
            >
              {{ submitting ? 'Just a moment...' : 'Attempt to charge again' }}
            </button>
          </div>
        </div>
        <div v-if="lastChargeSucceeded === false || (!hasPayments && hasSuspendedOrderActivation)">
          <div class="border-b border-gray-300 bg-white py-3 px-4">
            <h4 class="text-base font-semibold">Updated status #{{ order.id }}</h4>
          </div>
          <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6 space-y-4 text-base">
            <p>The order {{order.id}} is now created and expected to be paid on post-cutoff.</p>
            <p>Please keep in mind: The order already contains the post cutoff buffer items moved to it when the initial unsuspend was tried. If the user has updated their payment details, you can attempt to charge again.</p>
            <button
              @click.prevent="attemptSecondCharge"
              type="button"
              class="w-auto focus:shadow-outline-orange inline-flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-orange-500 focus:border-orange-700 focus:outline-none sm:text-sm sm:leading-5"
            >
            {{ submitting ? 'Just a moment...' : 'Attempt to charge again' }}
            </button>
          </div>
        </div>
        <div v-if="orderId && order.status === 'active' && postCutoffActivity">
          <div class="border-b border-gray-300 bg-white py-3 px-4">
            <h4 class="text-base font-semibold">Updated status #{{ order.id }}</h4>
          </div>
          <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6">
            <p>The order {{order.id}} migrated from Unsupended to Active</p>
          </div>
        </div>
        <div v-if="!orderId">
          <div class="shadeow rounded bg-white p-4 text-gray-700">
            No Post Cutoff Changes eligible order.
          </div>
        </div>
      </PanelSection>

      <div v-if="orderId && ['suspended', 'charge-failed'].includes(order.status)" class="mt-4">
        <div class="bg-white rounded p-4 shadow">
          <h2 class="font-semibold my-2 text-xl">Suspended order reactivation</h2>
          <display-preferences :profileId="user.profile" :horizontal="true" />
          <div class="bg-white mt-8">
            <div>
              <h2 class="font-semibold my-2 text-lg">Please select the new cart meals</h2>
              <label>
                <input
                  v-model="filterOnlyAskedForMeals"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out mr-2.5"
                />
                Show only original order meals
              </label>
            </div>
            <div v-if="products.length > 0">
              <fieldset class="mt-4 space-y-2">
                <div v-for="product in products" :key="product.sku"
                  class="relative flex flex-row rounded-sm border border-gray-200 p-2"
                  :class="{ 'border-teal-400 bg-teal-100': isSelected(product.sku) }">
                      <label class="flex flex-row space-x-1 cursor-pointer w-1/3">
                        <div class="flex h-5 w-5 items-center text-sm">
                          <input
                            v-if="product.buffer_quantity"
                            v-on:change="toggleSelected(product.sku)"
                            :checked="isSelected(product.sku)"
                            type="checkbox"
                            class="form-checkbox h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out mr-2.5"
                          />
                        </div>
                        <div class="block text-sm leading-5 truncate">
                          <p>{{ product.sku }}</p>
                          <p>{{ product.name }}</p>
                        </div>
                      </label>
                      <div class="text-xs uppercase text-center font-medium w-1/3 border border-gray-200 rounded">
                        <div class="grid grid-cols-2 bg-gray-200">
                          <span class="p-1 text-gray-600">Available</span>
                          <span class="p-1 border-l border-l-gray-200 text-gray-600">Original cart</span>
                        </div>
                        <div class="grid grid-cols-2">
                          <span class="p-1" :class="{'font-semibold': product.buffer_quantity ?? false}">{{ product.buffer_quantity ?? 0 }}</span>
                          <span class="p-1 border-l border-l-gray-200"  :class="{'font-semibold': product.inCart ?? false}">{{ product.inCart }}</span>
                        </div>
                      </div>

                      <div class="w-1/3 flex justify-center">
                        <selected-product-quantity-for-unskipping-post-cutoff
                          v-if="isSelected(product.sku) && product.buffer_quantity >= 1"
                          v-model="findSelected(product.sku).quantity"
                          :available="product.buffer_quantity"
                        />
                      </div>
                  </div>
              </fieldset>

              <div class="bg-gray-100 flex flex-col px-4 py-3 space-y-2">
                <label class="text-sm mt-4 mb-2">Order changes you can use in the customer email. <span class="text-red-500">Once order is saved, we won't have the original cart history, so make sure to copy before unsuspending the order.</span></label>
                <textarea class="form-textarea w-full text-sm text-gray-700" :value="supportEmail()" :rows="supportEmail().split('\n').length + 2"></textarea>
              </div>

              <div class="bg-gray-100 px-4 py-3 space-y-4">
                <div class="space-y-4">
                  <label class="flex text-sm hover:cursor-pointer">
                    <input
                      v-model="form.chargeCustomer"
                      type="radio"
                      value="now"
                      class="form-radio h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out mr-2.5 mt-1"
                    />
                    <div>
                      <p class="leading-5">Charge the customer now</p>
                      <p class="text-sm text-gray-600 leading-5">We will try to charge the customer now. If the charge fails, the order <strong>won't be resumed</strong> and you can ask the customer to update their CC details again.</p>
                    </div>
                  </label>
                  <label class="flex text-sm hover:cursor-pointer">
                    <input
                      v-model="form.chargeCustomer"
                      type="radio"
                      value="later"
                      class="form-radio h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out mr-2.5 mt-1"
                    />
                    <div>
                      <p class="leading-5">Charge the customer on post cutoff end</p>
                      <p class="text-sm text-gray-600 leading-5">We will charge the order automatically when other post cutoff orders as charged.</p>
                    </div>
                  </label>
                </div>
                <button
                  @click.prevent="confirm"
                  type="button"
                  class="w-auto focus:shadow-outline-teal inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none sm:text-sm sm:leading-5"
                >
                  {{ submitting ? 'Submitting...' : 'Unsuspend Order' }}
                </button>
              </div>

            </div>

            <div v-if="products.length === 0">
              <p>No items in cart</p>
            </div>
          </div>
        </div>
      </div>

      <ValidationMessages class="px-4 py-4 sm:px-0" :errors="allErrors" />
    </div>

    <PanelSection v-if="orderId">
      <PostCutoffActivity :order="order" />
    </PanelSection>

    <div v-if="!isLoading && eligibleOrderFound === false">
      <p>No eligible suspended order found</p>
    </div>

    <Loader v-if="isLoading && !eligibleOrderFound" />
    <FundsReturnStrategyModal :order="order" :user="user" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '~/components/global/Loader'
import PostCutoffActivity from './../../components/post-cutoff/PostCutoffActivity'
import FundsReturnStrategyModal from './../../components/post-cutoff/FundsReturnStrategyModal'
import DisplayPreferences from '@/components/post-cutoff/DisplayPreferences'
import SelectedProductQuantityForUnskippingPostCutoff from '@/components/post-cutoff/SelectedProductQuantityForUnskippingPostCutoff'
import HasErrors from '~/mixins/HasErrors'
import ValidationMessages from '~/components/global/UI/form/ValidationMessages'

export default {
  mixins: [HasErrors],
  components: {
    Loader,
    PostCutoffActivity,
    FundsReturnStrategyModal,
    DisplayPreferences,
    SelectedProductQuantityForUnskippingPostCutoff,
    ValidationMessages,
  },
  data() {
    return {
      isLoading: false,
      submitting: false,
      userId: null,
      orderId: null,
      addressId: null,
      showOrderCartTotals: false,
      productSkus: [],
      form: {
        selectedProducts: [],
        chargeCustomer: null,
      },
      filterOnlyAskedForMeals: false,
      eligibleOrderFound: null,
    }
  },
  async created() {
    try {
      this.isLoading = true
      this.userId = parseInt(this.$route.params.id, 10)
      await this.fetchUser()
      await this.fetchOrder()
      await this.fetchRelationships()
    } catch (e) {
      this.errorMessage('Error occurred')
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    user() {
      return this['users/byId'](this.userId)
    },
    order() {
      return this['orders/byId'](this.orderId)
    },
    cart() {
      return this['carts/byId'](this.order?.cart)
    },
    address() {
      return this['addresses/byId'](this.addressId)
    },
    existingCartProductSkus()
    {
      return this.cart.items.data.map(item => item.sku)
    },
    products() {
      let mergedSkus = this.filterOnlyAskedForMeals
        ? this.existingCartProductSkus
        : _.union(this.existingCartProductSkus, this.productSkus)

      mergedSkus = _.orderBy(mergedSkus, [sku => sku.toLowerCase()], ['asc']);

      return _.map(mergedSkus, sku => {
        const product = this.productBySku(sku) ?? this.cart.items.data.find(item => item.sku === sku)
        product.inCart = this.cart.items.data.find(item => item.sku === sku)?.quantity ?? 0
        return product;
      })
    },
    postCutoffActivity() {
      if (!this.orderId) {
        return null
      }

      return this['post-cutoff-activities/byOrderId'](this.orderId)
    },
    hasPayments() {
      return this.order?.payments?.data?.length > 0;
    },
    hasSuspendedOrderActivation() {
      return this.order?.suspendedOrderActivation?.data?.created_at ?? false;
    },
    lastPayment() {
      if (!this.hasPayments) {
        return null
      }

      return this.order.payments.data.slice(-1);
    },
    lastChargeFailed() {
      return this.lastPayment?.status === 'failed' ? true : null
    },
    lastChargeSucceeded() {
      return this.lastPayment?.status === 'successful' ? true : null
    },
    ...mapGetters([
      'orders/byId',
      'users/byId',
      'addresses/byId',
      'carts/byId',
      'products/bySKU',
      'post-cutoff-activities/byOrderId'
    ]),
  },
  methods: {
    fetchUser() {
      return this['users/fetchById']({
        id: this.userId,
        params: {
          include: [
            'activePromoCodeApplication',
            'compedAccount',
            'promoCodeApplications',
            'defaultAddress',
            'kids',
          ],
        },
      })
    },
    async fetchOrder() {
      try {
        const response = await this['orders/fetchUnsuspendPostCutoffEligibleOrder']({userId: this.user.id })
        this.orderId = response.data.id
        this.eligibleOrderFound = true
        this.addressId = response.data.address.data.id
      } catch (e) {
        this.orderId = null
        this.eligibleOrderFound = false
        this.loading = false
      }
    },
    async fetchRelationships() {
      this['products/fetchForUnskippingPostCutoff'](this.order.buffer_id)
        .then(data => (this.productSkus = _.map(data.data, el => el.sku)))
      this['tags/fetch']()
      this['indicators/fetch']()
      this['age-groups/fetch']()
    },
    async confirm() {
      this.submitting = true
      try {
        const res = await this['orders/unsuspendPostCutoffEligibleOrder']({
          userId: this.user.id,
          orderId: this.orderId,
          data: {
            items: this.form.selectedProducts,
            chargeCustomer: this.form.chargeCustomer
          }
        })
        this.errors = []
        this.successMessage('Success', res.data.message, 10000)
      } catch (err) {
        this.fetchOrder()
        this.errors = err.response.data.errors

        this.errorMessage('The request failed', this.firstError, 10000)
      } finally {
        this.submitting = false
      }
    },
    async attemptSecondCharge() {
      this.submitting = true
      try {
        const res = await this['orders/collectSuspendedOrderPayment']({ orderId: this.orderId, userId: this.user.id })
        this.successMessage('Successfully charged', res.data.message, 10000)
      } catch (err) {
        this.fetchOrder()
        this.errors = err.response.data.errors
        this.errorMessage('The request failed', this.firstError, 10000)
      } finally {
        this.submitting = false
      }
    },
    showFundsReturnStrategyModal() {
      this.$modal.show('funds-return-strategy-modal')
    },
    toggleSelected(sku) {
      if (this.isSelected(sku)) {
        this.form.selectedProducts.splice(
          this.form.selectedProducts.indexOf(this.findSelected(sku)),
          1
        )
      } else {
        this.form.selectedProducts.push({ sku, quantity: 1 })
      }
    },
    findOriginal(sku) {
      return this.cart.items.data.find(itemInCart => itemInCart.sku === sku)
    },
    findSelected(sku) {
      return this.form.selectedProducts.find(item => item.sku == sku)
    },
    isSelected(sku) {
      return this.findSelected(sku)
    },
    productBySku(sku) {
      return this['products/bySKU'](sku)
    },
    supportEmail() {
      if (this.form.selectedProducts.length === 0) {
        return 'Please add some items to the cart, so we can show the changes.'
      }

      let mail = ''
      let keptOriginalMeals = this.form.selectedProducts.filter(item => this.existingCartProductSkus.includes(item.sku))
      if (keptOriginalMeals.length > 0) {
        mail += `These meals in your original cart are still available:\n`
        mail += keptOriginalMeals.map(meal => this.getProductSentence(meal.sku, this.getProductQuantityDiff(meal.sku).addedFromAskedQty)).join('\n')
        mail += `\n\n`
      }

      let newItemsInCart = this.form.selectedProducts.filter(item =>
        ! this.existingCartProductSkus.includes(item.sku)
        || (this.existingCartProductSkus.includes(item.sku) && this.getProductQuantityDiff(item.sku).addedNewQty > 0)
      )
      if (newItemsInCart.length > 0) {
        mail += keptOriginalMeals.length > 0 ? `And we've added some new meals:\n` : `These meals are new:\n`
        mail += newItemsInCart.map(meal => this.getProductSentence(meal.sku, this.getProductQuantityDiff(meal.sku).addedNewQty)).join('\n')
        mail += `\n\n`
      }

      if (this.existingCartProductSkus.length > 0) {
        mail += `These meals are missing from the original order:\n`
        mail += this.existingCartProductSkus.filter(sku => this.getProductQuantityDiff(sku).missingQty > 0).map(sku => this.getProductSentence(sku, this.getProductQuantityDiff(sku).missingQty)).join('\n')
        mail += `\n\n`
      }

      return mail
    },
    getProductQuantityDiff(sku) {
      let originalCartQty = this.findOriginal(sku)?.quantity ?? 0
      let currentCartQty = this.findSelected(sku)?.quantity ?? 0

      let addedFromAskedQty = originalCartQty;
      if (originalCartQty !== currentCartQty) {
        addedFromAskedQty = originalCartQty > currentCartQty ? currentCartQty : originalCartQty
      }

      let addedNewQty = currentCartQty - addedFromAskedQty

      return {
        addedFromAskedQty,
        addedNewQty,
        missingQty: originalCartQty - addedFromAskedQty,
      }
    },
    getProductSentence(sku, quantity) {
      let product = this.productBySku(sku)
      return `${quantity}x ${product.name} ${product.with}`
    },
    ...mapActions([
      'orders/fetchUnsuspendPostCutoffEligibleOrder',
      'users/fetchById',
      'orders/unsuspendPostCutoffEligibleOrder',
      'orders/collectSuspendedOrderPayment',
      'products/fetchForUnskippingPostCutoff',
      'reasons/fetchSkipReasons',
      'tags/fetch',
      'indicators/fetch',
      'age-groups/fetch',
    ]),
  },
}
</script>
