import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
import axios from 'axios'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
  byUserId: state => id => state.items.filter(item => item.user_id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  updateById: (state, data) => {
    let item = state.items.find(item => item.id === data.id)
    Object.assign(item, data)
  },
}

export const actions = {
  depleteActive(store, id) {
    return axios.delete(`/api/sales/admin/users/${id}/promo-code-applications`).then(res => {
      store.commit('updateById', res.data.data)
      return res
    })
  },
  async massApply(store, { promoCode, emails, override }) {
    return await axios.post(`/api/sales/admin/promo-codes/${promoCode}/applications`, {
      emails,
      override,
    })
  },
}
