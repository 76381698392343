import { schema } from 'normalizr'
import products from '~/store/schema/products'
import category from '@/store/schema/categories'

const processStrategy = upsell => {
  const relations = {
    products: _.has(upsell, 'products') ? upsell.products.data : null,
    category: _.has(upsell, 'category') ? upsell.category.data : null,
  }
  return { ...upsell, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity('upsells', {products: [products], category}, {processStrategy})
