<template>
  <div>
    <div class="flex items-center justify-between px-4 py-4 pt-10">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Bestsellers</h3>
      <router-link
        :to="{ name: 'add-bestseller' }"
        class="rounded-sm bg-gray-700 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-500 hover:text-white focus:bg-teal-500 focus:text-white focus:outline-none"
      >
        <i class="fas fa-plus mr-2" /> New Bestsellers Order
      </router-link>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div v-if="bestsellers.length" class="mb-10">
          <ul class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            <li
              v-for="bestseller in bestsellers"
              :key="bestseller.id"
              class="col-span-1 flex flex-col rounded-lg bg-white text-center shadow"
            >
              <div class="flex flex-1 flex-col p-8">
                <img
                  v-if="bestseller.image"
                  class="mx-auto h-32 w-32 shrink-0 rounded-full bg-black"
                  :src="bestseller.image"
                  alt="bestseller.name"
                />
                <h3
                  class="text mt-6 font-semibold leading-5 text-gray-900"
                  v-text="bestseller.name"
                ></h3>
                <div class="mt-4 flex flex-col">
                  <span class="text-sm text-gray-600" v-if="bestseller.show_on_cart">
                    Shows as cart CTA
                  </span>
                  <span class="text-sm text-gray-600" v-if="bestseller.show_on_menu">
                    Shows as menu page card
                  </span>
                </div>
                <div class="mt-4 space-x-1" v-if="bestseller.inbound_categories.length > 0">
                  <span class="text-xs font-semibold uppercase tracking-wide text-gray-600">
                    Includes:
                  </span>
                  <span class="font-semibold capitalize text-teal-600">
                    {{ bestseller.inbound_categories.join(', ') }}
                  </span>
                </div>
              </div>
              <div class="border-t border-gray-200">
                <div class="-mt-px flex">
                  <div class="w-0 flex-1">
                    <router-link
                      :to="{ name: 'edit-bestseller', params: { id: bestseller.id } }"
                      class="focus:shadow-outline-blue relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none"
                    >
                      <span class="ml-3">Edit</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <empty-state v-else />
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
export default {
  mixins: [IndexPage],
  components: {},
  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 100,
    },
  }),
  computed: {
    ...mapGetters({
      itemById: 'bestsellers/byId',
    }),
    bestsellers() {
      return _.map(this.ids, id => this.itemById(id))
    },
  },
  methods: {
    ...mapActions({
      fetchBestsellers: 'bestsellers/fetch',
    }),
    fetchItems() {
      return this.fetchBestsellers()
    },
  },
}
</script>
