import Vue from 'vue'
import swal from 'sweetalert2'

Vue.mixin({
  methods: {
    successAlert(text = '', title = 'Success') {
      swal.fire({
        icon: 'success',
        title,
        text,
      })
    },
    confirmAlert(config = {}, callbackConfirm, callbackDeny) {
      config = {
        ...{
          title: 'Are you sure',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          cancelButtonColor: 'rgb(134 134 134)',
        },
        ...config,
      }

      swal
        .fire(config)
        .then((result) => {
          if (result.isConfirmed) {
            return typeof callbackConfirm == 'function' ? callbackConfirm() : true;
          } else if (result.isDenied) {
            return typeof callbackDeny == 'function' ? callbackDeny() : false;
          }
        })
    }
  },
})
