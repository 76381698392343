<template>
  <div>
    <div class="overflow-hidden rounded bg-white shadow-sm">
      <h1 class="border-b border-gray-200 px-4 py-2 text-xl font-bold lg:px-8 lg:py-4">
        {{ formTitle }}
      </h1>

      <!-- BASIC INFO -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3">
          <span class="font-semibold tracking-wide">Basic Info</span>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div>
            <label class="mb-1 text-base tracking-wide">Name</label>
            <input
              class="form-input w-full"
              type="text"
              v-model="form.name"
              placeholder="Experiment name"
            />
            <error :error="getError('name')"/>
          </div>
          <div class="mt-6">
            <label class="mb-1 text-base tracking-wide">Code</label>
            <input
              class="form-input w-full read-only:cursor-not-allowed"
              type="text"
              :readonly="!!experiment"
              v-model="form.code"
              placeholder="Experiment code (unique for all tests)"
            />
            <error :error="getError('code')"/>
          </div>
          <div class="mt-6">
            <div class="mt-3">
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="form.active" class="form-checkbox h-5 w-5"/>
                <span class="ml-2 text-sm text-gray-800">Active</span>
              </label>
              <p class="text-sm text-gray-600">
                Whether the experiment is active or not
              </p>
              <error :error="getError('active')"/>
            </div>
          </div>
        </div>
      </div>

      <!-- Variation INFO -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/5">
          <span class="font-semibold tracking-wide">Variants</span>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-4/5">
          <div v-for="(variant, idx) in form.variants" :key="'variation' + idx"
               class="flex justify-between items-center py-1">
            <label class="w-2/5 mb-1 text-base tracking-wide text-right pr-4">{{ variant.name }}</label>
            <input type="number" min="0" max="100" :value="variant.distribution"
                   class="form-input w-3/5 "
                   @change="e => updateVariation(variant.name, e.target.value)"
            />
            <TrashIcon
              v-if="form.variants.length > 2"
              @click="removeVariation(idx)"
              class="w-5 h-5 ml-2 text-red-500 cursor-pointer"
            />
          </div>
          <error :error="getError('variants')" class="text-right"/>

          <div class="flex mt-2 w-full justify-end">
            <input
              class="form-input w-2/3"
              type="text"
              v-model="newVariationName"
              placeholder="New Variation name"
            />
            <NLButton
              @click="createNewVariation"
              class="w-1/3 rounded bg-teal-600 ml-3 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
            >
              New Variation
            </NLButton>
          </div>
        </div>
      </div>
      <div class="flex justify-end bg-gray-100 py-6 px-4">
        <NLButton
          @click="onSave"
          class="w-full rounded bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
          :isLoading="submitting"
        >
          Save Changes
        </NLButton>
      </div>
    </div>
  </div>
</template>

<script>
import NLButton from '~/components/global/input/Button'
import HasErrors from '~/mixins/HasErrors'
import {findIndex} from "lodash";
import {TrashIcon} from '@vue-hero-icons/outline'

export default {
  mixins: [HasErrors],
  props: ['experiment', 'save'],
  components: {TrashIcon, NLButton},
  data() {
    return {
      form: {
        name: '',
        code: '',
        active: false,
        variants: [
          {
            name: 'Control',
            distribution: 50
          }
        ],
      },
      newVariationName: '',
      isLoading: false,
    }
  },
  watch: {
    experiment() {
      this.populateForm()
    },
  },
  computed: {
    formTitle() {
      return this.experiment ? 'Manage Experiment' : 'New Experiment'
    },
  },
  methods: {
    onSave() {
      this.wrapSubmit(this.save(this.form)).catch((e) => this.errorMessage(e.response.data.message))
    },
    populateForm() {
      this.form = {
        name: this.experiment ? this.experiment.name : '',
        code: this.experiment ? this.experiment.code : '',
        active: this.experiment ? this.experiment.active : false,
        variants: this.experiment ? this.experiment.variants.data : [],
      }
    },
    updateVariation(variationName, value) {
      const index = findIndex(this.form.variants, {name: variationName}) // Id is created after creation
      this.form.variants[index].distribution = parseInt(value)
    },
    removeVariation(idx) {
      if (this.form.variants[idx].name === 'Control') {
        alert('You cannot delete the control variation')
        return
      }
      this.form.variants.splice(idx, 1)
    },
    createNewVariation() {
      if (this.newVariationName.length === 0) {
        alert('The variation name is required')
        return;
      }

      this.form.variants.push({
        name: this.newVariationName,
        distribution: 10
      })

      this.newVariationName = ''
    }
  }
}
</script>
<style scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}
</style>
