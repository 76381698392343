<template>
  <div class="overflow-hidden rounded bg-white shadow-lg">
    <div class="flex items-center justify-between border-b border-gray-300 bg-white py-3 px-4">
      <h4 class="text-base font-semibold">Skip/unskip post cutoff</h4>
    </div>
    <div class="bg-gray-100 px-4 py-4 sm:pt-6">
      <div v-if="isSkipped" class="text-gray-600">
        <span>This order is currently <span class="font-bold">skipped</span>.</span>

        <span v-if="isSkippedPostCutoff">It was skipped post cutoff</span>

        <a
          @click.prevent="showUnskipModal"
          class="ml-2 inline-flex items-center space-x-2 rounded-full bg-teal-600 py-1 px-2 text-xs uppercase tracking-wide text-white transition duration-300 hover:bg-teal-500"
          href="#"
        >
          <span>Unskip it?</span>
          <div class="flex h-5 w-5 items-center justify-center rounded-full bg-black bg-opacity-25">
            <svg
              class="h-3 w-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
        </a>
      </div>
      <div v-else-if="isPostCutoff" class="text-gray-600">
        <span>
          This order is currently <span class="font-bold">unskipped</span> and cannot be skipped since it's post cutoff.
        </span>
      </div>
      <div v-else class="text-gray-600">
        <span>
          This order is currently <span class="font-bold">unskipped</span> and can be skipped
        </span>
        <a
          @click.prevent="showSkipModal"
          class="ml-2 inline-flex items-center space-x-1 rounded-full bg-orange-600 py-1 px-2 text-xs uppercase tracking-wide text-white transition duration-300 hover:bg-orange-500"
          href="#"
        >
          <span>Skip</span>
          <div class="flex h-5 w-5 items-center justify-center rounded-full bg-black bg-opacity-25">
            <svg
              class="h-3 w-3 text-orange-100"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
    <skip-post-cutoff-modal :user="user" :order-id="orderId" />
    <unskip-post-cutoff-modal :user="user" :order-id="orderId" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SkipPostCutoffModal from './SkipPostCutoffModal'
import UnskipPostCutoffModal from './UnskipPostCutoffModal'
import { isPast } from "date-fns"
export default {
  props: ['userId', 'orderId'],
  components: {
    SkipPostCutoffModal,
    UnskipPostCutoffModal,
  },
  computed: {
    user() {
      return this['users/byId'](this.userId)
    },
    order() {
      return this['orders/byId'](this.orderId)
    },
    isPostCutoff() {
      const cutoff = new Date(this.order.cutoff_at)

      return isPast(cutoff) && this.order.status === 'cancelled'
    },
    isSkipped() {
      return this.order && this.order.is_skipped
    },
    isSkippedPostCutoff() {
      return this.order && this.order.is_skipped_post_cutoff
    },
    ...mapGetters(['orders/byId', 'users/byId']),
  },
  methods: {
    showSkipModal() {
      this.$modal.show('skip-post-cutoff')
    },
    showUnskipModal() {
      this.$modal.show('unskip-post-cutoff')
    },
  },
}
</script>
