import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, removeItem, resolveIdAndParams, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [types.REMOVE]: (state, item) => removeItem(state, item),
}

export const actions = {
  create() {
    return axios.post('/api/sales/admin/meal-rotations').then(res => {
      processEntities(res.data.data, 'productRotations')
      return res.data
    })
  },

  fetch(store, params) {
    return axios.get('/api/sales/admin/meal-rotations', { params }).then(res => {
      processEntities(res.data.data, 'productRotations')
      return res.data
    })
  },

  fetchById(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`/api/sales/admin/meal-rotations/${id}`, { params }).then(res => {
      processEntities(res.data.data, 'productRotations')
      return res.data
    })
  },

  fetchOrderWeeks(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`/api/sales/admin/meal-rotations/${id}/order-weeks`, { params }).then(res => {
      processEntities({ id, orderWeeks: res.data }, 'productRotations')
      return res.data
    })
  },

  addOrderWeek(store, { id, weekId }) {
    return axios
      .post(`/api/sales/admin/meal-rotations/${id}/order-weeks`, { order_week_id: weekId })
      .then(res => {
        const week = _.assign(res.data.data, { meal_rotation_id: id })
        processEntities(week, 'orderWeeks')
        return res.data
      })
  },

  removeOrderWeek(store, { id, weekId }) {
    return axios.delete(`/api/sales/admin/product-rotations/${id}/order-weeks/${weekId}`).then(res => {
      const week = _.assign(res.data.data, { meal_rotation_id: null })
      processEntities(week, 'orderWeeks')
      return res.data
    })
  },

  fetchProducts(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`/api/sales/admin/meal-rotations/${id}/products`, { params }).then(res => {
      processEntities({ id, products: res.data }, 'productRotations')
      return res.data
    })
  },

  addProduct(store, { id, params }) {
    return axios.post(`/api/sales/admin/meal-rotations/${id}/products`, params)
  },

  removeProduct(store, { id, sku }) {
    return axios.delete(`/api/sales/admin/meal-rotations/${id}/products/${sku}`)
  },

  reorderProducts(store, { id, params }) {
    return axios.post(`/api/sales/admin/meal-rotations/${id}/products-order`, params).then(res => {
      processEntities({ id, products: res.data }, 'productRotations')
      return res.data
    })
  },

  remove({ commit }, id) {
    return axios
      .delete(`/api/sales/admin/meal-rotations/${id}`)
      .then(() => commit(types.REMOVE, { id }))
  },

  clone(store, id) {
    return axios.post(`/api/sales/admin/meal-rotations/${id}/clones`).then(res => {
      processEntities(res.data.data, 'productRotations')
    })
  },
}
