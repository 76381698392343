<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">
          Edit carrier
        </h2>
      </div>
    </div>

    <div class="mt-8">
      <div class="rounded-md bg-white p-5">
        <CarrierForm
          v-model="form"
          v-if="carrier"
          :carrier="carrier"
          v-on:submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HasErrors from '~/mixins/HasErrors'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import CarrierForm from '~/pages/carriers/Form'

export default {
  components: {
    CarrierForm,
  },
  mixins: [HasErrors],
  mounted() {
    this.load()
  },
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapGetters({
      carrierById: 'carriers/byId',
    }),
    carrierId() {
      return this.$route.params.id
    },
    carrier() {
      return this.carrierById(this.carrierId)
    },
  },
  methods: {
    ...mapActions({
      updateCarrier: 'carriers/update',
      fetchById: 'carriers/fetchById',
    }),
    load() {
      this.fetchById(this.carrierId).then(response => {
        this.setForm(response.data)
      })
    },
    setForm(data) {
      this.form = Object.assign({}, data)
    },
    submit(wrapSubmit) {
      wrapSubmit(this.updateCarrier({ id: this.carrierId, form: this.form }))
        .then(data => {
          this.successMessage('Carrier updated')
        })
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
  },
}
</script>
