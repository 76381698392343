<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <div class="flex items-center justify-between">
      <h4 class="font-bold">Comped Account Settings</h4>
      <span v-if="compedAccount" class="ml-5 inline-block rounded-full bg-teal-200 p-1">
        <svg
          class="h-3 w-3 stroke-current text-teal-800"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </span>
      <span v-if="!compedAccount" class="ml-5 inline-block rounded-full bg-pink-200 p-1">
        <svg
          class="h-3 w-3 stroke-current text-pink-800"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </span>
    </div>
    <div class="mt-4">
      <div v-if="compedAccount">
        <div v-if="compedAccount.remaining_weeks === null && compedAccount.amount === null">
          <span class="text-gray-700">Forever comped for unlimited weekly amount.</span>
        </div>
        <div v-if="compedAccount.remaining_weeks === null && compedAccount.amount > 0">
          <span class="text-gray-700"
            >Forever comped for ${{ compedAccount.amount }} weekly amount.</span
          >
        </div>
        <div v-if="compedAccount.remaining_weeks > 0 && compedAccount.amount === null">
          <span class="text-gray-700"
            >Comped for {{ compedAccount.remaining_weeks }} more weeks for unlimited weekly
            amount.</span
          >
        </div>
        <div v-if="compedAccount.remaining_weeks > 0 && compedAccount.amount > 0">
          <span class="text-gray-700"
            >Comped for {{ compedAccount.remaining_weeks }} more weeks for ${{
              compedAccount.amount
            }}
            weekly amount.</span
          >
        </div>
        <div class="mt-4">
          <a href="#" @click.prevent="openDeactivateModal" class="underline hover:text-teal-500">
            Deactivate comped
          </a>
        </div>
      </div>
      <a v-else href="#" class="link" @click.prevent="openActivateModal">Change...</a>
    </div>
    <comped-account-activate-modal :user="user" />
    <comped-account-deactivate-modal :user="user" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompedAccountActivateModal from '~/components/comped/CompedAccountActivateModal'
import CompedAccountDeactivateModal from '~/components/comped/CompedAccountDeactivateModal'

export default {
  props: ['user'],
  components: {
    CompedAccountActivateModal,
    CompedAccountDeactivateModal
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(['comped-accounts/byId']),
    compedAccount() {
      const account = this['comped-accounts/byId'](this.user.compedAccount)
      return account && account.is_activated && account
    },
  },
  methods: {
    openActivateModal() {
      this.$modal.show('activate-comped-account')
    },
    openDeactivateModal() {
      this.$modal.show('deactivate-comped-account')
    }
  },
}
</script>
