<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex justify-between border-b border-solid border-gray-400 py-4">
      <h3 class="text-3xl">State Sales Tax</h3>
    </div>

    <table class="mt-4 w-full table-auto bg-white">
      <thead>
        <tr class="border-b-2 font-bold">
          <th class="w-8 border px-4 py-2">ID</th>
          <th class="border px-4 py-2 text-left">Name</th>
          <th class="w-10 border px-4 py-2">Tax(%)</th>
          <th class="border px-4 py-2">Action</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="state in states" :key="state.id" class="text-center">
          <td class="border px-4 py-2">
            {{ state.id }}
          </td>
          <td class="border px-4 py-2 text-left">
            {{ state.name }}
          </td>
          <td class="border px-4 py-2">
            {{ state.tax }}
          </td>
          <td class="w-48 border px-4 py-2">
            <router-link
              :to="{ name: 'edit-tax-rate', params: { id: state.id } }"
              class="icon-link icon-green"
            >
              <i class="fas fa-edit" />
              <div class="tooltip">Edit</div>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      states: 'states/all',
    }),
  },

  mounted() {
    this.fetchItems()
  },

  methods: {
    ...mapActions({
      fetchItems: 'states/fetch',
    }),
  },
}
</script>
