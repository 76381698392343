var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left",class:{
  'grid grid-cols-3 bg-gray-50 rounded p-4 gap-3': _vm.horizontal
}},[_c('div',{class:{
    'space-y-2': _vm.horizontal
  }},[_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v("User prefers:")]),_c('div',_vm._l((_vm.profile.preferences.data),function(item){return _c('div',{staticClass:"text-sm text-gray-700"},[_vm._v(" "+_vm._s(item.quantity)+" items of "+_vm._s(item.category_name)+" ")])}),0)]),(_vm.hasTags)?_c('div',{class:{
    'mt-2 flex flex-wrap items-center space-x-2': !_vm.horizontal,
    'space-y-2': _vm.horizontal
  }},[_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v("Tags:")]),_c('div',{class:{
      'flex space-x-2': !_vm.horizontal,
      'grid grid-cols-1 gap-1': _vm.horizontal
    }},_vm._l((_vm.tags),function(tag){return _c('span',{key:tag.id,staticClass:"inline-block rounded-sm border border-blue-200 bg-blue-100 px-1 text-xs tracking-wider text-blue-700"},[_vm._v(" "+_vm._s(tag.name)+" ")])}),0)]):_vm._e(),(_vm.hasIndicators)?_c('div',{class:{
    'mt-2 flex items-start space-x-2': !_vm.horizontal,
    'space-y-2': _vm.horizontal
  }},[_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v("Indicators:")]),_c('div',{class:{
      'grid auto-cols-fr grid-flow-col-dense gap-2': !_vm.horizontal,
      'grid grid-cols-1 gap-1': _vm.horizontal
    }},_vm._l((_vm.indicators),function(indicator){return _c('span',{key:indicator.id,staticClass:"rounded-sm border border-blue-200 bg-blue-100 px-1 text-xs tracking-wider text-blue-700"},[_vm._v(" "+_vm._s(indicator.name)+" ")])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }