<template>
  <modal :adaptive="true" name="activate-comped-account" height="auto">
    <div class="m-4">
      <div>Chose an option</div>
      <hr />
      <form>
        <div class="form-group" :class="{ 'has-error': hasError('amount') }">
          <label
            >Comped Weekly Limit $
            <input type="number" v-model="form.amount" />
          </label>
          <error :error="getError('amount')" />
        </div>
        <div class="form-group" :class="{ 'has-error': hasError('number_of_weeks') }">
          <label
            >Number Of Weeks
            <input type="number" v-model="form.number_of_weeks" />
          </label>
          <error :error="getError('number_of_weeks')" />
        </div>
        <div class="form-group" :class="{ 'has-error': hasError('with_banner') }">
          <label>
            <input type="checkbox" v-model="form.with_banner" />
            Display Comped Banner
          </label>
          <error :error="getError('with_banner')" />
        </div>
        <div>
          <button class="btn btn-green" @click.prevent="save">Confirm</button>
          <button class="btn btn-gray" @click.prevent="close">Cancel</button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['user'],
  data: () => ({
    form: {
      amount: null,
      number_of_weeks: null,
      with_banner: true,
    },
  }),
  methods: {
    ...mapActions(['users/updateCompedSettings']),
    close() {
      this.$modal.hide('activate-comped-account')
    },
    save() {
      const params = {
        amount: +this.form.amount,
        number_of_weeks: +this.form.number_of_weeks,
        with_banner: this.form.with_banner,
      }
      this['users/updateCompedSettings']({ id: this.user.id, params }).then(this.close).catch(e => this.errorMessage(e.response.data.message))
    },
  },
}
</script>
