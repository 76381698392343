<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <form-component :save="createPromoCode" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import FormComponent from '~/components/promo-codes/Form'

export default {
  components: {
    FormComponent,
  },
  methods: {
    ...mapActions(['promo-codes/create']),
    createPromoCode(data) {
      return this['promo-codes/create'](data).then(data => {
        this.successMessage('Promo Code Created!')
        this.$router.push({ name: 'edit-promo-code', params: { id: data.data.id } })
      })
    },
  },
}
</script>
