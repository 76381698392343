<template>
  <div class="pb-8">
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <div class="border-r border-gray-300 px-6">
        <div class="pb-6">
          <InputGroup label="Header" :error="getError('header')">
            <input
              v-model="form.data.header"
              placeholder="Order Bump Header"
              class="form-input w-full"
            />
          </InputGroup>
        </div>

        <div class="pb-6">
          <InputGroup label="Content" :error="getError('content')">
            <textarea v-model="form.data.content" class="form-input w-full"></textarea>
          </InputGroup>
        </div>

        <div class="pb-6">
          <InputGroup
            label="CTA Text"
            helpText="Call to action button text"
            :error="getError('cta_text')"
          >
            <input
              v-model="form.data.cta_text"
              placeholder="CTA Button Text"
              class="form-input w-full"
            />
          </InputGroup>
        </div>

        <div class="pb-6">
          <InputGroup
            label="Link Text"
            helpText="Leave blank not to show the link"
            :error="getError('link_text')"
          >
            <input
              v-model="form.data.link_text"
              placeholder="Link Text"
              class="form-input w-full"
            />
          </InputGroup>
        </div>

        <div class="pb-6">
          <InputGroup
            label="Link URL"
            :helpText="'Full link url starting with <b>https://</b>.<br/> Variables available: <b>{{orderId}}</b>'"
            :error="getError('link_account_uri')"
          >
            <textarea v-model="form.data.link_account_uri" class="form-input w-full"></textarea>
          </InputGroup>
        </div>

        <div class="pb-6">
          <InputGroup
            label="Weeks"
            helpText="The order bump will be shown for selected weeks. To select more than one week, hold down the shift button and click each week. Only active and draft weeks are available."
            :error="getError('week_ids')"
          >
            <select v-model="form.data.week_ids" multiple class="form-input w-full">
              <option :value="null">No week selected</option>
              <option
                v-for="availableWeek in form.availableWeeks"
                v-bind:key="availableWeek.id"
                :value="availableWeek.id"
              >
                {{ availableWeek.number }}
              </option>
            </select>
          </InputGroup>
        </div>
      </div>

      <div class="px-6">
        <div class="border-b border-gray-300 pb-6">
          <InputGroup label="Product" :error="getError('product_sku')">
            <products-autocomplete
              v-model="form.data.product_sku"
              :params="{ active: 1 }"
              :exceptions="[form.data.product_sku]"
              :custom-label="getProductAutocompleteLabel"
            />
            <figure class="relative mt-4 text-right" v-if="product">
              <div class="relative inline-block text-center">
                <img class="w-full pb-2" :src="productPreviewURI" />
                <figcaption>${{ product.price.original_price }}</figcaption>
              </div>
            </figure>
          </InputGroup>
        </div>

        <div class="pb-6 pt-6">
          <InputGroup
            label="Is Active?"
            :error="getError('active')"
            helpText="Only active order bumps are displayed."
          >
            <input type="checkbox" v-model="form.data.active" class="form-checkbox h-5 w-5" />
          </InputGroup>
        </div>
      </div>
    </div>

    <div class="px-4">
      <button-loader label="Save" :loading="submitting" @click="submit" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '~/mixins/FormMixin'
import { uniq } from 'lodash'
import ProductsAutocomplete from '~/components/global/Autocompletes/Products'

export default {
  props: {
    orderBump: {
      type: Object,
      default: null,
    },
  },

  components: {
    ProductsAutocomplete,
  },

  mixins: [FormMixin],

  data: () => ({
    form: {
      data: {
        product_sku: null,
        header: null,
        content: null,
        cta_text: null,
        link_text: null,
        link_account_uri: null,
        active: false,
        week_ids: [null],
      },
      availableWeeks: [],
    },
  }),

  computed: {
    ...mapGetters({
      findOrderWeekById: 'order-weeks/byId',
      findProductBySKU: 'products/bySKU',
    }),

    product() {
      return this.form.data.product_sku ? this.findProductBySKU(this.form.data.product_sku) : null
    },

    productPreviewURI() {
      return this.product ? this.product.image['meal-card-desktop'] : null
    },
  },

  methods: {
    ...mapActions({
      fetchOrderWeeks: 'order-weeks/fetch',
    }),
    getProductAutocompleteLabel({ sku, name }) {
      return `${sku} - ${name}`
    },
    async retrieveOrderWeeks() {
      const results = await this.fetchOrderWeeks({ params: { status: ['active', 'draft'] } })
      let ids = results.data.map(week => week.id)
      if (this.orderBump) {
        ids.push(...this.orderBump.weeks)
      }
      ids = uniq(ids)
      this.form.availableWeeks = ids
        .map(id => this.findOrderWeekById(id))
        .sort((first, second) => new Date(second.date).getTime() - new Date(first.date).getTime())
    },
    populateForm(orderBump) {
      this.form.data.product_sku = orderBump.product
      this.form.data.header = orderBump.header
      this.form.data.content = orderBump.content
      this.form.data.cta_text = orderBump.cta_text
      this.form.data.link_text = orderBump.link_text
      this.form.data.link_account_uri = orderBump.link_account_uri
      this.form.data.active = orderBump.active
      this.form.data.week_ids = orderBump.weeks
    },
  },

  watch: {},

  async mounted() {
    await this.retrieveOrderWeeks()
    if (this.orderBump) {
      this.populateForm(this.orderBump)
    }
  },
}
</script>
<style>
.product-rating .mb-8 {
  margin-bottom: 10px;
}
</style>
