<template>
  <div>
    <multiselect
      autocomplete="off"
      id="ajax"
      v-model="selected"
      :label="label"
      :track-by="key"
      :placeholder="placeholder"
      :customLabel="customLabel"
      open-direction="bottom"
      :options="options"
      :searchable="true"
      :internal-search="false"
      :loading="isLoading"
      :hide-selected="true"
      :limit="5"
      @search-change="asyncFind"
      @select="$emit('selected')"
      :taggable="canCreateNewRecordsUsing !== null"
      tag-placeholder="Press enter to create"
      @tag="createNewRecord"
    >
      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import * as _ from 'lodash'
export default {
  components: {
    Multiselect,
  },
  props: {
    value: { default: null },
    params: { type: Object, default: () => {} },
    exceptions: { type: Array, default: () => [] },
    customLabel: { default: undefined },
    canCreateNewRecordsUsing: {type:Function, default: null},
  },
  data() {
    return {
      selected: null,
      options: [],
      isLoading: false,
      key: 'id',
      searchKey: 'search',
      label: 'name',
      placeholder: 'Search...',
    }
  },
  mounted() {
    if (this.value) {
      this.selected = this.getItem(this.value)
    }
  },
  watch: {
    selected() {
      this.$emit('input', this.selected && this.selected[this.key])
    },
    value() {
      if (!this.value && this.selected) this.selected = null

      if (this.value && (!this.selected || this.selected[this.key] !== this.value))
        this.selected = this.getItem(this.value)
    },
  },
  methods: {
    asyncFind(query) {
      this.isLoading = true

      const params = { ...this.params }
      params[this.searchKey] = query

      this.fetch(params).then(data => {
        this.options = _.filter(data.data, option => !this.exceptions.includes(option[this.key]))
        this.isLoading = false
      })
    },
    createNewRecord(name){
      if (this.canCreateNewRecordsUsing !== null){
        this.canCreateNewRecordsUsing(name)
      }
    }
  },
}
</script>
