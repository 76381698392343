import { schema } from 'normalizr'

const processStrategy = subscription => {
  const relations = {
    paused: _.get(subscription, 'paused.data', {}),
  }
  return { ..._.omit(subscription, []), ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity('subscriptions', {}, { processStrategy })
