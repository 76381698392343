<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['productHeatingInstructions'],
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    ...mapGetters({
      heatingInstructionsTypes: 'heating-instructions-types/all',
    }),
  },
  methods: {
    ...mapActions({
      fetchHeatingInstructionsTypes: 'heating-instructions-types/fetch',
    }),
    add() {
      this.selected.push({
        id: null,
        type: '',
        title: '',
        text: '',
      })
    },
    remove(index) {
      this.selected.splice(index, 1)
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
    productHeatingInstructions: {
      deep: true,
      handler(value) {
        if (!_.isEmpty(value)) {
          this.selected = value
        }
      },
    },
  },
  mounted() {
    this.fetchHeatingInstructionsTypes()
    this.selected = this.productHeatingInstructions
  },
}
</script>

<template>
  <div class="rounded-lg bg-gray-50 p-4">
    <div class="mb-6 space-y-6 divide-y-2 divide-gray-500 divide-opacity-25">
      <div v-for="(instruction, i) in selected" :key="i" class="relative pt-6">
        <div class="absolute top-0 right-0 mt-2 mr-2">
          <button @click="remove(i)">
            <svg
              class="h-4 w-4 text-red-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div>
              <label>Type</label>
            </div>
            <select v-model="selected[i].type" class="form-select w-full">
              <option
                v-for="type in heatingInstructionsTypes"
                :value="type.id"
                :selected="selected[i].type == type.id"
              >
                {{ type.alt }}
              </option>
            </select>
            <p class="text-sm text-gray-600">This will define the icon shown on the front-end</p>
          </div>
          <div>
            <div>
              <label>Title</label>
            </div>
            <input type="text" v-model="selected[i].title" class="form-input w-full" />
          </div>
        </div>
        <div class="mt-4">
          <div>
            <label>Description</label>
          </div>
          <textarea v-model="selected[i].text" class="form-textarea w-full"></textarea>
          <p class="text-sm text-gray-600">
            Basic HTML tags are acceptable, i.e.
            <code class="text-xs text-red-400">&lt;strong&gt;</code>
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-start py-3">
      <button
        class="transaction rounded bg-white px-4 py-3 duration-300 hover:bg-teal-500 hover:text-white"
        @click="add"
      >
        Add
      </button>
    </div>
  </div>
</template>
