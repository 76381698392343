<template>
  <modal
    name="regenerate-referral-id-modal"
    :adaptive="true"
    @closed="$emit('closed')"
    height="auto"
    :scrollable="true"
  >
    <header class="border-b border-gray-200 px-6 py-4">
      <h3 class="text-lg font-semibold">Are you sure you want to regenerate this user's referral code ?</h3>
    </header>

    <form @submit.prevent="submit" class="mt-4 divide-y-2">
      <div class="pb-4 px-4">
        <p>
          This will invalidate <span class="underline">all of their previously shared referral links</span> and a new referral id will be generated.
        </p>
      </div>
      <div class="flex justify-end bg-gray-100 px-4 py-4">
        <NLButton type="submit" class="px-3 py-1" :isLoading="submitting">Submit</NLButton>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import NLButton from '~/components/global/input/Button'

export default {
  props: ['user'],
  mixins: [HasErrors],
  components: { NLButton },
  methods: {
    ...mapActions(['users/regenerateReferralId']),
    async submit() {
      try {
        this.submitting = true
        await this.regenerate()
        this.successMessage('Referral Code regenerated successfully')
        this.close()
        this.submitting = false
      } catch (e) {
        console.log(e)
        // if (e.response.status === 422) {
        //   this.errors = e.response.data.errors
        //   this.errorMessage('Given data was invalid')
        // }
        this.submitting = false
      }
    },
    async regenerate() {
      console.log(this.user)
      await this['users/regenerateReferralId'](this.user.id)
    },
    close() {
      this.$modal.hide('regenerate-referral-id-modal')
    },
  },
}
</script>
