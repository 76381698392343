import * as types from '../mutation-types'
import axios from 'axios'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  regular: state => userId =>
    state.items.filter(item => item.user_id === userId).find(item => item.type === 'CREDIT'),
  referral: state => userId =>
    state.items.filter(item => item.user_id === userId).find(item => item.type === 'REFERRAL'),
  referrer: state => userId =>
    state.items.filter(item => item.user_id === userId).find(item => item.type === 'REFERRER'),
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  async fetch(state, userId) {
    const response = await axios.get(`api/credits/${userId}/credit-accounts`, {
      params: { include: 'transactions' },
    })
    processEntities(response.data.data, 'creditAccounts')
  },

  async submitCreditTransaction(state, data) {
    const response = await axios.post(
      `api/credits/credit-accounts/${data.creditAccountId}/transactions`,
      data.params
    )
    processEntities(response.data.data, 'creditAccounts')
  },

  async submitDebitTransaction(state, { creditAccountId, amount, reason_details }) {
    const response = await axios.post(
      `api/credits/credit-accounts/${creditAccountId}/debit-transactions`,
      {
        amount,
        reason_details,
      }
    )
    processEntities(response.data.data, 'creditAccounts')
  },

  async calculateAmount(state, { orderId, products }) {
    const {
      data: { amount },
    } = await axios.post(`api/credits/admin/orders/${orderId}/amount`, { products })
    return amount
  },
}
