import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

// The middleware for every page of the application
const globalMiddleware = ['auth']

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(require.context('~/middleware', false, /.*\.js$/))

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(beforeEach)

export default router

async function beforeEach(to, from, next) {
  let components = []

  try {
    // Get the matched components and resolve them.
    components = await resolveComponents(router.getMatchedComponents({ ...to }))
  } catch (e) {}

  if (components.length === 0) return next()

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components)

  callMiddleware(middleware, to, from, (...args) => {
    next(...args)
  })
}

function callMiddleware(middleware, to, from, next) {
  const stack = middleware.reverse()

  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }

    const middleware = stack.pop()

    if (typeof middleware === 'function') {
      middleware(to, from, _next)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw Error(`Undefined middleware [${middleware}]`)
    }
  }

  _next()
}

/**
 * Resolve async components.
 */
function resolveComponents(components) {
  return Promise.all(
    components.filter(Boolean).map(component => {
      return typeof component === 'function' ? component() : component
    })
  )
}

/**
 * Merge the the global middleware with the components middleware.
 */
function getMiddleware(components) {
  const middleware = [...globalMiddleware]

  components
    .filter(c => c.middleware)
    .forEach(component => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware)
      } else {
        middleware.push(component.middleware)
      }
    })

  return middleware
}

function resolveMiddleware(requireContext) {
  return requireContext
    .keys()
    .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce((guards, [name, guard]) => ({ ...guards, [name]: guard.default }), {})
}
