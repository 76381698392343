<template>
  <section
    class="relative rounded-lg bg-white object-cover p-5 text-teal-900 shadow-md"
    :class="{ 'opacity-50': !isActive, 'hover:bg-teal-100': isActive }"
  >
    <div class="flex">
      <figure class="order-bump-card-thumbnail relative w-1/3 border-r border-gray-300">
        <div class="relative mr-5 text-center flex flex-col justify-between h-full">
          <div>
            <img class="w-full pb-2" :src="imageUri" />
            <figcaption>${{ product.price.original_price }}</figcaption>
          </div>
          <p>{{orderBump.created_at}}</p>
        </div>
      </figure>
      <div class="order-bump-card-contents w-2/3 pl-5">
        <header class="mb-2">
          <h1 class="text-lg font-bold min-h-[58px]">{{ orderBump.header }}</h1>
        </header>
        <p class="mb-2">
          {{ orderBump.content }}
        </p>
        <footer>
          <p class="mb-2">
            <button
              disabled
              class="block w-full cursor-text rounded px-6 py-3 text-sm font-semibold tracking-tight text-white text-teal-900 md:inline-block md:w-auto"
              :class="{ 'bg-gray-400': !isActive, 'bg-yellow-400': isActive }"
            >
              {{ orderBump.cta_text }}
            </button>
          </p>

          <p>
            <a href="#" class="cursor-text">{{ orderBump.link_text }}</a>
          </p>
        </footer>
      </div>
    </div>
    <div class="absolute top-0 right-0">
      <router-link
        class="mr-3 mt-3 block font-semibold hover:text-teal-500 hover:underline"
        :to="editLinkUrl"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="block h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    orderBump: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters({
      getOrderWeekById: 'order-weeks/byId',
      getProductBySku: 'products/bySKU',
    }),
    isActive() {
      return this.orderBump.active === 1 || this.orderBump.active === '1'
    },
    weeks() {
      return this.orderBump.weeks.map(week => this.getOrderWeekById(week))
    },
    product() {
      return this.getProductBySku(this.orderBump.product)
    },
    editLinkUrl() {
      return { name: 'edit-order-bump', params: { id: this.orderBump.id } }
    },
    imageUri() {
      return this.product.image['meal-card-desktop']
    },
  },
}
</script>
