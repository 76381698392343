<template>
  <div>
    <label class="mb-1 text-base tracking-wide">
      {{ label }}
    </label>

    <label
      class="block w-full cursor-pointer rounded-lg border border-gray-200 py-2 px-3 shadow transition duration-150 hover:bg-gray-50"
      :class="{
        'bg-green-200': this.file,
      }"
    >
      <input type="file" @change="uploadFile" class="hidden" />
      <span
        class="inline-flex items-center space-x-1"
      >
        <UploadIcon class="h-4 w-4 text-gray-400" />
        <span
          class="text-sm text-gray-700"
        >{{ this.file ? (this.file.name + ' - File selected (click to change)') : 'Upload File'}}</span>
      </span>
    </label>
  </div>
</template>

<script>
import { UploadIcon } from '@vue-hero-icons/solid'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  components: { UploadIcon },
  data: () => ({
    file: null,
  }),

  methods: {
    uploadFile(event) {
      this.file = event.target.files[0]

      this.$emit('input', this.file)
    },
  },
}
</script>
