import { schema } from 'normalizr'
import _ from 'lodash'
import orders from './orders'
import reasons from './reasons'

const processStrategy = transaction => {
  const relations = {
    order: _.has(transaction, 'order') ? transaction.order.data : null,
    reason: _.has(transaction, 'reason') ? transaction.reason.data : null,
  }
  return { ...transaction, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'creditTransactions',
  {
    order: orders,
    reason: reasons,
  },
  {
    processStrategy,
  }
)
