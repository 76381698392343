import Login from './service-routes/login'

import PostCutoff from '../pages/post-cutoff/PostCutoff.vue'
import RogueOrder from '../pages/post-cutoff/RogueOrder.vue'
import SuspendedOrder from '../pages/suspended-order/SuspendedOrder.vue'
import UserCreate from '../pages/users/Create'
import UserEdit from '../pages/users/Edit'
import Users from '../pages/users/Index'

import CategoryEdit from '../pages/categories/Edit'
import Categories from '../pages/categories/Index'

import PromoCodesCreate from '../pages/promo-codes/Create'
import PromoCodesEdit from '../pages/promo-codes/Edit'
import PromoCodes from '../pages/promo-codes/Index'
import PromoCodesMassApply from '../pages/promo-codes/MassApply'

import ExperimentsCreate from '../pages/experiments/Create'
import ExperimentsEdit from '../pages/experiments/Edit'
import Experiments from '../pages/experiments/Index'

import GiftCards from '../pages/gift-cards/Index'

import HolidaysCreate from '../pages/holidays/Create'
import Holidays from '../pages/holidays/Index'

import ProductRotationEdit from '../pages/product-rotations/Edit'
import ProductRotations from '../pages/product-rotations/Index'
import ProductRotationOrderWeeks from '../pages/product-rotations/OrderWeeks'

import ProductsCreate from '../pages/products/Create'
import ProductEdit from '../pages/products/Edit'
import Products from '../pages/products/Index'

import OrderBumpCreate from '../pages/orderBumps/Create'
import OrderBumpEdit from '../pages/orderBumps/Edit'
import OrderBumps from '../pages/orderBumps/Index'

import TaxRateEdit from '../pages/tax-rates/Edit'
import TaxRates from '../pages/tax-rates/Index'

import OrderWeeks from '../pages/OrderWeeks'

import ZipCodesImport from '../pages/zip-codes/Import'
import ZipCodes from '../pages/zip-codes/Index'

import Reports from '../pages/reports/Index'

import ProductionCycles from '../pages/production-cycles/Index'

import ShipConfirmation from '../pages/ShipConfirmation'

import MenuItems from '../pages/menu-items/Index'

import MarketingPageEdit from '../pages/marketing-pages/Edit'
import MarketingPages from '../pages/marketing-pages/Index'

import AddBestSellers from '../pages/bestsellers/Create'
import EditBestseller from '../pages/bestsellers/Edit'
import Bestsellers from '../pages/bestsellers/Index'

import EditUpsell from '../pages/upsells/Edit'
import Upsells from '../pages/upsells/Index'

import PopupIcons from '../pages/popup-icons/Index'

import EditSaleBanners from '../pages/sale-banners/Edit'
import SaleBanners from '../pages/sale-banners/Index'

import EditBuffer from '../pages/buffers/EditBuffer'

import EditSetting from '../pages/settings/Edit'
import Settings from '../pages/settings/Index'

import CreateCarrier from '../pages/carriers/Create'
import EditCarrier from '../pages/carriers/Edit'
import Carriers from '../pages/carriers/Index'

import RegenerateOrders from '../pages/regenerate-orders/Index'
import CreateRegenerateOrders from '../pages/regenerate-orders/Create'

import MassApplyCredits from '../pages/mass-apply-credits/Index'
import CreateMassApplyCredits from '../pages/mass-apply-credits/Create'

import SkipPcChangeOffer from '../pages/skip-pc-change-offer/Index'
import EditSkipPcChangeOffer from '../pages/skip-pc-change-offer/Edit'
import CreateSkipPcChangeOffer from '../pages/skip-pc-change-offer/Create'

export default [
  { path: '/users', name: 'users', component: Users },
  { path: '/users/new', name: 'add-user', component: UserCreate },
  { path: '/users/:id', name: 'edit-user', component: UserEdit },
  { path: '/users/:id/post-cutoff', name: 'post-cutoff', component: PostCutoff },
  { path: '/users/:id/suspended-order', name: 'suspended-order', component: SuspendedOrder },
  { path: '/users/:id/rogue-order', name: 'rogue-order', component: RogueOrder },

  { path: '/categories', name: 'categories', component: Categories },
  { path: '/categories/:id', name: 'edit-category', component: CategoryEdit },

  { path: '/promo-codes', name: 'promo-codes', component: PromoCodes },
  { path: '/promo-codes/new', name: 'create-promo-code', component: PromoCodesCreate },
  {
    path: '/promo-codes/mass-apply',
    name: 'promo-codes-mass-apply',
    component: PromoCodesMassApply,
  },
  { path: '/promo-codes/:id', name: 'edit-promo-code', component: PromoCodesEdit },

  { path: '/experiments', name: 'experiments', component: Experiments },
  { path: '/experiments/new', name: 'create-experiment', component: ExperimentsCreate },
  { path: '/experiments/:id', name: 'edit-experiment', component: ExperimentsEdit },

  { path: '/gift-cards', name: 'gift-cards', component: GiftCards },

  { path: '/holidays', name: 'holidays', component: Holidays },
  { path: '/holidays/new', name: 'create-holiday', component: HolidaysCreate },

  { path: '/product-rotations', name: 'product-rotations', component: ProductRotations },
  { path: '/product-rotations/:id', name: 'edit-product-rotation', component: ProductRotationEdit },
  {
    path: '/product-rotations/:id/order-weeks',
    name: 'product-rotation.order-weeks',
    component: ProductRotationOrderWeeks,
  },

  { path: '/products', name: 'products', component: Products },
  { path: '/products/create', name: 'create-product', component: ProductsCreate },
  { path: '/products/:sku', name: 'edit-product', component: ProductEdit },

  { path: '/order-bumps', name: 'order-bumps', component: OrderBumps },
  { path: '/order-bumps/create', name: 'create-order-bump', component: OrderBumpCreate },
  { path: '/order-bumps/:id', name: 'edit-order-bump', component: OrderBumpEdit },

  { path: '/menu-items', name: 'menu-items', component: MenuItems },

  { path: '/marketing-pages', name: 'marketing-pages', component: MarketingPages },
  { path: '/marketing-pages/:id', name: 'edit-marketing-page', component: MarketingPageEdit },


  { path: '/bestsellers', name: 'bestsellers', component: Bestsellers },
  { path: '/bestsellers/create', name: 'add-bestseller', component: AddBestSellers },
  { path: '/bestsellers/:id/edit', name: 'edit-bestseller', component: EditBestseller },

  { path: '/upsells', name: 'upsells', component: Upsells },
  { path: '/upsells/:id/edit', name: 'edit-upsell', component: EditUpsell },

  { path: '/tax-rates', name: 'tax-rates', component: TaxRates },
  { path: '/tax-rates/:id', name: 'edit-tax-rate', component: TaxRateEdit },

  { path: '/zip-codes', name: 'zip-codes', component: ZipCodes },
  { path: '/zip-codes/import', name: 'zip-codes-import', component: ZipCodesImport },

  { path: '/carriers', name: 'carriers', component: Carriers },
  { path: '/carriers/create', name: 'add-carrier', component: CreateCarrier },
  { path: '/carriers/:id', name: 'edit-carrier', component: EditCarrier },

  { path: '/order-weeks', name: 'order-weeks', component: OrderWeeks },

  { path: '/reports', name: 'reports', component: Reports },

  { path: '/ship-confirmation', name: 'ship-confirmation', component: ShipConfirmation },

  { path: '/production-cycles', name: 'production-cycles', component: ProductionCycles },

  { path: '/popup-icons', name: 'popup-icons', component: PopupIcons },

  { path: '/sale-banners', name: 'sale-banners', component: SaleBanners },
  { path: '/sale-banners/:id', name: 'edit-sale-banner', component: EditSaleBanners },

  { path: '/settings', name: 'settings', component: Settings },
  { path: '/settings/:group', name: 'edit-setting', component: EditSetting },

  { path: '/buffers/:id', name: 'edit-buffer', component: EditBuffer },

  { path: '/login', name: 'login', beforeEnter: Login },

  { path: '/regenerate-orders', name: 'regenerate-orders', component: RegenerateOrders },
  { path: '/regenerate-orders/new', name: 'add-regenerate-orders', component: CreateRegenerateOrders },

  { path: '/mass-apply-credits', name: 'mass-apply-credits', component: MassApplyCredits },
  { path: '/mass-apply-credits/new', name: 'add-mass-apply-credits', component: CreateMassApplyCredits },

  { path: '/skip-pc-change-offer', name: 'skip-pc-change-offers', component: SkipPcChangeOffer },
  { path: '/skip-pc-change-offers/new', name: 'add-skip-pc-change-offer', component: CreateSkipPcChangeOffer },
  { path: '/skip-pc-change-offers/:id', name: 'edit-skip-pc-change-offer', component: EditSkipPcChangeOffer },

  { path: '*', redirect: '/users' },
]
