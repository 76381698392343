<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex justify-between py-4">
      <h3 class="text-3xl">Holidays</h3>
      <router-link :to="{ name: 'create-holiday' }" class="btn btn-green">
        <i class="fas fa-plus mr-2" />Create Holiday
      </router-link>
    </div>
    <div class="pt-4">
      <div class="border border-gray-400 bg-white p-4">
        <table class="w-full table-auto bg-white">
          <thead>
            <tr class="border-b-2 font-bold">
              <th class="border px-4 py-2 text-green-500">Holiday</th>
              <th class="border px-4 py-2 text-green-500">Start Date</th>
              <th class="border px-4 py-2 text-green-500">End Date</th>
              <th class="border px-4 py-2 text-green-500">Rules</th>
              <th class="border px-4 py-2 text-green-500"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="holiday in holidays" :key="holiday.id" class="text-center">
              <td class="border px-4 py-2 text-center">
                {{ holiday.name }}
              </td>
              <td class="border px-4 py-2">
                {{ holiday.start_date | date }}
              </td>
              <td class="border px-4 py-2">
                {{ holiday.end_date | date }}
              </td>
              <td class="border px-4 py-2">
                <div v-for="(rule, i) in holiday.delivery_conceptions" :key="i">
                  Carrier: <b>{{ carrierNameById(rule.carrier_id) }}</b
                  >; Shift Days: <b>{{ rule.shift_days }}</b>
                </div>
              </td>
              <td class="border px-4 py-2">
                <button
                  v-if="!holiday.is_applied"
                  class="w-full rounded bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
                  @click="onApplyClick(holiday.id)"
                >
                  Apply Rules
                </button>
                <span v-if="holiday.is_applied" class="text-gray-700">Rules applied</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-5 flex justify-end">
          <pagination
            :value="options.page"
            :per-page="options.per_page"
            :total="total"
            @input="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import Confirm from '~/components/swal/Confirm'

export default {
  mixins: [IndexPage],
  created() {
    this.fetchCarriers()
  },
  computed: {
    ...mapGetters(['holidays/all', 'carriers/all']),
    holidays() {
      return this['holidays/all']
    },
    carriers() {
      return this['carriers/all']
    },
  },
  methods: {
    ...mapActions({
      fetchItems: 'holidays/fetch',
      fetchCarriers: 'carriers/fetch',
      applyHolidayRules: 'holidays/apply',
    }),
    async onApplyClick(holidayId) {
      const { value } = await Confirm.fire({
        icon: 'info',
        text: 'This will reset ship and delivery dates for all orders that fall into this holiday range. Proceed?',
      })
      if (value) {
        try {
          await this.applyHolidayRules(holidayId)
          this.successMessage('Rules are applying to the orders...')
          this.loadPage()
        } catch (e) {
          this.errorMessage('Error occurred')
        }
      }
    },
    carrierNameById(carrierId) {
      return _.get(_.find(this.carriers, { id: carrierId }), 'name', `Unknown id ${carrierId}`)
    },
  },
}
</script>
