import axios from 'axios'

export const actions = {
  async addToExclusions(store, id) {
    await axios.post(`api/customers/admin/mov-excluded-users/${id}`)
  },
  async removeFromExclusions(store, id) {
    await axios.delete(`api/customers/admin/mov-excluded-users/${id}`)
  },
}
