<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-5">
    <div v-if="marketingPage && !fetching">
      <!-- Button Back -->
      <button class="mb-6 text-gray-700 underline hover:text-gray-800">
        <router-link :to="{ name: 'marketing-pages' }">
          <i class="fas fa-long-arrow-alt-left mr-2"/>Back to Marketing Pages
        </router-link>
      </button>

      <!-- Content -->
      <div class="lg:mr-4 lg:w-full">
        <form
          action="#"
          class="overflow-hidden rounded-lg bg-white p-4 shadow"
          @submit.prevent="update"
        >
          <div class="border-b border-gray-300 bg-white py-3">
            <h4 class="text-base font-semibold">Edit Marketing Page: {{ form.name }}</h4>
          </div>
          <div class="mt-4" :class="{ 'has-error': hasError('name') }">
            <label class="block">
              <span class="text-gray-700">Marketing Page Name</span>
              <input type="text" class="form-input mt-1 block w-full" v-model="form.name" readonly disabled/>
            </label>
            <error :error="getError('name')"/>
          </div>
          <div class="mt-4" :class="{ 'has-error': hasError('path') }">
            <label class="block">
              <span class="text-gray-700">Full website path</span>
              <input type="text" class="form-input mt-1 block w-full" v-model="form.path" readonly disabled/>
            </label>
            <error :error="getError('path')"/>
          </div>

          <div
            v-for="keyValue in form.content?.data" :key="keyValue.key"
            class="mt-4" :class="{ 'has-error': hasError(keyValue.key) }"
          >
            <label class="block">
              <span style="text-transform: capitalize">{{ keyValue.key.replaceAll('_', ' ') }}</span>
              <input
                v-if="keyValue.type === 'string'"
                type="text"
                class="form-input mt-1 block w-full"
                v-model="keyValue.value"
              />
              <textarea
                v-else-if="keyValue.type === 'text'"
                class="form-input mt-1 block w-full"
                v-model="keyValue.value"
              />
              <div v-else>No mapping for field type '{{ keyValue.type }}'</div>
            </label>
            <error :error="getError(keyValue.key)"/>
          </div>

          <div class="mt-4 text-right">
            <button
              type="submit"
              :class="{ disabled: updating }"
              class="rounded-sm bg-teal-500 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none"
            >
              <span v-if="updating">Saving...</span>
              <span v-if="!updating">Save</span>
            </button>
          </div>
        </form>

        <div v-if="errors.length">
          <p v-for="(err, key) in errors" :key="key">{{ err }}</p>
        </div>
      </div>
    </div>
    <loader v-if="fetching"/>
  </div>
</template>

<script>
import * as _ from 'lodash'
import {mapActions} from 'vuex'
import HasErrors from '~/mixins/HasErrors'

export default {
  components: {},
  mixins: [HasErrors],
  data: () => ({
    form: {
      name: null,
      path: null,
      content: [],
    },
    marketingPage: null,
    fetching: false,
    updating: false,
    errors: []
  }),
  mounted() {
    this.fetching = true
    this.fetch(this.id)
      .then(page => {
        this.marketingPage = page
        this.resetForm()
      })
      .finally(() => {
        this.fetching = false
      })
  },
  methods: {
    ...mapActions({
      updateMarketingPage: 'marketing-pages/update',
      fetch: 'marketing-pages/fetchSingle',
    }),
    resetForm() {
      _.assign(this.form, _.pick(this.marketingPage, Object.keys(this.form)))

      this.errors = []
    },
    update() {
      this.updating = true
      this.errors = []
      this.updateMarketingPage({id: this.id, params: this.form}).then(() => {
        this.successMessage('Updated')
      }).catch(e => {
        this.errors = e.response.data?.errors
        this.errorMessage('Error', e.response.data?.message)
      }).finally(() => this.updating = false)
    },
  },
  computed: {
    id() {
      return +this.$route.params.id
    },
  },
}
</script>
