import { schema } from 'normalizr'
import { pickBy, identity } from 'lodash'
import products from '@/store/schema/products'
import orderWeeks from '@/store/schema/order-weeks'

const processStrategy = orderBump => {
  const relations = {
    product: _.has(orderBump, 'product') ? orderBump.product.data : null,
    weeks: _.has(orderBump, 'weeks') ? orderBump.weeks.data : null,
  }
  return {
    ...orderBump,
    ...pickBy(relations, identity()),
  }
}

export default new schema.Entity(
  'orderBumps',
  {
    product: products,
    weeks: [orderWeeks],
  },
  {
    processStrategy,
  }
)
