import axios from 'axios'
import store from '../store'
import { BASE_API_URL, BASE_ACCOUNT_URL, LOGIN_URL } from '../config'

axios.defaults.baseURL = BASE_API_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response

    if (status === 401) {
      if (store.getters['auth/user']) {
        return store.dispatch('auth/logout').finally(() => {
          window.location.href = LOGIN_URL
        })
      }
      window.location.href = LOGIN_URL
    }

    if (status === 403) {
      window.location.href = BASE_ACCOUNT_URL
    }

    return Promise.reject(error)
  }
)
