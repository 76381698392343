<script>
import { creditReasonSettings } from '~/constants/reasons'
import { slugify } from '~/utils/str'

export default {
  props: ['reason'],
  computed: {
    reasonSettings() {
      return creditReasonSettings[slugify(this.reason.title)]
    },
    picks() {
      return this.reasonSettings.picks?.items || []
    },
    isSingleChoice() {
      return this.reasonSettings.picks?.isSingleChoice || false
    },
    extraPicksCaption() {
      return this.reasonSettings.picks?.caption
    },
    isWithMeals() {
      return this.reasonSettings.withMeals || false
    },
    isWithFreeTextForm() {
      return this.reasonSettings.withExtraNotes?.value || false
    },
    freeTextFormPlaceholder() {
      return this.reasonSettings.withExtraNotes?.caption || 'Extra notes'
    },
  },
  render() {
    return this.$scopedSlots.default({
      picks: {
        items: this.picks,
        singleChoice: this.isSingleChoice,
        caption: this.extraPicksCaption,
      },
      withMeals: this.isWithMeals,
      freeText: { enabled: this.isWithFreeTextForm, placeholder: this.freeTextFormPlaceholder },
    })
  },
}
</script>
