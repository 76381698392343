import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
import { headersFormData, objectToFormData } from '@/utils/form-data'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch(store, params) {
    return axios.get('api/product-menu-items', { params }).then(res => {
      processEntities(res.data.data, 'menuItems')
      return res.data
    })
  },

  update(store, { id, params }) {
    const formData = new FormData()
    formData.append('_method', 'PUT')

    return axios.post(`/api/product-menu-items/${id}`, objectToFormData(params, formData), { ...headersFormData })
      .then(res => {
      processEntities(res.data.data, 'menuItems')
      return res.data
    })
  },
}
