<template>
  <modal
    name="submit-credit-transaction-modal"
    :adaptive="true"
    @closed="$emit('closed')"
    height="auto"
    :scrollable="true"
  >
    <header class="border-b border-gray-200 px-6 py-4">
      <h3 class="text-2xl font-semibold">Submit credit transaction</h3>
      <div class="text-gray-600">
        <span class="font-semibold">{{ account.balance | dollars }}</span> is the current balance.
      </div>
    </header>

    <form @submit.prevent="submit" class="mt-4 divide-y-2">
      <div class="space-y-4 py-4 px-4">
        <InputGroup label="Amount" :error="getError('amount')" helpText="Amount in dollars">
          <input type="text" class="form-input w-full" v-model="amount" />
        </InputGroup>
        <InputGroup :error="getError('reason_details')" label="Reason Details">
          <textarea class="form-textarea w-full" v-model="reason_details" row="3"></textarea>
        </InputGroup>
      </div>
      <div class="flex justify-end bg-gray-100 px-4 py-4">
        <NLButton type="submit" class="px-3 py-1" :isLoading="submitting">Submit</NLButton>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import NLButton from '~/components/global/input/Button'

export default {
  props: ['creditAccountId'],
  mixins: [HasErrors],
  components: { NLButton },
  data() {
    return {
      amount: null,
      reason_details: null,
    }
  },
  computed: {
    ...mapGetters(['credit-accounts/byId']),
    account() {
      return this['credit-accounts/byId'](this.creditAccountId)
    },
  },
  methods: {
    ...mapActions(['credit-accounts/submitCreditTransaction']),
    async submit() {
      try {
        this.submitting = true
        await this.credit()
        this.successMessage('Transaction completed successfully')
        this.close()
        this.submitting = false
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data.errors
          this.errorMessage('Given data was invalid')
        }
        this.submitting = false
      }
    },
    async credit() {
      await this['credit-accounts/submitCreditTransaction']({
        creditAccountId: this.creditAccountId,
        params: {
          amount: this.amount,
          reason_details: this.reason_details,
        },
      })
    },
    close() {
      this.$modal.hide('submit-credit-transaction-modal')
    },
  },
}
</script>
