<template>
  <div>
    <div class="flex border-b-2 border-gray-300 bg-white p-6">
      <div class="flex w-full lg:w-4/5">
        <div class="w-full lg:w-1/2">
          <div class="flex items-center">
            <button
              @click.prevent="impersonate"
              class="font-sm mr-3 inline-flex items-center rounded-md border border-transparent bg-gray-200 px-2 py-1 text-sm leading-5 text-gray-600 lg:hidden"
            >
              <svg
                class="h-4 w-4 stroke-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="17 1 21 5 17 9"></polyline>
                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                <polyline points="7 23 3 19 7 15"></polyline>
                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
              </svg>
            </button>
            <span class="mr-3 text-base font-semibold text-gray-700"
              >{{ user.first_name }} {{ user.last_name }}</span
            >
            <span class="mr-3">&bull;</span>
            <user-status :name="user.status"></user-status>
          </div>
          <div class="mt-2">
            <div class="flex items-center">
              <svg
                class="mr-1 h-4 w-4 stroke-current text-teal-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <router-link :to="editUserUrl" class="font-semibold text-teal-500">
                {{ user.email }}
              </router-link>
            </div>
          </div>
          <div v-show="phone" class="mt-2 flex items-center text-gray-700">
            <svg
              class="mr-2 h-4 w-4 stroke-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
              ></path>
            </svg>
            <span>{{ phone }}</span>
          </div>
        </div>

        <div class="hidden flex-col items-start justify-between lg:flex">
          <div>
            <span v-show="user.activated_at"
              >Activated at <span class="text-teal-700">{{ user.activated_at }}</span></span
            >
          </div>
          <div>
            <span class="text-gray-800"
              ><span class="font-semibold text-gray-700">{{ user.number_of_active_weeks }}</span>
              active weeks</span
            >
          </div>
          <button
            @click.prevent="impersonate"
            class="mr-3 hidden items-center rounded-md border border-transparent bg-gray-200 px-2 py-1 text-sm leading-5 text-gray-800 shadow-sm hover:bg-gray-300 sm:inline-flex"
          >
            <svg
              class="mr-2 h-4 w-4 stroke-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="17 1 21 5 17 9"></polyline>
              <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
              <polyline points="7 23 3 19 7 15"></polyline>
              <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
            </svg>
            <span>Imitate</span>
          </button>
        </div>
      </div>

      <router-link
        :to="editUserUrl"
        class="flex flex-1 items-center justify-end text-gray-700 hover:text-teal-500"
      >
        <svg
          class="h-6 w-6 stroke-current lg:h-8 lg:w-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserStatus from '~/components/users/UserStatus'

export default {
  props: ['user'],
  components: { UserStatus },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['auth/impersonate']),
    async impersonate() {
      await this['auth/impersonate'](this.user.id)
    },
  },
  computed: {
    ...mapGetters(['addresses/byId']),
    phone() {
      const address = this['addresses/byId'](this.user.defaultAddress)
      return address && address.phone
    },
    editUserUrl() {
      return { name: 'edit-user', params: { id: this.user.id } }
    },
  },
}
</script>
