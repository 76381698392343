<template>
  <div>
    <multiselect @select="onSelect" label="number" :options="weeks"></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { Multiselect },
  computed: {
    ...mapGetters(['order-weeks/byProductRotationId']),
    weeks() {
      return this['order-weeks/byProductRotationId'](null)
    },
  },
  mounted() {
    this['order-weeks/fetchAvailable']()
  },
  methods: {
    ...mapActions(['order-weeks/fetchAvailable']),
    onSelect(value) {
      this.$emit('select', value)
    },
  },
}
</script>
