<template>
  <div class="custom-select" :class="classes">
    <multiselect
      :value="value"
      :placeholder="placeholder"
      :searchable="searchable"
      :preselect-first="preselectFirst"
      :options="optionsList"
      :custom-label="customLabel"
      @input="update"
      :key="value"
    />
  </div>
</template>

<script>
/* @TODO FIX css */
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    keyName: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    classes: {
      type: String,
      default: '',
    },
  },

  computed: {
    optionsList() {
      if (!this.keyName) return this.options
      return this.options.map(item => item[this.keyName] || item)
    },
  },

  methods: {
    update(value) {
      this.$emit('input', value)
    },
    customLabel(option, label) {
      if (this.isEmpty(option)) return ''
      if (this.keyName && this.label) return this.getLabel(this.keyName, option)
      return label ? option[label] : option
    },
    isEmpty(opt) {
      if (opt === 0) return false
      if (Array.isArray(opt) && opt.length === 0) return true
      return !opt
    },
    getLabel(key, option) {
      const opt = this.options.find(item => item[key] === option)
      return opt ? opt[this.label] : null
    },
  },
}
</script>
