<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <aria-loader :loading="loading" />
    <h4 class="mb-4 font-bold">Skip PC Change Offer</h4>
    <h6 v-if="!events.length">Not offered to user</h6>
    <table v-else class="w-full">
      <thead>
      <tr class="border-b-2 border-gray-400 font-bold">
        <th class="border border-gray-400 px-4 py-2">Description</th>
        <th class="border border-gray-400 px-4 py-2">Date</th>
        <th class="border border-gray-400 py-2">Order Index</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="activity in events" :key="activity.id">
        <td class="p-1">
          {{ activity.description }} Order ID: {{ activity.properties.order_id }}
        </td>
        <td class="p-1">
          {{ activity.created_at | date }}
        </td>
        <td class="p-1 text-center">
          {{ activity.properties.order_index }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getOrderLogs } from '@/infrastructure/api/user/logs/logs'
import AriaLoader from '@/components/AriaLoader'

export default {
  components: { AriaLoader },
  props: ['user'],
  data: () => ({
    events: [],
    loading: true,
  }),
  mounted() {
    getOrderLogs(this.user.id, 'skip-week-pc-change-actioned')
      .then(results => {
        this.events = results.sort((first, second) => {
          return new Date(second.created_at).getTime() - new Date(first.created_at).getTime()
        })
      })
      .finally(() => {
        this.loading = false
      })
  },
}
</script>
