<template>
  <div class="mx-10">
    <div class="rounded-md flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Mass Apply Credits</h3>
      <router-link :to="{ name: 'add-mass-apply-credits' }" class="px-4 py-2 text-gray-700 hover:text-teal-500">
        <span class="btn btn-sm btn-green">
          <i class="fas fa-plus mr-2" /> Create new
        </span>
      </router-link>
    </div>

    <div class="mt-8">
      <div v-if="!isLoading" class="space-y-4">
        <one-batch :batch="batch" v-for="batch in batches" :key="batch.id" />
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/global/Loader.vue";
import { mapActions, mapGetters } from "vuex";
import OneBatch from "../../components/batches/OneBatch.vue";

export default {
  components: {
    Loader,
    OneBatch
},
  data: () => ({
    isLoading: false,
    updates: null,
  }),
  async mounted() {
    await this.fetchOrderBatches()
    this.isLoading = false
  },
  beforeDestroy() {
    this.unsubscribeToUpdates()
  },
  computed: {
    ...mapGetters({
      batches: 'batches/all'
    })
  },
  methods: {
    ...mapActions({
      getBatches: 'batches/fetch'
    }),
    async fetchOrderBatches() {
      await this.getBatches({ name: 'Mass Apply Credits' })
      this.isLoading = false
    },
    async subscribeToUpdates() {
      this.updates = setTimeout(() => {
        this.fetchOrderBatches()
      }, 10000);
    },
    async unsubscribeToUpdates() {
      if (this.updates) {
        clearTimeout(this.updates)
      }
    }
  },
  watch: {
    batches() {
      if (this.batches.some(batch => batch.progress !== 100 && batch.finished_at === null && !batch.cancelled_at)) {
        this.subscribeToUpdates()
      } else {
        this.unsubscribeToUpdates()
      }
    },
  }
}
</script>
