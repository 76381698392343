import Vue from 'vue'
import _ from 'lodash'
import store from '~/store/index'
import schema from '~/store/schema.js'
import { normalize } from 'normalizr'
import * as types from '~/store/mutation-types'

const itemExists = (items, item, key) => {
  return _.findIndex(items, existing => existing[key] === item[key]) > -1
}

const listWithUpdatedItem = (items, item, key) => {
  return _.map(items, existing => {
    if (existing[key] === item[key]) {
      return Object.assign({}, existing, item)
    }
    return existing
  })
}

export const addOrUpdateItem = (state, item, key = 'id', node = 'items') => {
  if (itemExists(state[node], item, key)) {
    Vue.set(state, node, listWithUpdatedItem(state[node], item, key))
  } else {
    Vue.set(state, node, [...state[node], item])
  }
}

export const addOrUpdateItems = (state, items, key = 'id', node = 'items') => {
  _.each(items, item => addOrUpdateItem(state, item, key, node))
}

export const removeItem = (state, item, key = 'id', node = 'items') => {
  const index = _.findIndex(state[node], existing => existing[key] === item[key])

  if (index > -1) {
    state[node].splice(index, 1)
    Vue.set(state, node, state[node])
  }
}

export const resolveIdAndParams = param => {
  return typeof param === 'object' ? [param.id, param.params || null] : [param, null]
}

export const processEntities = (items, entity) => {
  if (!Array.isArray(items)) items = [items]
  const { entities } = normalize(items, [schema[entity]])
  storeEntities(entities)
}

export const storeEntities = entities => {
  _.each(entities, (items, name) => {
    const mutation = `${_.kebabCase(name)}/${types.BATCH_ADD_OR_UPDATE}`

    if (_.has(store._mutations, mutation)) {
      store.commit(mutation, items)
    }
  })
}

export const sortByDate = (dateField) => {
  return (a, b) => {
    var dateA = new Date(a[dateField])
    var dateB = new Date(b[dateField])

    return dateB - dateA
  }
}
