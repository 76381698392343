import axios from 'axios'
import _ from 'lodash'
import * as mutationTypes from '../mutation-types'
import {addOrUpdateItems, processEntities, removeItem} from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  types: state => state.types,
  byId: state => id => _.find(state.items, item => item.id === id),
  byIds: state => ids => _.filter(state.items, item => ids.includes(item.id)),
}

export const mutations = {
  [mutationTypes.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [mutationTypes.REMOVE]: (state, item) => removeItem(state, item),
}

export const actions = {
  fetch({dispatch}, params) {
    return axios.get('/api/experiments/admin/experiments', {params}).then(res => {
      processEntities(res.data.data, 'experiments')
      return res.data
    })
  },
  fetchById({dispatch}, id) {
    return axios.get(`/api/experiments/admin/experiments/${id}`).then(res => {
      processEntities([res.data.data], 'experiments')
      return res.data
    })
  },
  create({dispatch}, params) {
    return axios.post('/api/experiments/admin/experiments', params).then(res => {
      processEntities([res.data.data], 'experiments')
      return res.data
    })
  },
  update({dispatch}, {id, params}) {
    return axios.put(`/api/experiments/admin/experiments/${id}`, params).then(res => {
      processEntities([res.data.data], 'experiments')
      return res.data
    })
  },
  remove({commit}, id) {
    return axios.delete(`/api/experiments/admin/experiments/${id}`).then(res => {
      commit(mutationTypes.REMOVE, {id})
      return res.data
    })
  },
}
