import { schema } from 'normalizr'
import _ from 'lodash'
import products from '~/store/schema/products'
import orderWeeks from '~/store/schema/order-weeks'

const processStrategy = ProductRotation => {
  const relations = {
    products: _.has(ProductRotation, 'products') ? ProductRotation.products.data : null,
    orderWeeks: _.has(ProductRotation, 'orderWeeks')
      ? _.map(ProductRotation.orderWeeks.data, week =>
          _.assign({}, week, { meal_rotation_id: ProductRotation.id })
        )
      : null,
  }
  return { ...ProductRotation, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'ProductRotations',
  {
    products: [products],
    orderWeeks: [orderWeeks],
  },
  {
    processStrategy,
  }
)
