import axios from 'axios'
import _ from 'lodash'
import * as mutationTypes from '../mutation-types'
import { addOrUpdateItems, processEntities, removeItem } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
  byIds: state => ids => _.filter(state.items, item => ids.includes(item.id)),
}

export const mutations = {
  [mutationTypes.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [mutationTypes.REMOVE]: (state, item) => removeItem(state, item),
}

export const actions = {
  fetch({ dispatch }, params) {
    return axios.get('/api/sales/admin/gift-cards', { params }).then(res => {
      processEntities(res.data.data, 'giftCards')
      return res.data
    })
  },
  remove({ commit }, id) {
    return axios.delete(`/api/sales/admin/gift-cards/${id}`).then(res => {
      commit(mutationTypes.REMOVE, { id })
      return res.data
    })
  },
  refund({ dispatch }, id) {
    return axios.post(`/api/sales/admin/gift-cards/${id}/refunds`).then(res => {
      processEntities([res.data.data], 'giftCards')
      return res.data
    })
  },
}
