import Vue from 'vue'
import toast from 'izitoast'

Vue.mixin({
  methods: {
    successMessage(title = 'Success', message = '', timeout = 4500, onClosed = null) {
      toast.success({
        title,
        message,
        timeout,
        onClosed,
      })
    },
    errorMessage(title = 'Error', message = '', timeout = 4500) {
      toast.error({
        title,
        message,
        timeout,
      })
    },
  },
})
