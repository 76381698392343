<template>
  <div v-show="error" class="text-sm text-red-600">
    {{ error }}
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: null,
    },
  },
}
</script>
