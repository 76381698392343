<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5 text-gray-700 space-y-4">
    <h2 class="py-4 text-2xl">Import Zip codes</h2>
    <hr class="border-gray-400" />

    <div v-if="showUpload && !uploading">
      <form class="mb-4">
        <input type="file"
          ref="csv"
          class="rounded-sm bg-gray-700 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-500 hover:text-white focus:bg-teal-500 focus:text-white focus:outline-none"
          @change="handleFileUpload($event)"
        />
      </form>
      <div v-if="hasError && allErrors.length" class="text-red-500 text-base mb-6 mt-4">
        <p class="font-bold">We had some errors:</p>
        <p v-for="(err, key) in allErrors" :key="key">
          {{ err }}
        </p>
      </div>
      <div>
        <p>
          The file should be Zip archive containing only one csv/text file (so zip codes will have leading 0's).<br/>
          Expected rows are below. These row titles should be used in the same order:
        </p>
        <ul class="ml-6 mt-4 text-sm list-decimal">
          <li>unformatted_zip_code</li>
          <li>zip_code_id (5 digit zip code, include the 0)</li>
          <li>state (2 letter abbreviation)</li>
          <li>timezone (Eastern, Central, Mountain, Pacific)</li>
          <li>production_cycle_id</li>
          <li>days_in_transit</li>
          <li>ship_day_name (Sunday, Monday, Tuesday, etc)</li>
          <li>carrier (make sure this carrier exists in the platform)</li>
          <li>default_pc (TRUE, FALSE)</li>
          <li>alternate_ups_service make sure this carrier exists in the platform)</li>
        </ul>
      </div>
    </div>

    <div v-if="uploading">
      <p class="font-bold">Just a moment, uploading...</p>
    </div>

    <button v-if="!showUpload && !uploading" class="btn btn-green" @click="showUpload = !showUpload">Show upload</button>

    <p v-if="!loading && !zipCodeImports.length">No files to import yet.</p>

    <div v-if="zipCodeImports.length">
      <table class="mt-4 w-full table-auto bg-white">
        <thead>
          <tr class="border-b-2 font-bold text-left">
            <th class="border px-4 py-2">Uploaded at</th>
            <th class="border px-4 py-2">Imported at</th>
            <th class="border px-4 py-2">Is valid</th>
            <th class="border px-4 py-2">Validation Progress</th>
            <th class="border px-4 py-2">Import Progress</th>
            <th class="border px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="zipCodeImport in zipCodeImports" :key="zipCodeImport.id">
            <td class="border px-4 py-2">{{ zipCodeImport.created_at }}</td>
            <td class="border px-4 py-2">{{ zipCodeImport.imported_at }}</td>
            <td class="border px-4 py-2">
              <div v-if="zipCodeImport.is_valid === null">Validating..</div>
              <div v-if="zipCodeImport.is_valid !== null">{{zipCodeImport.is_valid ? 'Yes' : 'No'}}</div>
              <div v-if="!zipCodeImport.is_valid">
                <p class="text-sm" v-for="(messages, failedZipCodeId) in zipCodeImport.errors" :key="failedZipCodeId">
                  {{ failedZipCodeId }}: {{ messages[0] }}
                </p>
              </div>
            </td>
            <td class="border px-4 py-2">{{ zipCodeImport.validation_progress }}</td>
            <td class="border px-4 py-2">{{ zipCodeImport.import_progress }}</td>
            <td class="border px-4 py-2">
              <button class="hover:underline disabled:bg-gray-400 disabled:text-gray-600 disabled:cursor-not-allowed text-sm p-1 rounded"
                @click="confirmImport(zipCodeImport.id)"
                :disabled="!zipCodeImport.is_valid"
              >
                Import
              </button>
              <button class="hover:underline text-sm p-1 rounded" @click="confirmDelete(zipCodeImport.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasErrors from '~/mixins/HasErrors'

export default {
  mixins: [HasErrors],
  data() {
    return {
      loading: true,
      willRetry: false,
      errors: {},
      showUpload: false,
      uploading: false,
    }
  },
  async mounted() {
    await this.fetchLatest()
    this.loading = false
  },
  computed: {
    ...mapGetters({
      zipCodeImports: 'zip-code-imports/all',
    })
  },
  methods: {
    ...mapActions({
      fetch: 'zip-code-imports/fetch',
      upload: 'zip-code-imports/upload',
      zipImport: 'zip-code-imports/zipImport',
      zipDelete: 'zip-code-imports/zipDelete',
    }),
    async fetchLatest() {
      await this.fetch()

      // If there are files we're waiting validation on
      // retry to check their status
      if (this.zipCodeImports.some(zipCodeImport => zipCodeImport.is_valid === null || (zipCodeImport.import_progress < 100 && zipCodeImport.validation_progress == 100))) {
        this.willRetry = setTimeout(() => this.fetchLatest(), 6000)
      }
    },
    handleFileUpload(event) {
      this.loading = true
      this.uploading = true

      let file = event.target.files[0];
      let formData = new FormData()
      formData.append('csv', file)

      this.wrapSubmit(this.upload(formData))
        .then(res => console.log('res', res))
        .catch(e => {
          if (this.$refs.csv) {
            this.$refs.csv.value = null
          }
          const errors = e?.response?.data?.errors
          if (errors) {
            this.errorMessage(errors[Object.keys(errors)[0]])
          }
        })
        .finally(() => {
          this.uploading = false
          this.fetchLatest()
        })
    },
    confirmImport(id) {
      if(confirm("Are you sure you want to import this CSV?")) {
        this.zipImport(id)
          .then((res) => this.successMessage(res.data.message) && this.fetchLatest())
          .catch((err) => console.log(err.response.data))
          .finally(() => this.fetchLatest())
      }
    },
    confirmDelete(id) {
      if(confirm("Are you sure you want to delete this import?")) {
        this.zipDelete(id)
          .then((res) => this.successMessage(res.data.message) && this.fetchLatest())
          .catch((err) => console.log(err.response.data))
          .finally(() => this.fetchLatest())
      }
    }
  }
}
</script>
