<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <header class="items-center justify-between md:flex">
      <h4 class="mb-4 font-bold">Refunds</h4>
    </header>

    <div class="divide-y-2 divide-gray-200">
      <div v-if="!refunds.length">No Refunds Have Been Made</div>
      <div v-for="refund in refunds" :key="refund.id" class="px-2 py-5">
        <div class="flex items-center">
          <div class="mr-2 flex items-center space-x-1">
            <svg
              class="h-5 w-5 stroke-current"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span class="font-semibold">{{ refund.amount | dollars }}</span>
          </div>
          <div v-if="refund.reason">
            <span class="text-gray-700">({{ refund.reason }})</span>
          </div>
        </div>
        <div class="mt-2 pl-6">
          <span class="text-gray-700">Stripe transaction ID: </span>
          <code class="text-gray-800 text-teal-500">{{ refund.billing_transaction_id }}</code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['user'],
  mounted() {
    this['refunds/fetchByUserId'](this.user.id)
  },
  methods: {
    ...mapActions(['refunds/fetchByUserId']),
  },
  computed: {
    ...mapGetters(['refunds/byUserId']),
    refunds() {
      return this['refunds/byUserId'](this.user.id)
    },
  },
}
</script>
