<template>
  <div class="w-full overflow-scroll bg-gray-100 px-5">
    <div class="my-4">
      <a
        href="#"
        @click.prevent="$router.back()"
        class="flex items-center text-sm text-gray-600 underline"
      >
        <svg
          class="h-4 h-4 stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>
        back to rotation
      </a>
    </div>

    <h2 class="text-lg font-semibold">Add a week in the rotation</h2>

    <available-week-selector @select="add"></available-week-selector>

    <h2 class="mt-4 text-lg font-semibold">Order weeks in the rotation</h2>
    <div>
      <ul>
        <li v-for="week in selectedWeeks" :key="week.id">
          <div class="mt-4 rounded bg-white shadow">
            <div class="flex items-center justify-between px-4 py-2">
              <h4 class="text-lg">{{ week.number }}</h4>
              <div class="flex items-center">
                <span
                  class="rounded-sm bg-green-100 px-1 text-xs font-semibold uppercase text-teal-700"
                  >{{ week.status }}</span
                >

                <a
                  href="#"
                  class="relative mx-2"
                  @click.prevent="activate(week)"
                  v-if="week.status == 'draft'"
                >
                  <svg
                    class="h-4 w-4 stroke-current text-teal-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polygon points="10 8 16 12 10 16 10 8"></polygon>
                  </svg>
                  <div class="tooltip">Activate</div>
                </a>

                <a
                  href="#"
                  class="relative"
                  @click.prevent="remove(week)"
                  v-if="week.status == 'draft'"
                >
                  <svg
                    class="h-4 w-4 stroke-current text-red-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                  <div class="tooltip">Remove</div>
                </a>
              </div>
            </div>
            <div class="p-4 pt-2">
              <div v-for="cycle in week.productionCycles">
                <div class="text-sm">
                  <span class="font-semibold text-teal-700">{{ cycle.name }}: </span>
                  <span class="text-gray-700"
                    >{{ cycle.order_cutoff_day }}, {{ cycle.cutoff_date }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AvailableWeekSelector from '~/components/product-rotations/AvailableWeekSelector'

export default {
  components: { AvailableWeekSelector },
  computed: {
    ...mapGetters(['product-rotations/byId', 'products/bySKU', 'order-weeks/byProductRotationId']),
    id() {
      return +this.$route.params.id
    },
    selectedWeeks() {
      return this['order-weeks/byProductRotationId'](this.id)
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions([
      'product-rotations/fetchOrderWeeks',
      'product-rotations/addOrderWeek',
      'product-rotations/removeOrderWeek',
      'order-weeks/activate',
    ]),
    fetch() {
      this['product-rotations/fetchOrderWeeks']({
        id: this.id,
        params: { include: 'productionCycles' },
      })
    },
    add(week) {
      this['product-rotations/addOrderWeek']({ id: this.id, weekId: week.id })
    },
    remove(week) {
      this['product-rotations/removeOrderWeek']({ id: this.id, weekId: week.id })
    },
    activate(week) {
      this['order-weeks/activate']({ id: week.id })
    },
  },
}
</script>
