<template>
  <header class="sticky top-0 z-40 h-16 w-full bg-white shadow-lg">
    <div class="mx-auto h-full max-w-6xl md:flex md:items-center md:justify-between">
      <div class="flex items-center justify-between px-4 py-3 md:p-0">
        <div>
          <a href="/" class="inline-block relative">

              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 249.837 35.628">
              <g id="Group_1934" data-name="Group 1934" transform="translate(-120.049 -150.458)">
                <g id="Group_1976" data-name="Group 1976" transform="translate(120.049 153.844)">
                  <path id="Path_3029" data-name="Path 3029" d="M212.9,179.623v12.426a4.543,4.543,0,1,1-9.086,0V179.623h-8.993v12.6a11.454,11.454,0,1,0,22.907,0v-12.6Z" transform="translate(-165.171 -171.443)" fill="#009259"/>
                  <path id="Path_3030" data-name="Path 3030" d="M387.471,179.623v12.426a4.543,4.543,0,1,1-9.086,0V179.623h-8.993v12.6a11.454,11.454,0,1,0,22.908,0v-12.6Z" transform="translate(-270.51 -171.443)" fill="#009259"/>
                  <path id="Path_3031" data-name="Path 3031" d="M276.438,178.3a6.565,6.565,0,0,0-6.1,4.563v-4h-8.993v23.044h8.993V187.461c3.169-3.013,8.354-1.238,8.928,2.089,6-3.681,3.034-11.258-2.826-11.247" transform="translate(-205.31 -170.646)" fill="#009259"/>
                  <path id="Path_3032" data-name="Path 3032" d="M450.791,178.3a6.565,6.565,0,0,0-6.1,4.563v-4H435.7v23.044h8.993V187.461c3.169-3.013,8.353-1.238,8.928,2.089,6-3.681,3.034-11.258-2.826-11.247" transform="translate(-310.52 -170.646)" fill="#009259"/>
                  <path id="Path_3033" data-name="Path 3033" d="M513.664,191.216c.015-.254.026-.509.026-.767A12.251,12.251,0,1,0,501.439,202.7a11.4,11.4,0,0,0,8.948-3.887c3.5-4.323-.49-6.8-2.758-4.787.579,1.32-.968,3.881-4.217,3.881a5.463,5.463,0,0,1-5.463-5.462v-1.229Zm-10.5-8.087a5.12,5.12,0,0,1,4.789,3.32h-9.579a5.12,5.12,0,0,1,4.79-3.32" transform="translate(-342.798 -170.583)" fill="#009259"/>
                  <path id="Path_3034" data-name="Path 3034" d="M334.837,172v-4.828h-4.052V159h-8.993v8.18H317.74V172h4.052l0,12.815c0,2.753,1.635,6.062,5.776,6.286,3.317.179,5.508-2.231,5.776-3.656-1.51.323-2.559-.949-2.559-2.526l0-12.918Z" transform="translate(-239.342 -158.996)" fill="#009259"/>
                  <path id="Path_3035" data-name="Path 3035" d="M146.4,195.874l.008-7.227a11.453,11.453,0,0,0-19.921-7.713,4.368,4.368,0,0,0-3.194-1.082c-1.722.109-3.249,1.419-3.249,2.805,3.456.532,3.456,4.924,3.456,8.267v10.318H132.5V188.816a4.543,4.543,0,1,1,9.086,0v9.125a4.116,4.116,0,0,0,4.2,4.051,2.783,2.783,0,0,0,2.729-2.357c-2.256,0-2.147-1.414-2.106-3.763" transform="translate(-120.049 -169.977)" fill="#009259"/>
                </g>
                <g id="Group_1977" data-name="Group 1977" transform="translate(300.501 150.458)">
                  <path id="Path_3036" data-name="Path 3036" d="M712.732,191.529c.016-.254.027-.509.027-.767a12.252,12.252,0,1,0-12.252,12.252,11.4,11.4,0,0,0,8.948-3.887c3.5-4.323-.49-6.8-2.759-4.787.578,1.319-.967,3.88-4.217,3.88a5.462,5.462,0,0,1-5.462-5.462v-1.229Zm-10.5-8.087a5.119,5.119,0,0,1,4.789,3.32h-9.579a5.119,5.119,0,0,1,4.789-3.32" transform="translate(-643.374 -167.385)" fill="#009259"/>
                  <path id="Path_3037" data-name="Path 3037" d="M584.074,154.91h-8.993l0,27.568c0,2.752,1.635,6.061,5.776,6.285,3.317.179,5.508-2.231,5.776-3.656-1.51.324-2.559-.949-2.559-2.526Z" transform="translate(-575.081 -153.144)" fill="#009259"/>
                  <path id="Path_3038" data-name="Path 3038" d="M620.261,179.935h-8.993l0,17.644c0,2.752,1.635,6.061,5.776,6.285,3.316.179,5.508-2.231,5.776-3.656-1.51.323-2.559-.95-2.559-2.527Z" transform="translate(-596.917 -168.245)" fill="#009259"/>
                  <path id="Path_3039" data-name="Path 3039" d="M655.807,162.148v-1.639c0-3.174,3.874-2.789,3.85-.719,2.688-3.255.523-9.331-5.157-9.331-2.74,0-7.685,1.87-7.685,8.206v3.484h-4.052v4.828h4.052v18.147h8.993V166.976h4.052v-4.828Z" transform="translate(-615.922 -150.458)" fill="#009259"/>
                  <path id="Path_3040" data-name="Path 3040" d="M620.724,157.708a4.385,4.385,0,1,1-4.384-4.3,4.344,4.344,0,0,1,4.384,4.3" transform="translate(-597.332 -152.236)" fill="#009259"/>
                </g>
              </g>
            </svg>

            <span v-if="isDev" class="rounded-full px-2 py-0.5 -mb-10 ml-1 text-center text-xs font-bold uppercase tracking-wide bg-orange-100 text-orange-700 absolute -top-2 left-full">
              <small>{{visibleEnv}}</small>
            </span>

            <span v-if="isStaging" class="rounded-full px-2 py-0.5 -mb-10 ml-1 text-center text-xs font-bold uppercase tracking-wide bg-cyan-100 text-cyan-700 absolute -top-2 left-full">
              <small>{{visibleEnv}}</small>
            </span>

            <span v-if="isProduction" class="rounded-full px-2 py-0.5 -mb-10 ml-1 text-center text-xs font-bold uppercase tracking-wide bg-green-100 text-green-700 absolute -top-2 left-full">
              <small>{{visibleEnv}}</small>
            </span>

            <span v-if="isUnknownEnv" class="rounded-full px-2 py-0.5 -mb-10 ml-1 text-center text-xs font-bold uppercase tracking-wide bg-red-700 text-white absolute -top-2 left-full">
              <small>!UNKNOWN&nbspENV</small>
            </span>


          </a>
        </div>

        <div class="md:hidden">
          <button
            @click="isOpen = !isOpen"
            type="button"
            class="block text-gray-700 focus:text-gray-900 focus:outline-none"
          >
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path
                v-if="isOpen"
                fill-rule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
              />
              <path
                v-if="!isOpen"
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </button>
        </div>
      </div>
      <nav
        :class="{ hidden: !isOpen, block: isOpen }"
        class="bg-gray-200 px-2 pt-2 pb-4 md:flex md:bg-white md:p-0"
      >
        <div class="md:hidden">
          <a
            href="#"
            v-for="(item, index) in menuItems"
            :key="index"
            :to="item.route"
            class="mt-1 block rounded px-2 py-1 transition-all duration-300 hover:bg-gray-400"
            :class="{ 'bg-gray-400': $route.name == item.route }"
            @click.prevent="navigateToRoute(item.route)"
          >
            {{ item.name }}
          </a>
        </div>

        <div class="border-t border-gray-400 pt-2 pb-4 md:flex md:border-none md:p-0">
          <a
            :href="contentManagementUri"
            class="block rounded px-2 py-1 hover:bg-gray-400 md:hover:bg-gray-200"
          >
            <span class="inline-block w-4 h-4 pt-0.5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
              </svg>
            </span>

            Content Management
          </a>
          <a
            :href="marketingSiteUrl"
            class="block rounded px-2 py-1 hover:bg-gray-400 md:hover:bg-gray-200"
            >Marketing</a
          >
          <a
            :href="accountSiteUrl"
            class="mt-1 block rounded px-2 py-1 hover:bg-gray-400 md:mt-0 md:ml-2 md:hover:bg-gray-200"
            >My account</a
          >
          <a
            href="#"
            @click.prevent="logout"
            class="mt-1 block rounded px-2 py-1 hover:bg-gray-400 md:mt-0 md:ml-2 md:hover:bg-gray-200"
            >Log Out</a
          >
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { DOMAIN, CONTENT_MANAGEMENT_URL, VISIBLE_ENV } from '~/config'
import menuItems from '~/router/menu-items'

export default {
  data() {
    return {
      isOpen: false,
      menuItems,
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    },
    navigateToRoute(route) {
      this.$router.push({ name: route })
      this.isOpen = false
    },
  },

  computed: {
    marketingSiteUrl() {
      return `https://${DOMAIN}`
    },
    accountSiteUrl() {
      return `https://account.${DOMAIN}`
    },
    contentManagementUri() {
      return CONTENT_MANAGEMENT_URL;
    },
    visibleEnv() {
      return VISIBLE_ENV;
    },
    isProduction() {
      return this.visibleEnv === 'production' || this.visibleEnv === 'prod'
    },
    isStaging() {
      return this.visibleEnv === 'staging'
    },
    isDev() {
      return this.visibleEnv === 'development' || this.visibleEnv === 'dev'
    },
    isUnknownEnv() {
      return !this.isProduction && !this.isStaging && !this.isDev
    },
  },
}
</script>
