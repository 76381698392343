import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { objectToFormData, headersFormData } from '~/utils/form-data'
import { addOrUpdateItems, resolveIdAndParams, processEntities } from '../helpers'
export const state = {
  items: [],
}
export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id == id),
}
export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}
export const actions = {
  fetch(store, params) {
    return axios.get('api/bestsellers', { params }).then(res => {
      processEntities(res.data.data, 'bestsellers')
      return res.data
    })
  },
  fetchById(store, id) {
    return axios.get(`api/bestsellers/${id}`, { params: { include: 'products' } }).then(res => {
      processEntities(res.data.data, 'bestsellers')
      return res.data
    })
  },
  update(store, { id, form: params }) {
    const formData = new FormData()
    formData.append('_method', 'PUT')
    return axios
      .post(`/api/bestsellers/${id}`, objectToFormData(params, formData), { ...headersFormData })
      .then(res => {
        processEntities(res.data.data, 'bestsellers')
        return res.data
      })
  },
  create(store, params) {
    return axios.post(`/api/bestsellers/`, objectToFormData(params), headersFormData).then(res => {
      processEntities(res.data.data, 'bestsellers')
      return res.data
    })
  },
}
