<template>
  <div class="relative bg-gray-100">
    <navbar />
    <main class="mx-auto flex max-w-7xl">
      <sidebar class="hidden w-1/4 md:block" />
      <div class="mx-auto min-h-screen w-full">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'
import Sidebar from '~/components/Sidebar'

export default {
  components: {
    Navbar,
    Sidebar,
  },
}
</script>
