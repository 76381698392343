<template>
  <div>
    <!-- Button Back -->
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'upsells' }">
        <i class="fas fa-long-arrow-alt-left mr-2" />Back to upsells</router-link
      >
    </button>
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">
          Edit {{ upsell.name }} items
        </h2>
      </div>
    </div>
    <!-- Content -->
    <div class="mt-8">
      <div class="rounded-md bg-white p-5">
        <UpsellsForm
          v-model="form"
          v-if="upsell"
          :upsell="upsell"
          v-on:submit="submit"
          v-on:reordered="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { find, get, debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import HasErrors from '~/mixins/HasErrors'
import UpsellsForm from '~/components/upsells/Form'
export default {
  components: {
    UpsellsForm,
  },
  mixins: [HasErrors],
  mounted() {
    this.load()
  },
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapGetters({
      upsellById: 'upsells/byId',
    }),
    upsellId() {
      return this.$route.params.id
    },
    upsell() {
      return this.upsellById(this.upsellId)
    },
  },
  methods: {
    ...mapActions({
      updateUpsell: 'upsells/update',
      fetchById: 'upsells/fetchById',
    }),
    load() {
      const params = { include: ['products', 'category'] }
      this.fetchById({ id: this.upsellId, params }).then(response => {
        this.setForm(response.data)
      })
    },
    setForm(data) {
      this.form = Object.assign({}, {...this.upsell})
    },
    submit(wrapSubmit) {
      wrapSubmit(this.updateUpsell({ id: this.upsellId, form: this.form }))
        .then(data => {
          this.successMessage('Upsell updated')
        })
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
  },
}
</script>
