import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

const getDefaultState = () => {
  return {
    items: []
  }
}

export const state = getDefaultState()

export const getters = {
  all: state => _.orderBy(state.items, ['name'], 'asc'),
  byId: state => id => _.find(state.items, item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [types.RESET_STATE]: (state) => Object.assign(state, getDefaultState())
}

export const actions = {
  async fetch(store, params) {
    const res = await axios.get('api/sales/admin/settings', { params })
    store.commit(types.RESET_STATE)
    processEntities(res.data.data, 'settings')
    return res.data
  },
  async update(store, params) {
    const res = await axios.put(`api/sales/admin/settings`, params)
    processEntities(res.data.data, 'settings')
  },
}
