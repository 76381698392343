<template>
  <div>
    <PanelHeader>
      <h4 class="text-base font-semibold">Activity Log</h4>
    </PanelHeader>
    <div class="bg-gray-100 px-4 py-4">
      <div class="space-y-3 divide-y-2 divide-gray-300">
        <div v-for="activity in activities" :key="activity.id" class="pt-3">
          <p class="text-sm text-gray-600">
            <strong>{{ activity.type }}</strong> by {{ activity.causer.email }} on
            {{ activity.created_at }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['order'],
  computed: {
    ...mapGetters(['post-cutoff-activities/byIds', 'users/byId']),
    activities() {
      return this['post-cutoff-activities/byIds'](this.order.postCutoffActivities)
    },
  },
  methods: {},
}
</script>
