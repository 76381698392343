<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <h4 class="mb-5 font-bold">Manage User Roles</h4>
    <div v-for="role in roles" :key="role.id">
      <button class="btn btn-green" v-if="hasRole(role)" @click.prevent="removeRole(role)">
        Remove {{ role.name }} Role
      </button>
      <button class="btn btn-gray" v-else @click.prevent="addRole(role)">
        Add {{ role.name }} Role
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['user'],
  mounted() {
    this['roles/fetch']()
  },
  methods: {
    ...mapActions(['roles/fetch', 'users/addRole', 'users/removeRole']),
    hasRole(role) {
      return this.user.roles.includes(role.id)
    },
    addRole(role) {
      this['users/addRole']({ id: this.user.id, role_id: role.id })
    },
    removeRole(role) {
      this['users/removeRole']({ id: this.user.id, role_id: role.id })
    },
  },
  computed: {
    ...mapGetters(['roles/all']),
    roles() {
      return this['roles/all']
    },
  },
}
</script>
