<template>
  <div>
    <div v-if="order?.id">
      Order: <strong>{{ order.status.toUpperCase() }}</strong><br>
      Deliver: {{ order.deliver_at }}<br />
      <span v-if="!cutoffIsFuture">Cutoff passed<br /></span>
      <button v-if="cutoffIsFuture && order.status !== 'active'"
        class="btn btn-green btn-sm mt-2"
        type="button"
        @click="resumeOrder"
      >
          Reactivate
      </button>
      <button v-if="cutoffIsFuture && order.status === 'active'"
        class="btn btn-red btn-sm mt-2"
        type="button"
        @click="cancelOrder">
          Cancel
      </button>
    </div>
    <div v-else>
      No order <br>
      Deliver: {{ deliveryQuote?.delivery_date }}<br />
      <span v-if="!cutoffIsFuture">Cutoff passed<br /></span>
      <button class="btn btn-green btn-sm mt-2"
        type="button"
        @click="createOrder"
        v-if="cutoffIsFuture">
        Create
      </button>
    </div>
  </div>
</template>

<script>
import { isAfter } from "date-fns"

export default {
  props: {
    order: {
      type: Object,
      default: null
    },
    orderWeek: {
      type: Object
    },
    productionCycle: {
      type: Object,
    },
    deliveryQuote: {
      type: Object
    }
  },
  methods: {
    createOrder() {
      this.$emit('createOrder', this.orderWeek, this.productionCycle)
    },
    resumeOrder() {
      this.$emit('resumeOrder', this.order)
    },
    cancelOrder() {
      this.$emit('cancelOrder', this.order)
    },
  },
  computed: {
    cutoffIsFuture() {
      const cutoff = new Date(this.deliveryQuote.cutoff_date_local)
      const now = new Date()
      return isAfter(cutoff, now)
    }
  }
}
</script>
