<template>
  <button
    @click.prevent="handle"
    class="flex items-center rounded-sm bg-gray-300 px-2 py-1 text-gray-800 shadow-sm hover:bg-teal-500 hover:text-white"
  >
    <svg
      class="mr-1 h-4 w-4 stroke-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
      ></path>
    </svg>
    Reset Password
  </button>
</template>

<script>
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['user'],
  data() {
    return {}
  },
  methods: {
    ...mapActions(['users/fetchById', 'users/resetPassword']),

    handle() {
      Confirm.fire({
        text: 'This will reset user\'s password to "nurturelife", proceed?',
        preConfirm: () => {
          return this['users/resetPassword']({ id: this.user.id }).catch(error =>
            Swal.showValidationMessage(`Request failed: ${error}`)
          )
        },
      }).then(result => {
        result.value && this.successMessage('Password reset!')
        const include = [
          'activePromoCodeApplication',
          'compedAccount',
          'promoCodeApplications',
          'defaultAddress',
          'kids',
          'profile',
        ]
        this['users/fetchById']({ id: this.user.id, params: { include } })
      })
    },
  },
  computed: {},
}
</script>
