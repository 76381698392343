import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
import { headersFormData, objectToFormData } from '~/utils/form-data'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items.sort((a, b) => a > b ? -1 : 1),
  byId: state => id => state.items.find(item => item.id == id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch() {
    return axios.get('api/shipping/carriers').then(res => {
      processEntities(res.data.data, 'carriers')
      return res.data
    })
  },
  fetchById(store, id) {
    return axios.get(`api/shipping/carriers/${id}`).then(res => {
      processEntities(res.data.data, 'carriers')
      return res.data
    })
  },
  update(store, { id, form: params }) {
    const formData = new FormData()
    formData.append('_method', 'POST')
    return axios
      .post(`/api/shipping/carriers/${id}`, objectToFormData(params, formData), { ...headersFormData })
      .then(res => {
        processEntities(res.data.data, 'carriers')
        return res.data
      })
  },
  create(store, params) {
    return axios.post(`/api/shipping/carriers/`, objectToFormData(params), headersFormData).then(res => {
      processEntities(res.data.data, 'carriers')
      return res.data
    })
  },
}
