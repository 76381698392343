<template>
  <div class="mb-6 overflow-hidden rounded-lg bg-white shadow">
    <div class="border-b border-gray-300 bg-white py-3">
      <h4 class="px-4 text-base font-semibold">Promo Code History</h4>
    </div>
    <div class="px-4 pb-6 pt-2 sm:pt-6">
      <div v-if="applications.length == 0">
        <empty-state><span>No promo codes were used</span></empty-state>
      </div>
      <div v-else class="divide-y divide-gray-600 divide-opacity-25">
        <div v-for="application in applications">
          <div class="flex items-start justify-between py-4">
            <div>
              <div class="text-lg">{{ application.code }}</div>
              <div class="mt-1 text-sm text-gray-600">
                activated on
                <span class="font-semibold text-gray-600">{{ application.created_at | date }}</span>
              </div>
              <div class="mt-1 text-sm text-gray-600" v-if="application.status == 'deactivated'">
                deactivated on
                <span class="font-semibold text-gray-600">
                  {{ application.depleted_at | date }}
                </span>
              </div>
            </div>
            <div class="flex flex-col items-end justify-between space-y-2">
              <div>
                <span
                  class="rounded bg-red-100 px-1 text-xs font-semibold uppercase tracking-wide text-red-400"
                  v-if="application.status == 'deactivated'"
                  >Deactivated</span
                >
                <span
                  class="rounded bg-yellow-100 px-1 text-xs font-semibold uppercase tracking-wide text-yellow-700"
                  v-if="application.status == 'paused'"
                  >Paused</span
                >
                <span
                  class="rounded bg-teal-100 px-1 text-xs font-semibold uppercase tracking-wide text-teal-800"
                  v-if="application.status == 'active'"
                  >Active</span
                >
              </div>
              <div class="flex justify-end" v-if="application.status == 'active'">
                <button v-on:click="deplete">
                  <svg
                    class="h-4 w-4 text-red-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'

export default {
  props: ['user', 'activePromoCodeApplication'],
  computed: {
    ...mapGetters(['promo-code-applications/byUserId']),
    applications() {
      return this['promo-code-applications/byUserId'](this.user.id)
    },
  },
  methods: {
    deplete() {
      Confirm.fire({
        text: 'Are you sure you want to disable promo code?',
        preConfirm: () => {
          return this.$store
            .dispatch('promo-code-applications/depleteActive', this.user.id)
            .catch(error => Swal.showValidationMessage(`Request failed: ${error}`))
        },
      }).then(result => {
        result.value && this.successMessage('Promo code has been deactivated.')
      })
    },
  },
}
</script>
