import axios from 'axios'
import _ from 'lodash'
import * as mutationTypes from '../mutation-types'
import { addOrUpdateItems, processEntities, removeItem } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
  byIds: state => ids => _.filter(state.items, item => ids.includes(item.id)),
}

export const mutations = {
  [mutationTypes.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [mutationTypes.REMOVE]: (state, item) => removeItem(state, item),
}

export const actions = {
  fetch({ dispatch }, params) {
    return axios.get('api/admin/meal-components', { params }).then(res => {
      processEntities(res.data.data, 'mealComponents')
      return res.data
    })
  },
  create({ dispatch }, params) {
    return axios.post('/api/sales/admin/meal-components', params).then(res => {
      processEntities(res.data.data, 'mealComponents')
      return res.data
    })
  },
  // update({ dispatch }, { id, params }) {
  //   return axios.put(`/api/sales/admin/promo-codes/${id}`, params).then(res => {
  //     processEntities([res.data.data], 'promoCodes')
  //     return res.data
  //   })
  // },
  // remove({ commit }, id) {
  //   return axios.delete(`/api/sales/admin/promo-codes/${id}`).then(res => {
  //     commit(mutationTypes.REMOVE, { id })
  //     return res.data
  //   })
  // },
  // disable({ dispatch }, id) {
  //   return axios.delete(`/api/sales/admin/promo-codes/${id}/activity`).then(res => {
  //     processEntities([res.data.data], 'promoCodes')
  //     return res.data
  //   })
  // },
}
