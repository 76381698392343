<template>
  <div class="mx-10">
    <div class="rounded-md flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Regenerate Orders</h3>
    </div>
    <div class="mt-8">
      <div v-if="!isLoading">
        <FormSection title="Order IDs">
          <InputGroup :inline="false" label="Separate by new line or commas" :error="getError('order_ids')">
            <textarea :readonly="saving" rows="10" class="form-textarea w-full" v-model="order_ids" placeholder=""></textarea>
          </InputGroup>
          <InputGroup :inline="false" :error="getError('actions.refill_cart')">
            <div class="flex items-center space-x-2">
              <ToggleInput v-model="refill_cart" />
              <span>Refill cart items</span>
            </div>
          </InputGroup>
          <InputGroup :inline="false" :error="getError('actions.refill_cart')">
            <div class="flex items-center space-x-2">
              <ToggleInput v-model="recalculate_dates" />
              <span>Recalculate shipping dates</span>
            </div>
          </InputGroup>
        </FormSection>

        <div class="text-sm text-red-500 mt-4" v-if="firstError">{{ firstError }}</div>

        <div class="flex mt-6">
          <button-loader label="Regenerate" :loading="saving" @click="store" />
        </div>
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>
import ButtonLoader from "@/components/global/ButtonLoader.vue";
import Loader from "@/components/global/Loader.vue";
import { mapActions } from "vuex";
import HasErrors from '~/mixins/HasErrors';
import ToggleInput from '../../components/global/ToggleInput.vue';
import FormSection from "../../components/global/UI/form/FormSection.vue";

export default {
  mixins: [
    HasErrors
  ],
  components: {
    Loader,
    ButtonLoader,
    FormSection,
    ToggleInput
  },
  data: () => ({
    isLoading: false,
    saving: false,
      order_ids: null,
      refill_cart: false,
      recalculate_dates: false,
    batches: []
  }),
  async mounted() {
    await this.fetchOrderBatches()
    this.isLoading = false
  },
  computed: {
  },
  methods: {
    ...mapActions({
      getBatches: 'batches/fetch',
      regenerateOrders: 'batches/regenerateOrders',
    }),
    async fetchOrderBatches() {
      this.batches = await this.getBatches({ name: 'AdminBatchRefillAndRecalculateOrders' })
    },
    async store() {
      this.saving = true
      this.wrapSubmit(this.regenerateOrders({
        order_ids: this.order_ids,
        actions: {
          refill_cart: this.refill_cart,
          recalculate_dates: this.recalculate_dates,
        }
      }))
        .then(() => {
          this.successMessage('Orders pushed')
          this.$router.push({ name: 'regenerate-orders' })
        })
        .catch(e => {
          const msg = e.response.data?.message ?? 'Error Occured'
          this.errorMessage(msg)
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>
