import { schema } from 'normalizr'
import _ from 'lodash'
import carrier from './carriers'

const processStrategy = zipCode => {
  const defaultShipDay =
    _.has(zipCode, 'eligible_ship_days') &&
    _.find(zipCode.eligible_ship_days, shipDay => shipDay.pivot && shipDay.pivot.is_default)

  const relations = {
    defaultShipDay: defaultShipDay && defaultShipDay.name,
    shipDays: _.map(_.get(zipCode, 'eligible_ship_days'), shipDay => shipDay.name),
    shippingRules: _.get(zipCode, 'shippingRules.data'),
  }

  return { ..._.omit(zipCode, ['eligible_ship_days']), ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'zip-codes',
  {
    carrier: carrier,
  },
  {
    processStrategy,
  }
)
