<template>
  <div class="flex items-center">
    <span class="flex-1" :class="{'opacity-50': regulation.amount === 0}">
      {{ type }} discount for {{ amount }}
      {{ regulation.maximum_discount ? ' (max $' + regulation.maximum_discount + ')' : '' }} will apply on {{ basis
      }}. <br>
      It will <strong class="font-bold">{{ regulation.free_tax ? '' : 'NOT' }}</strong> cover tax,
      and will <strong class="font-bold">{{ regulation.free_shipping ? '' : 'NOT' }}</strong> cover shipping.
    </span>
    <button v-if="editable" class="rotate-45 transform" @click="remove">
      <span class="text-3xl text-red-400 hover:text-red-500">+</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  TYPE_DOLLARS_OFF,
  TYPE_PERCENT_OFF,
  TYPE_PRODUCTS_OFF,
} from '~/store/extras/promo-code-presets'
export default {
  props: ['regulation', 'editable'],
  methods: {
    remove() {
      this.$emit('remove', this.regulation)
    },
  },
  computed: {
    ...mapGetters({
      types: 'promo-codes/types',
    }),
    type() {
      return this.types.find(i => i.slug === this.regulation.type).label
    },
    amount() {
      let formatted = ''
      switch (this.regulation.type) {
        case TYPE_DOLLARS_OFF:
          formatted = this.$options.filters.dollars(+this.regulation.amount)
          break
        case TYPE_PERCENT_OFF:
          formatted = `${this.regulation.amount}%`
          break
        case TYPE_PRODUCTS_OFF:
          formatted = `${this.regulation.amount} products`
      }
      return formatted
    },
    basis() {
      if (this.regulation.weekly_ordinal_number === null) {
        return 'recurring basis'
      }

      return `${this.$options.filters.ordinal(this.regulation.weekly_ordinal_number)} week`
    },
  },
}
</script>
