<template>
  <modal :adaptive="true" name="remove-payment-methods" height="auto">
    <div class="m-4">
      <div class="pb-6">
        <h1 class="mb-5 font-bold text-lg">Are you sure?</h1>
        <h2>This will permanently delete the customer's payment methods from Stripe.</h2>
      </div>

      <hr class="mb-2" />

      <span v-if="loading">Loading...</span>
      <div v-else class="mb-3 flex items-center space-x-3">
        <button type="button" class="btn btn-gray" @click.prevent="close">Dismiss</button>
        <button type="button" class="btn btn-red" @click.prevent="confirm">Confirm</button>

        <p v-if="error !== null" class="text-sm text-red-600" v-text="error" />
      </div>

    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['user'],
  data: () => ({
    loading: false,
    error: null,
  }),
  methods: {
    ...mapActions(['users/removePaymentMethods']),
    confirm() {
      this.loading = true
      this['users/removePaymentMethods'](this.user.id)
        .then(this.close)
        .catch((error) => (this.error = error.response.data.error || error.response.data.message))
        .finally(() => (this.loading = false))
    },
    close() {
      this.$modal.hide('remove-payment-methods')
    },
  },
}
</script>
