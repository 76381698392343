<template>
  <div>
    <div>
      <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200">
        <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Marketing Menu Items</h3>
      </div>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div v-if="menuItems.length" class="mb-10">
          <div class="overflow-hidden bg-white shadow sm:rounded-md">
            <ul class="divide-y divide-gray-200">
              <ProductMenuItem
                v-for="item in menuItems"
                :menuItem="item"
                :key="item.id"
                v-on:editItem="editItem"
              />
            </ul>
          </div>
          <ProductMenuItemEditModal
            v-if="editingItem"
            :editingItem="editingItem"
            @closed="editingItem = null"
            @updated="loadPage"
          />
        </div>
        <empty-state v-else />
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
import ProductMenuItem from '~/components/ProductMenuItems/ProductMenuItem'
import ProductMenuItemEditModal from '~/components/ProductMenuItems/ProductMenuItemEditModal'

export default {
  mixins: [IndexPage],
  components: { ProductMenuItem, ProductMenuItemEditModal },
  data: () => ({
    editingItem: null,
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 100,
      transitional: false,
      include: 'primaryCategory,primaryComposableCategory'
    },
  }),

  computed: {
    ...mapGetters({
      itemById: 'menu-items/byId',
    }),
    menuItems() {
      return _.map(this.ids, id => this.itemById(id))
    },
  },

  methods: {
    ...mapActions({
      fetchItems: 'menu-items/fetch',
    }),
    editItem(item) {
      this.editingItem = item
      this.$nextTick(() => this.$modal.show('edit-menu-item'))
    },
  },
}
</script>
