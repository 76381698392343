<template>
  <nav class="overflow-auto">
    <ul class="flex justify-start">
      <li
        v-if="allowNull"
        class="inline-block"
        :class="{
          '-mx-1 rounded-md rounded-b-none border-t border-l border-r border-gray-400 bg-white':
            !value,
        }"
        @click.prevent="$emit('input', null)"
      >
        <a
          @click.prevent="$emit('input', option.value)"
          class="inline-block whitespace-nowrap px-8 py-4 text-xs font-semibold uppercase tracking-wider text-gray-700 hover:text-teal-700"
          href="#"
        >
          All
        </a>
      </li>
      <li
        v-for="option in options"
        :key="option.value"
        class="inline-block"
        :class="{
          '-mx-1 rounded-md rounded-b-none border-t border-l border-r border-gray-400 bg-white':
            option.value === value,
        }"
      >
        <a
          @click.prevent="$emit('input', option.value)"
          class="inline-block whitespace-nowrap px-8 py-4 text-xs font-semibold uppercase tracking-wider text-gray-700 hover:text-teal-700"
          href="#"
        >
          {{ option.label }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
/* @TODO need help of css master */
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    allowNull: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
