<template>
  <div v-if="withMeals && products.length > 0" class="bg-gray-50 px-6 py-4 mt-4 rounded">
    <div class="mb-2 text-gray-700 font-semibold">Select the meals</div>
    <div class="flex items-start space-x-6">
      <ul class="space-y-2">
        <li v-for="product in products" :key="product.sku">
          <label>
            <select
              class="form-select mr-1 w-16 bg-white text-sm"
              v-if="product.maxQuantity"
              @change="selectProduct(product.sku, $event)"
            >
              <option>0</option>
              <option v-for="quantity in product.maxQuantity" :key="quantity">
                {{ quantity }}
              </option>
            </select>
            {{ product.name }}
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { find, without } from 'lodash'
export default {
  props: ['withMeals', 'products', 'value'],
  data() {
    return { items: [] }
  },
  methods: {
    selectProduct(sku, event) {
      const quantity = parseInt(event.target.value, 10)
      const product = find(this.items, { sku })
      if (product) {
        this.items = without(this.items, product)
      }
      this.items.push({ sku, quantity })
      this.notify()
    },

    notify() {
      this.$emit('input', this.items)
    },
  },
  watch: {
    value(value) {
      this.items = value
    },
  },
}
</script>
