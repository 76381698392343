<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex items-center justify-between px-4 py-4 pt-10">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Carriers</h3>
      <router-link
        :to="{ name: 'add-carrier' }"
        class="rounded-sm bg-gray-700 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-500 hover:text-white focus:bg-teal-500 focus:text-white focus:outline-none"
      >
        <i class="fas fa-plus mr-2" /> New Carrier
      </router-link>
    </div>

    <div v-if="loaded">
      <div v-if="carriers.length">
        <table class="w-full table-auto bg-white">
          <thead>
            <tr class="border-b-2 font-bold">
              <th class="border px-4 py-2">ID</th>
              <th class="border px-4 py-2">Name</th>
              <th class="border px-4 py-2">Slug</th>
              <th class="border px-4 py-2">External Carrier</th>
              <th class="border px-4 py-2">Netsuite ID</th>
              <th class="border px-4 py-2">OrderBot Shipping Method</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="carrier in carriers" :key="carrier.id" class="text-center">
              <td class="border px-4 py-2 text-center">{{ carrier.id }}</td>
              <td class="border px-4 py-2">{{ carrier.name }}</td>
              <td class="border px-4 py-2">{{ carrier.slug }}</td>
              <td class="border px-4 py-2">{{ carrier.external_carrier }}</td>
              <td class="border px-4 py-2">{{ carrier.external_method_id }}</td>
              <td class="border px-4 py-2">{{ carrier.order_bot_shipping_method }}</td>
              <td class="border px-4 py-2">
                <router-link
                  :to="{ name: 'edit-carrier',  params: { id: carrier.id } }"
                  class="px-2 py-1 hover:text-teal-600"
                >
                  <i class="fas fa-edit mr-2 w-6 h-6 text-gray-600" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <empty-state v-else />
    </div>
    <loader v-else />
  </div>
</template>

<script>
import IndexPage from '~/mixins/IndexPage'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  mixins: [IndexPage],

  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 10,
      code: null,
      inactive: false,
    },
  }),

  computed: {
    ...mapGetters({
      carriers: 'carriers/all',
    }),
  },

  mounted() {
    this.fetchItems()
  },
  methods: {
    ...mapActions({
      fetchItems: 'carriers/fetch',
    }),
  },
}
</script>
