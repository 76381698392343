<template>
  <div class="pb-8">
    <ValidationMessages class="px-4 py-4 sm:px-0" :errors="allErrors" />
    <div class="space-y-6">
      <FormSection title="General Info">
        <InputGroup :inline="false" label="Product type" :error="getError('type')">
          <SelectInput v-model="form.type" :readonly="isReadOnly('type')">
            <option v-for="type in availableProductTypes" :value="type" :key="type" class="capitalize">{{ type.toUpperCase() }}</option>
          </SelectInput>
        </InputGroup>
        <InputGroup :inline="false" label="SKU" :error="getError('sku')">
          <TextInput v-model="form.sku" placeholder="4CLD4002" :readonly="isReadOnly('sku')" />
          <slot name="skuFooter" v-bind:errors="errors"></slot>
        </InputGroup>
        <InputGroup :inline="false" label="Name" :error="getError('name')">
          <TextInput
            v-model="form.name"
            placeholder="Chicken Meatball Slider"
            :readonly="isReadOnly('name')"
          />
        </InputGroup>
        <InputGroup :inline="false" label="With Statement" :error="getError('with')">
          <TextInput
            v-model="form.with"
            placeholder="with Carrots & Pear Slices"
            :readonly="isReadOnly('with')"
          />
        </InputGroup>
        <InputGroup :inline="false" label="Slug" :error="getError('slug')">
          <TextInput v-model="form.slug" placeholder="slug" :readonly="isReadOnly('slug')" />
        </InputGroup>
        <InputGroup :inline="false" label="Pakcing Name" :error="getError('packing_name')">
          <TextInput
            v-model="form.packing_name"
            placeholder="Checken Meat Balls"
            :readonly="isReadOnly('packing_name')"
          />
        </InputGroup>
        <InputGroup :inline="false" label="Package Type" :error="getError('package')">
          <SelectInput
            v-model="form.package"
            placeholder="Select your option"
            :readonly="isReadOnly('package')"
          >
            <option v-for="item in packageItems" :key="item.slug" :value="item.slug">
              {{ item.label }}
            </option>
          </SelectInput>
        </InputGroup>
        <InputGroup :inline="false" label="Auto rotate starting with week" :error="getError('after_cardinal_active_order')">
          <TextInput v-model="form.after_cardinal_active_order" placeholder="Will be included in rotation this week onwards" type="number" />
        </InputGroup>

        <div class="flex mb-4">
          <div class="w-1/2">
            <InputGroup :error="getError('is_active')" class="mb-3">
              <CheckboxInput label="Is active" v-model="form.is_active" />
            </InputGroup>
            <InputGroup :error="getError('is_premium')">
              <CheckboxInput label="Is Premium" v-model="form.is_premium" />
            </InputGroup>
          </div>
          <div class="w-1/2">
            <InputGroup :error="getError('is_sold_out')" class="mb-3">
              <CheckboxInput label="Is Sold Out" v-model="form.is_sold_out" />
            </InputGroup>
            <InputGroup :error="getError('show_on_product_preferences')">
              <CheckboxInput label="Show on product preferences page" v-model="form.show_on_product_preferences" />
            </InputGroup>
          </div>
        </div>




      </FormSection>

      <FormSection
        v-if="isComposite"
        title="Components"
        subtitle="Composite product must consist of other products. Please include them here. You may add the same product multiple times."
      >
        <InputGroup :inline="false" label="Select products">
          <CompositeProductComponents
            :initial="form.composables"
            @update="syncComposablesProducts"
            :readonly="isReadOnly('composables')"
          />
        </InputGroup>
      </FormSection>

      <FormSection title="Category &amp; Pricing">
        <InputGroup :inline="false" label="Category" :error="getError('category')">
          <SelectInput
            v-model="form.category"
            placeholder="Select your option"
            :readonly="isReadOnly('category')"
          >
            <option v-for="item in categories" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </SelectInput>

          <div class="mt-1 text-sm" v-if="!product || !isReadOnly('category')">
            <CheckboxInput label="with deleted" v-model="listCategoriesWithDeleted" />
          </div>

          <div class="mt-1 text-sm" v-if="pricePreview">
            <div class="text-gray-600">
              Category Product price:
              <span class="font-semibold text-gray-800">${{ pricePreview }}</span>
            </div>
            <div v-if="pricePremiumDifferencePreview">
              <span class="text-gray-600">Category Premium Difference:</span>
              <span class="font-semibold text-gray-800"
                >+ ${{ pricePremiumDifferencePreview }}</span
              >
            </div>
          </div>
        </InputGroup>
        <InputGroup
          :inline="false"
          label="Custom Price (Override)"
          help-text="In special cases, you may set a custom price that will override the category price."
        >
          <TextInput
            v-model="form.item_price"
            placeholder="14.99"
            type="number"
            step="0.01"
            min="0.01"
            :readonly="isReadOnly('item_price')"
          >
            <template v-slot:leading-icon>
              <CurrencyDollarIcon class="h-5 w-5 text-gray-400" />
            </template>
          </TextInput>
        </InputGroup>
      </FormSection>

      <FormSection title="Customer Facing Data">
        <InputGroup :inline="false" label="Description" :error="getError('description')">
          <textarea
            :readonly="isReadOnly('description')"
            rows="4"
            class="form-textarea w-full"
            v-model="form.description"
            placeholder="Product Description"
          ></textarea>
        </InputGroup>
        <InputGroup :inline="false" label="Short Description" :error="getError('excerpt')">
          <textarea
            :readonly="isReadOnly('excerpt')"
            rows="4"
            type="text"
            class="form-textarea w-full"
            v-model="form.excerpt"
            placeholder="Short excerpt"
          ></textarea>
        </InputGroup>
        <InputGroup
          v-if="!isComposite"
          :inline="false"
          label="Heating Instructions"
          :error="getError('heating_instructions')"
        >
          <heating-instructions
            v-model="form.heating_instructions"
            :product-heating-instructions="(product && product.heating_instructions) || []"
          ></heating-instructions>
        </InputGroup>
        <InputGroup :inline="false" label="Ingredients" :error="getError('ingredients')">
          <textarea
            :readonly="isReadOnly('ingredients')"
            rows="4"
            type="text"
            class="form-textarea w-full"
            v-model="form.ingredients"
            placeholder="Ingredients"
          ></textarea>
        </InputGroup>
        <InputGroup :inline="false" label="Rating" :error="getError('fixed_rating')">
          <TextInput
            type="number"
            step="0.01"
            min="0.01"
            v-model="form.fixed_rating"
            :readonly="isReadOnly('fixed_rating')"
          />
          <section v-if="product" class="text-xs">
            <div v-if="product.calculated_rating">
              Calculated rating:
              <span>
                {{ product.calculated_rating }}
              </span>
            </div>
            <div v-if="product.fixed_rating">
              Fixed rating:
              <span>{{ product.fixed_rating }}</span>
            </div>
            <div>Will be shown to user:</div>
            <rating :value="Number(publicRating)" />
            <span> (Nothing is shown in case the rating is &lt; 4 ) </span>
          </section>
        </InputGroup>
        <InputGroup :inline="false" label=" Badge" :error="getError('badge')">
          <SelectInput v-model="form.badge" :readonly="isReadOnly('badge')">
            <option value="">None</option>
            <option v-for="badge in badges" :key="badge.id" :value="badge.id">
              {{ badge.name }}
            </option>
          </SelectInput>
        </InputGroup>
        <InputGroup :inline="false" :error="getError('tags')" label="Tags">
          <div class="grid grid-cols-2">
            <label v-for="tag in tags" :key="tag.id" class="inline-flex items-center space-x-2">
              <input type="checkbox" v-model="form.tags" :value="tag.id" class="form-checkbox" />
              <span>{{ tag.name }}</span>
            </label>
          </div>
        </InputGroup>
        <div>
          <div>
            <div class="grid grid-cols-2 gap-4">
              <div class="col-span-1" :class="{ 'has-error': hasError('allergens') }">
                <label>Product Allergens</label>
                <label
                  v-for="allergen in allergens"
                  :key="allergen.id"
                  class="mb-2 flex items-center"
                >
                  <input
                    type="checkbox"
                    v-model="form.allergens"
                    :value="allergen.id"
                    class="form-checkbox"
                  />
                  <span class="ml-2 text-sm text-gray-800">{{ allergen.name }}</span>
                </label>
              </div>
              <div class="col-span-1" :class="{ 'has-error': hasError('indicators') }">
                <label>Product Indicators</label>
                <label
                  v-for="indicator in indicators"
                  :key="indicator.id"
                  class="mb-2 flex items-center"
                >
                  <input
                    type="checkbox"
                    v-model="form.indicators"
                    :value="indicator.id"
                    class="form-checkbox"
                  />
                  <span class="ml-2 text-sm text-gray-800">{{ indicator.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div class="w-full">
              <InputGroup
                :inline="false"
                label="Meal Components"
                help-text="Meal Components appear whenever the user is rating a meal"
              >
                <div>
                  <MealComponentAutocomplete
                    v-model="mealComponent"
                    class="w-full"
                    v-if="!isReadOnly('meal-components')"
                    :canCreateNewRecordsUsing="createNewComponentRecord"
                  />
                  <div v-if="form.mealComponents.length > 0" class="mt-2 space-x-3">
                    <div
                      v-for="(mealComponentId, i) in form.mealComponents"
                      :key="'mealComponents' + i"
                      class="inline-block rounded bg-teal-100 py-1 px-1 text-xs font-semibold tracking-wide text-teal-600"
                    >
                      <span class="relative">
                        {{ (i + 1) + '. ' + mealComponentsList.find(mealComponentsListItem => mealComponentsListItem.id === mealComponentId).name }}
                        <button
                          class="absolute right-0 -mt-3 -mr-3"
                          @click.prevent="removeMealComponent(mealComponentId)"
                        >
                          <TrashIcon class="h-4 w-4 text-red-300" />
                        </button>
                      </span>
                      <error :error="getError(`mealComponents.${i}`)" />
                    </div>
                  </div>
                </div>
              </InputGroup>

            </div>
            <div class="w-full mt-10">
              <InputGroup
                :inline="false"
                label="Rating Attributes"
                help-text="Rating Attributes appear whenever the user is rating a meal"
              >
                <div>
                  <RatingAttributeAutocomplete
                    v-model="ratingAttribute"
                    class="w-full"
                    v-if="!isReadOnly('rating-attributes')"
                  />
                  <div v-if="form.ratingAttributes.length > 0" class="mt-2 space-x-3">
                    <div
                      v-for="(ratingAttributeId, i) in form.ratingAttributes"
                      :key="'ratingAttributes' + i"
                      class="inline-block rounded bg-teal-100 py-1 px-1 text-xs font-semibold tracking-wide text-teal-600"
                    >
                      <span class="relative">
                        {{ (i + 1) + '. ' + ratingAttributes.find(ratingAttributeItem => ratingAttributeItem.id === ratingAttributeId).name}}
                        <button
                          class="absolute right-0 -mt-3 -mr-3"
                          @click.prevent="removeRatingAttribute(ratingAttributeId)"
                        >
                          <TrashIcon class="h-4 w-4 text-red-300" />
                        </button>
                      </span>
                      <error :error="getError(`ratingAttributes.${i}`)" />
                    </div>
                  </div>
                </div>
              </InputGroup>
            </div>
          </div>
        </div>

        <InputGroup
          :inline="false"
          label="Related Meals"
          help-text="Related meals will be shown on the meal details popup"
        >
          <div>
            <products-autocomplete
              v-model="relatedProduct"
              :params="{ active: 1 }"
              class="w-full"
              v-if="!isReadOnly('related_products')"
            />
            <div v-if="form.related_products.length > 0" class="mt-2 space-x-3">
              <div
                v-for="(product, i) in form.related_products"
                :key="i"
                class="inline-block rounded bg-teal-100 py-1 px-1 text-xs font-semibold tracking-wide text-teal-600"
              >
                <span class="relative">
                  {{ product }}
                  <button
                    class="absolute right-0 -mt-3 -mr-3"
                    @click.prevent="removeRelatedProduct(product)"
                  >
                    <TrashIcon class="h-4 w-4 text-red-300" />
                  </button>
                </span>
                <error :error="getError(`related_products.${i}`)" />
              </div>
            </div>
          </div>
        </InputGroup>

        <InputGroup :error="getError('is_active')">
          <CheckboxInput label="Show facility statement" v-model="form.show_facility_statement" />
        </InputGroup>

        <InputGroup
          :inline="false"
          label="Customers Also Loved Products"
          help-text="Selected products will be shown, in the same order, on the meal details popup under the Customers Also Loved Section"
        >
          <div>
            <products-autocomplete
              v-model="customerAlsoLovedProduct"
              class="w-full"
              v-if="!isReadOnly('customers_also_loved_products')"
            />
            <div v-if="form.customers_also_loved_products.length > 0" class="mt-2 space-x-3">
              <div
                v-for="(product, i) in form.customers_also_loved_products"
                :key="'customers_also_loved_products' + i"
                class="inline-block rounded bg-teal-100 py-1 px-1 text-xs font-semibold tracking-wide text-teal-600"
              >
                <span class="relative">
                  {{ i + 1 }}. {{ product }}
                  <button
                    class="absolute right-0 -mt-3 -mr-3"
                    @click.prevent="removeCustomersAlsoLovedProduct(product)"
                  >
                    <TrashIcon class="h-4 w-4 text-red-300" />
                  </button>
                </span>
                <error :error="getError(`customers_also_loved_products.${i}`)" />
              </div>
            </div>
          </div>
        </InputGroup>
      </FormSection>

      <FormSection title="Journey Statistics">
        <InputGroup
          label="Superfoods Number"
          :inline="false"
          :error="getError('superfoods_number')"
        >
          <input type="number" class="form-input w-full" v-model="form.superfoods_number" />
          <template v-slot:helper>
            <p class="mt-1">
              <b>{{ form.superfoods_number }}</b> units
            </p>
          </template>
        </InputGroup>
        <InputGroup
          label="Veggie milli-Servings"
          :inline="false"
          :error="getError('veggie_milliservings')"
        >
          <input type="number" class="form-input w-full" v-model="form.veggie_milliservings" />
          <template v-slot:helper>
            <p class="mt-1">
              <b>{{ milliunitsToUnits(form.veggie_milliservings) }}</b> servings
            </p>
          </template>
        </InputGroup>
        <InputGroup
          label="Milligrams of protein"
          :inline="false"
          :error="getError('protein_milligrams')"
        >
          <input type="number" class="form-input w-full" v-model="form.protein_milligrams" />
          <template v-slot:helper>
            <p class="mt-1">
              <b>{{ milliunitsToUnits(form.protein_milligrams) }}</b> grams
            </p>
          </template>
        </InputGroup>
        <InputGroup
          label="Milligrams of fiber"
          :inline="false"
          :error="getError('fiber_milligrams')"
        >
          <input type="number" class="form-input w-full" v-model="form.fiber_milligrams" />
          <template v-slot:helper>
            <p class="mt-1">
              <b>{{ milliunitsToUnits(form.fiber_milligrams) }}</b> grams
            </p>
          </template>
        </InputGroup>

        <div class="grid grid-cols-2 gap-4">
          <div class="col-span-1">
            <InputGroup :error="getError('is_hidden_veggie')">
              <CheckboxInput label="Is Hidden Veggie" v-model="form.is_hidden_veggie" />
            </InputGroup>
          </div>
          <div class="col-span-1">
            <InputGroup :error="getError('is_flavor_explorer')">
              <CheckboxInput label="Is Flavor Explorer" v-model="form.is_flavor_explorer" />
            </InputGroup>
          </div>
        </div>
      </FormSection>

      <FormSection title="Images">
        <InputGroup :inline="false" :error="getError('images')">
          <FileInput v-model="form.images" />
          <ImageList v-if="product" :product="product" class="mt-8" />
          <div v-for="(image, i) in form.images" :key="i">
            <div v-if="hasError(`images.${i}`)">
              <error :error="getError(`images.${i}`)"></error>
            </div>
          </div>
        </InputGroup>
        <InputGroup :inline="false" :error="getError('nutrition_image')">
          <image-field
            v-model="form.nutrition_image"
            label="Nutrition Facts Image"
            :default-image="$get(product, 'nutritionImage')"
          />
        </InputGroup>
      </FormSection>

      <FormSection title="Popup Icons">
        <InputGroup>
          <div class="mt-4 space-y-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0">
            <button
              v-for="icon in icons"
              @click.prevent="toggle(icon)"
              :key="icon.id"
              class="flex w-full transform space-x-4 rounded-md border border-gray-400 p-4 hover:scale-105"
              :class="{ 'border-blue-400 bg-blue-100': isSelected(icon) }"
            >
              <div><img class="h-24 w-24" :src="icon.url" /></div>
              <div>
                <span>{{ icon.title }}</span>
                <p v-html="icon.description"></p>
              </div>
            </button>
          </div>
        </InputGroup>
      </FormSection>
      <FormSection>
        <button-loader label="Save Product" :loading="submitting" @click="submit" />
      </FormSection>
    </div>
  </div>
</template>

<script>
import ValidationMessages from '~/components/global/UI/form/ValidationMessages'
import FileInput from '~/components/global/input/FileInput'
import ImageList from '~/components/products/ImageList'
import { TrashIcon, CurrencyDollarIcon } from '@vue-hero-icons/outline'
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '~/mixins/FormMixin'
import ImageField from './ImageField'
import HeatingInstructions from './HeatingInstructions'
import { filter } from 'lodash'
import rating from '~/components/products/rating/Rating.vue'
import CompositeProductComponents from '~/components/products/CompositeProductComponents'
import { EDITABLE_PRODUCT_FIELDS } from '~/constants/products'
import MealComponentAutocomplete from "@/components/global/Autocompletes/MealComponentAutocomplete";
import RatingAttributeAutocomplete from "@/components/global/Autocompletes/RatingAttributeAutocomplete";

const COMPOSITE_PRODUCT_TYPE = 'composite';
const REGULAR_PRODUCT_TYPE = 'regular';

export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
  },

  components: {
    RatingAttributeAutocomplete,
    MealComponentAutocomplete,
    ImageField,
    TrashIcon,
    HeatingInstructions,
    rating,
    CompositeProductComponents,
    CurrencyDollarIcon,
    FileInput,
    ImageList,
    ValidationMessages,
  },

  mixins: [FormMixin],

  data: () => ({
    form: {
      type: REGULAR_PRODUCT_TYPE,
      icons: [],
      is_active: true,
      show_on_product_preferences: true,
      is_premium: false,
      is_sold_out: false,
      name: null,
      with: null,
      slug: null,
      sku: null,
      item_price: null,
      fixed_rating: null,
      packing_name: null,
      ingredients: null,
      mealComponents: [],
      ratingAttributes: [],
      package: null,
      category: null,
      description: null,
      excerpt: null,
      images: [],
      nutrition_image: null,
      allergens: [],
      indicators: [],
      tags: [],
      related_products: [],
      customers_also_loved_products: [],
      heating_instructions: [],
      composables: [],
      superfoods_number: 0,
      fiber_milligrams: 0,
      protein_milligrams: 0,
      veggie_milliservings: 0,
      is_flavor_explorer: false,
      is_hidden_veggie: false,
      show_facility_statement: true,
      after_cardinal_active_order: 0,
    },
    relatedProduct: null,
    customerAlsoLovedProduct: null,
    mealComponent: null,
    ratingAttribute: null,
    listCategoriesWithDeleted: false,
    availableProductTypes: [REGULAR_PRODUCT_TYPE,  COMPOSITE_PRODUCT_TYPE]
  }),

  computed: {
    ...mapGetters({
      packageItems: 'products/packageItems',
      allergens: 'products/allergens',
      indicators: 'products/indicators',
      mealComponentsList: 'meal-components/all',
      ratingAttributes: 'rating-attributes/all',
      icons: 'icons/all',
      categories: 'categories/all',
      categoryById: 'categories/byId',
      badges: 'badges/all',
      tags: 'tags/all',
    }),

    sku() {
      return this.product ? this.product.sku : null
    },

    publicRating() {
      if (this.product && this.product.rating) {
        return this.product.rating
      }

      if (this.form.fixed_rating) {
        return this.form.fixed_rating
      }
      return 0
    },
    selectedCategory() {
      return this.categoryById(+this.form.category)
    },
    pricePreview() {
      if (!this.selectedCategory) {
        return null
      }

      if (this.form.is_premium) {
        return this.selectedCategory.price.original_premium_price
      }

      return this.selectedCategory.price.original_price
    },

    pricePremiumDifferencePreview() {
      if (
        !this.selectedCategory ||
        !this.form.is_premium ||
        !this.selectedCategory.price.original_premium_difference ||
        this.selectedCategory.price.original_premium_difference === 0
      ) {
        return null
      }

      return this.selectedCategory.price.original_premium_difference
    },
    isComposite() {
      return this.form.type === COMPOSITE_PRODUCT_TYPE
    },

  },

  methods: {
    ...mapActions({
      fetchAllergens: 'products/fetchAllergens',
      fetchIndicators: 'products/fetchIndicators',
      fetchMealComponents: 'meal-components/fetch',
      createMealComponent: 'meal-components/create',
      fetchRatingAttributes: 'rating-attributes/fetch',
      fetchCategories: 'categories/fetch',
      fetchTags: 'tags/fetch',
      fetchBadges: 'badges/fetch',
      fetchIcons: 'icons/fetch',
    }),
    addRelatedProduct() {
      if (!this.relatedProduct) {
        return
      }
      this.form.related_products.push(this.relatedProduct)
      this.relatedProduct = null
    },
    removeRelatedProduct(product) {
      this.form.related_products.splice(this.form.related_products.indexOf(product), 1)
    },
    addCustomerAlsoLovedProducts() {
      if (!this.customerAlsoLovedProduct) {
        return
      }
      this.form.customers_also_loved_products.push(this.customerAlsoLovedProduct)
      this.customerAlsoLovedProduct = null
    },
    removeCustomersAlsoLovedProduct(product) {
      this.form.customers_also_loved_products.splice(this.form.customers_also_loved_products.indexOf(product), 1)
    },
    addMealComponent() {
      if (!this.mealComponent) {
        return
      }
      this.form.mealComponents.push(this.mealComponent)
      this.mealComponent = null
    },
    addRatingAttribute() {
      if (!this.ratingAttribute) {
        return
      }
      this.form.ratingAttributes.push(this.ratingAttribute)
      this.ratingAttribute = null
    },
    async createNewComponentRecord(name) {
      let newComponents = await this.createMealComponent({name})
      this.mealComponent = newComponents.data.find(component => component.name === name).id
      this.addMealComponent()
    },
    removeMealComponent(product) {
      this.form.mealComponents.splice(this.form.mealComponents.indexOf(product), 1)
    },
    removeRatingAttribute(ratingAttribute) {
      this.form.ratingAttributes.splice(this.form.ratingAttributes.indexOf(ratingAttribute), 1)
    },
    isSelected(icon) {
      return this.form.icons.indexOf(icon.id) > -1
    },
    toggle(icon) {
      if (this.isSelected(icon)) {
        this.form.icons = filter(this.form.icons, selected => selected !== icon.id)
      } else {
        this.form.icons.push(icon.id)
      }
    },
    syncComposablesProducts(products) {
      this.form.composables = products
    },
    isReadOnly(field) {
      return this.product && EDITABLE_PRODUCT_FIELDS.includes(field) === false
    },
    milliunitsToUnits(milliunits) {
      return milliunits / 1000
    },
    fetchCategoriesInContext()
    {
      this.fetchCategories({
        withDeleted: this.listCategoriesWithDeleted,
        sku: this.sku
      })
    }
  },

  watch: {
    relatedProduct: {
      handler(val) {
        if (val) {
          this.addRelatedProduct()
        }
      },
    },
    customerAlsoLovedProduct: {
      handler(val) {
        if (val) {
          this.addCustomerAlsoLovedProducts()
        }
      },
    },
    mealComponent: {
      handler(val) {
        if (val) {
          this.addMealComponent()
        }
      },
    },
    listCategoriesWithDeleted: {
      handler() {
        this.fetchCategoriesInContext()
      },
    },
    ratingAttribute: {
      handler(val) {
        if (val) {
          this.addRatingAttribute()
        }
      },
    },
    'form.fixed_rating'(val, oldVal) {
      if (!isNaN(Number(val)) && val > 5) {
        this.form.fixed_rating = oldVal
      }
    },
  },

  mounted() {
    this.fetchIcons()
    this.fetchAllergens()
    this.fetchIndicators()
    this.fetchMealComponents()
    this.fetchRatingAttributes()
    this.fetchCategoriesInContext()
    this.fetchBadges()
    this.fetchTags()
  },
}
</script>
