<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex justify-between border-b border-solid border-gray-400 py-4">
      <h3 class="text-3xl">Product Rotations</h3>
      <div class="flex">
        <a class="btn" :href="logUrl">
          <i class="fas fa-file-alt mr-2" /> See the Log
        </a>
        <button class="btn btn-green" @click.prevent="createNewRotation">
          <i class="fas fa-plus mr-2" /> Add Rotation
        </button>
      </div>
    </div>
    <table class="mt-4 w-full table-auto bg-white">
      <thead>
        <tr class="border-b-2 font-bold">
          <th class="border px-4 py-2 text-left">Rotation</th>
          <th class="border px-4 py-2">Weeks</th>
          <th class="border px-4 py-2">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rotation in ProductRotations" class="text-center">
          <td class="border px-4 py-2 text-left">
            <router-link
              :to="{ name: 'edit-product-rotation', params: { id: rotation.id } }"
              class="text-green-500 underline"
            >
              Rotation {{ rotation.id }}
            </router-link>
          </td>
          <td class="w-48 border px-4 py-2">
            <ul>
              <li v-for="week in weeksByProductRotationId(rotation.id)" :key="week.id">
                {{ week.number }}
                <span v-if="week.status === 'draft'">
                  (<a href="#" @click.prevent="activateOrderWeek(week)" class="link">activate</a>)
                </span>
              </li>
            </ul>
          </td>
          <td class="w-48 border px-4 py-2">
            <div class="flex justify-center">
              <a
                href=""
                class="icon-link icon-red"
                @click.prevent="removeProductRotation(rotation.id)"
              >
                <i class="fas fa-trash" />
                <div class="tooltip">Remove</div>
              </a>
              <a
                href=""
                class="icon-link icon-gray"
                @click.prevent="cloneProductRotation(rotation.id)"
              >
                <i class="fas fa-clone" />
                <div class="tooltip">Clone</div>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'
import { BASE_API_URL } from '@/config'

export default {
  computed: {
    ...mapGetters(['product-rotations/all', 'order-weeks/byProductRotationId']),

    ProductRotations() {
      return this['product-rotations/all']
    },
    logUrl() {
      return `${BASE_API_URL}/api/sales/admin/rotations-log`
    }
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    ...mapActions([
      'product-rotations/fetch',
      'product-rotations/remove',
      'product-rotations/create',
      'product-rotations/clone',
    ]),
    ...mapActions({
      activateOrderWeek: 'order-weeks/activate',
    }),
    weeksByProductRotationId(id) {
      return this['order-weeks/byProductRotationId'](id)
    },
    removeProductRotation(id) {
      Confirm.fire({
        text: `Are you sure you wish to delete the Rotation ${id}?`,
        preConfirm: () => {
          return this['product-rotations/remove'](id).catch(error =>
            Swal.showValidationMessage(`Request failed: ${error}`)
          )
        },
      }).then(result => result.value && this.successMessage('Rotation Deleted!'))
    },
    cloneProductRotation(id) {
      Confirm.fire({
        icon: 'info',
        text: `Are you sure you wish to clone the Rotation ${id}?`,
        preConfirm: () => {
          return this['product-rotations/clone'](id).catch(error =>
            Swal.showValidationMessage(`Request failed: ${error}`)
          )
        },
      }).then(result => result.value && this.successMessage('Rotation Cloned!'))
    },
    createNewRotation() {
      this['product-rotations/create']().then(() => {
        this.successMessage('Rotation created')
        this.loadPage()
      })
    },
    loadPage() {
      this['product-rotations/fetch']({ per_page: 999, include: ['orderWeeks'] })
    },
  },
}
</script>
