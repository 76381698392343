<template>
  <div>
    <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
      <h4 class="mb-5 font-bold">Payment methods</h4>

      <p v-if="paymentMethods.length === 0" class="text-sm">Customer has no payment methods.</p>

      <template v-else>
        <ui-table class="w-full mb-4">
          <template #thead>
              <table-heading class="text-sm font-normal text-left" compact>Card</table-heading>
              <table-heading class="text-sm font-normal text-right" compact>Default</table-heading>
          </template>
          <tr v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
            <table-cell class="text-xs font-light" compact>xxxx-xxxx-xxxx-{{ paymentMethod.card_last_four }}</table-cell>
            <table-cell class="text-right" compact>
              <check-circle-icon v-if="paymentMethod.is_default" size="16" class="ml-auto text-teal-500" />
              <x-circle-icon v-else size="16" class="ml-auto text-red-400" />
            </table-cell>
          </tr>
        </ui-table>

        <button
          type="button"
          class="rounded-sm bg-white px-2 py-1 text-sm uppercase tracking-wide text-pink-500 shadow hover:bg-pink-300 hover:text-pink-800"
          :class="{ disabled: loading }"
          @click.prevent="showModal"
        >
          <i v-if="loading" class="fa fa-spin fa-spinner" />
          <small>
            Remove all {{ paymentMethods.length }} payment {{ 'method' | plural(paymentMethods.length) }}
          </small>
        </button>
      </template>

      <remove-payment-methods-modal :user="user" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { CheckCircleIcon, XCircleIcon } from "@vue-hero-icons/outline"
import RemovePaymentMethodsModal from './RemovePaymentMethodsModal'
import Table from '~/components/global/UI/Table'
import TableHeading from '~/components/global/UI/TableHeading'
import TableCell from '~/components/global/UI/TableCell'

export default {
  props: ['user'],
  components: {
    CheckCircleIcon,
    XCircleIcon,
    RemovePaymentMethodsModal,
    'ui-table': Table,
    TableHeading,
    TableCell,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    paymentMethods() {
      return this.user.payment_methods ?? []
    },
  },
  mounted() {
      this['users/fetchAllPaymentMethods'](this.user.id)
  },
  methods: {
    ...mapActions(['users/fetchAllPaymentMethods']),
    showModal() {
      this.$modal.show('remove-payment-methods')
    },
  },
}
</script>
