<template>
  <select
    v-bind="$attrs"
    :value="value"
    @input="onInput"
    class="block w-full rounded-md border-gray-200 bg-gray-50 py-1 px-2 text-gray-800 shadow-sm focus:border-teal-600/40 focus:bg-white focus:ring-2 focus:ring-teal-600/40 disabled:cursor-not-allowed disabled:opacity-80"
    :disabled="readonly"
  >
    <option v-if="placeholder" disabled :value="null">{{ placeholder }}</option>
    <slot />
  </select>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: { type: String },
    readonly: { type: Boolean, required: false },
  },
  data() {
    return {}
  },
  methods: {
    onInput($event) {
      const value = Number.isInteger($event.target.value)
        ? +$event.target.value
        : $event.target.value
      this.$emit('input', value)
    },
  },
  computed: {},
}
</script>
