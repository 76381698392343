import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, resolveIdAndParams, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => _.find(state.items, item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch(store, params) {
    if (params.is_admin || params.is_comped) params = _.omit(params, ['status'])

    return axios.get('api/customers/admin/users', { params }).then(res => {
      processEntities(res.data.data, 'users')
      return res.data
    })
  },
  fetchById(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`api/customers/admin/users/${id}`, { params }).then(res => {
      processEntities(res.data.data, 'users')
      return res.data
    })
  },
  fetchAllOrders(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`/api/sales/admin/users/${id}/orders`, { params }).then(res => {
      processEntities(res.data.data, 'orders')
      return res.data
    })
  },
  create(store, { params }) {
    return axios.post('/api/customers/admin/users', params)
  },
  update(store, { id, params }) {
    return axios.put(`/api/customers/admin/users/${id}`, params).then(res => {
      processEntities(res.data.data, 'users')
      return res.data
    })
  },
  updateCompedSettings(store, { id, params }) {
    return axios.put(`api/customers/admin/users/${id}/comped-accounts`, params).then(res => {
      processEntities(res.data.data, 'compedAccounts')
      return res.data
    })
  },
  // eslint-disable-next-line camelcase
  addRole(store, { id, role_id }) {
    axios.post(`/api/customers/admin/users/${id}/roles`, { role_id }).then(res => {
      processEntities({ id, roles: res.data }, 'users')
      return res.data
    })
  },
  // eslint-disable-next-line camelcase
  removeRole(store, { id, role_id }) {
    // eslint-disable-next-line camelcase
    axios.delete(`/api/customers/admin/users/${id}/roles/${role_id}`).then(res => {
      processEntities({ id, roles: res.data }, 'users')
      return res.data
    })
  },
  resetPassword(store, { id }) {
    return axios.put(`/api/customers/admin/users/${id}/password`).then(res => {
      processEntities(res.data.data, 'users')
      return res.data
    })
  },
  fetchAllPaymentMethods(store, id) {
    return axios.get(`/api/customers/admin/users/${id}/payment-methods`).then(res => {
      processEntities({ id, payment_methods: res.data.data }, 'users')
      return res.data
    })
  },
  removePaymentMethods(store, id) {
    return axios.delete(`/api/customers/admin/users/${id}/payment-methods`).then(() => {
      processEntities({ id, payment_methods: [] }, 'users')
    })
  },
  regenerateReferralId(store, id) {
    axios.post(`/api/customers/admin/users/${id}/referral-id`).then(res => {
      processEntities(res.data.data, 'users')
    })
  }
}
