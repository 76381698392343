import Swal from 'sweetalert2'

export default Swal.mixin({
  title: 'Warning!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes!',
  showLoaderOnConfirm: true,
  allowOutsideClick: () => !Swal.isLoading(),
})
