<template>
  <div class="px-4 sm:px-0">
    <div class="mt-4">
      <div class="flex items-center justify-between bg-gray-800 py-4 sm:bg-gray-200">
        <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Production Cycles</h3>
      </div>
    </div>

    <div>
      <div v-if="loaded">
        <div v-if="productionCycles.length" class="mb-10 space-y-10">
          <div v-for="cycle in productionCycles" :key="cycle.id">
            <production-cycle-panel :production-cycle="cycle" @change="updateAllocatedDays" />
          </div>
        </div>
        <empty-state v-if="!productionCycles.length" />
      </div>
      <loader v-else />
    </div>

    <div class="py-4 text-right">
      <button
        class="w-full rounded-sm bg-teal-500 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none sm:w-auto"
        @click.prevent="saveAllocatedDays"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import without from 'lodash/without'
import { mapGetters, mapActions } from 'vuex'
import ProductionCyclePanel from './../../components/production-cycles/ProductionCyclePanel'

export default {
  components: {
    ProductionCyclePanel,
  },
  data: () => ({
    loaded: false,
    productionCycles: [],
  }),
  computed: {
    ...mapGetters(['production-cycles/all']),
  },
  async mounted() {
    await this['production-cycles/fetch']()
    this.productionCycles = cloneDeep(this['production-cycles/all'])
    this.loaded = true
  },
  methods: {
    updateAllocatedDays({ id, day }) {
      const productionCycle = this.productionCycles.find(
        productionCycle => productionCycle.id === id
      )
      if (productionCycle.isAllocatedOnDays.includes(day)) {
        productionCycle.isAllocatedOnDays = without(productionCycle.isAllocatedOnDays, day)
      } else {
        productionCycle.isAllocatedOnDays.push(day)
        this.productionCycles
          .filter(productionCycleItem => productionCycleItem !== productionCycle)
          .forEach(productionCycle => {
            productionCycle.isAllocatedOnDays = without(productionCycle.isAllocatedOnDays, day)
          })
      }
    },
    async saveAllocatedDays() {
      try {
        await Promise.all(
          this.productionCycles.map(productionCycle => {
            return this['production-cycles/saveAllocatableShipDays']({
              productionCycleId: productionCycle.id,
              days: productionCycle.isAllocatedOnDays,
            })
          })
        )
        await this['production-cycles/fetch']()
        this.productionCycles = cloneDeep(this['production-cycles/all'])
        this.successMessage('Production Cycle Updated!')
      } catch (e) {
        this.errorMessage('Error Occurred!')
      }
    },
    ...mapActions(['production-cycles/fetch', 'production-cycles/saveAllocatableShipDays']),
  },
}
</script>
