import axios from 'axios'
import * as mutationTypes from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
}

export const mutations = {
  [mutationTypes.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch({ dispatch }, params) {
    return axios.get('/api/shipping/admin/holidays', { params }).then(res => {
      processEntities(res.data.data, 'holidays')
      return res.data
    })
  },
  create({ dispatch }, params) {
    return axios.post('/api/shipping/admin/holidays', params).then(res => {
      processEntities([res.data.data], 'holidays')
      return res.data
    })
  },
  apply(store, holidayId) {
    return axios.post(`/api/shipping/admin/applied-holidays/${holidayId}`)
  },
}
