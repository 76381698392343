<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5" v-if="ProductRotation">
    <!-- Content-->
    <header class="flex items-center justify-between">
      <h3 class="py-4 text-3xl">Edit Rotation {{ id }}</h3>
      <div>
        <router-link
          :to="{ name: 'product-rotation.order-weeks' }"
          class="rounded bg-teal-500 px-2 py-1 text-white"
        >
          Manage Weeks
        </router-link>
      </div>
    </header>

    <div class="mt-4 grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <ul class="vertical-tabs border-t">
          <li
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            :class="{ active: selectedMenuItem === menuItem }"
            @click="selectedMenuItem = menuItem"
          >
            {{ menuItem.name }}
          </li>
        </ul>
      </div>
      <div class="col-span-6">
        <div class="flex">
          <div class="grid w-full grid-cols-4 gap-4">
            <div class="form-group col-span-3">
              <products-autocomplete
                v-model="addProductForm.product_sku"
                :params="extraSearchParameters"
                :exceptions="ProductRotation.products"
              />
            </div>
            <button
              class="btn w-full self-start"
              :class="{ 'btn-green': addProductForm.product_sku }"
              @click.prevent="addProduct"
            >
              <i class="fas fa-plus mr-2" /> Add Product
            </button>
          </div>
        </div>
        <table class="w-full table-auto bg-white">
          <thead>
            <tr>
              <th class="w-10 border px-4 py-2 text-left">SKU</th>
              <th class="border px-4 py-2 text-left">Product Name</th>
              <th class="border px-4 py-2 text-left">#</th>
              <th class="w-10 border px-4 py-2" />
            </tr>
          </thead>
          <draggable tag="tbody" @update="reorderProducts">
            <tr
              :data-sku="product.sku"
              :key="product.sku"
              v-for="product in filteredProducts"
              class=""
            >
              <td class="border px-4 py-2">
                <div class="flex items-center justify-center space-x-2">
                  <span>{{ product.sku }}</span>
                </div>
              </td>
              <td class="border px-4 py-2">
                {{ product.name }}
              </td>
              <td class="border px-4 py-2">
                <span class="text-sm text-gray-700">{{ product.index_number }}</span>
              </td>
              <td class="border px-4 py-2">
                <a href="" class="icon-link icon-red" @click.prevent="removeProduct(product.sku)">
                  <i class="fas fa-trash" />
                  <div class="tooltip">Remove</div>
                </a>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import * as _ from 'lodash'
import AvailableWeekSelector from '~/components/product-rotations/AvailableWeekSelector'

export default {
  components: { AvailableWeekSelector },
  data() {
    return {
      menuItems: [],
      selectedMenuItem: null,
      addProductForm: {
        product_sku: null,
        category_id: null,
      },
    }
  },
  computed: {
    ...mapGetters(['product-rotations/byId', 'products/bySKU', 'order-weeks/byProductRotationId']),
    id() {
      return +this.$route.params.id
    },
    extraSearchParameters() {
      return {
        categories: this.addProductForm.category_id,
        active: 1,
      }
    },
    selectedWeeks() {
      return this['order-weeks/byProductRotationId'](this.id)
    },
    ProductRotation() {
      return this['product-rotations/byId'](this.id)
    },
    ProductRotationProducts() {
      return _.map(this.ProductRotation.products, this['products/bySKU'])
    },
    filteredProducts() {
      return _.filter(this.ProductRotationProducts, product => {
        return product.category === this.addProductForm.category_id
      })
    },
  },
  watch: {
    selectedMenuItem(value) {
      this.addProductForm.category_id = value && value.category_id
      this.addProductForm.product_sku = null
    },
  },
  mounted() {
    this.fetchMenuItems()
    this.fetchProducts()
    this.fetchWeeks()
    this['product-rotations/fetchById'](this.id)
  },
  methods: {
    ...mapActions([
      'product-rotations/fetchById',
      'product-rotations/fetchProducts',
      'product-rotations/addProduct',
      'product-rotations/removeProduct',
      'product-rotations/reorderProducts',
      'product-rotations/fetchOrderWeeks',
      'product-rotations/addOrderWeek',
      'product-rotations/removeOrderWeek',
      'order-weeks/fetchAvailable',
    ]),
    fetchWeeks() {
      this['product-rotations/fetchOrderWeeks'](this.id)
    },
    addWeek(week) {
      this['product-rotations/addOrderWeek']({ id: this.id, weekId: week.id })
    },
    removeWeek(week) {
      this['product-rotations/removeOrderWeek']({ id: this.id, weekId: week.id })
    },
    reorderProducts(event) {
      this['product-rotations/reorderProducts']({
        id: this.id,
        params: {
          new_index: event.newIndex,
          skus: _.map(event.from.rows, row => row.dataset.sku),
        },
      })
    },
    fetchProducts() {
      this['product-rotations/fetchProducts']({ id: this.id, params: { include: 'category' } })
    },
    addProduct() {
      if (!this.addProductForm.product_sku) return null

      this['product-rotations/addProduct']({ id: this.id, params: this.addProductForm }).then(() => {
        this.fetchProducts()
        this.addProductForm.product_sku = null
        this.successMessage('Product Added')
      })
    },
    removeProduct(sku) {
      this['product-rotations/removeProduct']({ id: this.id, sku }).then(() => {
        this.fetchProducts()
        this.successMessage('Product Removed')
      })
    },
    fetchMenuItems() {
      axios.get('/api/sales/admin/meal-rotations-menu-items').then(res => {
        this.menuItems = res.data.data
        this.selectedMenuItem = this.menuItems[0]
      })
    },
  },
}
</script>
