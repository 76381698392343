<template>
  <div class="flex items-center space-x-2">
    <ToggleInput v-model="isEnabled" />
    <span>Gift Card Promotions</span>
  </div>
</template>

<script>
import axios from 'axios'
import ToggleInput from '~/components/global/ToggleInput'

export default {
  components: { ToggleInput },
  data() {
    return {
      isEnabled: false,
    }
  },
  methods: {
    enable() {
      axios.post('/api/sales/admin/gift-card-promotion-status')
    },
    disable() {
      axios.delete('/api/sales/admin/gift-card-promotion-status')
    },
  },
  watch: {
    isEnabled(value) {
      value === true ? this.enable() : this.disable()
    },
  },
  mounted() {
    axios.get('/api/sales/admin/gift-card-promotion-status').then(response => {
      this.isEnabled = response.data.active
    })
  },
}
</script>
