<template>
  <modal
    :adaptive="true"
    name="funds-return-strategy-modal"
    v-on:before-open="beforeOpen"
    height="auto"
    classes="w-full bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
  >
    <div>
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-teal-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
              />
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              How would you like to return the money for any refundable amount?
            </h3>
            <div class="mt-8">
              <fieldset>
                <div class="-space-y-px rounded-md bg-white">
                  <div
                    class="relative flex rounded-tl-md rounded-tr-md border-2 border-gray-200 border-opacity-50 p-4"
                    :class="{ 'border-teal-400 bg-teal-100': strategy == 'credit' }"
                  >
                    <div class="flex h-5 items-center">
                      <input
                        v-model="strategy"
                        id="strategy-option-0"
                        value="credit"
                        type="radio"
                        class="form-radio h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out"
                        checked
                      />
                    </div>
                    <label for="strategy-option-0" class="ml-3 flex cursor-pointer flex-col">
                      <span
                        class="block text-sm font-medium leading-5"
                        :class="{ 'text-teal-900': strategy === 'credit' }"
                      >
                        Credit
                      </span>
                      <span
                        class="block text-sm leading-5 text-gray-500"
                        :class="{ 'text-teal-700': strategy === 'credit' }"
                      >
                        We will increase customer's credit balance
                      </span>
                    </label>
                  </div>

                  <div
                    class="relative flex border-2 border-gray-200 border-opacity-50 p-4"
                    :class="{ 'z-10 border-teal-400 bg-teal-100': strategy == 'refund' }"
                  >
                    <div class="flex h-5 items-center">
                      <input
                        v-model="strategy"
                        id="strategy-option-1"
                        value="refund"
                        type="radio"
                        class="form-radio h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out"
                      />
                    </div>
                    <label for="strategy-option-1" class="ml-3 flex cursor-pointer flex-col">
                      <span
                        class="block text-sm font-medium leading-5"
                        :class="{ 'text-teal-900': strategy === 'refund' }"
                      >
                        Refund
                      </span>
                      <span
                        class="block text-sm leading-5 text-gray-500"
                        :class="{ 'text-teal-700': strategy === 'refund' }"
                      >
                        We will issue a refund against original order. This option is only available
                        if order was charged on cutoff.
                      </span>
                    </label>
                  </div>

                  <div
                    class="relative flex rounded-bl-md rounded-br-md border-2 border-gray-200 border-opacity-50 p-4"
                    :class="{ 'z-10 border-teal-400 bg-teal-100': !strategy }"
                  >
                    <div class="flex h-5 items-center">
                      <input
                        v-model="strategy"
                        id="strategy-option-2"
                        value=""
                        type="radio"
                        class="form-radio h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out"
                      />
                    </div>
                    <label for="strategy-option-2" class="ml-3 flex cursor-pointer flex-col">
                      <!-- On: "text-teal-900", Off: "text-gray-900" -->
                      <span
                        class="block text-sm font-medium leading-5"
                        :class="{ 'text-teal-900': !strategy }"
                      >
                        None
                      </span>
                      <!-- On: "text-teal-700", Off: "text-gray-500" -->
                      <span
                        class="block text-sm leading-5 text-gray-500"
                        :class="{ 'text-teal-700': !strategy }"
                      >
                        No credit or refund
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click.prevent="confirm"
            type="button"
            class="focus:shadow-outline-teal inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none sm:text-sm sm:leading-5"
          >
            Save
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click.prevent="close"
            type="button"
            class="focus:shadow-outline inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['order', 'user'],
  data: () => ({
    loading: false,
    strategy: null,
  }),
  methods: {
    ...mapActions(['orders/update']),
    beforeOpen() {
      this.strategy = this.order.post_cutoff_funds_return_strategy
    },
    async confirm() {
      if (this.loading) {
        return false
      }

      this.loading = true

      try {
        const data = {
          post_cutoff_funds_return_strategy: this.strategy,
        }
        await this.wrapSubmit(
          this['orders/update']({ orderId: this.order.id, userId: this.user.id, data })
        )
        this.successMessage('Funds return strategy updated')
        this.close()
      } catch (e) {
        this.errorMessage('Error occurred')
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$modal.hide('funds-return-strategy-modal')
    },
  },
}
</script>
