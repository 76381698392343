import { schema } from 'normalizr'
import products from '~/store/schema/products'
import _ from 'lodash'

const processStrategy = cart => {
  const relations = {
    itemsAsProduct: _.get(cart, 'items.data'),
  }

  return { ...cart, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'carts',
  {
    itemsAsProduct: [products],
  },
  {
    processStrategy,
  }
)
