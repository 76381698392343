import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import { DOMAIN, BASE_ACCOUNT_URL } from '../../config'

export const state = {
  user: null,
}

export const getters = {
  user: state => state.user,
  check: state => state.user !== null,
}

export const mutations = {
  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user
  },
  [types.LOGOUT](state) {
    state.user = null
  },
}

export const actions = {
  impersonate({ commit }, userId) {
    return axios.post(`auth/impersonation/users/${userId}`).then(() => {
      Cookies.set('impersonation_mode', '1', {
        domain: `.${DOMAIN}`,
        secure: true,
        sameSite: 'strict',
      })
      window.location.href = BASE_ACCOUNT_URL
    })
  },
  async fetchUser({ commit }) {
    const { data } = await axios.get('api/me')
    commit(types.FETCH_USER_SUCCESS, { user: data })
  },
  async logout({ commit }) {
    try {
      await axios.delete('auth')
    } catch (e) {}

    commit(types.LOGOUT)
  },
}
