<template>
  <modal
    name="unlock-post-cutoff-changes-modal"
    @before-open="beforeOpen"
    height="auto"
    :adaptive="true"
    classes="w-full bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
  >
    <div>
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-teal-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Are you sure you want to unlock post cutoff changes for the order?
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Customer will be able to make order modifications until
                {{ order.post_cutoff_changes_deadline_at }}.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click.prevent="confirm"
            type="button"
            class="focus:shadow-outline-teal inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none sm:text-sm sm:leading-5"
          >
            Yes
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click.prevent="close"
            type="button"
            class="focus:shadow-outline inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
        <span v-if="firstError" class="text-sm text-orange-600">
          {{ firstError }}
        </span>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['order', 'user'],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['orders/unlockPostCutoffChanges']),
    beforeOpen() {},
    async confirm() {
      if (this.loading) {
        return false
      }

      this.loading = true

      try {
        await this.wrapSubmit(
          this['orders/unlockPostCutoffChanges']({ orderId: this.order.id, userId: this.user.id })
        )
        this.successMessage('Order has been unlocked for post cutoff changes.')
        this.close()
      } catch (e) {
        this.errorMessage('Error occurred')
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$modal.hide('unlock-post-cutoff-changes-modal')
    },
    validate() {
      this.resetErrors()

      if (!this.form.reason.reason_id) {
        this.errors.reasons = ['Please select a skip reason.']
        return false
      }

      if (this.selectedReason.answer_required && !this.form.reason.details) {
        this.errors.reasons = ['Please provide a response to: ' + this.selectedReason.question]
        return false
      }

      return true
    },
  },
}
</script>
