<template>
  <div v-if="subscription">
    <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
      <h4 class="mb-5 font-bold">Subscription Pausing</h4>
      <div v-if="canBePaused">
        <a
          href="#"
          class="rounded-sm bg-white px-2 py-1 text-sm uppercase tracking-wide text-pink-500 shadow hover:bg-pink-300 hover:text-pink-800"
          :class="{ disabled: loading }"
          @click.prevent="showModal"
        >
          <i v-if="loading" class="fa fa-spin fa-spinner" />
          <small>Pause {{ user.first_name }} {{ user.last_name }} account</small>
        </a>
      </div>

      <div v-if="!isPaused && !canBePaused">
        This account can not be paused as user status is '{{ subscription.status }}'. Only active
        users can be paused.
      </div>
      <div id="account-cancelled" v-if="isPaused">
        <p>
          Paused from
          <time class="font-semibold">{{ subscription.paused.from | date('MMMM Do YYYY') }}</time>
          until
          <time class="font-semibold">{{ subscription.paused.until | date('MMMM Do YYYY') }}</time>
        </p>
        <p v-if="reason" class="mt-2 border-l-2 border-teal-200 py-2 pl-4 italic">
          {{ reason.title }}
        </p>
        <p v-if="subscription.paused.details">({{ subscription.paused.details }})</p>

        <div class="mt-4">
          <span v-if="loading">Unpausing...</span>
          <button
            v-else
            class="w-full rounded-sm bg-white px-2 py-1 tracking-wide text-teal-700 shadow hover:bg-teal-600 hover:text-white focus:bg-teal-600 focus:text-white"
            @click.prevent="unpause"
          >
            Unpause
          </button>
        </div>
      </div>
      <pause-subscription-modal :user="user" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PauseSubscriptionModal from '~/components/users/PauseSubscriptionModal'
import { get } from 'lodash'

export default {
  props: ['user'],
  components: {
    PauseSubscriptionModal,
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this['reasons/fetchPauseReasons']()
  },
  computed: {
    ...mapGetters(['subscriptions/byId', 'reasons/byId']),
    subscription() {
      return this['subscriptions/byId'](this.user.subscription)
    },
    isPaused() {
      return this.subscription && this.subscription.status === 'paused'
    },
    canBePaused() {
      return !this.isPaused && this.subscription.status !== 'cancelled'
    },
    reason() {
      return this.isPaused ? this['reasons/byId'](this.subscription.paused.reason_id) : null
    },
  },
  methods: {
    ...mapActions(['reasons/fetchPauseReasons', 'subscriptions/unpause']),
    showModal() {
      this.$modal.show('pause-subscription')
    },
    unpause() {
      if (this.loading) return false
      this.loading = true

      this['subscriptions/unpause'](this.user.id)
        .then(() => this.successMessage('Success'))
        .catch(() => this.errorMessage('Error occurred =('))
        .then(() => (this.loading = false))
    },
  },
}
</script>
