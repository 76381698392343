<template>
  <div v-if="subscription">
    <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
      <h4 class="mb-5 font-bold">Subscription Cancellation</h4>
      <div v-if="canBeCancelled">
        <a
          href="#"
          class="rounded-sm bg-white px-2 py-1 text-sm uppercase tracking-wide text-pink-500 shadow hover:bg-pink-300 hover:text-pink-800"
          :class="{ disabled: loading }"
          @click.prevent="showModal"
        >
          <i v-if="loading" class="fa fa-spin fa-spinner" />
          <small>Cancel account</small>
        </a>
      </div>

      <div v-if="!isCancelled && !canBeCancelled">
        This account can not be cancelled as user status is '{{ subscription.status }}'. Only active
        users can be cancelled.
      </div>
      <div id="account-cancelled" v-if="isCancelled">
        <p>
          Cancelled on
          <time class="font-semibold text-gray-700" datetime="subscription.cancelled_at">{{
            subscription.cancelled_at | date('dddd, MMMM Do YYYY')
          }}</time>
        </p>
        <div v-if="reason" class="mt-2">Reason: {{ reason.title }}</div>
        <p v-if="details" class="mt-2 border-l-2 border-teal-300 py-2 pl-4 italic">{{ details }}</p>
        <div class="mt-5">
          <button
            href="#"
            class="w-full rounded-sm bg-white px-2 py-1 tracking-wide text-teal-700 shadow hover:bg-teal-600 hover:text-white focus:bg-teal-600 focus:text-white"
            :class="{ disabled: loading }"
            @click.prevent="activate"
          >
            <i v-if="loading" class="fa fa-spin fa-spinner" />
            <small>Activate account</small>
          </button>
        </div>
      </div>
      <cancel-subscription-modal :user="user" />
    </div>
  </div>
</template>

<script>
import CancelSubscriptionModal from './CalcelSubscriptionModal'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['user'],
  components: {
    CancelSubscriptionModal,
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this['reasons/fetchCancelReasons']()
  },
  computed: {
    ...mapGetters(['subscriptions/byId', 'reasons/byId']),
    subscription() {
      return this['subscriptions/byId'](this.user.subscription)
    },
    isCancelled() {
      return this.subscription.status === 'cancelled'
    },
    canBeCancelled() {
      return !this.isCancelled
    },
    reasonId() {
      const ids = Object.keys(this.subscription.cancellation_details)
      return ids && ids[0]
    },
    reason() {
      return this['reasons/byId'](+this.reasonId)
    },
    details() {
      return (
        this.subscription.cancellation_details &&
        this.subscription.cancellation_details[this.reasonId]
      )
    },
  },
  methods: {
    ...mapActions(['subscriptions/cancel', 'subscriptions/unpause', 'reasons/fetchCancelReasons']),
    showModal() {
      this.$modal.show('cancel-subscription')
    },
    activate() {
      this['subscriptions/unpause'](this.user.id)
    },
    cancel() {
      this['subscriptions/cancel'](this.user.id)
    },
  },
}
</script>
