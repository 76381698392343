<template>
  <div>
    <div class="overflow-hidden rounded bg-white shadow-sm">
      <h1 class="border-b border-gray-200 px-4 py-2 text-xl font-bold lg:px-8 lg:py-4">
        {{ formTitle }}
      </h1>

      <!-- BASIC INFO -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3">
          <span class="font-semibold tracking-wide">Basic Info</span>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div>
            <div>
              <label class="mb-1 text-base tracking-wide">Order Index</label>
            </div>
            <div>
              <input
                class="form-input w-full"
                type="text"
                v-model="form.order_index"
                :readonly="!!skipOffer"
                placeholder=""
              />
              <p class="mt-1 text-sm text-gray-600">
                The order index cannot be edited once the skip week is created. This represents the index number of the
                order for a customer since they registered. <br> <br>First order is Order Index 1, Second order is Order
                Index 2, etc.
              </p>
              <error :error="getError('order_index')"/>
            </div>
          </div>
          <div class="mt-6">
            <label class="inline-flex items-center">
              <input type="checkbox" v-model="form.enabled" class="form-checkbox h-5 w-5"/>
              <span class="ml-2 text-sm text-gray-800">Enabled</span>
            </label>
            <error :error="getError('enabled')"/>
          </div>
        </div>
      </div>

      <!-- LIMITERS -->
      <div class="mt-10 border-b border-gray-200 px-4 pb-10 sm:flex lg:px-8">
        <div class="sm:w-1/3 sm:pr-10">
          <div class="font-semibold tracking-wide">Application Limiters</div>
          <div class="mt-4 text-sm text-gray-600">
            Specify any criteria that should be met in order to qualify for skip offer.
          </div>
        </div>
        <div class="mt-6 sm:mt-0 sm:w-2/3">
          <div class="mt-10">
            <div>
              <label class="mb-1 text-base tracking-wide">Only Specific Emails</label>
            </div>
            <div class="mt-1">
              <textarea v-model="form.whitelisted_emails" class="form-textarea w-full"></textarea>
              <p class="text-sm text-gray-600">One email per line.</p>
              <error :error="getError('whitelisted_emails')"/>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end bg-gray-100 py-6 px-4">
        <NLButton
          @click="() => onSave(false)"
          class="w-full rounded bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
          :isLoading="submitting"
        >
          Create
        </NLButton>
        <NLButton
          @click="() => onSave(true)"
          class="w-full rounded bg-teal-600 px-3 py-2 ml-2 text-sm font-semibold text-white shadow-sm sm:w-auto"
          :isLoading="submitting"
        >
          Create & New
        </NLButton>
      </div>
    </div>
  </div>
</template>

<script>
import NLButton from '~/components/global/input/Button'
import _ from 'lodash'
import HasErrors from '~/mixins/HasErrors'

export default {
  mixins: [HasErrors],
  props: ['skipOffer', 'save'],
  components: {NLButton},
  data() {
    return {
      form: {
        order_index: null,
        enabled: null,
        whitelisted_emails: null,
      },
      isLoading: false,
    }
  },
  async mounted() {
  },
  watch: {
    skipOffer() {
      this.populateForm()
    },
  },
  computed: {
    preparedData() {
      const data = _.assign({}, this.form)
      if (this.form.whitelisted_emails) {
        data.whitelisted_emails = _.filter(this.form.whitelisted_emails.split('\n')).join(',')
      }
      return data
    },
    formTitle() {
      return this.skipOffer ? 'Manage Skip Week' : 'New Skip week'
    },
  },
  methods: {
    onSave(redirectToCreate = false) {
      this.wrapSubmit(this.save(this.preparedData, redirectToCreate)).catch(() => this.errorMessage('Error Occurred'))
      if (redirectToCreate){
        this.form = {
          order_index: null,
          enabled: null,
          whitelisted_emails: null,
        }
      }
    },
    populateForm() {
      this.form = {
        order_index: this.skipOffer.order_index,
        enabled: this.skipOffer.enabled,
        whitelisted_emails:
          this.skipOffer.whitelisted_emails && this.skipOffer.whitelisted_emails.join('\n'),

      }
    },
  },
}
</script>
