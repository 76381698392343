<template>
  <div class="mb-6 overflow-hidden rounded-lg bg-white shadow">
    <div class="border-b border-gray-300 bg-white py-3">
      <h4 class="px-4 text-base font-semibold">Production Cycle</h4>
    </div>

    <div class="px-4 pb-6 pt-2 sm:pt-6">
      <div v-show="hasProductionCycle" class="flex flex-wrap items-center justify-around">
        <button
          @click.prevent="setProductionCycle(cycle)"
          v-for="cycle in productionCycles"
          type="button"
          class="mt-4 block w-full rounded-lg border-2 bg-white px-6 py-4 shadow-lg hover:border-teal-500"
          :class="{ 'border-teal-500': cycle.id == profile.production_cycle_id }"
        >
          <div class="flex items-center justify-between">
            <span class="text-sm tracking-wide text-gray-700">{{ cycle.name }}</span>
            <span
              :class="{ hidden: cycle.id != profile.production_cycle_id }"
              class="ml-5 inline-block rounded-full bg-teal-200 p-1"
            >
              <svg
                class="h-3 w-3 stroke-current text-teal-800"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
          </div>
          <div class="mt-4 text-left">
            <span class="font-semibold">{{ cycle.order_cutoff_day }}</span>
            <span class="text-sm font-semibold">Cutoff</span>
          </div>
          <div class="mt-1 truncate text-left text-sm text-gray-700">
            ships on <span v-for="day in cycle.shipDays">{{ day }} </span>
          </div>
        </button>
      </div>
      <div v-show="!hasProductionCycle">customer doesn't have a production cycle.</div>
    </div>
  </div>
</template>

<script>
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['user'],
  mounted() {
    this['production-cycles/fetch']()
  },
  computed: {
    ...mapGetters(['production-cycles/all', 'profiles/byId']),
    profile() {
      return this['profiles/byId'](this.user.profile)
    },
    hasProductionCycle() {
      return this.profile.production_cycle_id
    },
    productionCycles() {
      return this['production-cycles/all']
    },
  },
  methods: {
    ...mapActions(['users/fetchById', 'production-cycles/fetch', 'profiles/updateProductionCycle']),
    setProductionCycle(cycle) {
      if (cycle.id == this.profile.production_cycle_id) {
        return
      }
      Confirm.fire({
        text: `This action will place user on the ${cycle.name} as well as all their addresses will be associated with this production cycle and any future order ship/delivery dates will reset. Proceed?`,
        preConfirm: () => {
          return this['profiles/updateProductionCycle']({
            id: this.user.id,
            params: { production_cycle_id: cycle.id },
          }).catch(error => Swal.showValidationMessage(`Request failed: ${error}`))
        },
      }).then(result => {
        result.value && this.successMessage('Production cycle has been changed!')
        const include = [
          'activePromoCodeApplication',
          'compedAccount',
          'promoCodeApplications',
          'defaultAddress',
          'kids',
          'profile',
        ]
        this['users/fetchById']({ id: this.user.id, params: { include } })
      })
    },
  },
}
</script>
