<template>
  <div class="text-left" :class="{
    'grid grid-cols-3 bg-gray-50 rounded p-4 gap-3': horizontal
  }">
    <div :class="{
      'space-y-2': horizontal
    }">
      <span class="text-sm text-gray-600">User prefers:</span>
      <div>
        <div v-for="item in profile.preferences.data" class="text-sm text-gray-700">
          {{ item.quantity }} items of {{ item.category_name }}
        </div>
      </div>
    </div>

    <div v-if="hasTags" :class="{
      'mt-2 flex flex-wrap items-center space-x-2': !horizontal,
      'space-y-2': horizontal
    }">
      <span class="text-sm text-gray-600">Tags:</span>
      <div :class="{
        'flex space-x-2': !horizontal,
        'grid grid-cols-1 gap-1': horizontal
      }">
        <span v-for="tag in tags" :key="tag.id"
          class="inline-block rounded-sm border border-blue-200 bg-blue-100 px-1 text-xs tracking-wider text-blue-700">
            {{ tag.name }}
          </span>
      </div>
    </div>

    <div v-if="hasIndicators" :class="{
      'mt-2 flex items-start space-x-2': !horizontal,
      'space-y-2': horizontal
    }">
      <span class="text-sm text-gray-600">Indicators:</span>
      <div :class="{
        'grid auto-cols-fr grid-flow-col-dense gap-2': !horizontal,
        'grid grid-cols-1 gap-1': horizontal
      }">
        <span v-for="indicator in indicators" :key="indicator.id"
          class="rounded-sm border border-blue-200 bg-blue-100 px-1 text-xs tracking-wider text-blue-700">
            {{ indicator.name }}
        </span
        >
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  props: {
    profileId: {
      type: Number,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['profiles/byId', 'tags/byIds', 'indicators/byIds']),
    profile() {
      return this['profiles/byId'](this.profileId)
    },
    tags() {
      return this['tags/byIds'](this.tagIds)
    },
    indicators() {
      return this['indicators/byIds'](this.indicatorIds)
    },
    tagIds() {
      return _.flatten(this.profile.kids.data.map(i => i.filters.tags))
    },
    indicatorIds() {
      return _.flatten(this.profile.kids.data.map(i => i.filters.indicators))
    },
    hasTags() {
      return this.tagIds.length > 0
    },
    hasIndicators() {
      return this.indicatorIds.length > 0
    },
  },
  mounted() {},
}
</script>
