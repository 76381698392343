import Error from './_error'
import { flatten, map } from 'lodash'
export default {
  components: {
    Error,
  },
  data: () => ({
    submitting: false,
    errors: {},
  }),
  computed: {
    firstError() {
      return flatten(this.errors[Object.keys(this.errors)[0]])[0]
    },
    allErrors() {
      return flatten(map(this.errors, errors => flatten(errors)))
    },
  },
  methods: {
    hasError(name) {
      const error = this.errors[name]
      return error && error.length > 0
    },
    getError(name) {
      const error = this.errors[name]
      return this.hasError(name) ? error[0] : null
    },
    setError(name, message) {
      this.errors[name] = [message]
    },
    resetErrors() {
      this.errors = {}
    },
    resetError(name) {
      delete this.errors[name]
    },
    wrapSubmit(request) {
      this.resetErrors()
      this.submitting = true
      return request
        .then(res => {
          this.submitting = false
          return res
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors
          }
          this.submitting = false
          throw err
        })
    },
  },
}
