const PACKAGE_TYPE_TRIPLE_TRAY = 'large-triple-tray'
const PACKAGE_TYPE_LARGE_DOUBLE_TRAY = 'large-double-tray'
const PACKAGE_TYPE_LARGE_SINGLE_TRAY = 'large-single-tray'
const PACKAGE_TYPE_SMALL_TRIPLE_TRAY = 'small-triple-tray'
const PACKAGE_TYPE_SMALL_DOUBLE_TRAY = 'small-double-tray'
const PACKAGE_TYPE_SMALL_SINGLE_TRAY = 'small-single-tray'
const PACKAGE_TYPE_HALF_PUREE_JAR = 'half-puree-jar'
const PACKAGE_TYPE_PUREE_JAR = 'puree-jar'
const PACKAGE_TYPE_SMOOTHIE_BOTTLE = 'smoothie-bottle'

export const packageItems = [
  { slug: PACKAGE_TYPE_TRIPLE_TRAY, label: 'Large triple compartment tray' },
  { slug: PACKAGE_TYPE_LARGE_DOUBLE_TRAY, label: 'Large double compartment tray' },
  { slug: PACKAGE_TYPE_LARGE_SINGLE_TRAY, label: 'Large single compartment tray' },
  { slug: PACKAGE_TYPE_SMALL_TRIPLE_TRAY, label: 'Small triple compartment tray' },
  { slug: PACKAGE_TYPE_SMALL_DOUBLE_TRAY, label: 'Small double compartment tray' },
  { slug: PACKAGE_TYPE_SMALL_SINGLE_TRAY, label: 'Small single compartment tray' },
  { slug: PACKAGE_TYPE_HALF_PUREE_JAR, label: '4oz Jar' },
  { slug: PACKAGE_TYPE_PUREE_JAR, label: '8oz Jar' },
  { slug: PACKAGE_TYPE_SMOOTHIE_BOTTLE, label: '8 oz. Bottle' },
]
