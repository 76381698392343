<template>
  <div class="mt-12 h-full grow overflow-auto bg-gray-200 px-4 pb-10">
    <button class="mb-6 text-gray-700 underline hover:text-gray-800">
      <router-link :to="{ name: 'promo-codes' }"
        ><i class="fas fa-long-arrow-alt-left mr-2" />Back To Promo Codes</router-link
      >
    </button>

    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">
          Mass Apply Promo Code
        </h2>
      </div>
    </div>

    <!-- Content -->
    <div class="mt-8 overflow-hidden rounded-lg bg-white p-4 shadow lg:px-8">
      <form @submit.prevent="submit">
        <div class="mt-4" :class="{ 'has-error': hasError('promoCode') }">
          <label class="block">
            <span class="text-gray-700">Promo Code</span>
            <div><small class="text-gray-500">Please, specify active promo code.</small></div>
            <input type="text" class="form-input mt-1 block" v-model="promoCode" />
          </label>
          <error :error="getError('promoCode')" />
        </div>

        <div class="mt-4" :class="{ 'has-error': hasError('override') }">
          <label class="mt-2 inline-flex items-center space-x-2">
            <input v-model="form.override" class="form-checkbox h-5 w-5 border" type="checkbox" />
            <span>Override</span>
          </label>
          <div>
            <small class="text-gray-500"
              >If checked, this will override any existing user promo code application.</small
            >
          </div>
          <error :error="getError('override')" />
        </div>

        <div class="mt-4" :class="{ 'has-error': hasError('emails') }">
          <label class="block">
            <span class="text-gray-700">Emails</span>
            <div><small class="text-gray-500">Comma separated list of email addresses</small></div>
            <textarea class="form-input mt-1 block w-full" v-model="form.emails" rows="20">
            </textarea>
          </label>
          <error :error="getError('emails')" />
        </div>

        <div>
          <small
            >Please, make sure you are applying the promo code to the correct list of emails. This
            action can not be reverted.</small
          >
        </div>

        <div class="mt-4 text-right">
          <button
            type="submit"
            class="rounded-sm bg-teal-500 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none"
          >
            <i v-if="busy" class="fa fa-spin fa-spinner" /> Submit
          </button>
        </div>
      </form>

      <div v-if="Object.keys(results).length > 0">
        RESULTS:

        <ul>
          <li
            v-for="(message, email) in results"
            :key="email"
            :class="{ 'text-red-500': !isEmailProcessed(message) }"
          >
            <strong>{{ email }}</strong> -
            <template v-if="!isEmailProcessed(message)">{{ message }}</template>
            <template v-else>Email processed successfully</template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import HasErrors from '@/mixins/HasErrors'
import { mapActions } from 'vuex'

export default {
  mixins: [HasErrors],
  data: () => ({
    promoCode: '',
    form: {
      emails: '',
      override: false,
    },
    results: {},
    busy: false,
  }),
  methods: {
    ...mapActions({
      apply: 'promo-code-applications/massApply',
    }),
    isEmailProcessed(message) {
      return message === true
    },
    submit() {
      if (this.busy) {
        return
      }

      this.busy = true
      this.results = {}
      this.apply({ promoCode: this.promoCode, ...this.form })
        .then(data => {
          this.results = data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.setError('promoCode', 'Promo code is not found.')
            return
          }
          this.errors = error.response.data.errors

          for (const errorProp in this.errors) {
            if (errorProp.indexOf('emails') > -1) {
              this.errors.emails = [errorProp + ': ' + this.errors[errorProp]]
              break
            }
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
  },
}
</script>
