<script>
import Abstract from '~/components/global/Autocompletes/Abstract'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [Abstract],
  data: () => ({
    label: 'name',
  }),
  computed: {
    ...mapGetters({
      getItem: 'meal-components/byId',
    }),
  },
  methods: {
    ...mapActions({
      fetch: 'meal-components/fetch',
    }),
  },
}
</script>
