<template>
  <div>
    <div>
      <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
        <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Banners</h3>
        <router-link
              :to="{ name: 'edit-sale-banner', params: { id: 'new' } }"
              class="px-4 py-2 text-gray-700 hover:text-teal-500"
            >
          <span class="btn btn-sm btn-green">
            <i class="fas fa-plus mr-2" /> New Banner
          </span>
        </router-link>
      </div>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div class="overflow-hidden bg-white shadow sm:rounded-md mb-10">
            <draggable
                tag="ul"
                v-model="bannersDraggable"
                :disabled="!draggableEnabled"
                class="divide-y divide-gray-200"
              >
            <li v-for="banner in banners" v-bind:key="banner.id" class="flex items-center bg-white">
                <div class="flex items-center justify-center pl-4 hover:cursor-move">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="w-4 h-4" v-if="draggableEnabled">
                      <path d="M145.6 7.7C141 2.8 134.7 0 128 0s-13 2.8-17.6 7.7l-104 112c-6.5 7-8.2 17.2-4.4 25.9S14.5 160 24 160H80V352H24c-9.5 0-18.2 5.7-22 14.4s-2.1 18.9 4.4 25.9l104 112c4.5 4.9 10.9 7.7 17.6 7.7s13-2.8 17.6-7.7l104-112c6.5-7 8.2-17.2 4.4-25.9s-12.5-14.4-22-14.4H176V160h56c9.5 0 18.2-5.7 22-14.4s2.1-18.9-4.4-25.9l-104-112z"/>
                    </svg>
                    <span class="w-4 h-4" v-if="!draggableEnabled"></span>
                </div>
                <router-link
                  :to="{ name: 'edit-sale-banner', params: { id: banner.id } }"
                  class="flex items-center justify-between px-4 py-2 text-gray-700 hover:text-teal-500 flex-grow"
                >
                  <div class="pr-6 space-y-1">
                    <div class="mb-1 text-black">{{ banner.title }}</div>
                    <div class="space-x-4">
                      <span class="bg-gray-500 rounded uppercase p-1 text-white text-xs w-20 inline-block">{{ slugToText(banner.type) }}</span>
                      <span class="bg-gray-500 rounded uppercase p-1 text-white text-xs w-32 inline-block">Created {{ banner.created_at | date }}</span>
                      <span class="rounded uppercase p-1 text-white text-xs w-44 inline-block opacity-80"
                        v-bind:class="{
                          'bg-red-500': banner.status == 'disabled',
                          'bg-green-500': banner.status != 'disabled'
                        }"
                      >
                        {{ slugToText(banner.status) }}
                      </span>
                    </div>
                    <p class="text-sm text-gray-700">{{ banner.help }}</p>
                  </div>
                  <div class="ml-auto">
                    <svg
                      class="h-6 w-6 stroke-current lg:h-8 lg:w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </router-link>
            </li>
            </draggable>
        </div>
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import IndexPage from "@/mixins/IndexPage";
import HasErrors from '~/mixins/HasErrors'

export default {
  mixins: [
    IndexPage,
    HasErrors
  ],
  components: {
  },
  data: () => ({
    options: {},
    filters: {
      status: 'all'
    }
  }),
  mounted() {
    this.fetchItems()
  },
  computed: {
    ...mapGetters({
      bannersFromStore: 'sale-banners/all'
    }),
    banners() {
      return this.bannersFromStore
        .filter(banner => this.filters.status == 'all' || this.filters.status == banner.status)
    },
    availableStatuses() {
      return _.chain(this.bannersFromStore)
        .groupBy('status')
        .keysIn()
        .value()
    },
    draggableEnabled() {
      return this.filters.status == 'all';
    },
    bannersDraggable: {
        get() {
          return this.banners
        },
        async set(value) {
          try {
            await this.wrapSubmit(this.reorder(value))
            this.successMessage('New order saved')
          } catch (e) {
            this.errorMessage('Error occurred while saving new order')
          }
        }
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'sale-banners/fetch',
      reorder: 'sale-banners/reorder'
    }),
    slugToText(text) {
      return text && text.length ? text.replace(/_|-/g, ' ') : text;
    }
  }
}
</script>
