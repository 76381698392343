<template>
  <div class="">
    <div class="mt-6 flex items-center">
      <label class="w-1/2"> Production Cycle: </label>
      <div class="w-2/3">
        <select
          class="h-12 w-full border border-gray-400 bg-white"
          v-model="reportedProductionCycle"
        >
          <option v-for="cycle in cycles" :key="cycle.id" :value="cycle">
            {{ cycle.name }}: {{ cycle.cutoff_date | date }}
          </option>
        </select>
      </div>
    </div>

    <div class="mt-6 flex items-center">
      <label class="w-1/2"> Report: </label>
      <div class="w-2/3">
        <select class="h-12 w-full border border-gray-400 bg-white" v-model="report">
          <option v-for="(report, index) in reports" :key="index" :value="report">
            {{ report.name }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="canGetReport" class="mt-8 flex justify-end">
      <button class="rounded bg-green-500 px-3 py-1 text-white" @click.prevent="getReport">
        Get the report
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    orderWeek: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    reports: [
      { name: 'Cheftec Production Report', route: 'cheftec-production' },
      { name: 'Cheftec Sales Report', route: 'cheftec-sales' },
      { name: 'Shipping Report', route: 'shipping' },
    ],
    submitting: false,
    reportedProductionCycle: null,
    report: null,
  }),

  computed: {
    cycles() {
      return this.orderWeek.productionCycles ? this.orderWeek.productionCycles : []
    },
    canGetReport() {
      return this.orderWeek && this.report
    },
  },

  methods: {
    getReport() {
      if (this.submitting) return false

      const params = {
        order_week_id: this.orderWeek.id,
        production_cycle_id: this.reportedProductionCycle ? this.reportedProductionCycle.id : null,
      }

      this.submitting = true

      axios
        .get(`api/reporting/${this.report.route}`, { params })
        .then(() => this.successAlert('The report has been successfully sent'))
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
        .finally(() => (this.submitting = false))
    },
  },

  filters: {
    typeOfCycle(string) {
      return string.replace('Production Cycle', '').trim()
    },
  },
}
</script>
