<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex border-b border-solid border-gray-400 py-4">
      <h3 class="text-3xl">Reports</h3>
    </div>

    <div class="bg-white px-6 py-8">
      <h4>Build Your Report:</h4>
      <div class="mt-6 flex items-center">
        <label class="w-1/2"> Order Week: </label>
        <div class="w-2/3">
          <select class="h-12 w-full border border-gray-400 bg-white" v-model="reported.orderWeek">
            <option :value="orderWeek" v-for="orderWeek in orderWeeks">
              {{ orderWeek.number }} ({{ orderWeek.status }})
            </option>
          </select>
        </div>
      </div>
      <order-week-item v-if="reported.orderWeek" :order-week="reported.orderWeek" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import OrderWeekItem from '~/components/reports/Item'

export default {
  components: {
    OrderWeekItem,
  },

  data: () => ({
    ids: [],
    reported: {
      orderWeek: null,
    },
  }),

  computed: {
    ...mapGetters({
      orderWeekById: 'order-weeks/byId',
    }),
    orderWeeks() {
      return _.orderBy(
        _.map(this.ids, id => this.orderWeekById(id)),
        'id',
        'desc'
      )
    },
  },

  methods: {
    ...mapActions({
      fetchWeeks: 'order-weeks/fetch',
      fetchActiveOrderWeeks: 'order-weeks/fetchActive',
      fetchDeliveredOrderWeeks: 'order-weeks/fetchDelivered',
    }),
    loadWeeks() {
      let params = {
        include: 'productionCycles',
      }
      this.fetchWeeks({ params }).then(data => (this.ids = _.map(data.data, item => item.id)))
    },
  },

  mounted() {
    this.loadWeeks()
  },
}
</script>
