<script>
export default {
  name: 'AriaLoader',
  props: {
    backgroundColorClass: {
      type: String,
      default: 'white',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    v-if="loading"
    class="area-loader absolute top-0 left-0 h-full w-full opacity-75"
    :class="`bg-${backgroundColorClass}`"
  >
    <div class="flex h-full w-full items-center justify-center">
      <div class="lds-ring lds-ring-md">
        <div v-for="i in 3" :key="i" />
      </div>
    </div>
  </div>
</template>
