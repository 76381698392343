import Vue from 'vue'
import * as _ from 'lodash'
import Tabs from './Tabs'
import Pagination from './Pagination'
import Search from './Search'
import ButtonLoader from './ButtonLoader'
import Loader from './Loader'
import EmptyState from './EmptyState'
import CustomSelect from './CustomSelect'
import ProductsAutocomplete from './Autocompletes/Products'
import PromoCodesAutocomplete from './Autocompletes/PromoCodes'
import VueClipboard from 'vue-clipboard2'
import VModal from 'vue-js-modal'
import Impersonate from './Impersonate'
import InputGroup from './input/Group'
import CheckboxInput from './input/CheckboxInput'
import RadioboxInput from './input/RadioboxInput'
import PanelSection from './UI/Panel'
import PanelHeader from './UI/PanelHeader'
import NLButton from './input/Button'
import FormSection from './UI/form/FormSection'
import TextInput from './input/TextInput'
import TextAreaInput from './input/TextAreaInput'
import SelectInput from './input/SelectInput'

import Draggable from 'vuedraggable'
import Datepicker from 'v-calendar/lib/components/date-picker.umd'

Vue.use(VModal)
Vue.use(VueClipboard)

_.forEach(
  {
    Datepicker,
    Draggable,
    Tabs,
    Pagination,
    Search,
    ButtonLoader,
    Loader,
    EmptyState,
    CustomSelect,
    ProductsAutocomplete,
    PromoCodesAutocomplete,
    Impersonate,
    InputGroup,
    PanelSection,
    PanelHeader,
    CheckboxInput,
    RadioboxInput,
    NLButton,
    FormSection,
    TextInput,
    TextAreaInput,
    SelectInput,
  },
  (component, name) => Vue.component(name, component)
)
