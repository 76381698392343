import { schema } from 'normalizr'
import creditTransactions from './credit-transactions'
import _ from 'lodash'

const processStrategy = creditAccount => {
  const relations = {
    balance: +creditAccount.balance,
    transactions: _.has(creditAccount, 'transactions')
      ? _.map(creditAccount.transactions.data, transaction => ({
          ...transaction,
          creditAccount: creditAccount.id,
        }))
      : null,
  }
  return { ...creditAccount, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'credit-accounts',
  {
    transactions: [creditTransactions],
  },
  {
    processStrategy,
  }
)
