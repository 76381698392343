import { schema } from 'normalizr'
import carts from '~/store/schema/carts'
import addresses from '~/store/schema/addresses'
import postCutoffActivities from '~/store/schema/post-cutoff-activities'
import _ from 'lodash'

const processStrategy = order => {
  const relations = {
    cart: _.get(order, 'cart.data'),
    address: _.get(order, 'address.data'),
    postCutoffActivities: _.get(order, 'post_cutoff_activities.data'),
  }

  return { ...order, ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'orders',
  {
    cart: carts,
    address: addresses,
    postCutoffActivities: [postCutoffActivities],
  },
  {
    processStrategy,
  }
)
