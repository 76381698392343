<template>
  <div class="flex space-x-12">
    <div class="relative mr-3 w-full lg:w-1/2">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          class="h-5 w-5 stroke-current text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </div>
      <input
        v-model="search"
        class="mr-3 rounded-md bg-white p-3 pl-10"
        :class="inputClasses"
        :placeholder="placeholder"
        @keypress.enter="input"
      />
    </div>
    <button :class="buttonClasses" @click.prevent="input">Search</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Find',
    },
    inputClasses: {
      type: Object,
      default: null,
    },
    buttonClasses: {
      type: Object,
      default: () => ({
        'bg-green-500 hover:bg-green-700 rounded-md py-3 px-6 text-white transition duration-300': true,
      }),
    },
  },

  data: () => ({
    search: null,
  }),

  watch: {
    value() {
      this.clear()
    },
  },

  mounted() {
    this.clear()
  },

  methods: {
    input() {
      this.$emit('input', this.search)
    },
    clear() {
      this.search = this.value
    },
  },
}
</script>
