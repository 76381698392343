import axios from 'axios'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities, resolveIdAndParams } from '../helpers'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  fetch({ commit }, params) {
    return axios.get('api/shipping/zip-codes', { params }).then(res => {
      processEntities(res.data.data, 'zipCodes')
      return res.data
    })
  },
  fetchById({ commit }, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`api/shipping/zip-codes/${id}`, { params }).then(res => {
      processEntities(res.data.data, 'zipCodes')
      return res.data
    })
  },
  create({ commit }, params) {
    return axios.post('api/shipping/zip-codes', params).then(res => {
      processEntities(res.data.data, 'zipCodes')
      return res.data
    })
  },
  update({ commit }, params) {
    return axios.put(`api/shipping/zip-codes/${params.id}`, params).then(res => {
      processEntities(res.data.data, 'zipCodes')
      return res.data
    })
  },
  disable({ commit }, id) {
    return axios.delete(`api/shipping/zip-codes/${id}/activity`).then(res => {
      processEntities(res.data.data, 'zipCodes')
      return res.data
    })
  },
}
