<template>
  <div v-if="profile" class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <div class="flex justify-between">
      <div>
        <h4 class="mb-4 font-bold">User Kids</h4>
        <p v-if="kids_same_meals" class="text-green-600 text-sm font-semibold">This user's kids share the same meals</p>
      </div>
      <div class="flex flex-row space-x-4">
        <p class="text-sm">Share meals</p>
        <ToggleInput v-model="kids_same_meals" @input="change" />
      </div>
    </div>
    <table class="mt-4 w-full">
      <thead>
        <tr class="border-b-2 border-gray-400 font-bold">
          <th class="border border-gray-400 px-4 py-2">Kid</th>
          <th class="border border-gray-400 px-4 py-2">Quantity/Preferred Category</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(kid, index) in kids" :key="kid.id">
          <td class="px-4 py-2">
            {{ kid.name || `Kid ${index+1}` }}
          </td>
          <td class="px-4 py-2">
            <span class="block" v-for="category in kid.categories" v-if="category.quantity" :key="category.id">
              {{ category.quantity }}x {{ category.category_name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import ToggleInput from '~/components/global/ToggleInput'

export default {
  props: ['user'],
  components: {
    ToggleInput
  },
  data() {
    return {
      kids_same_meals: false,
    }
  },
  mounted() {
    this.kids_same_meals = this.profile.kids_same_meals
  },
  computed: {
    ...mapGetters([
      'kids/byIds',
      'age-groups/byId',
      'categories/byId',
      'profiles/byId',
    ]),
    kids() {
      const theKids = this.user.kids && this['kids/byIds'](this.user.kids)
      if (!theKids) {
        return []
      }

      return theKids.sort((a, b) => a.id - b.id)
    },
    profile() {
      return this['profiles/byId'](this.user.profile)
    }
  },
  methods: {
    enable() {
      axios.post('/api/sales/admin/users/{userId}/kids-same-meals'.replace('{userId}', this.user.id))
        .then(res => this.successMessage(res.data.message))
        .catch(err => this.errorMessage(err.response.data.message))
    },
    disable() {
      axios.delete('/api/sales/admin/users/{userId}/kids-same-meals'.replace('{userId}', this.user.id))
        .then(res => this.successMessage(res.data.message))
        .catch(err => this.errorMessage(err.response.data.message))
    },
    change(toNewValue) {
      toNewValue === true ? this.enable() : this.disable()
    }
  },
}
</script>
