<template>
  <span
    class="rounded-full px-2 py-1 text-center text-xs font-bold uppercase tracking-wide"
    :class="[bgClass, textClass]"
  >
    {{ name }}
  </span>
</template>

<script>
export default {
  props: ['name'],
  computed: {
    bgClass() {
      switch (this.color) {
        case 'teal':
          return 'bg-teal-200'
        case 'gray':
          return 'bg-gray-200'
        case 'pink':
          return 'bg-pink-200'
        case 'orange':
          return 'bg-orange-200'
        case 'red':
          return 'bg-red-500'
        default:
          return 'bg-gray-200'
      }
    },
    textClass() {
      switch (this.color) {
        case 'teal':
          return 'text-teal-800'
        case 'gray':
          return 'text-gray-800'
        case 'pink':
          return 'text-pink-800'
        case 'orange':
          return 'text-orange-800'
        case 'red':
          return 'text-red-100'
        default:
          return 'text-gray-800'
      }
    },
    color() {
      switch (this.name) {
        case 'Active':
          return 'teal'
        case 'Admin':
          return 'gray'
        case 'Inactive':
          return 'pink'
        case 'Suspended':
          return 'orange'
        case 'Banned':
          return 'red'
        default:
          return 'gray'
      }
    },
  },
}
</script>
