import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities, storeEntities } from '../helpers'
import { fetch as fetchOrderWeeks } from '../../services/api/order-weeks'

export const state = {
  items: [],
}

export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
  byProductRotationId: state => id => _.filter(state.items, item => item.meal_rotation_id === id),
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}

export const actions = {
  async fetch(context, payload) {
    const response = await fetchOrderWeeks(payload.params)
    processEntities(response.data, 'orderWeeks')
    return response
  },
  fetchAvailable() {
    return axios.get('/api/sales/admin/order-weeks', { params: { notInRotation: 1 } }).then(res => {
      const rows = _.map(res.data.data, row => _.assign(row, { meal_rotation_id: null }))
      processEntities(rows, 'orderWeeks')
      return res.data
    })
  },
  fetchActive() {
    return axios
      .get('/api/sales/admin/order-weeks', {
        params: { status: ['active'], include: 'productionCycles' },
      })
      .then(res => {
        processEntities(res.data.data, 'orderWeeks')
        return res.data
      })
  },
  fetchDelivered() {
    return axios
      .get('/api/sales/admin/order-weeks', {
        params: { status: ['delivered'], include: 'productionCycles' },
      })
      .then(res => {
        processEntities(res.data.data, 'orderWeeks')
        return res.data
      })
  },
  create(store) {
    return axios.post('api/sales/admin/order-weeks').then(res => {
      processEntities(res.data.data, 'orderWeeks')
      return res.data
    })
  },
  activate(store, orderWeek) {
    return axios.post(`api/sales/admin/order-weeks/${orderWeek.id}/active`).then(res => {
      processEntities(Object.assign({}, orderWeek, { status: ['active'] }), 'orderWeeks')
      return res.data
    })
  },
}
