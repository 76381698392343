<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-5">
    <div>
      <!-- Button Back -->
      <button class="mb-6 text-gray-700 underline hover:text-gray-800">
        <router-link :to="{ name: 'users' }">
          <i class="fas fa-long-arrow-alt-left mr-2" />Back To All Users</router-link
        >
      </button>

      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9"
          >
            Create New User
          </h2>
        </div>
      </div>
      <!-- Content -->
      <div class="mt-8">
        <form
          class="overflow-hidden rounded-lg bg-white p-4 shadow lg:px-8"
          @submit.prevent="submit"
        >
          <div class="border-b border-gray-300 bg-white py-3">
            <h4 class="text-base font-semibold">User Info</h4>
          </div>
          <div class="mt-6 w-full lg:w-2/3">
            <InputGroup label="User Type" :error="getError('form.role')">
              <select class="form-select w-full" v-model="form.role">
                <option value="comped">Comped Account</option>
                <option value="admin">Admin</option>
              </select>
            </InputGroup>
          </div>
          <div class="mt-4 lg:grid lg:grid-cols-2 lg:gap-8">
            <div :class="{ 'has-error': hasError('account.first_name') }">
              <label class="block">
                <span class="text-gray-700">First Name</span>
                <input
                  type="text"
                  class="form-input mt-1 block w-full"
                  v-model="form.account.first_name"
                />
              </label>
              <error :error="getError('account.first_name')" />
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('account.last_name') }">
              <label class="block">
                <span>Last Name</span>
                <input
                  type="text"
                  class="form-input mt-1 block w-full"
                  v-model="form.account.last_name"
                />
              </label>
              <error :error="getError('account.last_name')" />
            </div>
          </div>
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mt-4" :class="{ 'has-error': hasError('account.email') }">
              <label>
                <span>Email</span>
                <input
                  type="text"
                  class="form-input mt-1 block w-full"
                  v-model="form.account.email"
                />
              </label>
              <error :error="getError('account.email')" />
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('account.password') }">
              <label>
                <span>Password</span>
                <input
                  type="password"
                  class="form-input mt-1 block w-full"
                  v-model="form.account.password"
                />
              </label>
              <error :error="getError('account.password')" />
            </div>
          </div>
          <div class="mt-4" :class="{ 'has-error': hasError('with_welcome_email') }">
            <label class="mt-2 inline-flex items-center space-x-2">
              <input
                v-model="form.with_welcome_email"
                class="form-checkbox h-5 w-5 border"
                type="checkbox"
              />
              <span>Send Welcome Email</span>
            </label>
            <error :error="getError('with_welcome_email')" />
          </div>

          <div v-if="isShippingSectionVisible">
            <div class="border-b border-gray-300 bg-white pb-3 pt-10">
              <h4 class="text-base font-semibold">Shipping Details</h4>
            </div>
            <div class="mt-4 lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mt-4" :class="{ 'has-error': hasError('shipping.line_1') }">
                <label>
                  <span>Address Line #1</span>
                  <input
                    type="text"
                    class="form-input mt-1 block w-full"
                    v-model="form.shipping.line_1"
                  />
                </label>
                <error :error="getError('shipping.line_1')" />
              </div>
              <div class="mt-4" :class="{ 'has-error': hasError('shipping.line_2') }">
                <label>
                  <span>Address Line #2</span>
                  <input
                    type="text"
                    class="form-input mt-1 block w-full"
                    v-model="form.shipping.line_2"
                  />
                </label>
                <error :error="getError('shipping.line_2')" />
              </div>
            </div>
            <div class="mt-4 grid grid-cols-3 gap-6">
              <div :class="{ 'has-error': hasError('shipping.city') }">
                <label>
                  <span>City</span>
                  <input type="text" class="form-input block w-full" v-model="form.shipping.city" />
                </label>
                <error :error="getError('shipping.city')" />
              </div>
              <div :class="{ 'has-error': hasError('shipping.state_id') }">
                <div>State</div>
                <select v-model="form.shipping.state_id" class="form-select w-full">
                  <option v-for="state in states" :key="state.id" :value="state.id">
                    {{ state.id }}
                  </option>
                </select>
                <error :error="getError('shipping.state_id')" />
              </div>
              <div :class="{ 'has-error': hasError('shipping.zip_code') }">
                <label>
                  <span>Zip Code</span>
                  <input
                    type="text"
                    class="form-input block w-full"
                    v-model="form.shipping.zip_code"
                  />
                </label>
                <error :error="getError('shipping.zip_code')" />
              </div>
            </div>
            <div class="mt-4 text-right">
              <div v-if="deliveryDate">
                <span class="text-sm text-gray-700"
                  >First Delivery date: <span class="font-bold">{{ deliveryDate | date }}</span>
                </span>
              </div>
              <span class="text-sm text-gray-700" v-if="!deliveryDate && deliveryQuote.fetched">
                No delivery quote available for zip code
                <span class="font-bold">{{ shipping.zip_code }}</span>
              </span>
            </div>
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mt-4" :class="{ 'has-error': hasError('shipping.phone') }">
                <label>
                  <span>Phone</span>
                  <input
                    type="text"
                    class="form-input mt-1 block w-full"
                    v-model="form.shipping.phone"
                  />
                </label>
                <error :error="getError('shipping.phone')" />
              </div>
              <div class="mt-4" :class="{ 'has-error': hasError('shipping.type') }">
                <span>Address Type</span>
                <div class="space-x-6">
                  <label>
                    <input
                      class="form-radio"
                      type="radio"
                      value="personal"
                      v-model="form.shipping.type"
                    />
                    Home/Personal
                  </label>
                  <label>
                    <input
                      class="form-radio"
                      type="radio"
                      value="business"
                      v-model="form.shipping.type"
                    />
                    Business
                  </label>
                </div>
                <error :error="getError('shipping.type')" />
              </div>
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('shipping.delivery_instructions') }">
              <label>
                <span>Delivery Instructions</span>
                <textarea
                  class="form-input mt-1 block w-full"
                  v-model="form.shipping.delivery_instructions"
                ></textarea>
              </label>
              <error :error="getError('shipping.delivery_instructions')" />
            </div>
          </div>

          <div v-if="isCompedShippingVisisble">
            <div class="border-b border-gray-300 bg-white pb-3 pt-10">
              <h4 class="text-base font-semibold">Comped Settings</h4>
            </div>
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mt-4" :class="{ 'has-error': hasError('comped.amount') }">
                <label>
                  <span>Comped Amount</span>
                  <input
                    type="text"
                    class="form-input mt-1 block w-full"
                    v-model="form.comped.amount"
                  />
                  <span class="text-xs text-gray-600"
                    >Weekly complemented amount. Leave blank for unlimited amount</span
                  >
                </label>
                <error :error="getError('comped.amount')" />
              </div>
              <div class="mt-4" :class="{ 'has-error': hasError('comped.number_of_weeks') }">
                <label>
                  <span>Comped Weeks</span>
                  <input
                    type="text"
                    class="form-input mt-1 block w-full"
                    v-model="form.comped.number_of_weeks"
                  />
                  <span class="text-xs leading-6 text-gray-600"
                    >How many order weeks user will get complemented. Leave blank for forever
                    comped</span
                  >
                </label>
                <error :error="getError('comped.number_of_weeks')" />
              </div>
            </div>
          </div>

          <div v-if="isSubscriptionSectionVisible">
            <div class="border-b border-gray-300 bg-white pb-3 pt-10">
              <h4 class="text-base font-semibold">Subscription</h4>
            </div>
            <div class="lg:flex lg:items-end lg:gap-6">
              <div class="mt-4 flex items-center gap-2">
                <div class="lg:w- flex w-5/6 flex-col">
                  <label>Select Category</label>
                  <select v-model="newCategory.category_id" class="form-select mt-1 block w-full">
                    <option
                      :value="category.id"
                      v-for="category in selectableCategories"
                      :key="category.id"
                      class="capitalize"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="flex w-1/6 flex-col">
                  <label>Quantity</label>
                  <input
                    v-model="newCategory.quantity"
                    class="form-input mt-1 block w-full"
                    placeholder="6"
                  />
                </div>
              </div>
              <div class="mt-2 flex justify-end">
                <NLButton @click="addCategory" class="px-2 py-1 lg:px-3">Add</NLButton>
              </div>
              <error :error="getError('subscription')" />
            </div>
            <ul class="mt-3 overflow-hidden rounded-sm shadow-sm">
              <li
                v-for="category in form.subscription"
                :key="category.category_id"
                class="bg-teal-100 bg-opacity-75 py-2 px-2"
              >
                <div class="relative pr-8">
                  <div class="absolute inset-y-0 right-0 flex w-8 items-center justify-center">
                    <button
                      type="button"
                      class="rotate-45 transform text-2xl text-red-500 transition hover:text-red-600"
                      @click.prevent="removeCategory(category.category_id)"
                    >
                      +
                    </button>
                  </div>
                  <span class="capitalize">{{ categoryById(category.category_id).name }}</span> —
                  {{ category.quantity }}
                </div>
              </li>
            </ul>
          </div>

          <div class="mt-4 text-right">
            <NLButton type="submit" class="px-3 py-1" :isLoading="submitting"> Save User </NLButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { get, debounce, cloneDeep, omit } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import HasErrors from '~/mixins/HasErrors'
import NLButton from '~/components/global/input/Button'

export default {
  components: { NLButton },
  mixins: [HasErrors],
  created() {
    this['states/fetch']()
    this['categories/fetch']({})
  },
  data: () => ({
    submitting: false,
    form: {
      role: 'comped',
      with_welcome_email: false,
      account: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
      },
      shipping: {
        ship_day: null,
        line_1: null,
        line_2: null,
        city: null,
        state_id: null,
        zip_code: null,
        phone: null,
        delivery_instructions: null,
        type: 'personal',
      },
      comped: {
        amount: null,
        number_of_weeks: null,
      },
      subscription: [],
    },
    newCategory: {
      category_id: null,
      quantity: null,
    },
    deliveryQuote: {
      deliveryDate: null,
      fetched: false,
    },
  }),
  computed: {
    ...mapGetters({
      states: 'states/all',
      allCategories: 'categories/all',
      categoryById: 'categories/byId',
    }),
    isShippingSectionVisible() {
      return this.form.role === 'comped'
    },
    isCompedShippingVisisble() {
      return this.form.role === 'comped'
    },
    isSubscriptionSectionVisible() {
      return this.form.role === 'comped'
    },
    deliveryDate() {
      return get(this.deliveryQuote, 'deliveryDate', null)
    },
    selectableCategories() {
      return this.allCategories.filter(category => {
        return !this.form.subscription.find(item => item.category_id === category.id)
      })
    },
  },
  methods: {
    ...mapActions(['users/create', 'states/fetch', 'categories/fetch']),
    async submit() {
      try {
        const response = await this.wrapSubmit(this['users/create'](this.prepareForm()))
        this.successMessage('User Created!')
        this.$router.push({ name: 'edit-user', params: { id: response.data.data.id } })
      } catch (e) {
        this.errorMessage('Error Occurred!')
      }
    },
    prepareForm() {
      if (this.form.role === 'admin') {
        const params = omit(cloneDeep(this.form), ['shipping', 'comped', 'subscription'])
        return { params }
      }
      return { params: this.form }
    },
    async fetchDeliveryQuotes(zipCode) {
      try {
        const {
          data: { data: deliveryQuote },
        } = await axios.get('/api/sales/delivery-quotes', {
          params: { zip_code: zipCode, subscription: 1 },
        })
        this.deliveryQuote = {
          deliveryDate: deliveryQuote.delivery_date,
          fetched: true,
        }
      } catch (e) {
        this.deliveryQuote.fetched = true
      }
    },
    addCategory() {
      if (!this.newCategory.category_id || !this.newCategory.quantity) {
        return
      }
      this.form.subscription.push(this.newCategory)
      this.newCategory = { category_id: null, quantity: null }
    },
    removeCategory(categoryId) {
      this.form.subscription.splice(
        this.form.subscription.indexOf(i => item.category_id === categoryId),
        1
      )
    },
  },
  watch: {
    'shipping.zip_code': debounce(function (zipCode) {
      this.deliveryQuote.fetched = false
      this.deliveryQuote.deliveryDate = null

      if (zipCode.trim().length > 0) {
        this.fetchDeliveryQuotes(zipCode)
      }
    }, 333),
  },
}
</script>
