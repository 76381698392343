<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex items-end justify-between">
      <router-link :to="{ name: 'order-bumps' }">
        <button class="btn btn-gray self-start">
          <i class="fas fa-long-arrow-alt-left mr-2" />
          Back To List
        </button>
      </router-link>
    </div>

    <h3 class="py-4 text-3xl">Create Order Bump</h3>

    <order-bump-form v-model="form" @submit="submit" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import OrderBumpForm from '~/components/orderBumps/Form'

export default {
  components: {
    OrderBumpForm,
  },

  data: () => ({
    form: {},
  }),

  methods: {
    ...mapActions({
      create: 'order-bumps/create',
    }),
    submit(wrapSubmit) {
      return wrapSubmit(this.submitRequest(this.form.data))
    },
    async submitRequest(data) {
      let created

      try {
        created = await this.create(data)
      } catch (exception) {
        this.errorMessage('Error', this.$get(exception, 'response.data.message'))
        throw exception
      }

      this.successMessage('Order Bump Created!')
      await this.$router.push({ name: 'edit-order-bump', params: { id: created.data.id } })

      return created
    },
  },
}
</script>
