<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex justify-between border-b border-solid border-gray-400 py-4">
      <h3 class="text-3xl">ZIP Codes</h3>

      <div class="flex">
      </div>
    </div>

    <div class="flex justify-between py-4">
      <search v-model="options.code" @input="onSearch" placeholder="Find zip code" />

      <div class="flex">
        <router-link
          class="mr-3 mt-3 block font-semibold hover:text-teal-500 hover:underline"
          :to="{name: 'zip-codes-import'}"
        >
          Import
        </router-link>
        <button class="btn btn-gray" @click.prevent="toggleInactive">
          <i class="fas fa-eye mr-2" />
          <span v-if="!options.inactive">Show Inactive ZIP Codes</span>
          <span v-else>Hide Inactive ZIP Codes</span>
        </button>
      </div>
    </div>

    <div v-if="loaded">
      <div v-if="zipCodes.length">
        <table class="w-full table-auto bg-white">
          <thead>
            <tr class="border-b-2 font-bold">
              <th class="border px-4 py-2">ZIP Code</th>
              <th class="border px-4 py-2">Zone</th>
              <th class="border px-4 py-2">State /Time&nbsp;Zone</th>
              <th class="border px-4 py-2 whitespace-nowrap w-full">
                Shipping rule<br>
                <div class="grid md:grid-cols-6 grid-cols-1 text-left">
                  <span class="col-span-2">Carrier</span>
                  <span>PC</span>
                  <span class="col-span-2">Day</span>
                  <span>Transit</span>
                </div>
              </th>
              <th class="border px-4 py-2">Default PC</th>
              <th class="border px-4 py-2">Act</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="zipCode in zipCodes" :key="zipCode.id" class="text-center">
              <td class="border px-4 py-2 text-center">{{ zipCode.id }}</td>
              <td class="border px-4 py-2">{{ zipCode.zone }}</td>
              <td class="border px-4 py-2">{{ zipCode.state }} /{{ zipCode.time_zone }}</td>
              <td class="border px-4 py-2">
                  <div v-for="rule in zipCode.shippingRules" :key="rule.id" class="grid md:grid-cols-6 grid-cols-1 text-left">
                    <span class="col-span-2">{{ rule.carrier }}</span>
                    <span>{{ rule.production_cycle }}</span>
                    <span class="col-span-2">
                      {{ rule.ship_day_name }} <i v-if="rule.is_default" class="fas fa-check text-green-400" />
                    </span>
                    <span>{{ rule.days_in_transit }} days</span>
                  </div>
              </td>
              <td class="border px-4 py-2"></td>
              <td class="w-48 border px-4 py-2">
                <div class="flex-end mb-1 flex justify-end">
                  <a
                    v-if="zipCode.is_active"
                    href="#"
                    class="icon-link icon-gray"
                    @click.prevent="disable(zipCode.id)"
                  >
                    <i class="fas fa-times" />
                    <div class="tooltip">Disable</div>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-5 flex justify-end">
          <pagination
            :value="options.page"
            :per-page="options.per_page"
            :total="total"
            @input="onPageChange"
          />
        </div>
      </div>

      <empty-state v-else />
    </div>
    <loader v-else />
  </div>
</template>

<script>
import IndexPage from '~/mixins/IndexPage'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import Confirm from '~/components/swal/Confirm'
import Swal from 'sweetalert2'

export default {
  mixins: [IndexPage],

  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 10,
      code: null,
      inactive: false,
    },
  }),

  computed: {
    ...mapGetters({
      zipCodeById: 'zip-codes/byId',
    }),
    zipCodes() {
      return _.map(this.ids, id => this.zipCodeById(id))
    },
  },

  methods: {
    ...mapActions({
      fetchItems: 'zip-codes/fetch',
      disableZipCode: 'zip-codes/disable',
    }),
    toggleInactive() {
      this.options.inactive = this.options.inactive ? 0 : 1
      this.loadPage()
    },
    disable(id) {
      Confirm.fire({
        text: `Are you sure you wish to disable the Zip Code #${id}?`,
        preConfirm: () => {
          return this.disableZipCode(id).catch(error => {
            const message =
              error.response && error.response.status === 422 ? error.response.data.message : error
            Swal.showValidationMessage(`Request failed: ${message}`)
          })
        },
      }).then(result => {
        if (result.value) {
          this.successMessage('Zip Code Disabled!')
          this.loadPage()
        }
      })
    },
  },
}
</script>
