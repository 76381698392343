<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-4 sm:mt-6">
    <div v-if="user" class="space-x-4 sm:flex sm:items-center justify-between">
      <button class="text-gray-700 underline hover:text-gray-800">
        <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
          <i class="fas fa-long-arrow-alt-left mr-2" />
          Back to {{ user.email }} details
        </router-link>
      </button>

      <router-link
        v-if="rogueOrder"
        target="_blank"
        :to="{ name: 'edit-buffer', params: { id: ongoingBufferId } }"
        class="inline-flex items-center space-x-2 hover:underline"
      >
        <svg
          class="h-4 w-4 text-current text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path
            fill-rule="evenodd"
            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>See the buffer</span>
      </router-link>
    </div>

    <div v-if="rogueOrder && !isLoading" class="bg-white">

      <PanelSection>
        <div class="border-b border-gray-300 bg-white py-3 px-4">
          <h4 class="text-base font-semibold">Create rogue order</h4>
        </div>

        <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6">
          <div class="font-semibold uppercase tracking-wider text-gray-600 mb-1">
            Order details:
          </div>
          <div>
            <span class="font-medium">{{rogueOrder.productionCycle.name}}</span>
            <span class="mx-2">&bull;</span>
            <span class="font-medium">
              {{rogueOrder.orderWeek.number}} (start: {{ rogueOrder.orderWeek.date }})
            </span>
            <span class="mx-2">&bull;</span>
            <span class="font-medium">Cutoff: {{rogueOrder.cutoff_date}}</span>
          </div>
        </div>

        <div class="mt-6">
          <h4 class="text-base font-semibold">Details</h4>
          <div class="mt-2">
            <div class="border-b-2 border-gray-400 border-opacity-50 pb-2">
              <p class="text-sm leading-5 text-gray-500">
                Please select the meals to generate the order with. Once created, order will be
                unlocked for post cutoff changes.
              </p>
            </div>
            <div v-if="products.length > 0" class="mt-2">
              <display-preferences :profileId="user.profile" />
              <fieldset class="mt-4">
                <div class="-space-y-px rounded-md bg-white">
                  <div v-for="product in products" :key="product.sku">
                    <div
                      class="relative flex rounded-tl-md rounded-tr-md border-2 border-gray-200 border-opacity-50 p-4"
                      :class="{ 'border-teal-400 bg-teal-100': isSelected(product.sku) }"
                    >
                      <div class="flex h-5 items-center">
                        <input
                          :id="`prodcut-sku-${product.sku}`"
                          v-on:change="toggleSelected(product.sku)"
                          :checked="isSelected(product.sku)"
                          type="checkbox"
                          class="form-checkbox h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out"
                        />
                      </div>
                      <div>
                        <label
                          :for="`prodcut-sku-${product.sku}`"
                          class="ml-3 flex cursor-pointer flex-col"
                        >
                          <span
                            class="block text-sm leading-5"
                            :class="{ 'text-teal-900': isSelected(product.sku) }"
                          >
                            {{ product.sku }} &bull; {{ product.buffer_quantity }} item{{
                              product.buffer_quantity == 1 ? '' : 's'
                            }}
                            available
                          </span>
                          <span
                            class="block text-sm leading-5 text-gray-500"
                            :class="{ 'text-teal-700': isSelected(product.sku) }"
                          >
                            {{ product.name }}
                          </span>
                        </label>
                        <selected-product-quantity-for-unskipping-post-cutoff
                          class="ml-3 mt-2"
                          v-if="isSelected(product.sku) && product.buffer_quantity > 1"
                          v-model="findSelected(product.sku).quantity"
                          :available="product.buffer_quantity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <InputGroup label="Promo code"
                help-text="If you'd want a promo code applied on the account"
                class="mt-4"
              >
                <input type="text" class="form-input w-full" v-model="form.promoCode" />
              </InputGroup>

              <button
                  @click.prevent="confirm"
                  :disabled="isSubmitting"
                  type="button"
                  class="w-36 mt-4 focus:shadow-outline-teal inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none sm:text-sm sm:leading-5"
                >
                  {{isSubmitting ? 'Just a moment' : 'Create Order'}}
                </button>
            </div>
            <p v-if="products.length == 0" class="text-sm leading-5 text-gray-500">
              There are no meals in the buffer
            </p>
          </div>
        </div>

      </PanelSection>
    </div>
    <div v-if="!rogueOrder && !isLoading" class="text-sm px-3 py-2">{{createRogueOrderMessage ?? 'No active post cutoff cycle found. We cant create a rogue order.'}}</div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { mapDataKeysToParent } from '@/utils/mapDataKeysToParent'
import DisplayPreferences from '@/components/post-cutoff/DisplayPreferences'
import SelectedProductQuantityForUnskippingPostCutoff from '@/components/post-cutoff/SelectedProductQuantityForUnskippingPostCutoff'
import HasErrors from '~/mixins/HasErrors'

export default {
  mixins: [HasErrors],
  components: {
    DisplayPreferences,
    SelectedProductQuantityForUnskippingPostCutoff
  },
  data() {
    return {
      rogueOrder: null,
      isLoading: false,
      isSubmitting: false,
      productSkus: [],
      form: {
        selectedProducts: [],
        promoCode: null,
      },
      createRogueOrderMessage: null,
      ongoingBufferId: null,
    }
  },
  async mounted() {
    await this['users/fetchById']({id: this.userId})
    await this.fetchCurrentlyOngoingBuffer()

    if (this.ongoingBuffer) {
      await this.fetchPostCutoffRogueOrder()

      this['products/fetchForUnskippingPostCutoff'](this.ongoingBuffer.id).then(
        data => (this.productSkus = _.map(data.data, el => el.sku))
      )
      this['tags/fetch']()
      this['indicators/fetch']()
    }
  },
  computed: {
    user() {
      return this['users/byId'](this.userId)
    },
    userId() {
      return +this.$route.params.id
    },
    products() {
      return _.map(this.productSkus, sku => this['products/bySKU'](sku))
    },
    ongoingBuffer() {
      return this.ongoingBufferId ? this['buffers/byId'](this.ongoingBufferId) : null
    },
    ...mapGetters([
      'users/byId',
      'orders/byId',
      'products/bySKU',
      'buffers/byId'
    ]),
  },
  methods: {
    async fetchPostCutoffRogueOrder() {
      this.isLoading = true
      try {
        const res = await this['orders/fetchEligibleRogueOrder']({userId: this.userId, bufferId: this.ongoingBufferId })
        this.rogueOrder = mapDataKeysToParent(res.data)
      } catch (e) {
        if(e.response.data.message) {
          this.createRogueOrderMessage = e.response.data.message
        }
      }
      this.isLoading = false
    },
    async confirm() {
      this.isSubmitting = true
      try {
        const res = await this.wrapSubmit(
          this['orders/createRogueOrder']({
            userId: this.user.id,
            bufferId: this.ongoingBuffer.id,
            payload: {
              items: this.form.selectedProducts,
              promo_code: this.form.promoCode
            }
          })
        )

        this.successMessage('Success. Redirecting to Post cutoff order..', res.data.message, 3000, () => this.$router.push({name: 'post-cutoff', params: { userId: this.user.id }}))
      } catch (err) {
        this.errors = err.response.data.errors ?? []
        this.errorMessage('The request failed', this.firstError ?? err.response.data.message ?? null, 10000)
      } finally {
        this.isSubmitting = false
      }
    },
    toggleSelected(sku) {
      if (this.isSelected(sku)) {
        this.form.selectedProducts.splice(
          this.form.selectedProducts.indexOf(this.findSelected(sku)),
          1
        )
      } else {
        this.form.selectedProducts.push({ sku, quantity: 1 })
      }
    },
    findSelected(sku) {
      return this.form.selectedProducts.find(item => item.sku == sku)
    },
    isSelected(sku) {
      return this.findSelected(sku)
    },
    async fetchCurrentlyOngoingBuffer() {
      try {
        const { data } = await this['buffers/fetchCurrentlyOngoingBuffer']()
        this.ongoingBufferId = data.id
      } catch (e) {}
    },
    ...mapActions([
      'orders/fetchEligibleRogueOrder',
      'orders/postCutoffUnskip',
      'products/fetchForUnskippingPostCutoff',
      'reasons/fetchSkipReasons',
      'tags/fetch',
      'indicators/fetch',
      'users/fetchById',
      'orders/createRogueOrder',
      'buffers/fetchCurrentlyOngoingBuffer'
    ]),
  }
}
</script>
