<template>
  <ul v-if="pagesCount > 1" class="list-reset flex justify-end rounded border bg-white">
    <li>
      <a
        href="#"
        class="text-blue block border-r px-3 py-2 hover:bg-teal-600 hover:text-white"
        :class="{ 'opacity-50': !canSelectPrev }"
        @click.prevent="prev"
      >
        Previous
      </a>
    </li>

    <li v-for="page in pages" :key="page">
      <a
        href="#"
        class="text-blue block border-r px-3 py-2 hover:bg-teal-600 hover:text-white"
        :class="{ 'border-teal-600 bg-teal-600 text-white': page === value }"
        @click.prevent="select(page)"
      >
        {{ page }}
      </a>
    </li>

    <li>
      <a
        href="#"
        class="text-blue block px-3 py-2 hover:bg-teal-600 hover:text-white"
        :class="{ 'opacity-50': !canSelectNext }"
        @click.prevent="next"
      >
        Next
      </a>
    </li>
  </ul>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    value: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    pagesCount() {
      return Math.ceil(this.total / this.perPage)
    },
    canSelectPrev() {
      return this.value > 1
    },
    canSelectNext() {
      return this.value < this.pagesCount
    },
    pages() {
      if (this.pagesCount < 6) return _.range(1, this.pagesCount + 1)

      return _.range(
        this.value > 2 ? this.value - 2 : 1,
        this.value + 3 < this.pagesCount ? this.value + 3 : this.pagesCount
      )
    },
  },

  methods: {
    select(page) {
      this.$emit('input', page)
    },
    next() {
      if (!this.canSelectNext) return false
      this.select(this.value + 1)
    },
    prev() {
      if (!this.canSelectPrev) return false
      this.select(this.value - 1)
    },
  },
}
</script>
