<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-5">
    <div v-if="category && !fetching">
      <!-- Button Back -->
      <button class="mb-6 text-gray-700 underline hover:text-gray-800">
        <router-link :to="{ name: 'categories' }">
          <i class="fas fa-long-arrow-alt-left mr-2" />Back to Categories
        </router-link>
      </button>

      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9"
          >
            {{ category.name }}
          </h2>
        </div>
      </div>

      <!-- Content -->
      <div class="mt-8 lg:flex">
        <div class="lg:mr-4 lg:w-2/3">
          <form
            action="#"
            class="overflow-hidden rounded-lg bg-white p-4 shadow"
            @submit.prevent="update"
          >
            <div class="border-b border-gray-300 bg-white py-3">
              <h4 class="text-base font-semibold">Edit category: {{ form.name }}</h4>
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('name') }">
              <label class="block">
                <span class="text-gray-700">Category Name</span>
                <input type="text" class="form-input mt-1 block w-full" v-model="form.name" />
              </label>
              <error :error="getError('name')" />
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('price') }">
              <label class="block">
                <span>Price</span>
                <input type="text" class="form-input mt-1 block w-full" v-model="form.price" />
                <i class="text-xs text-gray-600">(in cents)</i>
              </label>
              <error :error="getError('price')" />
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('premium_price') }">
              <label>
                <span>Premium Price</span>
                <input
                  type="text"
                  class="form-input mt-1 block w-full"
                  v-model="form.premium_price"
                />
                <i class="text-xs text-gray-600">(in cents)</i>
              </label>
              <error :error="getError('premium_price')" />
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('display_price') }">
              <label>
                <span>Display Price</span>
                <input
                  type="text"
                  class="form-input mt-1 block w-full"
                  v-model="form.display_price"
                />
                <i class="text-xs text-gray-600">(in cents)</i>
              </label>
              <error :error="getError('display_price')" />
            </div>
            <div class="mt-4" :class="{ 'has-error': hasError('description') }">
              <label>
                <span>Description</span>
                <textarea
                  type="text"
                  class="form-textarea mt-1 block w-full"
                  v-model="form.description"
                ></textarea>
              </label>
              <error :error="getError('description')" />
            </div>

            <div class="mt-4 text-right">
              <button
                type="submit"
                :class="{ disabled: updating }"
                class="rounded-sm bg-teal-500 px-3 py-2 text-xs font-semibold uppercase text-white shadow transition duration-300 hover:bg-teal-600 focus:bg-teal-600 focus:text-white focus:outline-none"
              >
                <span v-if="updating">Saving...</span>
                <span v-if="!updating">Save</span>
              </button>
            </div>
          </form>

          <div v-if="errors.length">
            <p v-for="(err, key) in errors" :key="key">{{err}}</p>
          </div>
        </div>
      </div>
    </div>
    <loader v-if="fetching" />
  </div>
</template>

<script>
import * as _ from 'lodash'
import schema from '~/store/schema.js'
import { normalize } from 'normalizr'
import { mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'

export default {
  components: {},
  mixins: [HasErrors],
  data: () => ({
    form: {
      name: null,
      price: null,
      premium_price: null,
      description: null,
      display_price: null,
    },
    category: null,
    fetching: false,
    updating: false,
    errors: []
  }),
  mounted() {
    this.fetching = true
    this.fetch(this.id)
      .then(results => {
        const categories = normalize([results], [schema['categories']]).entities.categories
        this.category = categories[Object.keys(categories)[0]]
        this.resetForm()
      })
      .finally(() => {
        this.fetching = false
      })
  },
  methods: {
    ...mapActions({
      updateCategory: 'categories/update',
      fetch: 'categories/fetchSingle',
    }),
    resetForm() {
      _.assign(this.form, _.pick(this.category, Object.keys(this.form)))
      this.form.price = this.category.price.original_price_in_cents
      this.form.premium_price = this.category.price.original_premium_price_in_cents
      this.form.display_price = this.category.price.display_price_in_cents
      this.errors = []
    },
    update() {
      this.updating = true
      this.errors = []
      this.updateCategory({ id: this.id, params: this.form }).then(() => {
        this.successMessage('Updated')
      }).catch(e => {
        this.errors = e.response.data?.errors
        this.errorMessage('Error', e.response.data?.message)
      }).finally(() => this.updating = false)
    },
  },
  computed: {
    id() {
      return +this.$route.params.id
    },
  },
}
</script>
