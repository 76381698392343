import { schema } from 'normalizr'
import _ from 'lodash'
import categories from './categories'

const processStrategy = kid => {
  const relations = {
    categories: _.has(kid, 'categories') ? kid.categories.data : null,
  }

  return { ..._.omit(kid, ['age_group_id']), ..._.pickBy(relations, _.identity) }
}

export default new schema.Entity(
  'kids',
  {
    category: categories,
  },
  {
    processStrategy,
  }
)
