<template>
  <div>
    <div class="flex items-center justify-between px-4 py-4 pt-10">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Upsells</h3>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div v-if="upsells.length" class="mb-10 space-y-4">
          <div v-for="upsell in upsells" :key="upsell.id" class="bg-white">
              <router-link
                :to="{ name: 'edit-upsell', params: { id: upsell.id } }"
                class="py-3 px-2 text font-semibold text-gray-900 block hover:text-green-600 rounded"
              >
                {{ upsell.name }}
              </router-link>
          </div>
        </div>
        <empty-state v-else />
      </div>
      <loader v-else />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IndexPage from '~/mixins/IndexPage'
export default {
  mixins: [IndexPage],
  components: {},
  data: () => ({
    options: {
      page: 1,
      sort: 'id',
      order: 'desc',
      per_page: 100,
    },
  }),
  computed: {
    ...mapGetters({
      itemById: 'upsells/byId',
    }),
    upsells() {
      return _.map(this.ids, id => this.itemById(id))
    },
  },
  methods: {
    ...mapActions({
      fetchUpsells: 'upsells/fetch',
    }),
    fetchItems() {
      const params = { include: ['products', 'category'] }
      return this.fetchUpsells(params)
    },
  },
}
</script>
